import { QETermChainCmp } from "../../../common/QETerm";
import { QEHelper, QEValueTree, QEValueWidget } from '../../../common/QEHelper';
import { tokenize_and_parse, GrammarType, findGrammarType } from '../../../common/QEGrammar';
import { SolverStepOutput } from '../../QESolver';
import { hasOwnProperty } from '../Utils';
import { GraphPoints, GraphSegment } from '../../../common/Widget/QEWidgetGraph';
import { QEWidgetGraph } from 'QEWidgetGraph'; // aliased in webpack.config.js

// verify "value1 cmp1 value2" AND "value1 cmp2 value2" are both true
function validateComparison(a, cmp, b) {
    switch (cmp) {
        case 'EQUAL': return a == b;
        case 'GREATER': return a > b;
        case 'GREATER_OR_EQUAL': return a >= b;
        case 'LESS': return a < b;
        case 'LESS_OR_EQUAL': return a <= b;
    }
}

export class GraphSolver {
	static graphLinearInequality1d(input_value: QEValueTree, options:{ graph_widget?: QEValueWidget }):any {
		if (input_value.type != "tree") return undefined;
		const root = input_value.value;
		const eq = root.children[0];

		if (!(eq instanceof QETermChainCmp)) {
			console.log("Invalid tree type. Expecting comparison CHAIN, not: ", eq);
			return undefined;
		} else if (eq.children.length != 3 && eq.children.length != 5) {
			console.log("Invalid comparison. Expecting simple comparison chain with 3 children, or compound comparison with 5, not: ", eq);
			return undefined;
		}

		const opposite_cmp_type = {
			EQUAL: "EQUAL",
			GREATER: "LESS",
			GREATER_OR_EQUAL: "LESS_OR_EQUAL",
			LESS: "GREATER",
			LESS_OR_EQUAL: "GREATER_OR_EQUAL",
		};

		const cmp_symbols = {
			EQUAL: "=",
			GREATER: ">",
			GREATER_OR_EQUAL: ">=",
			LESS: "<",
			LESS_OR_EQUAL: "<=",
		};

		// use passed graph widget to get graph dimensions
		const passed_graph_widget = options.graph_widget;
		if (!passed_graph_widget || !(passed_graph_widget.value instanceof QEWidgetGraph)) {
			console.log("graphLinearInequality1d called without graph_widget to operate on");
			return undefined;
		}

		// clone graph_widget
		const src_graph = passed_graph_widget.value;
		const graph_widget = new QEWidgetGraph([], src_graph.display_options, {});

		let desc = "<div>";
		desc += "When graphing an inequality on a number line, remember:";
		desc += "<ul>";
		desc += '<li>&lt;, &gt;, and &ne; are represented by an <span style="font-weith: bold;">open</span> circle</li>';
		desc += '<li>&le;, &ge;, and = are represented by a <span style="font-weith: bold;">filled</span> circle</li>';
		desc += "</ul>";
		desc += "</div>";

		// if chain has 5 children it's a compound inequality
		let values: GraphPoints[] | GraphSegment[];
		if (eq.children.length == 5) {
			// compound inequality
			// ensure inequality takes the form "value1 cmp1 x cmp2 value2"
			let l_comparator_type:GrammarType = eq.children[1].type;
			let r_comparator_type:GrammarType = eq.children[3].type;

			const lhs = eq.children[0];
			const mid = eq.children[2];
			const rhs = eq.children[4];

			if (mid.type != "VARIABLE") {
				console.log("Error, compound inequality with middle argument a non-variable.");
				return undefined;
			}

			// validate lhs and rhs are values
			let lhs_value = lhs.evaluate_to_float();
			let rhs_value = rhs.evaluate_to_float();

			if (isNaN(lhs_value) || isNaN(rhs_value)) {
				console.log("Error, compound inequality with NaN argument");
				return undefined;
			}

			if (!validateComparison(lhs_value, l_comparator_type, rhs_value) || !validateComparison(lhs_value, r_comparator_type, rhs_value)) {
				console.log("Error, compound inequality with mutually exclusive inequalities.");
				return undefined;
			}

			const cmp_point_types = {
				EQUAL: "closed",
				GREATER: "open",
				GREATER_OR_EQUAL: "closed",
				LESS: "open",
				LESS_OR_EQUAL: "closed",
			};

			// if l_comparator_type is GREATER or GREATER_OR_EQUAL, flip inequality
			if (l_comparator_type == "GREATER" || l_comparator_type == "GREATER_OR_EQUAL") {
				desc += "<br>Flip the inequality so lower values are on the left-hand side:<br>";
				let temp = l_comparator_type;
				l_comparator_type = findGrammarType(opposite_cmp_type[r_comparator_type]);
				r_comparator_type = findGrammarType(opposite_cmp_type[temp]);

				let temp2 = lhs_value;
				lhs_value = rhs_value;
				rhs_value = temp2;

				desc += eq.display() + " becomes: ";
				const flipped_string = lhs_value + cmp_symbols[l_comparator_type] + mid.value + cmp_symbols[r_comparator_type] + rhs_value;
				const new_eq = tokenize_and_parse(flipped_string);
				desc += new_eq.tree.display();
			}

			const start_state = cmp_point_types[l_comparator_type];
			const end_state = cmp_point_types[r_comparator_type];

			if (l_comparator_type == "EQUAL") {
				// special case: val1 = x = val2
				desc += "<br>Compound inequality, but the bounds are both equal";
				desc += '<br>The comparison symbol is <span style="font-weight: bold;">equal to</span>, so a filled circle is used:<br>';
				values = [{ x: rhs_value, y: 0, state: "closed" }];
                graph_widget.setSeries({ type: "points", values: values });
			} else {
				desc += "<br>A compound inequality is represented as a line segment.<br>";

				if (start_state == "closed") {
					desc += '<br>'+ mid.value +' is <span style="font-weight: bold;">greater than or equal to</span> the lower bound, so a <span style="font-weight: bold;">closed</span> point is used:<br>';
                    graph_widget.setSeries({ type: "points", values: [{ x: lhs_value, y: 0, state: "closed" }] });
				} else {
					desc += '<br>'+ mid.value +' is <span style="font-weight: bold;">greater than</span> the lower bound, so an <span style="font-weight: bold;">open</span> point is used:<br>';
					graph_widget.setSeries({ type: "points", values: [{ x: lhs_value, y: 0, state: "open" }] });
				}

				if (end_state == "closed") {
					desc += '<br>'+ mid.value +' is <span style="font-weight: bold;">less than or equal to</span> the upper bound, so a <span style="font-weight: bold;">closed</span> point is used:<br>';
				} else {
					desc += '<br>'+ mid.value +' is <span style="font-weight: bold;">less than</span> the upper bound, so an <span style="font-weight: bold;">open</span> point is used:<br>';
				}

				values = [{ x1: lhs_value, y1: 0, start_point: start_state, x2: rhs_value, y2: 0, end_point: end_state }];
			    graph_widget.setSeries({ type: "segments", values: values });
			}
		} else {
			// simple inequality. Get left and right side arguments and validate type
			const lhs = eq.children[0];
			const rhs = eq.children[2];
			let comparator_type = eq.children[1].type;

			let rhs_value = 0.0;
			if (lhs.type == "VARIABLE") {
				// "x cmp ..."
				if (rhs.type == "VARIABLE") {
					console.log("Error, too many variable arguments. Expected one.");
					return undefined;
				}

				// "x cmp value"
				rhs_value = rhs.evaluate_to_float();
			} else if (rhs.type == "VARIABLE") {
				// "... cmp x"                
				if ( findGrammarType(lhs.type) == "VARIABLE") {
					console.log("Error, too many variable arguments. Expected one.");
					return undefined;
				} else {
					// flip comparison to normalize inequality to "x cmp value" form
					rhs_value = lhs.evaluate_to_float();
					comparator_type = opposite_cmp_type[comparator_type];
				}
			} else {
				console.log("Error, no variable argument");
				return undefined;
			}

			// x_min and x_max, capped to graph area
			const x_min = parseFloat(graph_widget.x_min);
			const x_max = parseFloat(graph_widget.x_max);

			if (isNaN(rhs_value)) {
				console.log("Error, inequality with NaN argument");
				return undefined;
			}

			// define series
			if (comparator_type == "EQUAL") {
				desc += '<br>The comparison symbol is <span style="font-weight: bold;">equal to</span>, so a filled circle is used:<br>';
				values = [{ x: rhs_value, y: 0, state: "closed" }];
			    graph_widget.setSeries({ type: "points", values: values });
			} else if (comparator_type == "GREATER") {
				desc += '<br>The comparison symbol is <span style="font-weight: bold;">greater than</span>, so an open circle is used:<br>';
				graph_widget.setSeries({ type: "points", values: [{ x: rhs_value, y: 0, state: "open" }] });

				desc += '<br>x is <span style="font-weight: bold;">greater than</span> the lower bound, so we include all values in the positive direction.';
				values = [{ x1: rhs_value, y1: 0, start_point: "open", x2: x_max, y2: 0, end_point: "arrow" }];
				graph_widget.setSeries({ type: "segments", values: values });
			} else if (comparator_type == "GREATER_OR_EQUAL") {
				desc += '<br>The comparison symbol is <span style="font-weight: bold;">greater than or equal to</span>, so a filled circle is used:<br>';
				graph_widget.setSeries({ type: "points", values: [{ x: rhs_value, y: 0, state: "closed" }] });

				desc += '<br>x is <span style="font-weight: bold;">greater than or equal to</span> the lower bound, so we include all values in the positive direction.';
				values = [{ x1: rhs_value, y1: 0, start_point: "closed", x2: x_max, y2: 0, end_point: "arrow" }];
				graph_widget.setSeries({ type: "segments", values: values });
			} else if (comparator_type == "LESS") {
				desc += '<br>The comparison symbol is <span style="font-weight: bold;">less than</span>, so an open circle is used:<br>';
				graph_widget.setSeries({ type: "points", values: [{ x: rhs_value, y: 0, state: "open" }] });

				desc += '<br>x is <span style="font-weight: bold;">less than</span> the upper bound, so we include all values in the negative direction.';
				values = [{ x1: rhs_value, y1: 0, start_point: "open", x2: x_min, y2: 0, end_point: "arrow" }];
				graph_widget.setSeries({ type: "segments", values: values });
			} else if (comparator_type == "LESS_OR_EQUAL") {
				desc += '<br>The comparison symbol is <span style="font-weight: bold;">less than or equal to</span>, so a filled circle is used:<br>';
				graph_widget.setSeries({ type: "points", values: [{ x: rhs_value, y: 0, state: "closed" }] });

				desc += '<br>x is <span style="font-weight: bold;">less than or equal to</span> the upper bound, so we include all values in the negative direction.';
				values = [{ x1: rhs_value, y1: 0, start_point: "closed", x2: x_min, y2: 0, end_point: "arrow" }];
				graph_widget.setSeries({ type: "segments", values: values });
			}
		}

		// display the graph
		desc += graph_widget.display();

		return {
			desc: desc,
			value: values,
			type: "json",
		};
	}

	/**
	 * rotatePointAroundPoint	Rotates "input_point" clockwise around "reference_point" by "angle" and returns "output_point"
	 */
	static rotatePointAroundPoint(input_value, options) {
		if (input_value.type != "map") return undefined;
		var params = input_value.value;

		if (!params["ref_point"] || !params["input_point"] || !params["angle"]) {
			console.log("Error: called rotatePointAroundPoint without required params: ", params);
			return;
		}

		// validate ref_point
		var ref_point = params["ref_point"];
		if (
			ref_point.type != "json" ||
			ref_point.value.x === undefined ||
			ref_point.value.y === undefined
		) {
			return undefined;
		}
		ref_point = ref_point.value;

		// validate input_point
		var input_point = params["input_point"];
		if (
			input_point.type != "json" ||
			input_point.value.x === undefined ||
			input_point.value.y === undefined
		) {
			return undefined;
		}
		input_point = input_point.value;

		// validate angle
		var angle = params["angle"];
		if (
			angle.type != "tree" ||
			angle.value.children[0].type != "RATIONAL"
		) {
			return undefined;
		}
		angle = angle.value.children[0].evaluate_to_float();

		console.log(
			"ref_point, input_point, angle: ",
			ref_point,
			input_point,
			angle
		);

		// TODO: rotate input_point around ref_point by angle to create new point
		// TODO: return new point (json x, y)
		return undefined;
	}
}
