import { QEAssert } from "../../common/QEAssert.js";
import { QETerm } from "../../common/QETerm.ts";
import { findGrammar, tokenize_and_parse } from '../../common/QEGrammar';
import { QEQ, QEQInterval, QEQinf } from "../../common/QE";
import { QESolver, SolverStepOutput } from "QESolver";
import {
	comparisonResult,
	multiplicativeTermToList,
	listToMultiplicativeTerm,
	createDividedTerm,
	cancelIdenticalFracTerms,
	divideDecimalsInFracTerms,
	factorOutCommonFracFactors,
	parseToChainModeTerm,
	additiveChainToList,
	listToAdditiveChain,
	sortMultiplicativeTermList,
	sortAdditiveTermList,
} from "./SolverUtils";

import { Analyze } from "./Steps/Analyze";
import { Evaluate } from "./Steps/Evaluate";
import { Validate } from "./Steps/Validate";
import { Convert } from "./Steps/Convert";
import { Simplify } from "./Steps/Simplify";
import { GraphSolver } from "./Steps/GraphSolver";
import { Arithmetic } from "./Steps/Arithmetic";
import { Operation } from "./Steps/Operation";

export class QESolverStep {
	constructor() {}

	static characterizeExpression(input_value, options) {
		return Analyze.characterizeExpression(input_value, options);
	}

	static characterizeNumber(input_value, options) {
		return Analyze.characterizeNumber(input_value, options);
	}

	static characterizeTerm(input_value, options) {
		return Analyze.characterizeTerm(input_value, options);
	}

	static getPrimeFactorList(num) {
		return Evaluate.getPrimeFactorList(num);
	}

	static getPrimeFactorDecomposition(num) {
		return Evaluate.getPrimeFactorDecomposition(num);
	}

	static getFactorList(num) {
		return Evaluate.getFactorList(num);
	}

	static getGCF(input_value, options) {
		return Evaluate.getGCF(input_value, options);
	}

	static getFactors(input_value, options) {
		return Evaluate.getFactors(input_value, options);
	}

	// TODO: deprecate
	static getForwardsNumbers(input_value, options) {
		return Evaluate.getForwardsNumbers(input_value, options);
	}

	static getArithmeticSequence(input_value, options) {
		return Evaluate.getArithmeticSequence(input_value, options);
	}

	static getAdditionStatement(input_value, options) {
		return Evaluate.getAdditionStatement(input_value, options);
	}

	static getPrimeFactors(input_value, options) {
		return Evaluate.getPrimeFactors(input_value, options);
	}

	static getCommonFactors(input_value, options) {
		return Evaluate.getCommonFactors(input_value, options);
	}

	static getStandardMultiplication(input_value, options) {
		return Evaluate.getStandardMultiplication(input_value, options);
	}

	static getDividingUsingPlaceValue(input_value, options) {
		return Evaluate.getDividingUsingPlaceValue(input_value, options);
	}

	static getDividingUsingLongDivision(input_value, options) {
		return Evaluate.getDividingUsingLongDivision(input_value, options);
	}

	static getMultiples(input_value, options) {
		return Evaluate.getMultiples(input_value, options);
	}

	static get_LCM_by_GCF(input_value, options) {
		return Evaluate.get_LCM_by_GCF(input_value, options);
	}

	static getListMean(input_value, options) {
		return Evaluate.getListMean(input_value, options);
	}

	static getListMedian(input_value, options) {
		return Evaluate.getListMedian(input_value, options);
	}

	static getListMode(input_value, options) {
		return Evaluate.getListMode(input_value, options);
	}

	static getListRange(input_value, options) {
		return Evaluate.getListRange(input_value, options);
	}

	// eq type-checking helpers
	static validateTermIsNumber(term) {
		return Validate.validateTermIsNumber(term);
	}
	static validateTermIsComparator(term) {
		return Validate.validateTermIsComparator(term);
	}
	static validateEqIsNumericInequality(eq) {
		return Validate.validateEqIsNumericInequality(eq);
	}

	static convertDenominatorToCondenseWords(numString, denString) {
		return Convert.convertDenominatorToCondenseWords(numString, denString);
	}

	static convertDecimalToCondensedWords(numberString) {
		return Convert.convertDecimalToCondensedWords(numberString);
	}

	static convertIntNumberToWord(number) {
		return Convert.convertIntNumberToWord(number);
	}

	static convertThreeDigitsToWords(number, isOneOrdinal = false) {
		return Convert.convertThreeDigitsToWords(
			number,
			(isOneOrdinal = false)
		);
	}

	static convertFractionToWords(input_value, options) {
		return Convert.convertFractionToWords(input_value, options);
	}

	static convertNumberToWords(input_value, options) {
		return Convert.convertNumberToWords(input_value, options);
	}

	static convertDecimalToFraction(input_value, options) {
		return Convert.convertDecimalToFraction(input_value, options);
	}

	static convertDecimalToPercent(input_value, options) {
		return Convert.convertDecimalToPercent(input_value, options);
	}

	static convertFractionToDecimal(input_value, options) {
		return Convert.convertFractionToDecimal(input_value, options);
	}

	static convertFractionToPercent(input_value, options) {
		return Convert.convertFractionToPercent(input_value, options);
	}

	static convertPercentToFraction(input_value, options) {
		return Convert.convertPercentToFraction(input_value, options);
	}

	static convertPercentToDecimal(input_value, options) {
		return Convert.convertPercentToDecimal(input_value, options);
	}

	static convertImperialLengthUnit(input_value, options) {
		return Convert.convertImperialLengthUnit(input_value, options);
	}

	static convertMetricLengthUnit(input_value, options) {
		return Convert.convertMetricLengthUnit(input_value, options);
	}

	static convertToChains(input_value, options) {
		return Convert.convertToChains(input_value, options);
	}

	static convertFractionsToSameDenominator(input_value, options) {
		return Convert.convertFractionsToSameDenominator(input_value, options);
	}

	static convertMixedRadicalToEntire(input_value, options) {
		return Convert.convertMixedRadicalToEntire(input_value, options);
	}

	static convertRadicalToFractionalExponent(input_value, options) {
		return Convert.convertRadicalToFractionalExponent(input_value, options);
	}

	static convertFractionalExponentToRadical(input_value, options) {
		return Convert.convertFractionalExponentToRadical(input_value, options);
	}

	static convertWholeNumberToRoman(input_value, options?) {
		return Convert.convertWholeNumberToRoman(input_value, options);
	}

	static CT_simplifyComparisonFracToInts(input_value, options) {
		return Simplify.CT_simplifyComparisonFracToInts(input_value, options);
	}

	static simplifyComparisonDecimalsToDecimalFraction(input_value, options) {
		return Simplify.simplifyComparisonDecimalsToDecimalFraction(
			input_value,
			options
		);
	}

	static simplifyComparisonFractions(input_value, options) {
		return Simplify.simplifyComparisonFractions(input_value, options);
	}

	static simplifyDecimalFractions(input_value, options) {
		return Simplify.simplifyDecimalFractions(input_value, options);
	}

	static digitAtPlace(input_value, options): SolverStepOutput {
		return Analyze.digitAtPlace(input_value, options);
	}

	static roundNumberToNumPlaces(input_value, options): SolverStepOutput {
		return Convert.roundNumberToNumPlaces(input_value, options);
	}

	static isPrime(input_value, options) {
		return Validate.isPrime(input_value, options);
	}

	static primeOrComposite(input_value, options) {
		return Analyze.primeOrComposite(input_value, options);
	}

	static MakeRationalComparison(input_value, options) {
		return Evaluate.MakeRationalComparison(input_value, options);
	}

	static MakeFracComparison(input_value, options) {
		return Evaluate.MakeFracComparison(input_value, options);
	}

	static FracComparisonEvaluate(input_value, options?) {
		return Evaluate.FracComparisonEvaluate(input_value, options);
	}

	static graph_linear_inequality_1d(input_value, options) {
		return GraphSolver.graphLinearInequality1d(input_value, options);
	}

	static substituteVariableValues(input_value, options) {
		return Convert.substituteVariableValues(input_value, options);
	}

	static expandPowerToChain(input_value, options) {
		return Convert.expandPowerToChain(input_value, options);
	}

	static reduceChainToPower(input_value, options) {
		return Convert.reduceChainToPower(input_value, options);
	}

	static isDivisibleBy(input_value, options) {
		return Validate.isDivisibleBy(input_value, options);
	}

	static lookupTableValue(input_value, options) {
		return Analyze.lookupTableValue(input_value, options);
	}

	static rotatePointAroundPoint(input_value, options) {
		return GraphSolver.rotatePointAroundPoint(input_value, options);
	}

	static CT_removePlusSigns(input_value, options) {
		return Simplify.CT_removePlusSigns(input_value, options);
	}

	static CT_combineNestedMinusSigns(input_value, options) {
		return Simplify.CT_combineNestedMinusSigns(input_value, options);
	}

	static CT_mergeMinusWithTerms(input_value, options) {
		return Simplify.CT_mergeMinusWithTerms(input_value, options);
	}

	static CT_extractNegativeSign(input_value, options) {
		return Simplify.CT_extractNegativeSign(input_value, options);
	}

	static CT_combineNegativeSignsWithAddSubtract(input_value, options) {
		return Simplify.CT_combineNegativeSignsWithAddSubtract(input_value, options);
	}

	static CT_extractNegativeSignFromAddChain(input_value, options) {
		return Simplify.CT_extractNegativeSignFromAddChain(input_value, options);
	}

	static CT_extractNumericFractions(input_value, options) {
		return Simplify.CT_extractNumericFractions(input_value, options);
	}

	static CT_handleDivideByZero(input_value, options) {
		return Simplify.CT_handleDivideByZero(input_value, options);
	}

	static CT_handleRootOfNegative(input_value, options) {
		return Simplify.CT_handleRootOfNegative(input_value, options);
	}

	static CT_combineZero(input_value, options) {
		return Simplify.CT_combineZero(input_value, options);
	}

	static CT_combineOne(input_value, options) {
		return Simplify.CT_combineOne(input_value, options);
	}

	static CT_removeTermBrackets(input_value, options) {
		return Simplify.CT_removeTermBrackets(input_value, options);
	}

	static CT_removeChainBrackets(input_value, options) {
		return Simplify.CT_removeChainBrackets(input_value, options);
	}

	static CT_invertNegativeExponent(input_value, options) {
		return Simplify.CT_invertNegativeExponent(input_value, options);
	}

	static CT_invertNegativeRoot(input_value, options) {
		return Simplify.CT_invertNegativeRoot(input_value, options);
	}

	static CT_simplifyPower(input_value, options) {
		return Simplify.CT_simplifyPower(input_value, options);
	}

	static CT_simplifyRoot(input_value, options) {
		return Simplify.CT_simplifyRoot(input_value, options);
	}

	static CT_separateRootOfFraction(input_value, options) {
		return Simplify.CT_separateRootOfFraction(input_value, options);
	}

	static CT_separatePowerOfFraction(input_value, options) {
		return Simplify.CT_separatePowerOfFraction(input_value, options);
	}

	static CT_mergeFractionInFraction(input_value, options) {
		return Simplify.CT_mergeFractionInFraction(input_value, options);
	}

	static CT_removeCommonFractionFactors(input_value, options) {
		return Simplify.CT_removeCommonFractionFactors(input_value, options);
	}

	static CT_evaluateFactorial(input_value, options) {
		return Evaluate.CT_evaluateFactorial(input_value, options);
	}

	static CT_invertDividedFraction(input_value, options) {
		return Simplify.CT_invertDividedFraction(input_value, options);
	}

	static CT_convertMixedNumberToFraction(input_value, options) {
		return Convert.CT_convertMixedNumberToFraction(input_value, options);
	}

	static CT_convertFractionToMixedNumberSimplified(input_value, options) {
		return Convert.CT_convertFractionToMixedNumberSimplified(input_value, options);
	}

	static CT_convertMixedMultiplicativeChainToFraction(input_value, options) {
		return Convert.CT_convertMixedMultiplicativeChainToFraction(input_value, options);
	}

	static CT_combineMultiplicativeChainTerms(input_value, options) {
		return Simplify.CT_combineMultiplicativeChainTerms(input_value, options);
	}

	static CT_combineAdditiveChainTerms(input_value, options) {
		return Simplify.CT_combineAdditiveChainTerms(input_value, options);
	}

	static CT_multiplyBracketTerms(input_value, options) {
		return Simplify.CT_multiplyBracketTerms(input_value, options);
	}

	static CT_sortMultiplicativeChainTerms(input_value, options) {
		return Convert.CT_sortMultiplicativeChainTerms(input_value, options);
	}

	static CT_sortAdditiveChainTerms(input_value, options) {
		return Convert.CT_sortAdditiveChainTerms(input_value, options);
	}

	static CT_combineAdditiveChainRationalFractions(input_value, options) {
		return Simplify.CT_combineAdditiveChainRationalFractions(input_value, options);
	}

	static CT_combineAdditiveChainPolyFractions(input_value, options) {
		return Simplify.CT_combineAdditiveChainPolyFractions(input_value, options);
	}

	static CT_isolateVar_AddSubtractVarTermToLHS(input_value, options) {
		return Convert.CT_isolateVar_AddSubtractVarTermToLHS(input_value, options);
	}

	static CT_isolateVar_AddSubtractNonVarTermToRHS(input_value, options) {
		return Convert.CT_isolateVar_AddSubtractNonVarTermToRHS(input_value, options);
	}

	static CT_isolateVar_MultiplyDenominatorToRHS(input_value, options) {
		return Convert.CT_isolateVar_MultiplyDenominatorToRHS(input_value, options);
	}

	static CT_isolateVar_MultiplyVarDenominator(input_value, options) {
		return Convert.CT_isolateVar_MultiplyVarDenominator(input_value, options);
	}

	static CT_isolateVar_DivideNonVarToRHS(input_value, options) {
		return Convert.CT_isolateVar_DivideNonVarToRHS(input_value, options);
	}

	static CT_isolateVar_MultiplyNegativeToRHS(input_value, options) {
		return Convert.CT_isolateVar_MultiplyNegativeToRHS(input_value, options);
	}

	static sortFractionListByAscend(input_value, options) {
		return Convert.sortFractionListByAscend(input_value, options);
	}

	static compareFractionsWithSameDenominator(input_value, options) {
		return Evaluate.compareFractionsWithSameDenominator(input_value, options);
	}

	static compareDecimals(input_value, options) {
		return Evaluate.compareDecimals(input_value, options);
	}

	static extractComparisonSign(input_value, options) {
		return Evaluate.extractComparisonSign(input_value, options);
	}

	static prependLeadingZero(input_value, options) {
		return Convert.prependLeadingZero(input_value, options);
	}

	static stripTrailingZeros(input_value, options) {
		return Convert.stripTrailingZeros(input_value, options);
	}
}
