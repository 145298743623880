import { QEHelper } from '../QEHelper';
import { QEWidget, DisplayOptions, TagElement } from '../QEWidget';

export class QEWidgetPolygon extends QEWidget {
	display_options: { [key: string]: any };

	is_regular: boolean;
	sides?: number;
	len?: number;
	points?: number[][];

	congruent_colour: string;
	fill_colour: string;
	line_colour: string;
	show_congruent_angles: boolean;
	show_congruent_sides: boolean;


	static default_style = {
		congruent_colour: '#dd0000',
		fill_colour: 'none',
		line_colour: '#000000',
		show_congruent_angles: 1,
		show_congruent_sides: 1,
	};

	constructor(is_regular: boolean, sides: number, len: number, points: number[][], display_options: DisplayOptions = {}) {
		super();

		this.is_regular = is_regular;
		this.sides = sides;
		this.len = len;
		this.points = points;

		this.display_options = display_options;

		// apply default style, and any style overrides
		Object.assign(this, QEWidgetPolygon.default_style, display_options);
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		let deserialized = JSON.parse(serialized);

		const is_regular = deserialized.is_regular;
		const sides = QEHelper.resolveToNumber(deserialized.sides, resolved_data);
		const len = QEHelper.resolveToNumber(deserialized.len, resolved_data);
		if ([sides, len].filter(x => {return !Number.isInteger(x);}).length) {
			console.log('Error: non-integer sides or len value');
			return null;
		}

		// resolve points placeholders
		let points = [];
		if (!is_regular) {
			const points_str = QEHelper.resolvePlaceholderToString(deserialized.points, resolved_data);
			if (!points_str) {
				console.log('Error: failed to resovle placeholders in JSON points string');
				return null;
			}

			try {
				points = JSON.parse(points_str.value);
			} catch (err) {
				console.log('Error: unable to parse irregular polygon points: ', err);
				return null;
			}
		}

		// build map and resolve any [$name] placeholders in display_options
		let display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new QEWidgetPolygon(is_regular, sides, len, points, display_options);
		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} value
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options) {
		// TODO: support passed display option overrides

		return this.draw();
	}

	draw() {
		const padding = 5;
		let points = [];

		const svg = new TagElement('svg');
		svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");

		const shape = new TagElement('g');
		let svgWidth = 0;
		let svgHeight = 0;

		if (this.is_regular) { // is regular
			const length = this.len;
			const sides = this.sides;
			const rot = 360 / sides;

			// Determine the radius
			const a = rot / 2;
			const opp = length / 2;
			const hyp = opp / Math.sin(a * Math.PI/180);

			// Now calculate the points of the polygon (screen origin 0,0)
			points[0] = [0, Number(-hyp.toFixed(4))];
			for (let i = 1; i < sides; i++) {
				let theta = -(rot * i) * (Math.PI/180); // convert to radians
				let x = 0 * Math.cos(theta) - hyp * Math.sin(theta);
				let y = hyp * Math.cos(theta) + 0 * Math.sin(theta);
				points.push([Number(x.toFixed(4)), Number(-y.toFixed(4))]);
			}

			shape.innerHTML += '<polygon points="'+ points.join(' ') +'" fill="'+ this.fill_colour +'" stroke="'+ this.line_colour +'" stroke-width="2" />';

			// Move the shape into the viewport
			const offset = Math.ceil(hyp + padding);
			if (sides % 2) {
				shape.setAttribute('transform', 'translate('+ offset +','+ offset +')');
			} else {
				shape.setAttribute('transform', 'translate('+ offset +','+ offset +') rotate('+ a +')');
			}

			// Set the size of the viewport
			svgWidth = svgHeight = Math.ceil(hyp * 2 + padding * 2);
		} else { // is irregular
			points = this.points;

			let xMax = 0;
			let yMax = 0;
			let xMin = 0;
			let yMin = 0;
			let xOffset = padding;
			let yOffset = padding;

			shape.innerHTML += '<polygon points="'+ points.join(' ') +'" fill="'+ this.fill_colour +'" stroke="'+ this.line_colour +'" stroke-width="2" />';

			// Determine the min and max values of the shape
			for (let i = 0; i < points.length; i++) {
				let pt = points[i];
				if (pt[0] < xMin) xMin = pt[0];
				if (pt[0] > xMax) xMax = pt[0];
				if (pt[1] < yMin) yMin = pt[1];
				if (pt[1] > yMax) yMax = pt[1];
			}

			shape.dataset.cx = ((xMin + xMax) / 2).toString();
			shape.dataset.cy = ((yMin + yMax) / 2).toString();

			// Move the the shape into the viewport
			xOffset = Math.abs(xMin) + padding;
			yOffset = Math.abs(yMin) + padding;
			shape.setAttribute('transform', 'translate('+ xOffset +','+ yOffset +')');

			// Set the size of the viewport
			svgWidth = (xMax - xMin) + padding * 2;
			svgHeight = (yMax - yMin) + padding * 2;
		}

		if (this.show_congruent_angles) this.drawCongruentAngles(shape, points);
		if (this.show_congruent_sides) this.drawCongruentSides(shape, points);

		svg.innerHTML += shape.outerHTML();

		svg.setAttribute('width', svgWidth.toString());
		svg.setAttribute('viewBox', [0, 0, svgWidth, svgHeight].join(' '));

		return svg.outerHTML();
	}

	drawCongruentAngles(shape, points) {
		const congruent_colour = this.congruent_colour;
		const line_colour = this.line_colour;

		const congruents = {};

		// Calculate the angle for each point
		for (let i = 0; i < points.length; i++) {
			const pt1 = points[i]; // current point
			const pt2 = i - 1 < 0 ? points[points.length - 1] : points[i - 1]; // previous point
			const pt3 = i + 1 <= points.length - 1 ? points[i + 1] : points[0]; // next point

			const a = Math.sqrt(Math.pow((pt3[0] - pt1[0]), 2) + Math.pow(pt3[1] - pt1[1], 2));
			const b = Math.sqrt(Math.pow((pt2[0] - pt1[0]), 2) + Math.pow(pt2[1] - pt1[1], 2));
			const c = Math.sqrt(Math.pow((pt3[0] - pt2[0]), 2) + Math.pow(pt3[1] - pt2[1], 2));

			const rads = Math.acos((Math.pow(a, 2) + Math.pow(b, 2) - Math.pow(c, 2)) / (2 * a * b));
			const degs = Math.round(rads * 180 / Math.PI);

			if (!congruents[degs]) congruents[degs] = [];

			congruents[degs].push(i);
		}

		// Now draw the corresponding symbols
		let rings = 1;
		const maskId = 'mask-' + Math.floor(new Date().getTime() * Math.random());
		let markup = '';

		markup += '<mask id="'+ maskId +'">';
		markup +=   '<polygon points="'+ points.join(' ') +'" fill="white"></polygon>';
		markup += '</mask>';

		Object.keys(congruents).forEach(function(degs){
			const indices = congruents[degs];

			if (Number(degs) == 90) { // for 90's, always draw
				for (let i = 0; i < indices.length; i++) {
					const index = indices[i];
					const pt1 = points[index];
					const pt2 = index + 1 <= points.length - 1 ? points[index + 1] : points[0]; // next point

					const rise = pt2[1] - pt1[1];
					const run = pt2[0] - pt1[0];
					const deg = Math.atan2(rise, run) * 180 / Math.PI;

					markup += '<g transform="translate('+ pt1[0] +','+ pt1[1] +') rotate('+ deg +')"><path d="M 10 0 L 10 10 L 0 10" fill="none" stroke="'+ congruent_colour +'" /></g>';
				}
			} else if (indices.length > 1) { // for matching angles, must be 2 or more
				const size = Number(degs) > 90 ? 8 : 12;
				const offset = 2;
				for (let i = 0; i < indices.length; i++) {
					let pt = points[indices[i]];
					for (let j = 0; j < rings; j++) {
						markup += '<circle cx="'+ pt[0] +'" cy="'+ pt[1] +'" r="'+ (j * offset + size) +'" fill="none" stroke="'+ congruent_colour +'" mask="url(#'+ maskId +')"></circle>';
					}
				}
				rings++;
			}
		});

		shape.innerHTML += '<g>' + markup + '</g>';
	}

	drawCongruentSides(shape, points) {
		const congruent_colour = this.congruent_colour;
		const congruents = {};
		const midpoints = [];

		// Calculate the lengths for each side and their midpoints
		for (let i = 0; i < points.length; i++) {
			const pt1 = points[i];
			const pt2 = i < points.length - 1 ? points[i + 1] : points[0];
			const length = Math.round(Math.sqrt(Math.pow(pt2[0] - pt1[0], 2) + Math.pow(pt2[1] - pt1[1], 2)));
			const midpoint = [(pt1[0] + pt2[0]) / 2, (pt1[1] + pt2[1]) / 2];

			if (!congruents[length]) congruents[length] = [];

			congruents[length].push(i);
			midpoints.push(midpoint);
		}

		// Now draw the corresponding symbols
		let marks = 1;
		Object.keys(congruents).forEach(function(length){
			const indices = congruents[length];

			if (indices.length > 1) { // must be 2 or more
				for (let i = 0; i < indices.length; i++) {
					const index = indices[i];
					const midpoint = midpoints[index]; // x, y
					const pt1 = points[index];
					const pt2 = index < points.length - 1 ? points[index + 1] : points[0];

					// Use the side's slope to find the perpendicular angle to draw the mark
					const rise = pt2[1] - pt1[1];
					const run = pt2[0] - pt1[0];
					const deg = -Math.atan2(run, rise) * 180 / Math.PI; // rads to degrees

					// Calculate the position of the marks
					let offsetX = 5;
					let offsetY = 0;
					let spaceY = 4;
					let startY = 0;

					if (marks > 1) startY = (marks - 1) * 2;

					let markup = '';
					for (let j = 0; j < marks; j++) {
						offsetY = -startY + (spaceY * j);
						markup += '<line x1="'+ -offsetX +'" y1="'+ offsetY +'" x2="'+ offsetX +'" y2="'+ offsetY +'" stroke="'+ congruent_colour +'" stroke-width="2"></line>';
					}
					// markup += '<circle cx="0" cy="0" r="2" fill="#0D0"></circle>';
					markup = '<g transform="translate('+ midpoint[0] +','+ midpoint[1] +') rotate('+ deg +')">' + markup + '</g>';

					shape.innerHTML += markup;
				}
				marks++;
			}
		});
	}

	exportValue(options?){
		return {
			type: 'polygon',
			is_regular: this.is_regular,
			sides: this.sides,
			len: this.len,
			points: JSON.stringify(this.points),
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

