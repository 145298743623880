import { QEHelper } from '../QEHelper';
import { QEWidget, DisplayOptions, TagElement } from '../QEWidget';

class Vertex {
	x: number;
	y: number;
	z: number;

	constructor(x, y, z) {
		this.x = x;
		this.y = y; // invert
		this.z = z;
	}
	matrixMultiply(matrix: number[][]) {
		// 3x3 matrix
		let x = this.x * matrix[0][0] + this.y * matrix[1][0] + this.z * matrix[2][0];
		let y = this.x * matrix[0][1] + this.y * matrix[1][1] + this.z * matrix[2][1];
		let z = this.x * matrix[0][2] + this.y * matrix[1][2] + this.z * matrix[2][2];
		return new Vertex(x, y, z);
	}
	rotateAroundX(theta: number) {
		let a = Math.cos(theta * Math.PI / 180);
		let b = Math.sin(theta * Math.PI / 180);
		let x_rotate_matrix = [ // rotate around x-axis
			[ 1, 0, 0],
			[ 0, a,-b],
			[ 0, b, a],
		];
		return this.matrixMultiply(x_rotate_matrix);
	}
	rotateAroundY(theta: number) {
		let a = Math.cos(theta * Math.PI / 180);
		let b = Math.sin(theta * Math.PI / 180);
		let y_rotate_matrix = [ // rotate around y-axis
			[ a, 0, b],
			[ 0, 1, 0],
			[-b, 0, a],
		];
		return this.matrixMultiply(y_rotate_matrix);
	}
	rotateAroundZ(theta: number) {
		let a = Math.cos(theta * Math.PI / 180);
		let b = Math.sin(theta * Math.PI / 180);
		let z_rotate_matrix = [ // rotate around z-axis
			[ a,-b, 0],
			[ b, a, 0],
			[ 0, 0, 1],
		];
		return this.matrixMultiply(z_rotate_matrix);
	}

	project(theta: number) {
		// cabinet projection
		const cabinet_scale = 0.5;
		const x_scale = cabinet_scale * Math.cos(theta * Math.PI / 180);
		const y_scale = cabinet_scale * Math.sin(theta * Math.PI / 180);
		return new Vertex(this.x + this.z * x_scale, this.y + this.z * y_scale, 0);
	}
}

class Polygon {
	vertices: Vertex[];
	vertex_indices: number[];
	options: { [key: string]: any };
	fill_colour: string;
	line_colour: string;

	constructor(vertices: Vertex[], vertex_indices: number[], options = {}) {
		this.vertices = vertices;
		this.vertex_indices = vertex_indices;
		this.options = options;

		this.fill_colour = "rgba(204,204,204,0.5)";
		this.line_colour = "#000000";
	}

	draw(g: TagElement){
		// draw using the default / untransformed vertices
		let vertices = this.vertices;
		this.drawUsing(g, vertices);
	}
	drawUsing(g: TagElement, vertices: Vertex[]){
		// draw using the specified vertex list
		let points = this.vertex_indices.map(index => {
			let vertex = vertices[index];
			return [Number(vertex.x.toFixed(2)), Number(vertex.y.toFixed(2))];
		});

		if (this.options.fill) {
			g.innerHTML += '<polygon points="'+ points.join(' ') +'" fill="'+ this.fill_colour +'" stroke="'+ this.line_colour +'" stroke-linejoin="round" stroke-width="2" />';
		} else {
			g.innerHTML += '<polygon points="'+ points.join(' ') +'" fill="none" stroke="'+ this.line_colour +'" stroke-linejoin="round" stroke-width="2" />';
		}
	}
}

class Solid {
	vertices: Vertex[];
	faces: Polygon[];

	constructor(vertices: Vertex[], faces: Polygon[]) {
		this.vertices = vertices;
		this.faces = faces;
	}
	draw(g: TagElement) {
		// draw using the default / untransformed vertices
		let vertices = this.vertices;
		this.drawUsing(g, vertices);
	}
	drawUsing(g: TagElement, vertices: Vertex[]){
		// draw each face using the specified vertex list
		this.faces.forEach(face => {
			face.drawUsing(g, vertices);
		});
	}
}

export class QEWidgetPseudo3d extends QEWidget {
	display_options: { [key: string]: any };
	value: string; // type

	sides?: number;
	radius?: number;
	length?: number;
	width?: number;
	height?: number;
	angle?: number; // extrusion angle
	orientation?: string;

	dash_pattern: string;
	fill_colour: string;
	line_colour: string;

	static default_style = {
		dash_pattern: '4,2',
		fill_colour: 'rgba(204,204,204,0.5)',
		line_colour: '#000000',
	};

	constructor(value: string, display_options: DisplayOptions = {}) {
		super();

		this.value = value;
		this.display_options = display_options;

		// apply default style, side length, angle settings, and any style overrides
		Object.assign(this, QEWidgetPseudo3d.default_style, display_options);
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		let deserialized = JSON.parse(serialized);

		let value = deserialized.value;

		// build map and resolve any [$name] placeholders in display_options
		let display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		// iterate over display_options fields and serialize any QEValues
		Object.keys(display_options).forEach((key)=>{
			if (typeof display_options[key] == 'object') {
				display_options[key] = display_options[key].serialize_to_text();
			}
		});

		let widget = new QEWidgetPseudo3d(value, display_options);
		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} value
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options) {
		// TODO: support passed display option overrides

		return this.draw();
	}

	draw() {
		let svg = new TagElement("svg");
		svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");

		let g = new TagElement('g'); // enclose the shape into a group element

		const padding: number = 5; // padding to prevent draw clipping
		var svgWidth: number = 200;
		var svgHeight: number = 200;
		var points = [];

		switch (this.value) { // sphere, cone, cylinder, pyramid, prism

		case 'sphere': // radius
			var radius: number = Number(this.radius) || 50;

			// Draw the outer ring
			g.innerHTML += '<circle cx="0" cy="0" r="'+ radius +'" fill="none" stroke="'+ this.line_colour +'" stroke-width="2" />';

			// Draw the z-axis rings
			points = ['M', -radius, 0, 'A', 5, 1, 0, 0, 1, radius, 0];
			g.innerHTML += '<path d="'+ points.join(' ') +'" fill="none" stroke="'+ this.line_colour +'" stroke-width="1" stroke-dasharray="'+ this.dash_pattern +'" />';
			points = ['M', -radius, 0, 'A', 5, 1, 0, 1, 0, radius, 0];
			g.innerHTML += '<path d="'+ points.join(' ') +'" fill="none" stroke="'+ this.line_colour +'" stroke-width="2" />';

			points = ['M', 0, -radius, 'A', 1, 5, 0, 0, 1, 0, radius];
			g.innerHTML += '<path d="'+ points.join(' ') +'" fill="none" stroke="'+ this.line_colour +'" stroke-width="1" stroke-dasharray="'+ this.dash_pattern +'" />';
			points = ['M', 0, -radius, 'A', 1, 5, 0, 1, 0, 0, radius];
			g.innerHTML += '<path d="'+ points.join(' ') +'" fill="none" stroke="'+ this.line_colour +'" stroke-width="2" />';

			g.setAttribute('transform', 'translate('+ (radius + padding) + ',' + (radius + padding) +')');

			svgWidth = svgHeight = radius * 2 + padding * 2;

			break;

		case 'cone': // radius, height
			var radius: number = Number(this.radius) || 50;
			var height: number = Number(this.height) || 100;

			var arcX: number = 5;
			var arcHeight: number = radius / arcX + 1;

			// Draw the walls
			points = ['M', -radius, 0, 'L', 0, -height, 'L', radius, 0];
			g.innerHTML += '<path d="'+ points.join(' ') +'" fill="none" stroke="'+ this.line_colour +'" stroke-width="2" />';

			// Draw the base
			points = ['M', -radius, 0, 'A', arcX, 1, 0, 0, 1, radius, 0];
			g.innerHTML += '<path d="'+ points.join(' ') +'" fill="'+ this.fill_colour +'" stroke="'+ this.line_colour +'" stroke-width="1" stroke-dasharray="'+ this.dash_pattern +'" />';
			points = ['M', -radius, 0, 'A', arcX, 1, 0, 1, 0, radius, 0];
			g.innerHTML += '<path d="'+ points.join(' ') +'" fill="'+ this.fill_colour +'" stroke="'+ this.line_colour +'" stroke-width="2" />';

			g.setAttribute('transform', 'translate('+ (radius + padding) + ',' + (height + padding) +')');

			svgWidth = radius * 2 + padding * 2;
			svgHeight = height + arcHeight + padding * 2;

			break;

		case 'cylinder': // radius, height, orientation
			var radius: number = Number(this.radius) || 50;
			var height: number = Number(this.height) || 100;
			var orientation = this.orientation || 'y';

			var arcX = 5;
			var arcHeight = radius / arcX + 1;

			if (orientation === 'x') {
				// Draw the walls
				points = ['M', 0, -radius, 'L', height, -radius];
				g.innerHTML += '<path d="'+ points.join(' ') +'" fill="none" stroke="'+ this.line_colour +'" stroke-width="2" />';
				points = ['M', 0, radius, 'L', height, radius];
				g.innerHTML += '<path d="'+ points.join(' ') +'" fill="none" stroke="'+ this.line_colour +'" stroke-width="2" />';

				// Draw the base and top
				points = ['M', 0, -radius, 'A', 1, arcX, 0, 0, 1, 0, radius];
				g.innerHTML += '<path d="'+ points.join(' ') +'" fill="'+ this.fill_colour +'" stroke="'+ this.line_colour +'" stroke-width="2" />';
				points = ['M', 0, -radius, 'A', 1, arcX, 0, 1, 0, 0, radius];
				g.innerHTML += '<path d="'+ points.join(' ') +'" fill="'+ this.fill_colour +'" stroke="'+ this.line_colour +'" stroke-width="2" />';

				points = ['M', height, -radius, 'A', 1, arcX, 0, 0, 1, height, radius];
				g.innerHTML += '<path d="'+ points.join(' ') +'" fill="'+ this.fill_colour +'" stroke="'+ this.line_colour +'" stroke-width="2" />';
				points = ['M', height, -radius, 'A', 1, arcX, 0, 1, 0, height, radius];
				g.innerHTML += '<path d="'+ points.join(' ') +'" fill="'+ this.fill_colour +'" stroke="'+ this.line_colour +'" stroke-width="1" stroke-dasharray="'+ this.dash_pattern +'" />';

				g.setAttribute('transform', 'translate('+ (arcHeight + padding) + ',' + (radius + padding) +')');

				svgWidth = height + arcHeight * 2 + padding * 2;
				svgHeight = radius * 2 + padding * 2;
			} else { // is y, vertical extrusion
				// Draw the walls
				points = ['M', -radius, 0, 'L', -radius, height];
				g.innerHTML += '<path d="'+ points.join(' ') +'" fill="none" stroke="'+ this.line_colour +'" stroke-width="2" />';
				points = ['M', radius, 0, 'L', radius, height];
				g.innerHTML += '<path d="'+ points.join(' ') +'" fill="none" stroke="'+ this.line_colour +'" stroke-width="2" />';

				// Draw the base and top
				points = ['M', -radius, 0, 'A', arcX, 1, 0, 0, 1, radius, 0];
				g.innerHTML += '<path d="'+ points.join(' ') +'" fill="'+ this.fill_colour +'" stroke="'+ this.line_colour +'" stroke-width="2" />';
				points = ['M', -radius, 0, 'A', arcX, 1, 0, 1, 0, radius, 0];
				g.innerHTML += '<path d="'+ points.join(' ') +'" fill="'+ this.fill_colour +'" stroke="'+ this.line_colour +'" stroke-width="2" />';

				points = ['M', -radius, height, 'A', arcX, 1, 0, 0, 1, radius, height];
				g.innerHTML += '<path d="'+ points.join(' ') +'" fill="'+ this.fill_colour +'" stroke="'+ this.line_colour +'" stroke-width="1" stroke-dasharray="'+ this.dash_pattern +'" />';
				points = ['M', -radius, height, 'A', arcX, 1, 0, 1, 0, radius, height];
				g.innerHTML += '<path d="'+ points.join(' ') +'" fill="'+ this.fill_colour +'" stroke="'+ this.line_colour +'" stroke-width="2" />';

				g.setAttribute('transform', 'translate('+ (radius + padding) + ',' + (arcHeight + padding) +')');

				svgWidth = radius * 2 + padding * 2;
				svgHeight = height + arcHeight * 2 + padding * 2;
			}

			break;

		case 'pyramid': // sides, length, height
			// base
			var length = Number(this.length) || 100; // pyramid base width
			var width = Number(this.width) || length; // pyramid base depth
			var height = Number(this.height) || 100; // pyramid height
			var sides = Number(this.sides);

			// 3, 4, 5+ sides
			var base_vertices = [];
			if (sides == 3) {
				// triangle base
				base_vertices = [new Vertex(length/2, width/2, 0), new Vertex(-length/2, width/2, 0), new Vertex(0, -width/2, 0)];
			} else if (sides == 4) {
				// rectangle base
				base_vertices = [new Vertex(length/2, width/2, 0), new Vertex(-length/2, width/2, 0), new Vertex(-length/2, -width/2, 0), new Vertex(length/2, -width/2, 0)];
			} else {
				// Use the side length as the radius to calculate the vertices
				for (var i = 0; i < sides; i++) {
					var angle = 360 / sides * i;
					var theta = angle * Math.PI / 180; // convert to radians
					var x = Number((length * Math.cos(theta)).toFixed(2));
					var y = Number((length * Math.sin(theta)).toFixed(2));
					base_vertices.push(new Vertex(x, y, 0));
				}
			}

			var peak_vertex = new Vertex(0, 0, height);
			var vertices = base_vertices.concat(peak_vertex);

			var base_vertex_indices = [];
			for (let i = 0; i < sides; i++) {
				base_vertex_indices.push(i);
			}
			var base_face = new Polygon(vertices, base_vertex_indices, { fill: true });

			var side_faces = [];
			for (let i = 0; i < sides; i++) {
				side_faces.push(new Polygon(vertices, [i, (i+1) % sides, sides]));
			}

			var pyramid = new Solid(vertices, [base_face].concat(side_faces));

			// rotate 90 degrees around the x-axis to sit the pyramid on its base, then apply cabinet projection
			var transformed_vertices = vertices.map(vertex => { return vertex.rotateAroundX(-90).project(-30); });

			pyramid.drawUsing(g, transformed_vertices);

			// get limits from transformed vertices
			var min_x = Math.min.apply(null, transformed_vertices.map(vertex => { return vertex.x; }))
			var min_y = Math.min.apply(null, transformed_vertices.map(vertex => { return vertex.y; }))
			var max_x = Math.max.apply(null, transformed_vertices.map(vertex => { return vertex.x; }))
			var max_y = Math.max.apply(null, transformed_vertices.map(vertex => { return vertex.y; }))

			g.setAttribute('transform', 'translate('+ Math.floor(-min_x + 5) +', '+ Math.floor(-min_y + 5) +')');

			svgWidth = Math.floor(max_x - min_x + padding * 2);
			svgHeight = Math.floor(max_y - min_y + padding * 2);

			break;

		case 'prism': // sides, length, width, height, orientation
			// NOTES:
			// No support for irregular polygons
			// Height is the depth of the extrusion
			// TODO: Orientation is the direction of the extrusion

			var eAngle = Number(this.angle) || 20; // horizontal extrusion angle (ie. isometric drawing)
			var sLength = Number(this.length) || 30; // triangle base width
			var sWidth = Number(this.width) || sLength; // triangle height
			var sHeight = Number(this.height) || 100; // extruded length
			var sMax = [], sMin = [];
			var sides = Number(this.sides);

			var offsetY = Math.sin(eAngle * Math.PI/180) * sHeight;
			var offset = [sHeight, -offsetY];
			var points = [];

			// Add the points
			if (sides === 3) { // triangle
				points = [[0, 0], [sLength/2, -sWidth], [sLength, 0]];

				if (!this.width) { // not equilateral
					points[1][1] = -Math.sqrt(Math.pow(sLength, 2) - Math.pow(sLength/2, 2));
				}

				svgWidth = points[2][0] + offset[0] + padding * 2;
				svgHeight = Math.floor(Math.abs(points[1][1] + offset[1]) + padding * 2);
			} else if (sides === 4) { // quad
				points = [[0, 0], [0, -sWidth], [sLength, -sWidth], [sLength, 0]];

				svgWidth = points[2][0] + offset[0] + padding * 2;
				svgHeight = Math.floor(Math.abs(points[2][1] + offset[1]) + padding * 2);
			} else { // is a standard polygon
				var xMin = 9999, xMax = 0;
				var yMin = 9999, yMax = 0;

				// Use the side length as the radius to calculate the vertices
				for (var i = 0; i < sides; i++) {
					var a = 360 / sides * i;
					var theta = -a * Math.PI / 180; // convert to radians
					var x = Number(((0 * Math.cos(theta) - 1 * Math.sin(theta)) * sLength + sLength).toFixed(2));
					var y = Number((sLength - (1 * Math.cos(theta) + 0 * Math.sin(theta)) * sLength).toFixed(2));

					if (sides % 2 === 0) { // for even shapes, rotate it so its side is on x-axis
						// X=xcos(θ)+ysin(θ)
						// Y=−xsin(θ)+ycos(θ)
						var rot = (360 / sides / 2) * Math.PI / 180;
						var _x = (x * Math.cos(rot)) + (y * Math.sin(rot));
						var _y = (-x * Math.sin(rot)) + (y * Math.cos(rot));

						x = _x;
						y = _y;
					}

					points.push([x, y]);

					xMin = Math.min(x, xMin);
					xMax = Math.max(x, xMax);
					yMin = Math.min(y, yMin);
					yMax = Math.max(y, yMax);
				}

				svgWidth = Math.floor(xMax - xMin + offset[0] + padding * 2);
				svgHeight = Math.floor(yMax - yMin + Math.abs(offset[1]) + padding * 2);
			}

			g.innerHTML += '<polygon points="'+ points.join(' ') +'" fill="'+ this.fill_colour +'" stroke="'+ this.line_colour +'" stroke-linejoin="round" stroke-width="2" transform="translate('+ offset.toString() +')" />';
			g.innerHTML += '<polygon points="'+ points.join(' ') +'" fill="'+ this.fill_colour +'" stroke="'+ this.line_colour +'" stroke-linejoin="round" stroke-width="2" />';

			for (var i = 0; i < points.length; i++) {
				var str = 'M ' + points[i].join(' ');
				str += ' L ' + (points[i][0] + offset[0]) + ' ' + (points[i][1] + offset[1]);
				if (sides > 4) {
					if (i > Math.floor(points.length/2)) {
						g.innerHTML += '<path d="'+ str +'" fill="none" stroke="'+ this.line_colour +'" stroke-width="1" stroke-dasharray="'+ this.dash_pattern +'"/>';
					} else g.innerHTML += '<path d="'+ str +'" fill="none" stroke="'+ this.line_colour +'" stroke-width="2" />';
				} else if (i < Math.ceil(points.length/2 - 1)) {
					g.innerHTML += '<path d="'+ str +'" fill="none" stroke="'+ this.line_colour +'" stroke-width="1" stroke-dasharray="'+ this.dash_pattern +'"/>';
				} else g.innerHTML += '<path d="'+ str +'" fill="none" stroke="'+ this.line_colour +'" stroke-width="2" />';
			}

			if (sides < 5) {
				g.setAttribute('transform', 'translate('+ padding +','+ (svgHeight - padding) +')');
			} else g.setAttribute('transform', 'translate('+ (padding - xMin) +','+ Math.floor(Math.abs(offset[1] + yMin) + padding) +')');

			break;
		}

		svg.innerHTML = g.outerHTML();
		svg.setAttribute('width', svgWidth.toString());
		svg.setAttribute('height', svgHeight.toString());
		svg.setAttribute('viewBox', [0, 0, svgWidth, svgHeight].join(' '));

		return svg.outerHTML();
	}

	exportValue(options?){
		return {
			type: 'pseudo3d',
			value: this.value,
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

