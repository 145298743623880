import { SvgRenderingContext } from '../../common/SvgRenderingContext';
import * as jQuery from "jquery";
import { QEGraph } from "../QEGraph";

export class Label {
	x: number;
	y: number;
	color: string;
	value: any;
	pos: string;
	fontSize: number;

	constructor(data) {
		this.x = NaN;
		this.y = NaN;
		this.color = "#D00";
		this.value = "";
		this.pos = "N"; // compass pts
		this.fontSize = 16;

		for (const prop in data) {
			if (data[prop] !== undefined) {
				// attempt to cast to number or boolean
				if (!Number.isNaN(Number(data[prop]))) this[prop] = Number(data[prop]);
				else if (data[prop] === true || data[prop] === false) this[prop] = data[prop];
				else if (data[prop] === "true") this[prop] = true;
				else if (data[prop] === "false") this[prop] = false;
				else this[prop] = data[prop];
			}
		}
	}

	plot(graph: QEGraph, ctx: SvgRenderingContext): void {
		const self = this;

		// Convert x, y to widget's screen Pos
		let x = graph.screenX(this.x);
		let y = graph.screenY(this.y);
		const offset = this.fontSize / 4;

		// limit labels to bounds of plot area
		const plotMinX = graph.padding_left;
		const plotMaxX = graph.padding_left + graph.plot_width;
		const plotMinY = graph.padding_top;
		const plotMaxY = graph.padding_top + graph.plot_height;
		if (x > plotMaxX) return;
		if (x < plotMinX) return;
		if (y > plotMaxY) return;
		if (y < plotMinY) return;

		// Add Label Text
		graph.setFont(ctx);
		ctx.fillStyle = this.color;

		// handle markup (usually katex) label content
		let is_markup = false;
		let content = this.value;
		if (typeof this.value == "object" && this.value.type == "markup") {
			content = this.value.value;

			// check if value actually contains markup characters
			if (content.match(/<.*>/)) {
				is_markup = true;
			}
		}

		let content_measure_func;
		if (is_markup) {
			content_measure_func = function (callback) {
				// markup content rendered as a foreignObject. The actual size is unknown until post-render, so we use a default size here
				callback({ width: 16, height: 16 });

/*
				markupToCanvas(markup, { style: "color: " + self.color + ";" }, function (canvas, svg) {
					callback(
						{
							width: canvas.width,
							height: canvas.height,
						},
						canvas
					);
				});
*/
			};
		} else {
			content_measure_func = function (callback) {
				const rect = { width: 0, height: 0 };
				rect.width = ctx.measureText(content).width;
				rect.height = 16; // use graph font size? how to get this value;

				callback(rect);
			};
		}

		content_measure_func(function (rect, canvas) {
			switch (self.pos) {
				case "NNE":
					ctx.textAlign = "left";
					ctx.textBaseline = "bottom";
					x += offset * 0.5;
					y -= offset * 2;
					rect.x = x;
					rect.y = y - rect.height;
					break;
				case "NE":
					ctx.textAlign = "left";
					ctx.textBaseline = "bottom";
					x += offset * 1.75;
					y -= offset * 1.75;
					rect.x = x;
					rect.y = y - rect.height;
					break;
				case "ENE":
					ctx.textAlign = "left";
					ctx.textBaseline = "bottom";
					x += offset * 2;
					y -= offset * 0.5;
					rect.x = x;
					rect.y = y - rect.height;
					break;
				case "E":
					ctx.textAlign = "left";
					ctx.textBaseline = "middle";
					x += offset * 2;
					rect.x = x;
					rect.y = y - rect.height / 2;
					break;
				case "ESE":
					ctx.textAlign = "left";
					ctx.textBaseline = "top";
					x += offset * 2;
					y += offset * 0.5;
					rect.x = x;
					rect.y = y;
					break;
				case "SE":
					ctx.textAlign = "left";
					ctx.textBaseline = "top";
					x += offset * 1.75;
					y += offset * 1.75;
					rect.x = x;
					rect.y = y;
					break;
				case "SSE":
					ctx.textAlign = "left";
					ctx.textBaseline = "top";
					x += offset * 0.5;
					y += offset * 2;
					rect.x = x;
					rect.y = y;
					break;
				case "S":
					ctx.textAlign = "center";
					ctx.textBaseline = "top";
					y += offset * 2;
					rect.x = x - rect.width / 2;
					rect.y = y;
					break;
				case "SSW":
					ctx.textAlign = "right";
					ctx.textBaseline = "top";
					x -= offset * 0.5;
					y += offset * 2;
					rect.x = x - rect.width;
					rect.y = y;
					break;
				case "SW":
					ctx.textAlign = "right";
					ctx.textBaseline = "top";
					x -= offset * 1.75;
					y += offset * 1.75;
					rect.x = x - rect.width;
					rect.y = y;
					break;
				case "WSW":
					ctx.textAlign = "right";
					ctx.textBaseline = "top";
					x -= offset * 2;
					y += offset * 0.5;
					rect.x = x - rect.width;
					rect.y = y;
					break;
				case "W":
					ctx.textAlign = "right";
					ctx.textBaseline = "middle";
					x -= offset * 2;
					rect.x = x - rect.width;
					rect.y = y - rect.height / 2;
					break;
				case "WNW":
					ctx.textAlign = "right";
					ctx.textBaseline = "bottom";
					x -= offset * 2;
					y -= offset * 0.5;
					rect.x = x - rect.width;
					rect.y = y - rect.height;
					break;
				case "NW":
					ctx.textAlign = "right";
					ctx.textBaseline = "bottom";
					x -= offset * 1.75;
					y -= offset * 1.75;
					rect.x = x - rect.width;
					rect.y = y - rect.height;
					break;
				case "NNW":
					ctx.textAlign = "right";
					ctx.textBaseline = "bottom";
					x -= offset * 0.5;
					y -= offset * 2;
					rect.x = x - rect.width;
					rect.y = y - rect.height;
					break;
				default:
					// N and default
					ctx.textAlign = "center";
					ctx.textBaseline = "bottom";
					y -= offset * 2;
					rect.x = x - rect.width / 2;
					rect.y = y - rect.height;
					break;
			}

			// Draw block
			ctx.beginPath();
			ctx.rect(rect.x, rect.y, rect.width, rect.height);
			ctx.fillStyle = "rgba(255,255,255,0.5)";
			ctx.fill();

			if (is_markup) {
				ctx.drawForeignObject(content, rect.x, rect.y);
//				ctx.drawImage(canvas, parseInt(rect.x) + 0.5, parseInt(rect.y) + 0.5);
			} else {
				// Revert fill style
				ctx.fillStyle = self.color;
				ctx.fillText(content, x, y);
			}
		});
	}
}

function markupToCanvas(markup, options, callback) {
	/*  CONVERSION STEPS:
        1. Add the markup to the DOM to obtain its dimensions
        2. Create and size the svg container
        3. Convert the svg markup to a base64 data url
        4. Load data url into Image, then draw the image on the canvas
    */
	// 1. Add the markup to the DOM to obtain its dimensions
	let markup_node = jQuery("#graph_markup");
	if (!markup_node.length) {
		markup_node = jQuery('<div id="graph_markup" style="display: inline-block; position: fixed; top: 10px; left:0; visibility: hidden; z-index:-1"></div>');
		jQuery("body").append(markup_node);
	}
	markup_node.html(markup);

	// add padding to account for minor styling mismatch between document and svg container
	const markup_width = markup_node.outerWidth() + 2;
	const markup_height = markup_node.outerHeight() + 2;

	// 2. Create and size the svg container
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");
	canvas.width = markup_width;
	canvas.height = markup_height;

	// NOTE: any css, font, img, etc. resources required to display markup
	//    on the canvas must be loaded and included INLINE in data urls.
	// Katex css and two essential katex woff fonts are currently included in "inline_katex_css.js".
	let katexCSS = "";
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	if (typeof QE_inline_katex_css != "undefined") {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		katexCSS = QE_inline_katex_css;
	}

	const data =
		'<svg xmlns="http://www.w3.org/2000/svg" width="' +
		markup_width +
		'" height="' +
		markup_height +
		'">' +
		'<foreignObject width="100%" height="100%">' +
		"<style>" +
		katexCSS +
		"</style>" +
		'<div xmlns="http://www.w3.org/1999/xhtml" style="padding-top: 1px;' +
		(options.style || "") +
		'">' +
		markup +
		"</div>" +
		"</foreignObject>" +
		"</svg>";

	// 3. Convert the svg markup to a base64 data url
	function buildSvgImageUrl(svg) {
		const b64 = window.btoa(unescape(encodeURIComponent(svg)));
		return "data:image/svg+xml;base64," + b64;
	}
	const url = buildSvgImageUrl(data);

	// 4. Load data url into Image, then draw the image on the canvas
	const img = new Image();
	img.onload = function () {
		ctx.drawImage(img, 0, 0);
		callback(canvas, data);
	};
	img.width = canvas.width;
	img.height = canvas.height;
	img.src = url;
}
