import { QETerm } from '../../QETerm';
import { QEHelper } from '../../QEHelper';
import { QEWidget, DisplayOptions, TagElement } from '../../QEWidget';

export class QEWidgetCircleGraph extends QEWidget {
	display_options: { [key: string]: any };

	dataset_from_ref: boolean;
	dataset: { label: string, value: number }[];

	font_family: string;
	font_size: string;
	fill_colours: string[];
	label_colour: string;
	line_colour: string;

	static default_style = {
		font_family: 'sans-serif',
		font_size: '14px',
		fill_colours: ['#f50', '#36f', '#3cc', '#cc0', '#f39', '#09f', '#0f9', '#fc0'],
		label_colour: '#fff',
		line_colour: '#000',
	};

	constructor(dataset: { label: string, value: number }[], display_options: DisplayOptions = {}) {
		super();

		this.dataset = dataset;
		this.display_options = display_options;

		// apply default style, then display options and style overrides
		Object.assign(this, QEWidgetCircleGraph.default_style, display_options);
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		const deserialized = JSON.parse(serialized);

		const dataset = QEHelper.resolvePlotDataset(serialized, resolved_data, options);
		if (!dataset) return null; // unresolved dependency, or resolution error

		// build map and resolve any [$name] placeholders in display_options
		const display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new QEWidgetCircleGraph(dataset, display_options);
		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options) {
		// TODO: support passed display option overrides

		return this.draw();
	}

	draw() {
		const padding = 5; // padding to prevent edge clipping
		const size = 200 + padding * 2; // draw size

		// var a = 360 / demoninator;
		const r = size / 2 - padding;
		const cx = size / 2;
		const cy = size / 2;

		const svg = new TagElement("svg");
		svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");

		let rot = 0;

		// sum the values of all dataset items so we can weight the items
		let total_value = 0;
		this.dataset.forEach(item => { total_value += item.value; });

		// two-pass: draw pie slices, then text labels on top
		const label_data = [];
		for (let i = 0; i < this.dataset.length; i++) {
			const g = new TagElement("g"); // enclose the shape into a group element

			const item = this.dataset[i];
			if (!item.value) continue; // skip empty items

			const weight = Number((100 * item.value / total_value).toFixed(1)); // percentage

			const a = weight * 3.6;
			let arr = [rot, cx, cy];
			let theta = -a * (Math.PI/180);
			let path_transform = 'rotate('+ arr.toString() +')';
			let x = ((0 * Math.cos(theta) - 1 * Math.sin(theta)) * r + r) + padding;
			let y = (r - (1 * Math.cos(theta) + 0 * Math.sin(theta)) * r) + padding;

			const large_arc_flag = a >= 180 ? 1 : 0;
			const pts = [];
			pts[0] = ['M', cx, cy].join(' ');
			pts[1] = ['L', cx, padding].join(' ');
			pts[2] = ['A', r, r, 0, large_arc_flag, 1, x.toFixed(2), y.toFixed(2), 'Z'].join(' ');

			g.setAttribute('transform', path_transform);
			g.innerHTML += '<path class="" d="'+ pts.join(' ') +'" fill="'+ this.fill_colours[i] +'" stroke="'+ this.line_colour +'" stroke-width="2"/>';

			// Determine the label position
			theta = -a/2 * (Math.PI/180);
			const label_x = ((0 * Math.cos(theta) - 0.7 * Math.sin(theta)) * r + r) + padding;
			const label_y = (r - (0.7 * Math.cos(theta) + 0 * Math.sin(theta)) * r) + padding;

			arr = [-rot, label_x, label_y];
			const label_transform = 'rotate('+ arr.toString() +')';

			label_data.push({
				weight: weight,
				path_transform: path_transform,
				path_points: pts.join(' '),
				path_fill: this.fill_colours[i],
				label: item.label,
				label_x: label_x,
				label_y: label_y,
				label_transform: label_transform,
			});

			rot += a;
			svg.innerHTML += g.outerHTML();
		}

		// add labels
		for (let i = 0; i < label_data.length; i++) {
			const path_transform = label_data[i].path_transform;
			const x = label_data[i].label_x;
			const y = label_data[i].label_y;
			const weight = label_data[i].weight;
			const label = label_data[i].label;
			const label_transform = label_data[i].label_transform;

			const g = new TagElement("g"); // enclose the shape into a group element
			g.setAttribute('transform', path_transform);

			g.innerHTML += '<text x="'+ x +'" y="'+ y +'" font-family="'+ this.font_family +'" font-size="'+ this.font_size +'" alignment-baseline="middle" text-anchor="middle" fill="'+this.label_colour+'" stroke="none" transform="'+ label_transform +'">';
			g.innerHTML += '<tspan x="'+ x +'">'+ label + '</tspan>';
			g.innerHTML += '<tspan x="'+ x +'" dy="1.2em">'+ weight + '%</tspan>';
			g.innerHTML += '</text>';

			svg.innerHTML += g.outerHTML();
		}

		svg.setAttribute('width', size.toString());
		svg.setAttribute('viewBox', [0,0, size, size].join(' '));

		return svg.outerHTML();
	}

	exportValue(options?){
		return {
			type: 'circle_graph',
			dataset: JSON.stringify(this.dataset),
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

