import { TagElement } from './QEWidget';

//////////////////////////////////////////////////////////////

// SvgRenderingContext - emulates a canvas rendering context to build an SVG
// Some of the logic adapted from http://gliffy.github.io/canvas2svg/
export class SvgRenderingContext {
	context_stack: { [key: string]: string|number|TagElement }[];

	lineWidth: number;
	fillStyle: string;
	strokeStyle: string;
	dash_array: string;
	font: string;
	textAlign: string;
	textBaseline: string;
	cur_x: number;
	cur_y: number;

	root_element: TagElement;
	defs_element: TagElement;
	current_element: TagElement;

	constructor() {
		this.lineWidth = 1; // "stroke-width",
		this.fillStyle = "none"; // fill
		this.strokeStyle = "#000000"; // stroke
		this.dash_array = "";
		this.font = "10px sans-serif"; // svg?
		this.textAlign = "start"; // svg?
		this.textBaseline = "alphabetic"; // svg?
		this.cur_x = 0;
		this.cur_y = 0;

		this.context_stack = [];
	}
	setRootElement(root_element: TagElement) {
		this.root_element = root_element;

		// create defs tag for storing clipping paths
		this.defs_element = new TagElement("defs");
		this.root_element.append(this.defs_element);

		this.current_element = new TagElement("g");
		this.root_element.append(this.current_element);
	}
	save() {
		// walk up current_element parents until "g" found
		let g = this.current_element;
		while (g && g.name !== "g") { g = g.parent_element; }
		if (g.name !== "g") throw "Error: called save() but no <g> element in parent chain.";

		// push current context values onto context_stack
		const context = {};

		// record each context field
		const fields = ['cur_x', 'cur_y', 'lineWidth', 'fillStyle', 'strokeStyle', 'dash_array', 'font', 'textAlign', 'textBaseline'];
		for (let i = 0; i < fields.length; i++) {
			context[fields[i]] = this[fields[i]];
		}
		context["g"] = g; // save the element reference so that restore() can find it
		this.context_stack.push(context);

		// create new group context
		const new_group = new TagElement("g");
		g.append(new_group);

		this.current_element = new_group;
	}
	restore() {
		// pop saved context values off context_stack and apply
		const context = this.context_stack.pop();

		const fields = ['cur_x', 'cur_y', 'lineWidth', 'fillStyle', 'strokeStyle', 'dash_array', 'font', 'textAlign', 'textBaseline'];
		for (let i = 0; i < fields.length; i++) {
			this[fields[i]] = context[fields[i]];
		}

		// get to restored group context
		let g = this.current_element;
		while (g && g !== context.g) { g = g.parent_element; }
		if (!g) throw "Error: called restore() but no matching <g> element in parent chain.";

		this.current_element = g;
	}
	translate(x: number, y: number) {
		// walk up current_element parents until "g" found
		let g = this.current_element;
		while (g && g.name !== "g") { g = g.parent_element; }
		if (g.name !== "g") throw "Error: called translate() but no <g> element in parent chain.";

		// append to current transform
		const cur_transform = g.getAttribute("transform") || "";
		g.setAttribute("transform", cur_transform + " translate("+ x +","+ y +")");
	}
	rotate(angle) {
		// walk up current_element parents until "g" found
		let g = this.current_element;
		while (g && g.name !== "g") { g = g.parent_element; }
		if (g.name !== "g") throw "Error: called translate() but no <g> element in parent chain.";

		// append to current transform
		const cur_transform = g.getAttribute("transform") || "";
		const radians = angle * 180 / Math.PI
		g.setAttribute("transform", cur_transform + " rotate("+ radians +")");
	}
	setLineDash(pattern: number[]) {
		if (pattern && pattern.length) {
			this.dash_array = pattern.join(",");
		} else {
			this.dash_array = "";
		}
	}
	beginPath() {
		// walk up current_element parents until "g" found
		let g = this.current_element;
		while (g && g.name !== "g") { g = g.parent_element; }
		if (g.name !== "g") throw "Error: called beginPath() but no <g> element in parent chain.";

		const path = new TagElement("path");
		g.append(path);

		path.setAttribute("fill", "none");
		path.setAttribute("stroke", "none");
		path.setAttribute("d", "");

		this.current_element = path;

		// TODO: discard the current path and start a new path
		this.cur_x = 0;
		this.cur_y = 0;
	}
	closePath() {
		const path = this.current_element;
		if (path.name !== "path") throw "Error: called closePath() but no currently open path.";

		// close cur_path
		// TODO: only if not already closed
		const cur_path = path.getAttribute("d");
		path.setAttribute("d", cur_path + " Z");
	}
	fill() {
		const path = this.current_element;
		if (path.name !== "path") throw "Error: called fill() but no currently open path.";

		// TODO: set the fill attribute of the current path element
		path.setAttribute("fill", this.fillStyle);

		// TODO: possibly update paint-order
	}
	stroke() {
		const path = this.current_element;
		if (path.name !== "path") throw "Error: called stroke() but no currently open path.";

		path.setAttribute("stroke", this.strokeStyle);
		path.setAttribute("stroke-width", this.lineWidth.toString());

		// possibly set dasharray
		if (this.dash_array) {
			path.setAttribute("stroke-dasharray", this.dash_array);
		}

		// TODO: possibly update paint-order
	}
	moveTo(x: number, y: number) {
		this.cur_x = x;
		this.cur_y = y;

		const path = this.current_element;
		if (path.name !== "path") throw "Error: called closePath() but no currently open path.";

		// close cur_path
		// TODO: only if not already closed
		const cur_path = path.getAttribute("d");
		path.setAttribute("d", cur_path + " M "+ x +" "+ y);
	}
	lineTo(x: number, y: number) {
		this.cur_x = x;
		this.cur_y = y;

		const path = this.current_element;
		if (path.name !== "path") throw "Error: called closePath() but no currently open path.";

		// close cur_path
		// TODO: only if not already closed
		const cur_path = path.getAttribute("d");
		path.setAttribute("d", cur_path + " L "+ x +" "+ y);
	}
	clip(path?, fillRule?) {
		// TODO: check if path and/or fillRule was passed

		// get current path element
		path = this.current_element;
		if (path.name !== "path") throw "Error: called closePath() but no currently open path.";

		// get parent group of current path element
		let g = this.current_element;
		while (g && g.name !== "g") { g = g.parent_element; }
		if (g.name !== "g") throw "Error: called clip() but no <g> element in parent chain.";

		// remove current path object - will be used in a clipPath if needed
		g.removeChild(path);

		// create a unique id based on the path
		const path_id = "path_"+ path.getAttribute("d").replace(/ /g, '_');

		// check if there is already a clipPath element with corresponding path_id in defs node
		const matching_elements = this.defs_element.findChildrenWithId(path_id);
		if (!matching_elements.length) {
			// no clipPath with matching path_id already exists in defs node; create one
			const clip_path = new TagElement("clipPath");
			clip_path.id = path_id;
			clip_path.append(path);
			this.defs_element.append(clip_path);
		}

		// create new g element, using clip-path referencing clipPath id
		const new_group = new TagElement("g");
		new_group.setAttribute("clip-path", "url(#"+ path_id +")");

		// set new g element as current_element and append to parent group
		this.current_element = new_group;
		g.append(new_group);
	}
	arc(x, y, radius, startAngle, endAngle, counterclockwise?) {
		if (startAngle === endAngle) return;

		startAngle = startAngle % (2*Math.PI);
		endAngle = endAngle % (2*Math.PI);
		if (startAngle === endAngle) {
			//circle time! subtract some of the angle so svg is happy (svg elliptical arc can't draw a full circle)
			endAngle = ((endAngle + (2*Math.PI)) - 0.001 * (counterclockwise ? -1 : 1)) % (2*Math.PI);
		}
		let endX = x+radius*Math.cos(endAngle),
		endY = y + radius * Math.sin(endAngle),
		startX = x + radius * Math.cos(startAngle),
		startY = y + radius * Math.sin(startAngle),
		sweepFlag = counterclockwise ? 0 : 1,
		largeArcFlag = 0,
		diff = endAngle - startAngle;

		// https://github.com/gliffy/canvas2svg/issues/4
		if (diff < 0) {
			diff += 2 * Math.PI;
		}

		if (counterclockwise) {
			largeArcFlag = diff > Math.PI ? 0 : 1;
		} else {
			largeArcFlag = diff > Math.PI ? 1 : 0;
		}

		// move to start point only if we aren't already there
		if (this.cur_x != startX || this.cur_y != startY) {
			this.moveTo(startX, startY);
		}

		const path = this.current_element;
		if (path.name !== "path") throw "Error: called closePath() but no currently open path.";

		const cur_path = path.getAttribute("d");
		path.setAttribute("d", cur_path + " A "+ [radius, radius, 0, largeArcFlag, sweepFlag, endX, endY].join(" "));

		this.cur_x = endX;
		this.cur_y = endY;
	}
	ellipse(x, y, radiusX, radiusY, rotation, startAngle, endAngle, counterclockwise?) {
		this.arc(x, y, radiusX, startAngle, endAngle, counterclockwise);
	}
	rect(x, y, width, height) {
		// TODO: check if path already closed

		const path = this.current_element;
		if (path.name !== "path") throw "Error: called closePath() but no currently open path.";

		// TODO: only if not already closed
		let cur_path = path.getAttribute("d");
		cur_path += " M "+ x +" "+ y;
		cur_path += " L "+ (x + width) +" "+ y;
		cur_path += " L "+ (x + width) +" "+ (y + height);
		cur_path += " L "+ x +" "+ (y + height);
		cur_path += " L "+ x +" "+ y;
		path.setAttribute("d", cur_path + " M "+ x +" "+ y);
	}
	drawForeignObject(content, x, y, width?, height?) {
		// walk up current_element parents until "g" found
		let g = this.current_element;
		while (g && g.name !== "g") { g = g.parent_element; }
		if (g.name !== "g") throw "Error: called beginPath() but no <g> element in parent chain.";

		const foreignObject = new TagElement("foreignObject");
		foreignObject.setAttribute("x", x);
		foreignObject.setAttribute("y", y);
		if (width) {
			foreignObject.setAttribute("width", width);
		}
		if (height) {
			foreignObject.setAttribute("height", height);
		}

		foreignObject.setAttribute("style", "overflow: visible; font-size: 12px;");
		g.append(foreignObject);

		const div = new TagElement("div");
		div.setAttribute("xmlns", "http://www.w3.org/1999/xhtml");
		foreignObject.append(div);

		div.innerHTML += content;
	}
	drawImage(image, sx, sy, sWidth?, sHeight?, dx?, dy?, dWidth?, dHeight?) {
		console.log('drawImage - not implemented');
	}
	measureText(text): {width: number} {
		let str = "";
		if (typeof text == "string") str = text;
		else if (typeof text == "number") str = text.toString();
		else {
			console.log("Error: called measureText on non-string, non-number: ", text);
		}

		// TODO: could use char-width map to produce a more precise measurement for current font

		// server-side we can't easily measure text without emulating browser layout, but 8px per char is a decent avg.
		const width = str.length * 8;
		return { width: width };
	}
	parseFont() {
		const regex = /^\s*(?=(?:(?:[-a-z]+\s*){0,2}(italic|oblique))?)(?=(?:(?:[-a-z]+\s*){0,2}(small-caps))?)(?=(?:(?:[-a-z]+\s*){0,2}(bold(?:er)?|lighter|[1-9]00))?)(?:(?:normal|\1|\2|\3)\s*){0,3}((?:xx?-)?(?:small|large)|medium|smaller|larger|[.\d]+(?:\%|in|[cem]m|ex|p[ctx]))(?:\s*\/\s*(normal|[.\d]+(?:\%|in|[cem]m|ex|p[ctx])))?\s*([-,\'\"\sa-z0-9]+?)\s*$/i;
		const fontPart = regex.exec(this.font);
		const data = {
			style: fontPart[1] || 'normal',
			size: fontPart[4] || '10px',
			family: fontPart[6] || 'sans-serif',
			weight: fontPart[3] || 'normal',
			decoration: fontPart[2] || 'normal',
			href: null
		};
		return data;
	}
	fillText(text, x, y, maxWidth?) {
		// get current group context
		let g = this.current_element;
		while (g && g.name !== "g") { g = g.parent_element; }
		if (g.name !== "g") throw "Error: called restore() but no <g> element in parent chain.";

		// create text element
		const elem = new TagElement("text");
		g.append(elem);

		// decompose current font into size, style, weight
		const font = this.parseFont();
		elem.setAttribute("x", x);
		elem.setAttribute("y", y);
		elem.setAttribute("fill", this.fillStyle);
		elem.setAttribute("stroke", "none");
		elem.setAttribute("font-family", font.family);
		elem.setAttribute("font-size", font.size);
		elem.setAttribute("font-style", font.style);
		elem.setAttribute("font-weight", font.weight);
		elem.setAttribute("text-decoration", font.decoration);

		const anchor_mapping = {"left":"start", "right":"end", "center":"middle", "start":"start", "end":"end"};
		elem.setAttribute("text-anchor", anchor_mapping[this.textAlign] || "start");

		const baseline_mapping = {"alphabetic": "alphabetic", "hanging": "hanging", "top":"text-before-edge", "bottom":"text-after-edge", "middle":"central"};
		elem.setAttribute("dominant-baseline", baseline_mapping[this.textBaseline] || "alphabetic");

		elem.innerHTML += text;
	}
	getImageData(x, y, w, h){ return null; } // not implemented
	putImageData(image_data, x, y){} // not implemented

	serialize(){
		return this.root_element.outerHTML();
	}
}
