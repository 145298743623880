import * as katex from 'katex';
import * as jQuery from 'jquery';
import { QEHelper } from '../../common/QEHelper'

const QEEqKeyboard = function(QEEqKeyboardCommon){
	class QEEqKeyboard extends QEEqKeyboardCommon {

	static instantiate(serialized: string, resolved_data, options){
		resolved_data = resolved_data || {};
		options = options || {};
	
		let deserialized = JSON.parse(serialized);

		// parse display options
		let display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		if (display_options.char_limit) {
			display_options.char_limit = parseInt(display_options.char_limit);
		}
		if (display_options.per_char_limit) {
			try {
				display_options.per_char_limit = JSON.parse(display_options.per_char_limit);
			} catch(err) {
				display_options.per_char_limit = {};
			}
		}

		let widget = new QEEqKeyboard('', {type: deserialized.type, name: options.name, display_options: display_options});
		return widget;
	}

	constructor(str: string, options) {
		super(str, options);
	}

	bindEventHandlers(widget_container) {
		// KB input events are bound by the calling code since they don't just affect a single KB instance,
		//    but also involve document-level keypress handling, and de-focusing other KB inputs
		return;
	}

	getKeypadMarkup() {
		var kb_rows = this.kb_rows;
		var keymap = this.keys;

		var ml = '';
		ml += '<div class="keyboard col' + kb_rows[0].length + '">';
		for (var row = 0; row < kb_rows.length; row++) {
			var kb_row = kb_rows[row];
			ml += '<div class="keyrow">';
			for (var col = 0; col < kb_row.length; col++) {
				var key = kb_row[col];
				var display = keymap[key].display || keymap[key].val;

				// check string for '<katex>' and '</katex>' -> if found, latex render
				if (display.indexOf('<katex>') !== -1) {
					display = display.replace(/<katex>(.*?)<\/katex>/g, function (_full, inner) {
						return katex.renderToString(inner, { displayMode: true });
					});
				} else if (key == "*" && this.display_options && this.display_options.multiply_symbol) {
					// multiply symbol display override
					if (this.display_options.multiply_symbol == "\\times") {
						display = "&times;";
					}
				}
				var hover_label = keymap[key].label != undefined ? ' title="' + keymap[key].label + '"' : '';

				var type = keymap[key].type;
				ml += '<div class="keybox"' + hover_label + '>';
				ml += 	'<div class="key-wrap">';
				ml += 		'<div class="key" data-value="' + key + '" data-type="' + type + '">';
				ml += 			'<span>' + display + '</span>';
				ml += 		'</div>';
				ml += 	'</div>';
				ml += '</div>';
			}
			ml += '</div>';
		}
		ml += '</div>'; // keyboard
		return ml;
	}
	/**
	 * Sets the widget value
	 * @param {string} value
	 */
	setInputValue(value) { this.value = value; }
	/**
	 * Gets the widget value
	 */
	getInputValue(input_widgets?) { return this.value; }

	isUserInputComplete(): boolean {
		// ensure input content is valid
		if (this.tree.findAllChildren(function(item){ return item.type == 'EMPTY' ? item : false; }).length) {
			return false;
		}

		let input_value = this.getInputValue();
		return typeof input_value != 'undefined' && input_value !== '';
	}

	isUserInputAutoSubmittable(): boolean {
		return false;
	}

	getMarkup() {
		// NOTE: client QEEqKeyboard getMarkup() appears to only be called by question_logic.js,
		//    and is used on the client mainly for the keypad

		let display_options = this.display_options || {};

		var ml = '';
		ml += '<div ';
		ml += (this.name ? ' name="' + this.name + '"' : '');
		ml += (this.input_key_index !== undefined ? ' data-input_key_index="' + this.input_key_index + '"' : '');
		ml += ' class="input_board'+ (display_options.container_classes ? ' '+ display_options.container_classes : '') +'"';
		ml += (display_options.container_styles ? ' style="'+ display_options.container_styles +'"': '');
		ml += '>';
		ml += 	'<div class="input_bar">';
		ml += 		'<div class="edit_area">';
		ml += 			'<div class="placeholder">Enter your answer...</div>';
		ml += 			'<button class="full-clear">&#x2716;</button>';
		ml += 			'<div class="content"></div>';
		ml += 		'</div>';
		ml += 		'<div class="submit_area">';
		ml += 		'</div>';
		ml += 	'</div>';

		ml += this.getKeypadMarkup();
		ml += '</div>'; // input_board
		return ml;
	}
	// generates input_bar markup - show_cursor
	render() {
		var self = this;

		// generate keypad and input_bar 
		var ml = jQuery(this.getMarkup());
		ml.hide();
		this.keyboard_dom = ml;

		// display initial equation content
		ml.find('.input_bar .content').html(self.tree.display(Object.assign({
			show_cursor: 1,
			show_placeholders: 1,
			cursor_pos: self.cursor_index,
			token_array: self.token_array,
			show_implied_faded: 1
		}, this.display_options)));

		if (this.output_str != '') {
			ml.find('.input_bar .placeholder').hide();
			ml.find('.input_bar .full-clear').show();
		}

		ml.on('mouseup', '.keyboard .key', function (e) {
			if (e.which != 1)
				return;

			var key = jQuery(this);
			var val = key.attr('data-value');

			self.keypress(val);
		});

		// eslint-disable-next-line no-unused-vars
		ml.on('click', '.input_bar .full-clear', function (_e) {
			// set content to empty, and trigger re-render and callback
			self.keypress('FullClear');
		});

		/*
			// TODO: mouse handling
			ml.on('mouseup', '.input_bar', function(e){
				if (e.which != 1) return;
				if (jQuery(e.target).is('input')) return;
	    
				// get data-pos_index of target, or closest parent with data-pos_index set
				var pos_index_parents = jQuery(e.target).closest('[data-pos_index]');
				if (pos_index_parents.length) {
					var pos_index = jQuery(pos_index_parents[0]).attr('data-pos_index');
					// find QE.EqPart with matching pos_index
					var target = self.tree.find(function(item){ return item.pos_index && item.pos_index == pos_index ? item : false; } ).matches[0];
	    
					// remove cursor and insert after target
					var cursor = self.tree.find(function(item){ return item instanceof QE.EqPart.Cursor ? item : false; } ).matches[0];
	    
					if (cursor != target) {
						cursor.insertAfter(target);
					}
				} else {
					// TODO: move cursor to end of equation
				}
	    
				// redraw
				ml.find('.input_bar .content').html(self.tree.display({ pos_index: 1, show_placeholders: 1 }));
			});
		*/
		return this.keyboard_dom;
	}
	// generates keyboard dom and populates specified container with keyboard dom
	renderTo(container) {
		var keyboard_dom = this.render();
		container.children().remove();
		container.html(keyboard_dom);
	}
	setSubmitAreaContent(dom_fragment) {
		var keyboard_dom = this.keyboard_dom || this.render();
		keyboard_dom.find('.input_bar .submit_area').html(dom_fragment);
	}
	setTargetContainer(container) {
		this.target_container = container;
	}
	setUpdateCallback(callback) {
		this.update_callback = callback;
	}
	setSubmitCallback(callback) {
		this.submit_callback = callback;
	}
	disableInput(_val) {
		this.input_disabled = true;
	}
	enableInput(_val) {
		this.input_disabled = false;
	}
	keypress(val) {
		//console.log('EqKeyboard.prototype.keypress: ', val);
		var self = this;

		if (this.input_disabled) {
			return false;
		}

		// check if key is supported at all
		if (!this.keys[val])
			return false; // key not handled


		// restrict keypresses to only keys found on this keyboard, or as a key_alias for this keyboard
		var allowed_keys = { 'Home': 1, 'End': 1, 'Delete': 1, 'Backspace': 1, 'ArrowLeft': 1, 'ArrowRight': 1, 'FullClear': 1 };
		this.kb_rows.forEach(function (kb_row) {
			kb_row.forEach(function (col) { allowed_keys[col] = 1; });
		});
		Object.keys(this.key_aliases).forEach(function (key){ allowed_keys[key] = 1; });

		if (val == 'Enter') {
			if (this.submit_callback) {
				this.submit_callback();
			}
			return true; // key handled
		}

		// TODO: check for allowed key ranges (e.g. a-z)

		if (!allowed_keys[val])
			return false; // key not handled

		// apply key alias, if any
		if (this.key_aliases[val]) {
			val = this.key_aliases[val];
		}

		var type = this.keys[val].type;
		var subtype = this.keys[val].subtype;

		// only need to handle particular cases: Left, Right, Backspace, Delete, Insert
		// Issue: What to do with Backspace and Delete for multi-token nodes. E.g "\frac{","1","}{","2","}"
		// iterate over token_array to find cursor position
		var cursor_token_index = -1;
		var cursor_token_sub_index = 0;
		var running_length = 0;
		for (var i = 0; i < this.token_array.length; i++) {
			var token = this.token_array[i];
			// NOTE: zero-length tokens, such as EMPTY or implied MULTIPLY, result in cursor_token_index being AFTER those tokens
			if (this.cursor_index >= running_length && this.cursor_index < running_length + token.value.length) {
				cursor_token_index = i;
				cursor_token_sub_index = this.cursor_index - running_length;
				break;
			}
			running_length += token.value.length;
		}
		if (cursor_token_index == -1) {
			// we reached the end of the token array, set the cursor_token_index to last token + 1
			cursor_token_index = this.token_array.length;
		}

		// escapeRegExp - helper for escaping char search strings
		function escapeRegExp(string) { return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); }

		function handleKey(keyboard) {
			if (type == 'Insert') {
				// check if input is at total char limit
				if (keyboard.display_options.char_limit &&
					keyboard.output_str.length >= keyboard.display_options.char_limit) {
					// total char limit reached
					return;
				}

				// check if input is at per-char limit
				if (keyboard.display_options.per_char_limit &&
					keyboard.display_options.per_char_limit[val] !== undefined
				) {
					let char_matches = keyboard.output_str.match(new RegExp(escapeRegExp(val), "g"));
					if (keyboard.display_options.per_char_limit[val] == 0 ||
						char_matches && char_matches.length >= keyboard.display_options.per_char_limit[val]
					) {
						// per-char limit reached
						return;
					}
				}

				// create array of new token entries
				var new_tokens = ([].concat(keyboard.keys[val].val));

				// check if preceding token is implied and we're inserting the same value
				if (cursor_token_index && keyboard.token_array[cursor_token_index - 1].implied && new_tokens[0] == keyboard.token_array[cursor_token_index - 1].value) {
					// we're making the implied token explicit. No need to increment cursor position
				} else {
					// increment cursor_index by length of first new token inserted
					keyboard.cursor_index += new_tokens[0].length;
				}

				// NOTE: "current_token" is the token the cursor is currently within, or to the RIGHT of the cursor. Undefined if cursor at end.
				// TODO: ?? close following implied tokens?? E.g. "2+3)*5)" => "((2+3)*5)". If "7" inserted at index 1, should we get "7*(2+3)*5)" or "72+3)*5)"?
				// insert new tokens
				var current_token = keyboard.token_array[cursor_token_index];

				if (current_token && cursor_token_sub_index > 0) {
					// currently within a token
					if (self.keys[val].take_neighbouring) {
						// if "take_neighbouring" flag set, interleave the new tokens with current_token fragments
						current_token.value = new_tokens[0] +
							current_token.value.substr(0, cursor_token_sub_index) +
							new_tokens[1] +
							current_token.value.substr(cursor_token_sub_index) +
							new_tokens[2];
					} else {
						// if currently within a token, insert new tokens into token value
						current_token.value = current_token.value.substr(0, cursor_token_sub_index) + new_tokens.join('') + current_token.value.substr(cursor_token_sub_index);
					}
				} else {
					if (self.keys[val].take_neighbouring) {
						// handle new term opening token and delimiter
						if (cursor_token_index > 0 && ["RATIONAL", "VARIABLE"].indexOf(keyboard.token_array[cursor_token_index - 1].type) != -1) {
							// preceding token is RATIONAL or VARIABLE
							let preceding_val = keyboard.token_array[cursor_token_index - 1];

							// prepend new term opening token to preceding value, and append new term delimiter (comma)
							preceding_val.value = new_tokens[0] + preceding_val.value + new_tokens[1];
							keyboard.cursor_index += new_tokens[1].length;
						} else if (cursor_token_index > 1 &&
							keyboard.token_array[cursor_token_index - 1].type == "MULTIPLY" && keyboard.token_array[cursor_token_index - 1].value === "" &&
							["RATIONAL", "VARIABLE"].indexOf(keyboard.token_array[cursor_token_index - 2].type) != -1
						) {
							// preceding token is an implied MULTIPLY preceded by RATIONAL or VARIABLE
							let preceding_mult = keyboard.token_array[cursor_token_index - 1];
							let preceding_val = keyboard.token_array[cursor_token_index - 2];

							// prepend new term opening token to preceding value
							preceding_val.value = new_tokens[0] + preceding_val.value;

							// replace preceding implied multiply with new term delimiter (comma)
							preceding_mult.value = new_tokens[1];
							preceding_mult.implied = false;
							keyboard.cursor_index += new_tokens[1].length;
						} else {
							// not absorbing preceding value, simple insert of new term opening and delim tokens into token array
							keyboard.token_array = keyboard.token_array.slice(0, cursor_token_index).concat( // tokens up to cursor_token_index
								new_tokens.slice(0, 2).map(function(val){ return { value: val }; }), // new term opening and delim tokens, cast to token map format
								keyboard.token_array.slice(cursor_token_index) // remaining tokens
							);
							cursor_token_index += 2;
						}

						// handle new term closing token
						if (keyboard.token_array.length > cursor_token_index &&
							["RATIONAL", "VARIABLE"].indexOf(keyboard.token_array[cursor_token_index].type) != -1
						) {
							let following_val = keyboard.token_array[cursor_token_index];

							following_val.value += new_tokens[2]; // append new term closing token to following value
						} else {
							// not absorbing following value, simple insert of new term closing token into token array
							keyboard.token_array = keyboard.token_array.slice(0, cursor_token_index).concat(
								new_tokens.slice(2).map(function(val){ return { value: val }; }), // new term closing token, cast to token map format
								keyboard.token_array.slice(cursor_token_index) // remaining tokens
							);
						}
					} else {
						// else cast new tokens to token format and splice into token array
						new_tokens = new_tokens.map(function (val) { return { value: val }; });
						keyboard.token_array = keyboard.token_array.slice(0, cursor_token_index).concat(new_tokens, keyboard.token_array.slice(cursor_token_index));
					}
				}

				keyboard.output_str = keyboard.token_array.map(function (x) { return x.implied ? '' : x.value; }).join('');
			} else if (type == 'Modify') {
				if (subtype == 'PlusMinus') {
					// step back along the token list until we reach a MINUS, or a token that may be preceded by a MINUS
					var current_token = keyboard.token_array[cursor_token_index];
					while (cursor_token_index && (!current_token || ['EMPTY', 'INTEGER', 'DECIMAL', 'RATIONAL', 'FUNCTION_OPEN', 'FUNCTION', 'PARAMETER', 'INPUT', 'MINUS', 'VARIABLE'].indexOf(current_token.type) == -1)) {
						cursor_token_index--;
						current_token = keyboard.token_array[cursor_token_index];
					}

					if (!cursor_token_index) {
						if (current_token.type == 'MINUS') {
							keyboard.token_array.splice(cursor_token_index, 1);
						} else {
							keyboard.token_array.splice(cursor_token_index, 0, { type: "MINUS", value: "-" });
							keyboard.cursor_index++;
						}
					} else {
						if (current_token.type == 'MINUS') {
							keyboard.token_array.splice(cursor_token_index, 1);
						} else {
							var prev_token = keyboard.token_array[cursor_token_index - 1];
							if (prev_token.type != 'MINUS') {
								keyboard.token_array.splice(cursor_token_index, 0, { type: "MINUS", value: "-" });
								keyboard.cursor_index++;
							} else {
								keyboard.token_array.splice(cursor_token_index - 1, 1);
								if (keyboard.cursor_index) {
									keyboard.cursor_index--;
								}
							}
						}
					}
					keyboard.output_str = keyboard.token_array.map(function (x) { return x.implied ? '' : x.value; }).join('');
				}
			} else if (type == 'Backspace') {
				if (keyboard.cursor_index > 0) {
					var current_token = keyboard.token_array[cursor_token_index];
					if (current_token && current_token.separable && cursor_token_sub_index > 0) {
						// delete preceding character
						current_token.value = current_token.value.substr(0, cursor_token_sub_index - 1) + current_token.value.substr(cursor_token_sub_index);
						keyboard.output_str = keyboard.token_array.map(function (x) { return x.implied ? '' : x.value; }).join('');
						keyboard.cursor_index--;
					} else {
						cursor_token_index--;
						var prev_token = keyboard.token_array[cursor_token_index];

						// step past zero-length tokens
						while (cursor_token_index > 0 && prev_token.value.length == 0) {
							cursor_token_index--;
							prev_token = keyboard.token_array[cursor_token_index];
						}

						if (prev_token.implied) {
							// step over implied tokens
							keyboard.cursor_index -= prev_token.value.length;
						} else if (prev_token.separable && prev_token.value.length > 1) {
							// preceding token can be chopped up: remove its last character
							prev_token.value = prev_token.value.substr(0, prev_token.value.length - 1);
							keyboard.output_str = keyboard.token_array.map(function (x) { return x.implied ? '' : x.value; }).join('');
							keyboard.cursor_index--;
						} else if (prev_token.term && prev_token.term.tokens.length > 2) {
							// token belongs to a multi-argument FUNCTION
							var function_tokens = prev_token.term.tokens;

							// check if function contains only EMPTY children (sum of token lengths == total length of term)
							var total_length = 0;
							for (var i = 0; i < function_tokens.length; i++) {
								total_length += function_tokens[i].value.length;
							}
							var closing_token = function_tokens[function_tokens.length - 1];
							if (closing_token.offset + closing_token.value.length - function_tokens[0].offset == total_length) {
								// term is empty, delete it
								keyboard.cursor_index = function_tokens[0].offset;

								for (var i = function_tokens.length - 1; i >= 0; i--) {
									var token_to_remove = function_tokens[i];
									keyboard.token_array.splice(keyboard.token_array.indexOf(token_to_remove), 1);
								}
								keyboard.output_str = keyboard.token_array.map(function (x) { return x.implied ? '' : x.value; }).join('');
							} else if (prev_token.type == 'FUNCTION_OPEN') {
								// step out
								keyboard.cursor_index -= prev_token.value.length;
							} else if (prev_token.type == 'COMMA') {
								// check FUNCTION_OPEN "num_args" to determine whether to delete related tokens, or just COMMA
								var open_token = function_tokens[0];
								if (open_token.num_args) {
									// COMMA belongs to a function with a defined number of arguments.
									// remove function tokens, but leave children behind
									for (var i = function_tokens.length - 1; i >= 0; i--) {
										var token_to_remove = function_tokens[i];

										// if removed token is before cursor, update cursor position
										if (token_to_remove.offset < keyboard.cursor_index)
											keyboard.cursor_index -= token_to_remove.value.length;

										// splice token out of keyboard.token_array
										keyboard.token_array.splice(keyboard.token_array.indexOf(token_to_remove), 1);
									}
								} else {
									// remove preceding token
									keyboard.token_array.splice(keyboard.token_array.indexOf(prev_token), 1);
									keyboard.cursor_index -= prev_token.value.length;
								}
								keyboard.output_str = keyboard.token_array.map(function (x) { return x.implied ? '' : x.value; }).join('');
							} else if (prev_token.type == 'FUNCTION_CLOSE') {
								// step in
								keyboard.cursor_index -= prev_token.value.length;
							}
						} else if (prev_token.term && prev_token.term.tokens.length == 2) {
							// token belongs to BRACKETS or single-argument FUNCTION with opening and closing tokens
							// check if preceding token is a FUNCTION or has a matching implied token, and remove both if so
							var matching_token = prev_token.term.tokens.filter(function (x) { return x !== prev_token; })[0];
							if (prev_token.type == 'FUNCTION_OPEN' || matching_token.implied) {
								// remove matching token as well
								keyboard.token_array.splice(keyboard.token_array.indexOf(matching_token), 1);

								// decrement cursor position if matching token before cursor
								if (matching_token.offset < keyboard.cursor_index)
									keyboard.cursor_index -= matching_token.value.length;
							}
							// remove preceding token
							keyboard.token_array.splice(keyboard.token_array.indexOf(prev_token), 1);
							keyboard.cursor_index -= prev_token.value.length;

							keyboard.output_str = keyboard.token_array.map(function (x) { return x.implied ? '' : x.value; }).join('');
						} else {
							// remove preceding token
							keyboard.token_array.splice(keyboard.token_array.indexOf(prev_token), 1);
							keyboard.cursor_index -= prev_token.value.length;

							keyboard.output_str = keyboard.token_array.map(function (x) { return x.implied ? '' : x.value; }).join('');
						}
					}
				}
			} else if (type == 'Delete') {
				if (keyboard.cursor_index <= (keyboard.implied_string.length - 1)) {
					var next_token = keyboard.token_array[cursor_token_index];
					if (next_token.separable && cursor_token_sub_index < next_token.value.length) {
						// delete following character
						next_token.value = next_token.value.substr(0, cursor_token_sub_index) + next_token.value.substr(cursor_token_sub_index + 1);
						keyboard.output_str = keyboard.token_array.map(function (x) { return x.implied ? '' : x.value; }).join('');
					} else if (next_token.implied) {
						// step over implied tokens
						keyboard.cursor_index += next_token.value.length;
					} else if (next_token.term && next_token.term.tokens.length > 2) {

						// token belongs to a FUNCTION
						var function_tokens = next_token.term.tokens;

						// check if function contains only EMPTY children (sum of token lengths == total length of term)
						var total_length = 0;
						for (var i = 0; i < function_tokens.length; i++) {
							total_length += function_tokens[i].value.length;
						}
						var closing_token = function_tokens[function_tokens.length - 1];
						if (closing_token.offset + closing_token.value.length - function_tokens[0].offset == total_length) {
							// term is empty, delete it
							keyboard.cursor_index = function_tokens[0].offset;

							for (var i = function_tokens.length - 1; i >= 0; i--) {
								var token_to_remove = function_tokens[i];
								keyboard.token_array.splice(keyboard.token_array.indexOf(token_to_remove), 1);
							}
							keyboard.output_str = keyboard.token_array.map(function (x) { return x.implied ? '' : x.value; }).join('');
						} else if (next_token.type == 'FUNCTION_OPEN') {
							// step in
							keyboard.cursor_index += next_token.value.length;
						} else if (next_token.type == 'COMMA') {
							// check FUNCTION_OPEN "num_args" to determine whether to delete related tokens, or just COMMA
							var opening_token = function_tokens[0];
							if (opening_token.num_args) {
								// COMMA belongs to a function with a defined number of arguments.
								// remove function tokens, but leave children behind
								for (var i = function_tokens.length - 1; i >= 0; i--) {
									var token_to_remove = function_tokens[i];

									// if removed token is before cursor, update cursor position
									if (token_to_remove.offset < keyboard.cursor_index)
										keyboard.cursor_index -= token_to_remove.value.length;

									// splice token out of keyboard.token_array
									keyboard.token_array.splice(keyboard.token_array.indexOf(token_to_remove), 1);
								}
							} else {
								// remove following token
								keyboard.token_array.splice(keyboard.token_array.indexOf(next_token), 1);
							}

							keyboard.output_str = keyboard.token_array.map(function (x) { return x.implied ? '' : x.value; }).join('');
						} else if (next_token.type == 'FUNCTION_CLOSE') {
							// step out
							keyboard.cursor_index += next_token.value.length;
						}
					} else if (next_token.term && next_token.term.tokens.length == 2) {
						// token belongs to BRACKETS or single-argument FUNCTION with opening and closing tokens
						// check if following token is a FUNCTION or has a matching implied token, and remove both if so
						var matching_token = next_token.term.tokens.filter(function (x) { return x !== next_token; })[0];
						if (next_token.type == 'FUNCTION_OPEN' || matching_token.implied) {
							// remove matching token as well
							keyboard.token_array.splice(keyboard.token_array.indexOf(matching_token), 1);

							// decrement cursor position if matching token before cursor
							if (matching_token.offset < keyboard.cursor_index)
								keyboard.cursor_index -= matching_token.value.length;
						}

						// remove following token
						keyboard.token_array.splice(keyboard.token_array.indexOf(next_token), 1);
						keyboard.output_str = keyboard.token_array.map(function (x) { return x.implied ? '' : x.value; }).join('');
					} else {
						// remove following token
						keyboard.token_array.splice(keyboard.token_array.indexOf(next_token), 1);
						keyboard.output_str = keyboard.token_array.map(function (x) { return x.implied ? '' : x.value; }).join('');
					}
				}
			} else if (type == 'CursorLeft') {
				if (keyboard.cursor_index > 0) {
					var current_token = keyboard.token_array[cursor_token_index];
					if (current_token && current_token.separable && cursor_token_sub_index > 0)
						keyboard.cursor_index--;
					else {
						cursor_token_index--;
						var prev_token = keyboard.token_array[cursor_token_index];

						// step past zero-length tokens
						while (cursor_token_index > 0 && prev_token.value.length == 0) {
							cursor_token_index--;
							prev_token = keyboard.token_array[cursor_token_index];
						}

						if (prev_token.separable) {
							keyboard.cursor_index--;
						} else {
							keyboard.cursor_index -= prev_token.value.length;
						}
					}
				}
			} else if (type == 'CursorRight') {
				if (keyboard.cursor_index < keyboard.implied_string.length) {

					var current_token = keyboard.token_array[cursor_token_index];
					if (current_token.separable) {
						keyboard.cursor_index++;
					} else {
						keyboard.cursor_index += current_token.value.length;
					}
				}
			} else if (type == 'Home') {
				keyboard.cursor_index = 0;
			} else if (type == 'End') {
				keyboard.cursor_index = keyboard.implied_string.length;
			} else if (type == 'FullClear') {
				keyboard.cursor_index = 0;
				keyboard.output_str = '';
				keyboard.token_array = [];
			}
		}

		handleKey(this);

		this.setEquation(this.output_str);

		// re-render edit bar
		var ml = this.keyboard_dom;

		// if prefix content present, update cursor pos to reflect the prefix offset in the tree
		var prefix_offset = 0;
		if (this.display_options.prefix) {
			prefix_offset = this.display_options.prefix.length;
		}

		ml.find('.input_bar .content').html(self.tree.display(Object.assign({
			show_cursor: 1,
			show_placeholders: 1,
			cursor_pos: self.cursor_index + prefix_offset,
			token_array: self.token_array,
			show_implied_faded: 1
		}, self.display_options)));

		// hide/display placeholder text
		if (this.output_str == '') {
			ml.find('.input_bar .placeholder').show();
			ml.find('.input_bar .full-clear').hide();
		} else {
			ml.find('.input_bar .placeholder').hide();
			ml.find('.input_bar .full-clear').show();
		}

		// reflect input contents in target input field, if any
		if (this.target_container) {
			this.target_container.html(self.tree.display(Object.assign({
				show_cursor: 1,
				show_placeholders: 1,
				cursor_pos: self.cursor_index + prefix_offset,
				token_array: self.token_array,
				show_implied_faded: 1
			}, this.display_options)));
		}

		// update
		if (self.update_callback)
			self.update_callback(self.tree);

		return true; // key handled
	}

	} // class QEEqKeyboard
	return QEEqKeyboard;
}
export { QEEqKeyboard };
