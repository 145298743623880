import { SvgRenderingContext } from '../../common/SvgRenderingContext';
import { QEGraph } from '../QEGraph';
import { Label } from './Label';
import { Point } from './Point';

interface ScreenPos {
    x1: number,
    x2: number,
    y1: number,
    y2: number
}

export class Line {
	lineType: string;
	line_color: string;
	line_width: number;
	x1: number;
	x2: number;
	y1: number;
	y2: number;
	label: string;
	label_pos: string;
	start_point: string;
	end_point: string;
	line_style: string;
	isArrowheadSolid: boolean;
	arrowheadLength: number;
	arrowheadWidth: number;
	x: number; // supported for case where single point data passed
	y: number;
	state: string;

	constructor(data) {
		// Default line properties
		this.lineType = "segment"; // segment, ray, line
		this.line_color = "#D00";
		this.line_width = 2;
		this.x1 = NaN;
		this.y1 = NaN;
		this.x2 = NaN;
		this.y2 = NaN;
		this.x = NaN;
		this.y = NaN;
		this.label = "";
		this.label_pos = "N";
		this.start_point = "none";
		this.end_point = "none";
		this.line_style = "solid";

		// Add new and override any default properties
		for (const prop in data) {
			if (data[prop] !== undefined) {
				// attempt to cast to number or boolean
				if (!Number.isNaN(Number(data[prop]))) this[prop] = Number(data[prop]);
				else if (data[prop] === true || data[prop] === false) this[prop] = data[prop];
				else if (data[prop] === "true") this[prop] = true;
				else if (data[prop] === "false") this[prop] = false;
				else this[prop] = data[prop];
			}
		}

		if (isNaN(this.x1) && isNaN(this.x2) && isNaN(this.y1) && isNaN(this.y2) &&
			!isNaN(this.x) && !isNaN(this.y)
		) {
			this.x1 = this.x;
			this.x2 = this.x;
			this.y1 = this.y;
			this.y2 = this.y;
			this.start_point = this.state;
		}
	}

	plot(graph: QEGraph, ctx: SvgRenderingContext): void {
		const screenPos: ScreenPos = {
			x1: graph.screenX(this.x1),
			y1: graph.screenY(this.y1),
			x2: graph.screenX(this.x2),
			y2: graph.screenY(this.y2),
		};

		// don't draw lines where coords are outside of graph area
		const plotMinX = graph.padding_left;
		const plotMaxX = graph.padding_left + graph.plot_width;
		const plotMinY = graph.padding_top;
		const plotMaxY = graph.padding_top + graph.plot_height;
		if (screenPos.x1 > plotMaxX && screenPos.x2 > plotMaxX) return;
		if (screenPos.x1 < plotMinX && screenPos.x2 < plotMinX) return;
		if (screenPos.y1 > plotMaxY && screenPos.y2 > plotMaxY) return;
		if (screenPos.y1 < plotMinY && screenPos.y2 < plotMinY) return;

		// save context style info
		ctx.save();

		// Set line styling
		if (this.lineType == "line") {
			this.start_point = "arrow";
			this.end_point = "arrow";
		} else if (this.lineType == "ray") {
			this.end_point = "arrow";
		}
		ctx.lineWidth = this.line_width;
		ctx.fillStyle = ctx.strokeStyle = this.line_color;

		if (this.line_style == "dotted") {
			ctx.setLineDash([5, 5]);
		} else if (this.line_style == "dashed") {
			ctx.setLineDash([5, 15]);
		} else if (this.line_style == "none") {
			ctx.strokeStyle = "rgba(0,0,0,0)";
		}

		// create clipping area, to restrict line drawing to graph area
		ctx.save();
		ctx.beginPath();
		ctx.rect(graph.padding_left, graph.padding_top, graph.plot_width, graph.plot_height);
		ctx.clip();

		// draw the line
		this.drawSegment(ctx, screenPos);

		ctx.restore();

		let startRadians, endRadians;

		// draw line segment end point
		if (this.start_point == "closed" || this.start_point == "open") {
			new Point({
				x: this.x1,
				y: this.y1,
				color: this.line_color,
				radius: this.line_width * 2,
				state: this.start_point,
			}).plot(graph, ctx);
		} else if (this.start_point == "arrow") {
			// draw the starting arrowhead
			if (screenPos.x1 !== screenPos.x2) {
				startRadians = Math.atan((screenPos.y2 - screenPos.y1) / (screenPos.x2 - screenPos.x1));
				startRadians += ((screenPos.x2 > screenPos.x1 ? -90 : 90) * Math.PI) / 180;
				this.drawArrowhead(ctx, screenPos.x1, screenPos.y1, startRadians);
			} else {
				// straight vertical rays
				startRadians = Math.atan((screenPos.y2 - screenPos.y1) / (screenPos.x2 - screenPos.x1));
				startRadians += ((screenPos.x2 > screenPos.x1 ? 90 : -90) * Math.PI) / 180;
				this.drawArrowhead(ctx, screenPos.x1, screenPos.y1, startRadians);
			}
		}

		// draw line segment end point
		if (this.end_point == "closed" || this.end_point == "open") {
			new Point({
				x: this.x2,
				y: this.y2,
				color: this.line_color,
				radius: this.line_width * 2,
				state: this.end_point,
			}).plot(graph, ctx);
		} else if (this.end_point == "arrow") {
			// draw the end arrowhead
			if (screenPos.x1 !== screenPos.x2) {
				endRadians = Math.atan((screenPos.y2 - screenPos.y1) / (screenPos.x2 - screenPos.x1));
				endRadians += ((screenPos.x2 > screenPos.x1 ? 90 : -90) * Math.PI) / 180;
				this.drawArrowhead(ctx, screenPos.x2, screenPos.y2, endRadians);
			} else {
				// straight vertical rays
				endRadians = Math.atan((screenPos.y2 - screenPos.y1) / (screenPos.x2 - screenPos.x1));
				endRadians += ((screenPos.x2 > screenPos.x1 ? -90 : 90) * Math.PI) / 180;
				this.drawArrowhead(ctx, screenPos.x2, screenPos.y2, endRadians);
			}
		}

		// restore context style info
		ctx.restore();

		// Add the label
		if (this.label) {
			// Use the line's midpoint for the label's reference pt
			new Label({
				x: (this.x2 - this.x1) / 2 + this.x1,
				y: (this.y2 - this.y1) / 2 + this.y1,
				color: this.line_color,
				value: this.label,
				pos: this.label_pos,
			}).plot(graph, ctx);
		}

		// TODO: start_point_label, end_point_label
	}

	drawSegment(ctx: SvgRenderingContext, screenPos: ScreenPos): void {
		ctx.beginPath();
		ctx.moveTo(screenPos.x1, screenPos.y1);
		ctx.lineTo(screenPos.x2, screenPos.y2);
		ctx.stroke();
	}

	drawArrowhead(ctx: SvgRenderingContext, x: number, y: number, radians: number): void {
		const isSolid = this.isArrowheadSolid || false;
		const arrowheadLength = this.arrowheadLength || 10; // x
		const arrowheadWidth = this.arrowheadWidth || 5;

		ctx.save();
		ctx.setLineDash([]); // clear any dotted/dashed style
		ctx.beginPath();
		ctx.translate(x, y);
		ctx.rotate(radians);

		if (isSolid) {
			ctx.moveTo(0, 0);
			ctx.lineTo(arrowheadWidth, arrowheadLength);
			ctx.lineTo(-arrowheadWidth, arrowheadLength);
			ctx.closePath();
			ctx.restore();
			ctx.fill();
		} else {
			ctx.moveTo(0, 0);
			ctx.lineTo(arrowheadWidth, arrowheadLength);
			ctx.stroke();
			ctx.moveTo(0, 0);
			ctx.lineTo(-arrowheadWidth, arrowheadLength);
			ctx.stroke();
			ctx.restore();
		}
	}
}
