import { QETerm } from '../QETerm';
import { log } from '../QE';
import { QEHelper, shuffle } from '../QEHelper';
import { QEWidget, DisplayOptions } from '../QEWidget';
import * as jQuery from 'jquery';

export class QEWidgetVerticalMath extends QEWidget {
	display_options: { [key: string]: any };
	value: QETerm;
	user_value: QETerm;
//	name: string;

	// VerticalMath is used to present the user with a vertically-oriented addition/subtraction/multiplication expression/equation
	constructor(value: QETerm, display_options?: DisplayOptions = {}, input_options) {
		super();

		this.value = value;
		this.user_value = '';
		this.cursor_pos = 0;
		this.display_options = display_options;
		this.input_options = input_options;

		this.keys = {
			'1': { val: '1', type: 'Insert', subtype: 'Integer' },
			'2': { val: '2', type: 'Insert', subtype: 'Integer' },
			'3': { val: '3', type: 'Insert', subtype: 'Integer' },
			'4': { val: '4', type: 'Insert', subtype: 'Integer' },
			'5': { val: '5', type: 'Insert', subtype: 'Integer' },
			'6': { val: '6', type: 'Insert', subtype: 'Integer' },
			'7': { val: '7', type: 'Insert', subtype: 'Integer' },
			'8': { val: '8', type: 'Insert', subtype: 'Integer' },
			'9': { val: '9', type: 'Insert', subtype: 'Integer' },
			'0': { val: '0', type: 'Insert', subtype: 'Integer' },
			'.': { val: '.', type: 'Insert', subtype: 'Decimal' },
			// cursor control
			'Backspace': { val: '&#9003;', type: 'Backspace' },
			'Delete': { val: '&#8998;', type: 'Delete' },
			'ArrowLeft': { val: '&larr;', type: 'CursorLeft' },
			'ArrowRight': { val: '&rarr;', type: 'CursorRight' },
			'ArrowUp': { val: '&uarr;', type: 'CursorUp' },
			'ArrowDown': { val: '&darr;', type: 'CursorDown' },
			'Home': { val: 'Home', type: 'Home' },
			'End': { val: 'End', type: 'End' },
			'Enter': { val: 'Enter', type: 'Enter' },
		};
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		const deserialized = JSON.parse(serialized);

		const resolved_value = QEHelper.resolvePlaceholderToTree(deserialized.value, resolved_data);
		if (!resolved_value) return null;

		// TODO: verify resolved_value.value is either isAddChain with all ops the same, or isComparatorChain with only EQUAL comparisons
		if (!resolved_value.value.children[0].isAddChain() &&
			!resolved_value.value.children[0].isComparatorChain()
		) {
			log.warn('Invalid input. VeticalMath requires isAddChain or isComparatorChain, not: ', resolved_value.value.serialize_to_text());
			return null;
		}

		// build map and resolve any [$name] placeholders in display_options
		const display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let input_options = {};
		input_options.max_digits = deserialized.display_options.max_digits;

		let widget = new QEWidgetVerticalMath(resolved_value.value, display_options, input_options);
		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options = {}) {
		const self = this;

		let display_options = Object.assign({}, this.display_options, options);
		let container_classes = display_options.container_classes || "";
		let container_styles = '';
		if (display_options.container_styles) {
			container_styles = ' style="' + display_options.container_styles + '"';
		}

		// split expression into multiple rows
		let rows = [];

		function appendAddTerms(add_term, preceding_cmp){
			// TODO: check if add_term is an add chain or a single term
			if (add_term.isAddChain()) {
				for (let child_idx = 0; child_idx < add_term.children.length; child_idx += 2){
					let child = add_term.children[child_idx];
					if (!child_idx) {
						if (preceding_cmp) {
							rows.push({
								value: child.serialize_to_text(),
								preceding_op: preceding_cmp.serialize_to_text()
							});
						} else {
							rows.push({ value: child.serialize_to_text()});
						}
						continue;
					}

					let preceding_op = add_term.children[child_idx - 1];
					rows.push({
						value: child.serialize_to_text(),
						preceding_op: preceding_op.serialize_to_text(),
					});
				}
			} else {
				// single term
				if (preceding_cmp) {
					rows.push({
						value: add_term.serialize_to_text(),
						preceding_op: preceding_cmp.serialize_to_text()
					});
				} else {
					rows.push({ value: add_term.serialize_to_text()});
				}
			}
		}

		let value = this.value.children[0];
		if (value.isComparatorChain()) {
			appendAddTerms(value.children[0]);
			for (let child_idx = 1; child_idx < value.children.length; child_idx += 2){
				appendAddTerms(value.children[child_idx + 1], value.children[child_idx]);
			}
		} else {
			appendAddTerms(value);
		}

		// TODO: min_digits display option

		// number of cols determined by max of longest term digits and min_digits display option, plus one for the op
		let num_cols = 2; // at least two columns, for operator and at least one digit
		rows.forEach((row)=>{
			if (row.value != '\\blank' && row.value.length >= num_cols) {
				num_cols = row.value.length + 1; // plus one for op column
			}
		});
		if (self.user_value.length >= num_cols) {
			num_cols = self.user_value.length + 1; // plus one for op column
		}
		if (this.display_options.highlight_input_col) {
			container_classes += ' highlight-rcol'+ (this.cursor_pos + 1);
		}

		let ml = '';
		ml += '<div class="vertical-math '+ container_classes +'"'+ container_styles +'>';
		ml += 	'<table><tbody>';

		// TODO: carry row - needs to be an array so it can have empty columns

		// one row per term
		rows.forEach((row)=>{
			let is_input_row = false;
			let cursor_col = num_cols - 1 - self.cursor_pos;

			let term_digits = [];
			if (row.value == '\\blank') {
				is_input_row = true;
				if (self.user_value.length) {
					term_digits = self.user_value.split('');
				}
			} else {
				term_digits = row.value.split('');
			}

			// sum/equals line
			if (row.preceding_op == '=') {
				ml += 	'<tr class="after-equals'+ (is_input_row ? ' blank' : '') +'">';
			} else {
				ml += 	'<tr class="'+ (is_input_row ? 'blank' : '') +'">';
			}

			// op column
			if (['+','-'].indexOf(row.preceding_op) != -1) {
				ml += '<td>'+ row.preceding_op +'</td>'
			} else if (is_input_row && cursor_col == 0) {
				ml += '<td><span class="cursor">|</span></td>';
			} else {
				ml += '<td></td>'
			}

			for (let col_idx = 1; col_idx < num_cols; col_idx++){
				if (num_cols - col_idx <= term_digits.length) {
					ml += '<td>';
					ml += term_digits[term_digits.length - num_cols + col_idx];
					if (is_input_row && col_idx == cursor_col) {
						ml += '<span class="cursor">|</span>';
					}
					ml += '</td>';
				} else {
					ml += '<td>';
					if (is_input_row && col_idx == cursor_col) {
						ml += '<span class="cursor">|</span>';
					}
					ml += '</td>';
				}
			}
			ml += 	'</tr>';
		});

		ml += 	'</tbody></table>';
		ml += '</div>';
		return ml;
	}

	bindEventHandlers(widget_container) {
		// VerticalMath input events are bound by the calling code since they may involve interactions between multiple widgets
		return;
	}

	keypress(val) {
		// NOTE: a handled keypress updates user_value and/or cursor_pos.
		// - If an update_callback has been set by the calling code, then this calls the callback with the updated content
		var self = this;

		if (this.input_disabled) {
			return false;
		}

		// check if key is supported at all
		if (!this.keys[val]) return false; // key not handled

		if (val == 'Enter') {
			if (this.submit_callback) {
				this.submit_callback();
			}
			return true; // key handled
		}

		let type = this.keys[val].type;
		let subtype = this.keys[val].subtype;

		function handleKey(self) {
			// split user_value into digits, insert new digit at cursor_pos (RTL)
			let digits = [];
			if (self.user_value.length) {
				digits = self.user_value.split('');
			}

			if (type == 'Insert') {
				// TODO: max_digits input option

				// check if input is at total char limit
				if (self.display_options.char_limit &&
					self.user_value.length >= self.display_options.char_limit) {
					// total char limit reached
					return;
				}

				digits.splice(digits.length - self.cursor_pos, 0, val)

				// update cursor_pos
				self.cursor_pos++;

				// set new value
				self.user_value = digits.join('');
			} else if (type == 'Backspace') {
				if (self.cursor_pos < digits.length) {
					// delete digit to the left. No change to cursor_pos
					digits.splice(digits.length - self.cursor_pos - 1, 1);

					// set new value
					self.user_value = digits.join('');
				}
			} else if (type == 'Delete') {
				if (self.cursor_pos) {
					// delete digit to the right. Decrement cursor_pos
					digits.splice(digits.length - self.cursor_pos, 1);

					self.cursor_pos--;

					// set new value
					self.user_value = digits.join('');
				}
			} else if (type == 'CursorLeft') {
				if (self.cursor_pos < digits.length) self.cursor_pos++;
			} else if (type == 'CursorRight') {
				if (self.cursor_pos) self.cursor_pos--;
			} else if (type == 'Home') {
				self.cursor_pos = digits.length;
			} else if (type == 'End') {
				self.cursor_pos = 0;
			}
		}

		handleKey(this);

		// update
		if (this.update_callback) this.update_callback(this.user_value);

		return true;
	}


	setUpdateCallback(callback) {
		this.update_callback = callback;
	}
	setSubmitCallback(callback) {
		this.submit_callback = callback;
	}
	disableInput(_val) {
		this.input_disabled = true;
	}
	enableInput(_val) {
		this.input_disabled = false;
	}

	setInputValue(value: string[]) {
		// not needed - handled in keypress logic
	}

	getInputValue(input_widgets?): string[] { return this.user_value; }

	isUserInputComplete(): boolean {
		let input_value = this.getInputValue();
		return typeof input_value != 'undefined' && input_value !== '';
	}

	isUserInputAutoSubmittable(): boolean {
		return false;
	}

	serialize_to_text(): string {
		return JSON.stringify(this.user_value);
	}

	exportValue(options?){
		return {
			type: 'vertical-math',
			name: this.name,
			value: this.value.serialize_to_text(),
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

