import { QETerm } from '../QETerm';
import { QEHelper } from '../QEHelper';
import { QEWidget, DisplayOptions, TagElement } from '../QEWidget';
import * as jQuery from 'jquery';

export class QEWidgetFractionShape extends QEWidget {
	display_options: { [key: string]: any };
	value: QETerm;
	user_value: string;

	fill_ccw: boolean;
	fill_colour: string;
	fill_offset: number;
	is_polygon: boolean;
	line_colour: string;
	width: number;

	static default_style = {
		fill_ccw: false,
		fill_colour: '#0091ff',
		fill_offset: 0,
		is_polygon: false,
		line_colour: '#000000',
	};

	constructor(value: QETerm, display_options: DisplayOptions = {}) {
		super();

		this.value = value;
		this.user_value = value.serialize_to_text();
		this.display_options = display_options;

		// apply default style
		Object.assign(this, QEWidgetFractionShape.default_style);

		// apply side length, angle settings, and any style overrides
		Object.assign(this, display_options);
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		let deserialized = JSON.parse(serialized);

		// resolve any references in value
		let resolved = QEHelper.resolvePlaceholderToTree(deserialized.value, resolved_data);
		if (!resolved) return null;

		// validate resolve value is a numeric fraction
		let value = resolved.value;
		if (value.children[0].value != "frac") {
			console.log("FractionShape value must be a QETerm tree with an integer frac");
			return null;
		}
		let frac = value.children[0];
		let numerator = Number(frac.children[0].serialize_to_text());
		let denominator = Number(frac.children[1].serialize_to_text());

		if (isNaN(numerator) || !Number.isInteger(numerator)) {
			console.log("FractionShape value is a fraction but numerator is not an integer");
			return null;
		}
		if (isNaN(denominator) || !Number.isInteger(denominator)) {
			console.log("FractionShape value is a fraction but denominator is not an integer");
			return null;
		}

		// build map and resolve any [$name] placeholders in display_options
		let display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new QEWidgetFractionShape(value, display_options);

		// for polygons, must have at least three segments
		if (widget.is_polygon && denominator < 3) {
			console.log("FractionShape value is_polygon but denominator is < 3. Polygons have at least three sides.");
			return null;
		}

		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} value
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options) {
		// TODO: support passed display option overrides

		return this.draw();
	}

	draw() {
		const svgWidth = 200;
		const svgHeight = 200;

		const padding = 5; // padding to prevent edge clipping
		const size = 200 + padding * 2; // draw size

		// value is a QETerm tree with a frac{int, int} child
		const frac = this.value.children[0];
		const numerator = Number(frac.children[0].serialize_to_text());;
		const denominator = Number(frac.children[1].serialize_to_text());

		const a = 360 / denominator;
		const r = size / 2 - padding;
		const cx = size / 2;
		const cy = size / 2;

		if (!this.width) this.width = size;

		const svg = new TagElement("svg");
		svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
		svg.setAttribute('viewBox', [0, 0, size, size].join(' '));
		svg.setAttribute('width', this.width.toString());

		// BUILD THE SHAPE
		let markup = '';
		const transforms = [];

		// Determine the points for the repeating shape
		// Based on the rotation, calculate the other point using the cartesian system
		// x' = xCosØ - ySinØ
		// y' = yCosØ + xSinØ
		const theta = -a * (Math.PI/180); // convert to radians
		const x = ((0 * Math.cos(theta) - 1 * Math.sin(theta)) * r + r) + padding;
		const y = (r - (1 * Math.cos(theta) + 0 * Math.sin(theta)) * r) + padding;
		
		const pts = [];

		pts[0] = ['M', cx, cy].join(' ');
		pts[1] = ['L', cx, padding].join(' ');
		pts[2] = ['A', r, r, 0, 0, 1, x.toFixed(2), y.toFixed(2), 'Z'].join(' ');

		if (this.is_polygon) {
			pts[2] = ['L', x.toFixed(2), y.toFixed(2)].join(' ');
			if (denominator === 4) { // is a square
				pts[1] = ['L', cx, padding * 2].join(' ');
				pts[2] = ['L', cx + r - padding, padding * 2].join(' ');
				pts[3] = ['L', cx + r - padding, cy, 'Z'].join(' ');
			}
		}

		// Generate the segments
		// Apply a transform on the group to apply ccw and offset
		if (this.fill_ccw) {
			transforms.push('scale(' + [-1, 1].toString() +')');
			transforms.push('translate(' + [-size, 0].toString() +')');
		}
		if (this.fill_offset) {
			transforms.push('rotate(' + [a * this.fill_offset, cx, cy].toString() +')');
		}

		// style node and fill classes
		markup += '<style>';
		markup += 	'g.fraction-shapes__segments path.no-fill { fill: none;}';
		markup += 	'g.fraction-shapes__segments path.filled { fill: '+ this.fill_colour +' }';
		markup += 	'g.fraction-shapes__segments path.no-fill.hover { fill: '+ this.fill_colour +'; opacity: 0.25; }';
		markup += 	'g.fraction-shapes__segments path.filled.hover { fill: '+ this.fill_colour +'; opacity: 0.75; }';
		markup += '</style>';

		let class_modifiers = '';
		markup += '<g class="fraction-shapes__segments" transform="' + transforms.join(' ') +'">';
		if (denominator == 1) {
				// special case for unsegmented circle
				if (numerator == denominator) class_modifiers = ' filled';
				else class_modifiers = ' no-fill';

				markup += '<circle data-index="0" pointer-events="visible" class="fraction-shape__segment'+ class_modifiers +'" cx="'+ cx +'" cy="'+ cy +'" r="'+ r +'" stroke="'+ this.line_colour +'" stroke-width="2"></circle>';
		} else {
			for (let i = 0; i < denominator; i++) {
				const rot = a * i;
				const arr = [rot, cx, cy];
				const transform = 'rotate('+ arr.toString() +')';
				if (i < numerator) class_modifiers = ' filled';
				else class_modifiers = ' no-fill';

				markup += '<path data-index="'+ i +'" pointer-events="visible" class="fraction-shape__segment'+ class_modifiers +'" d="'+ pts.join(' ') +'" stroke="'+ this.line_colour +'" stroke-width="2" transform="'+ transform +'"/>';
			}
		}
		markup += '</g>';

		svg.innerHTML += markup;

		return svg.outerHTML();
	}

	bindEventHandlers(widget_container) {
		const self = this;

		widget_container.on('mouseenter', 'svg path.fraction-shape__segment', function(){
			if (widget_container.is('.disable_input')) return; // skip if disabled
			jQuery(this).addClass('hover');
		}).on('mouseleave', 'svg path.fraction-shape__segment', function(){
			if (widget_container.is('.disable_input')) return; // skip if disabled
			jQuery(this).removeClass('hover');
		}).on('click', 'svg path.fraction-shape__segment', function(){
			if (widget_container.is('.disable_input')) return; // skip if disabled

			const event_item = jQuery(this);
			if (event_item.hasClass('filled')) {
				event_item.removeClass('filled');
				event_item.addClass('no-fill');
			} else {
				event_item.removeClass('no-fill');
				event_item.addClass('filled');
			}

			// get the number of filled slices
			const frac = self.value.children[0];
			const den = frac.children[1].serialize_to_text();
			const num = widget_container.find('svg path.fraction-shape__segment.filled').length;

			const new_value = '\\frac{'+ num +','+ den +'}';
			self.setInputValue(new_value);
		});
	}

	setInputValue(value: string) {
		this.user_value = value;
	}

	getInputValue(input_widgets?): string { return this.user_value; }

	isUserInputComplete(): boolean {
		// FractionShape has a user_value pre-populated and so is always "complete"
		return true;
	}

	exportValue(options?){
		return {
			type: 'fraction_shape',
			value: this.value,
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

