import { QETerm } from '../QETerm';
import { QEHelper, shuffle } from '../QEHelper';
import { QEWidget, DisplayOptions } from '../QEWidget';
import * as jQuery from 'jquery';

export class QEWidgetDragSource extends QEWidget {
	display_options: { [key: string]: any };
	dataset_from_ref: boolean;
	dataset: { display: string, value: string, quantity: number }[];
	name: string;

	// DragSource is used to present the user with a collection of draggable items
	constructor(dataset: { display: string, value: string, quantity: number }[], display_options?: DisplayOptions = {}) {
		super();

		dataset.forEach(function(item){ item.quantity = Number(item.quantity); });
		this.dataset = dataset;

		this.display_options = display_options;

//		if (display_options.name) this.name = display_options.name;
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		const deserialized = JSON.parse(serialized);

		// each dataset item should have a value (string), a quantity (number), and optional display (placeholder reference?) field
		const dataset = QEHelper.resolveDataset(serialized, resolved_data, options);
		if (!dataset) return null; // unresolved dependency, or resolution error

		// cull duplicates
		let dataset_values = [];
		dataset = dataset.filter(function(item){
			if (dataset_values.indexOf(item.value) != -1) return false;
			dataset_values.push(item.value);
			return true;
		});

		// shuffle values
		if (deserialized.shuffle) {
			dataset = shuffle(dataset);
		}

		// build map and resolve any [$name] placeholders in display_options
		const display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new QEWidgetDragSource(dataset, display_options);
		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options = {}) {
		// TODO: support passed display option overrides

		var display_options = Object.assign({}, this.display_options, options);
		var value = display_options.value || "";

		let container_classes = display_options.container_classes || "";
		let container_styles = "";
		let item_styles = "";
		let ml = '';

		if (display_options.container_styles) {
			container_styles = ' style="' + display_options.container_styles + '"';
		}

		if (display_options.item_styles) {
			item_styles = ' style="' + display_options.item_styles + '"';
		}

		ml += '<div class="drag-sources ' + container_classes  + '"' + container_styles + '>';
		for (let i = 0; i < this.dataset.length; i++) {
			let item = this.dataset[i];
			let display = item.display;
			if (display === undefined || !display.length) {
				display = item.value;
			}
			ml += '<div class="drag-source'+ (item.quantity == 0 ? ' empty': '') + '" ' + item_styles + ' data-index="'+ i +'" value="'+ item.value +'">'+ display +'</div>';
		}
		ml += '</div>';

		return ml;
	}

	bindEventHandlers(widget_container) {
		// DragSource input events are bound by the calling code since they may involve interactions between multiple widgets
		return;
	}

	exportValue(options?){
		return {
			type: 'drag-source',
			name: this.name,
			dataset: JSON.stringify(this.dataset),
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

