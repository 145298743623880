import { QEHelper } from '../QEHelper';
import { QEWidget, DisplayOptions, TagElement } from '../QEWidget';

export class QEWidgetQuad extends QEWidget {
	display_options: { [key: string]: any };
	value: any;

	angle_colour: string;
	congruent_colour: string;
	parallel_colour: string;
	shape_fill_colour: string;
	shape_line_colour: string;
	show_angles: boolean;
	show_congruent: boolean;
	show_parallel: boolean;
	show_perpendicular: boolean;

	length_1?: string;
	length_2?: string;
	angle_1?: string;
	points?: string;

	static default_style = {
		angle_colour: '#dd0000',
		congruent_colour: '#333333',
		parallel_colour: '#333333',
		shape_fill_colour: 'none',
		shape_line_colour: '#000000',
		show_angles: false,
		show_congruent: true,
		show_parallel: true,
		show_perpendicular: true,
	};

	constructor(value: string, display_options: DisplayOptions = {}) {
		super();

		this.value = value;
		this.display_options = display_options;

		// apply default style
		Object.assign(this, QEWidgetQuad.default_style);

		// apply side length, angle settings, and any style overrides
		Object.assign(this, display_options);
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		let deserialized = JSON.parse(serialized);

		let value = deserialized.value;

		// build map and resolve any [$name] placeholders in display_options
		let display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new QEWidgetQuad(value, display_options);
		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} value
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options) {
		// TODO: support passed display option overrides

		return this.draw();
	}

	draw() {
		var svgWidth = 200;
		var svgHeight = 200;
		var padding = 5;

		let svg = new TagElement("svg");
		svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");

		let quad = new TagElement("g");

		var angles: number[] = [];
		if (this.angle_1 !== undefined)
			angles.push(Number(this.angle_1));
		var lengths: number[] = [];
		var points: number[][] = [];

		let xLength: number;
		let yLength: number;
		let xOffset: number;
		let yOffset: number;
		let type = this.value;

		switch (type) {

		case 'square': // 1 length
		case 'rect': // 2 lengths
			angles.push(90);
			xLength = yLength = Number(this.length_1);
			if (type === 'rect') {
				if (this.length_1 === this.length_2)
					type = 'square';
				else
					yLength = Number(this.length_2);
			}

			points = [[-xLength/2, -yLength/2], [xLength/2, -yLength/2], [xLength/2, yLength/2], [-xLength/2, yLength/2]];

			quad.innerHTML = '<polygon points="'+ points.join(' ') +'" fill="'+ this.shape_fill_colour +'" stroke="'+ this.shape_line_colour +'" stroke-width="2" />';
			quad.setAttribute('transform', 'translate('+ (xLength/2 + padding) +','+ (yLength/2 + padding) +')');

			svgWidth = xLength + padding * 2;
			svgHeight = yLength + padding * 2;

			break;
		case 'rhombus': // 1 length, 1 angle
		case 'parallelogram': // 2 lengths, 1 angle
			var a = Number(this.angle_1);

			xLength = yLength = Number(this.length_1);
			if (type === 'parallelogram')
				yLength = Number(this.length_2);

			// Calculate the x and y offsets based on the acute angle
			xOffset = Number((Math.cos(a * Math.PI/180) * yLength).toFixed(4));
			yOffset = Number((Math.sin(a * Math.PI/180) * yLength).toFixed(4));

			points = [[0, 0], [xOffset, yOffset], [xOffset + xLength, yOffset], [xLength, 0]];

			quad.innerHTML = '<polygon points="'+ points.join(' ') +'" fill="'+ this.shape_fill_colour +'" stroke="'+ this.shape_line_colour +'" stroke-width="2" />';

			if (a > 90)
				quad.setAttribute('transform', 'translate('+ (padding + Math.abs(xOffset)) +','+ padding +')');
			else
				quad.setAttribute('transform', 'translate('+ padding +','+ padding +')');

			svgWidth = xLength + Math.abs(xOffset) + padding * 2;
			svgHeight = yOffset + padding * 2;

			break;
		case 'kite': // 2 lengths, 1 angle
			var a = Number(this.angle_1); // top angle

			// length_1 and length_2 are top and bottom section side lengths
			var x = Number(this.length_1) * Math.sin(a / 2 * Math.PI / 180);
			var y1 = Number(this.length_1) * Math.cos(a / 2 * Math.PI / 180) // top part height
			var y2 = Math.sqrt(Math.pow(Number(this.length_2), 2) - Math.pow(x, 2)) // bottom part height

			xLength = Math.abs(x) * 2;
			yLength = Math.abs(y1) + Math.abs(y2);

			// find the bottom and side angles
			let ang_bottom = 2 * Math.atan(x / y2) * 180 / Math.PI;
			angles.push(ang_bottom);

			points = [[0, -y1], [x, 0], [0, y2], [-x, 0]];

			quad.innerHTML = '<polygon points="'+ points.join(' ') +'" fill="'+ this.shape_fill_colour +'" stroke="'+ this.shape_line_colour +'" stroke-width="2" />';
			quad.setAttribute('transform',  'translate('+ (x + padding) +','+ (y1 + padding) +')');

			svgWidth = xLength + padding * 2;
			svgHeight = yLength + padding * 2;

			break;
		case 'trapezoid': // 4 lengths, 4 angles
		case 'irregular': // 4 lengths, 4 angles
			try {
				// clockwise
				points = JSON.parse(this.points); // top left, top right, bot left, bot right

				// TODO: verify there are four pairs of numbers, and cast any strings (from placeholders) as numbers
			} catch(err) {
				console.log("Error: trapezoid points are not valid JSON");
				return null;
			}

			// TODO: check if the y-values for the top-two points and bottom-two points are equal, if so, type is trapezoid, else irregular

			xLength = Math.max(points[0][0], points[1][0], points[2][0], points[3][0]) - Math.min(points[0][0], points[1][0], points[2][0], points[3][0]);
			yLength = Math.max(points[0][1], points[1][1], points[2][1], points[3][1]) - Math.min(points[0][1], points[1][1], points[2][1], points[3][1]);
			xOffset = -Math.min(points[0][0], points[1][0], points[2][0], points[3][0]);
			yOffset = -Math.min(points[0][1], points[1][1], points[2][1], points[3][1]);

			quad.innerHTML = '<polygon points="'+ points.join(' ') +'" fill="'+ this.shape_fill_colour +'" stroke="'+ this.shape_line_colour +'" stroke-width="2" />';
			quad.setAttribute('transform', 'translate('+ (xOffset + padding) +','+ (yOffset + padding) +')');

			svgWidth = xLength + padding * 2;
			svgHeight = yLength + padding * 2;

			break;
		}

		quad.dataset.type = type;
		quad.dataset.points = points.join(' ');
//		quad.id = type + '-' + lengths.join('-');

		// TODO: support parallel line markers for trapezoid
		if (type !== 'trapezoid' && type !== 'irregular') {
			this.addSymbols(quad, points, angles);
			this.addSideLengthMarks(quad, points, angles);
		}

		svg.innerHTML = quad.outerHTML();

		svg.setAttribute('width', svgWidth.toString());
		svg.setAttribute('viewBox', [0, 0, svgWidth, svgHeight].join(' '));

		return svg.outerHTML();
	}

	addSymbols(ele, points, angles) {
		const type = ele.dataset.type;

		var defs = new TagElement('defs');
		var markup = '';

		defs.innerHTML += '<path id="square-angle" d="'+ ['M 10 0', 'L 10 10', 'L 0 10'].join(' ') +'" fill="none" stroke="'+ this.angle_colour +'"/>';
		defs.innerHTML += '<path id="congruent-hash" d="M 0 -5 L 0 5" fill="none" stroke="'+ this.congruent_colour +'" stroke-width="2" />';
		defs.innerHTML += '<path id="parallel-arrow" d="M -5 -5 L 0 0 L -5 5" fill="none" stroke="'+ this.parallel_colour +'" stroke-width="2" />';

		if (this.show_angles) {
			// Generate the arcs
			if (type === 'rhombus' || type === 'parallelogram') {
				// soh cah toa tan = opp/adj cos = add/hyp

				var a1 = Number(ele.dataset.angle);
				var a2 = 180 - a1;

				var y = Math.sin(a1 * Math.PI/180) * 10;
				var x = Math.cos(a1 * Math.PI/180) * 10;
				var str = 'M 0 0 L 10 0 A 1 1 0 0 1 ' + x + ' ' + y + ' Z';
				markup  = '<g id="'+ ele.id +'-angle-1">'; // x
				markup +=   '<path d="'+ str +'" fill="none" stroke="#00D"></path>';
				if (this.show_congruent) {
					// add congruent hash
					var ePt = [Math.cos(a1/2 * Math.PI/180) * 18, Math.sin(a1/2 * Math.PI/180) * 18];
					var sPt = [Math.cos(a1/2 * Math.PI/180) * 8, Math.sin(a1/2 * Math.PI/180) * 8];
					str = 'M ' + sPt.join(' ') + ' L ' + ePt.join(' ');
					markup +=   '<path d="'+ str +'" fill="none" stroke="#00D"></path>';
				}
				// add degrees label?
				markup += '</g>';
				defs.innerHTML += markup;

				y = Math.sin(a2 * Math.PI/180) * 8;
				x = Math.cos(a2 * Math.PI/180) * 8;

				var str = 'M 0 0 L 8 0 A 1 1 0 1 0 ' + x + ' ' + -y + ' Z';
				markup  = '<g id="'+ ele.id +'-angle-2">'; // x
				markup +=   '<path d="'+ str +'" fill="none" stroke="#00D" />';
				markup += '</g>';
				defs.innerHTML += markup;
			}
		}

		if (this.show_perpendicular) {
			if (type === 'square' || type === 'rect') {
				markup  = '<g id="'+ ele.id +'-angle-1">'; // x
				markup +=   '<use href="#square-angle" transform="translate(0, 0)"></use>';
				markup += '</g>';
				defs.innerHTML += markup;
			}
		}

		// sides-1, sides-2
		if (this.show_congruent && this.show_parallel) {
			if (type !== 'kite' && type !== 'trapezoid') {
				markup  = '<g id="'+ ele.id +'-sides-1">'; // x
				markup +=   '<use href="#congruent-hash" transform="translate(4, 0)"></use>';
				markup +=   '<use href="#parallel-arrow" transform="translate(0, 0)"></use>';
				markup += '</g>';
				defs.innerHTML += markup;
			}

			if (type === 'square' || type === 'rhombus') { // 4 equal sides, 2 parallel sets
				markup  = '<g id="'+ ele.id +'-sides-2">'; // y
				markup +=   '<use href="#congruent-hash" transform="translate(4, 0)"></use>';
				markup +=   '<use href="#parallel-arrow" transform="translate(0, 0)"></use>';
				markup +=   '<use href="#parallel-arrow" transform="translate(-4, 0)"></use>';
				markup += '</g>';
				defs.innerHTML += markup;
			} else if (type === 'rect' || type === 'parallelogram') {
				markup  = '<g id="'+ ele.id +'-sides-2">'; // y
				markup +=   '<use href="#congruent-hash" transform="translate(6, 0)"></use>';
				markup +=   '<use href="#congruent-hash" transform="translate(2, 0)"></use>';
				markup +=   '<use href="#parallel-arrow" transform="translate(-2, 0)"></use>';
				markup +=   '<use href="#parallel-arrow" transform="translate(-6, 0)"></use>';
				markup += '</g>';
				defs.innerHTML += markup;
			} else if (type === 'kite') {
				markup  = '<g id="'+ ele.id +'-sides-1">'; // x
				markup +=   '<use href="#congruent-hash" transform="translate(0, 0)"></use>';
				markup += '</g>';
				defs.innerHTML += markup;

				markup  = '<g id="'+ ele.id +'-sides-2">'; // x
				markup +=   '<use href="#congruent-hash" transform="translate(2, 0)"></use>';
				markup +=   '<use href="#congruent-hash" transform="translate(-2, 0)"></use>';
				markup += '</g>';
				defs.innerHTML += markup;
			} else if (type === 'trapezoid') {
				markup  = '<g id="'+ ele.id +'-sides-1">'; // x
				markup +=   '<use href="#parallel-arrow" transform="translate(0, 0)"></use>';
				markup += '</g>';
				defs.innerHTML += markup;
			}
		} else if (this.show_congruent) {
			markup  = '<g id="'+ ele.id +'-sides-1">'; // x
			markup +=   '<use href="#congruent-hash" transform="translate(0, 0)"></use>';
			markup += '</g>';
			defs.innerHTML += markup;

			if (type === 'square' || type === 'rhombus') { // 4 equal sides, 2 parallel sets
				markup  = '<g id="'+ ele.id +'-sides-2">'; // y
				markup +=   '<use href="#congruent-hash" transform="translate(0, 0)"></use>';
				markup += '</g>';
				defs.innerHTML += markup;
			} else if (type === 'rect' || type === 'parallelogram' || type === 'kite') {
				markup  = '<g id="'+ ele.id +'-sides-2">'; // y
				markup +=   '<use href="#congruent-hash" transform="translate(2, 0)"></use>';
				markup +=   '<use href="#congruent-hash" transform="translate(-2, 0)"></use>';
				markup += '</g>';
				defs.innerHTML += markup;
			}
		} else if (this.show_parallel) {
			markup  = '<g id="'+ ele.id +'-sides-1">'; // x
			markup +=   '<use href="#parallel-arrow" transform="translate(0, 0)"></use>';
			markup += '</g>';
			defs.innerHTML += markup;

			if (type === 'square' || type === 'rhombus' || type === 'rect' || type === 'parallelogram') {
				markup  = '<g id="'+ ele.id +'-sides-2">'; // y
				markup +=   '<use href="#parallel-arrow" transform="translate(2, 0)"></use>';
				markup +=   '<use href="#parallel-arrow" transform="translate(-2, 0)"></use>';
				markup += '</g>';
				defs.innerHTML += markup;
			}
		}
		ele.innerHTML += defs.outerHTML();
	}

	addSideLengthMarks(ele, points, angles) {
		const type = ele.dataset.type;

		// Now add the marks
		var midpoints = [];
		var scales = []; // mirror scaling for each vertex angle

		switch (type) {
		case 'square': // all sides congruent, all angles congruent and 90
		case 'rect': // 2 sets of congruents sides, all angles congruent and 90
				var cx = cy = Number(this.length_1) / 2;

				if (type === 'rect')
					cy = Number(this.length_2) / 2;
				midpoints = [[0, -cy], [cx, 0], [0, cy], [-cx, 0]];
				scales = [[1,1], [-1,1], [-1,-1], [1,-1]];

			break;
		case 'rhombus': // all sides congruent, 2 sets of congruent angles and parallel lines
			var len: number = points[3][0];
			var x: number = points[1][0];
			var y: number = points[1][1];
			var cx: number = len/2;
			var cy: number = y/2;

			midpoints = [[cx, 0], [x/2, cy], [cx+x, y], [x/2+len, cy]];

			var str = 'translate('+ points[0].toString() + ')';
			ele.innerHTML += '<use href="#'+ ele.id +'-angle-1" transform="'+ str +'" />';

			str = 'translate('+ points[2].toString() + ') scale(-1, -1)';
			ele.innerHTML += '<use href="#'+ ele.id +'-angle-1" transform="'+ str +'" />';

			str = 'translate('+ points[1].toString() + ')';
			ele.innerHTML += '<use href="#'+ ele.id +'-angle-2" transform="'+ str +'" />';

			str = 'translate('+ points[3].toString() + ') scale(-1, -1)';
			ele.innerHTML += '<use href="#'+ ele.id +'-angle-2" transform="'+ str +'" />';

			break;
		case 'parallelogram': // 2 sets of congruent angles, sides, and parallel lines
			var len: number = points[3][0];
			var x: number = points[1][0];
			var y: number = points[1][1];
			var cx: number = len/2;
			var cy: number = y/2;

			midpoints = [[cx, 0], [x/2, cy], [cx+x, y], [x/2+len, cy]];

			var str = 'translate('+ points[0].toString() + ')';
			ele.innerHTML += '<use href="#'+ ele.id +'-angle-1" transform="'+ str +'" />';

			str = 'translate('+ points[2].toString() + ') scale(-1, -1)';
			ele.innerHTML += '<use href="#'+ ele.id +'-angle-1" transform="'+ str +'" />';

			str = 'translate('+ points[1].toString() + ')';
			ele.innerHTML += '<use href="#'+ ele.id +'-angle-2" transform="'+ str +'" />';

			str = 'translate('+ points[3].toString() + ') scale(-1, -1)';
			ele.innerHTML += '<use href="#'+ ele.id +'-angle-2" transform="'+ str +'" />';

			break;
		case 'kite': // 2 congruents sides 1 congruent angle

			// Get the midpoints for each side
			// midpt = [(x1+x2)/2, (y1+y2)/2]
			midpoints = [
				[(points[0][0]+ points[1][0])/2, (points[0][1]+points[1][1])/2],
				[(points[2][0]+ points[1][0])/2, (points[2][1]+points[1][1])/2],
				[(points[0][0]+ points[3][0])/2, (points[0][1]+points[3][1])/2],
				[(points[2][0]+ points[3][0])/2, (points[2][1]+points[3][1])/2],
			];

			scales = [[1,1], [1,1], [-1,1], [-1,1]];

			break;
		case 'trapezoid': // 1 set of parallel lines
			midpoints = [
				[(Number(points[0][0])+Number(points[1][0]))/2, (Number(points[0][1])+Number(points[1][1]))/2],
				[(Number(points[2][0])+Number(points[3][0]))/2, (Number(points[2][1])+Number(points[3][1]))/2],
			];
			break;
		}

		// Do we need to draw the angles for each vertex?
		if (this.show_angles || this.show_perpendicular) {
			for (var i = 0; i < scales.length; i++) {
				// var id = '';
				// var str = '';
				// ele.innerHTML += '<use href="#'+ id +'" transform="'+ str +'" />';
				var str = 'translate('+ points[i].toString() + ') scale(' + scales[i].toString() + ')';
				ele.innerHTML += '<use href="#'+ ele.id +'-angle-1" transform="'+ str +'" />';
			}
		}

		// Do we need to draw the symbols for each midpoint?
		if (this.show_congruent || this.show_perpendicular) {
			for (var i = 0; i < midpoints.length; i++) {
				var id = ele.id + ((i % 2) ? '-sides-2' : '-sides-1');
				if (type === 'trapezoid')
					id = ele.id + '-sides-1';
				var str = 'translate('+ midpoints[i].toString() +')' + (i % 2 ? ' rotate('+ (angles[0] || 0) +')' : '');
				if (type === 'kite') {
					str = 'translate('+ midpoints[i].toString() +') scale('+ scales[i].toString() +')';
					if (i % 2)
						str += ' rotate('+ (-90 + angles[1]/2) +')';
					else
						str += ' rotate('+ (90 - angles[0]/2) +')';
				}
				ele.innerHTML += '<use href="#'+ id +'" transform="'+ str +'" />';
			}
		}
	}

	exportValue(options?){
		return {
			type: 'quad',
			value: this.value,
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

