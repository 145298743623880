import { QEGraph } from '../QEGraph';
import { QEValueJSON, QEValueAnswer } from '../../common/QEHelper';
import * as jQuery from 'jquery';

const QEWidgetGraph = function(QEWidgetGraphCommon) {
	class QEWidgetGraph extends QEWidgetGraphCommon {

	input_handled: boolean; // flag to throttle user input preview updates
	postInputHandler: any; // handler function to execute after each user input event

	/**
	 * Display widget for representing Widget values on a graph
	 * @constructor
	 * @param {Object} value - Widget object (usually Equation) containing the value to be presented
	 * @param {string} display_options - string representing the image style to use
	 */
	constructor(series_data, display_options, input_options) {
		super(series_data, display_options, input_options);

		this.graph = new QEGraph(this.display_options);

		this.input_handled = true;
		this.postInputHandler = null;
	}

	setPostInputHandler(postInputHandler){ this.postInputHandler = postInputHandler }

	draw(passed_series?) {
		// use passed series data, else configured series data, else default to empty
		const series_list = passed_series || this.series || [];

		// render based on config data
		const graph = this.graph
		const canvas = graph.draw(series_list);

		return canvas;
	}

	drawUserSeries() {
		const self = this;

		// render based on config data
		const graph = this.graph;

		// check if input_options.enabled
		const input_options = this.input_options;
		if (!input_options.enabled) {
			return;
		}

		// draw user input series
		let draw_series = [];
		const user_values = this.user_values;
		if (input_options.type == 'points') {
			const show_labels = input_options.display_options.show_labels || false;
			const labels = input_options.display_options.labels || 'A,B,C,D,E,F,G';
			const label_list = labels.split(',');
			const radius = parseInt(input_options.display_options.radius) || 4;
			const color = input_options.display_options.color || '#ff0000';

			// convert user_values to series for drawing
			for (let i = 0; i < user_values.length; i++) {
				const point = user_values[i];
				const series = { type: 'points', values: [Object.assign({}, point)], display_options: { radius: radius, color: color } };
				if (show_labels) {
					// assign label to point
					series.values[0].label = label_list[i];
				}
				draw_series.push(series);
			}
		} else if (input_options.type == 'segments'
			|| input_options.type == 'linear_inequality_1d') {
			const line_width = parseInt(input_options.display_options.line_width) || 2;
			const radius = line_width * 2; // default point size
			const line_color = input_options.display_options.line_color || '#ff0000';
			// TODO: line style: solid, dotted, dashed
			// TODO: label, start_point_label, end_point_label

			// convert user_values to series for drawing
			if (user_values.length == 1 && 'x' in user_values[0]) {
				// line segment is actually a single point
				draw_series = [{
					type: 'points',
					values: user_values,
					display_options: {
						radius: radius,
						color: line_color,
					}
				}];
			} else if (user_values.length > 0) {
				// one or more line segments
				draw_series = [{
					type: 'segments',
					values: user_values,
					display_options: {
						radius: radius,
						line_color: line_color,
						line_width: line_width
					}
				}];
			}
		} else {
			console.log('Error: unsupported user input type: ', input_options.type);
		}

		// add preview action series
		for (let i = 0; i < this.preview_values.length; i++) {
			const preview_series = this.preview_values[i];
			if (preview_series.type == 'cursor') {
				const x = preview_series.x;
				const y = preview_series.y;
				draw_series.push({
					type: 'arcs', values: [
						{ cX: x, cY: y, start_angle: -Math.PI * 1 / 8, end_angle: Math.PI * 1 / 8 },
						{ cX: x, cY: y, start_angle: Math.PI * 3 / 8, end_angle: Math.PI * 5 / 8 },
						{ cX: x, cY: y, start_angle: Math.PI * 7 / 8, end_angle: Math.PI * 9 / 8 },
						{ cX: x, cY: y, start_angle: Math.PI * 11 / 8, end_angle: Math.PI * 13 / 8 }
					], display_options: { pxRadius: 7, line_width: 2, color: '#0000FF' }
				});
			} else if (preview_series.type == 'cross-out') {
				const x = preview_series.x;
				const y = preview_series.y;
				draw_series.push({
					type: 'points', values: [
						{ x: x, y: y, state: 'cross-out' }
					], display_options: { line_width: 2, color: '#FF0000', pxRadius: 10 }
				});
			} else if (preview_series.type == 'segments') {
				draw_series.push(preview_series);
			}
		}

		// clear input_handled flag - will be reset after svg dom updated to reflect user input
		this.input_handled = false;

		// create new graph using existing graph config, but with grid, border, etc. disabled
		const init_data = Object.assign({}, graph.init_data, {show_border: false, show_grid: false, show_y_axis: false, show_x_axis: false, watermark: false});
		const user_graph = new QEGraph(init_data);
		const user_canvas = user_graph.draw(draw_series);

		// convert the user svg markup to a dom and extract the drawn content user series group
		const user_svg_dom = jQuery(user_canvas);
		const top_g = user_svg_dom.find('g[name="top_group"]');

		// create a group element to hold the user series group content - this will be inserted/replaced into the widget svg
		const user_g = jQuery(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
		user_g.attr({name: "user_series"});
		user_g.html(top_g.html());

		// remove any existing user_series group from svg_dom, then append the new g[name="user_series"] to g[name="top_group"]
		const svg_dom = graph.svg_dom;
		svg_dom.find('g[name="top_group"] > g[name="user_series"]').remove();

		svg_dom.find('g[name="top_group"]').append(user_g);

		// set input_handled after svg redraw
		setTimeout(function() {
			self.input_handled = true;
		}, 0);

	}

	handleInput(input_type, canvas_x?, canvas_y?) {
		const graph = this.graph;

		// check if input_options.enabled
		const input_options = this.input_options;
		if (!input_options.enabled) {
			return;
		}

		// always clear preview_values
		this.preview_values = [];

		// draw non-user data series
		const graph_options = Object.assign({}, this.display_options);
		const series_list = this.series || [];
		graph.draw(series_list);

		// mouseleave
		if (input_type == 'mouseleave') {
			// clear preview series
			this.drawUserSeries()
			return;
		}

		if (input_type != 'click' && input_type != 'mousemove') {
			return;
		}

		// get position of mouse in graph coordinates
		const graph_x = graph.x_min + (graph.x_max - graph.x_min) * (canvas_x - graph.padding_left) / graph.plot_width;
		const graph_y = graph.y_max - (graph.y_max - graph.y_min) * (canvas_y - graph.padding_top) / graph.plot_height;

		// check if cursor position within graph plot area
		let in_bounds = false;
		if (graph_x >= graph.x_min && graph_x <= graph.x_max && graph_y >= graph.y_min && graph_y <= graph.y_max) {
			in_bounds = true;
		}

		// snap-to-grid step: minor, major, axis, none
		let snap_x = graph_x;
		let snap_y = graph_y;
		if (input_options.display_options.snap_to_grid_x == 'minor') {
			snap_x = fixFloat(Math.round(snap_x / graph.x_step_minor) * graph.x_step_minor);
		} else if (input_options.display_options.snap_to_grid_x == 'major') {
			snap_x = fixFloat(Math.round(snap_x / graph.x_step_major) * graph.x_step_major);
		} else if (input_options.display_options.snap_to_grid_x == 'axis') {
			// snap to vertical axis, if visible
			if (graph.x_min <= 0 && graph.x_max >= 0) {
				snap_x = 0;
			}
		}
		if (input_options.display_options.snap_to_grid_y == 'minor') {
			snap_y = fixFloat(Math.round(snap_y / graph.y_step_minor) * graph.y_step_minor);
		} else if (input_options.display_options.snap_to_grid_y == 'major') {
			snap_y = fixFloat(Math.round(snap_y / graph.y_step_major) * graph.y_step_major);
		} else if (input_options.display_options.snap_to_grid_y == 'axis') {
			// snap to vertical axis, if visible
			if (graph.y_min <= 0 && graph.y_max >= 0) {
				snap_y = 0;
			}
		}

		// helper function to find the first point within threshold distance of cursor position, if any
		function getPointAtCursorPos(point_list, cursor_pos, threshold_px) {
			const threshold_sq = Math.pow(threshold_px, 2);

			for (let i = 0; i < point_list.length; i++) {
				const point = point_list[i];

				const delta_x = graph.screenX(point.x) - graph.screenX(cursor_pos.x);
				const delta_y = graph.screenY(point.y) - graph.screenY(cursor_pos.y);

				if (Math.pow(delta_x, 2) + Math.pow(delta_y, 2) <= threshold_sq) {
					return point;
				}
			}
			return undefined;
		}

		// helper function to find the first segment within threshold distance of cursor position, if any
		function getSegmentAtCursorPos(point_list, cursor_pos, threshold_px) {
			const threshold_sq = Math.pow(threshold_px, 2);

			for (let i = 0; i < point_list.length - 1; i++) {
				const point1 = point_list[i];
				const point2 = point_list[i + 1];

				// first get the line equation: "ax + by + c = 0"
				// y - y1 = m * (x - x1)
				// y - y1 = (delta_y / delta_x) * (x - x1)
				// delta_x * (y - y1) = delta_y * (x - x1)
				// -delta_y * x + delta_x * y - delta_x * y1 + delta_y * x1 = 0
				// a = -delta_y, b = delta_x, c = delta_y * x1 - delta_x * y1

				const p1_x_px = graph.screenX(point1.x);
				const p1_y_px = graph.screenY(point1.y);
				const p2_x_px = graph.screenX(point2.x);
				const p2_y_px = graph.screenY(point2.y);
				const cursor_x_px = graph.screenX(cursor_pos.x);
				const cursor_y_px = graph.screenY(cursor_pos.y);

				// constrain to end-points of line segment
				const major_axis = Math.abs(p1_x_px - p2_x_px) > Math.abs(p1_y_px - p2_y_px) ? 'x' : 'y';
				if (major_axis == 'x' && (
					(cursor_x_px < p1_x_px && cursor_x_px < p2_x_px) ||
					(cursor_x_px > p1_x_px && cursor_x_px > p2_x_px)
				)) {
					continue;
				}
				if (major_axis == 'y' && (
					(cursor_y_px < p1_y_px && cursor_y_px < p2_y_px) ||
					(cursor_y_px > p1_y_px && cursor_y_px > p2_y_px)
				)) {
					continue;
				}

				const delta_x = p2_x_px - p1_x_px;
				const delta_y = p2_y_px - p1_y_px;
				const a = -delta_y;
				const b = delta_x;
				const c = delta_y * p1_x_px - delta_x * p1_y_px;

				// distance^2 from point (x,y) to line: d^2 = (ax + bx + c)^2 / (a^2 + b^2))
				const d_sq = Math.pow(a * cursor_x_px + b * cursor_y_px + c, 2) / (Math.pow(a, 2) + Math.pow(b, 2));

				// check if cursor_pos is within threshold distance of line
				if (d_sq <= threshold_sq) {
					return [point1, point2];
				}
			}
			return undefined;
		}

		// update user input series
		const user_points = this.user_points;
		const user_values = this.user_values;

		if (!in_bounds) {
			// do nothing
		} else if (input_options.type == 'points') {
			const num_points = parseInt(input_options.display_options.num_points) || 1;
			const allow_open = input_options.display_options.allow_open || false;
			const radius = parseInt(input_options.display_options.radius) || 4;

			// use point radius for cursor threshold distance
			const threshold_px = radius * 2;

			// TODO: support other states, such as "image"
			const point_states = ["closed"];
			if (allow_open) {
				point_states.push('open');
			}

			// check if cursor is within threshold distance of existing user_points point
			const closest_point = getPointAtCursorPos(user_points, { x: graph_x, y: graph_y }, threshold_px);
			if (closest_point) {
				// already a point at location. Toggle its state, or remove
				if (point_states.indexOf(closest_point.state) < point_states.length - 1) {
					if (input_type == 'click') {
						// toggle to next state
						closest_point.state = point_states[point_states.indexOf(closest_point.state) + 1];
					} else if (input_type == 'mousemove') {
						this.preview_values = [{ type: 'cursor', x: snap_x, y: snap_y }];
					}
				} else {
					if (input_type == 'click') {
						// remove
						const point_index = user_points.indexOf(closest_point);
						user_points.splice(point_index, 1);
					} else if (input_type == 'mousemove') {
						this.preview_values = [{ type: 'cross-out', x: snap_x, y: snap_y }];
					}
				}
			} else {
				// add point, unless we've already reached max num_points
				if (user_points.length < num_points) {
					if (input_type == 'click') {
						user_points.push({ x: snap_x, y: snap_y, state: point_states[0] });
					} else if (input_type == 'mousemove') {
						this.preview_values = [{ type: 'cursor', x: snap_x, y: snap_y }];
					}
				} else {
					// replace first point
					if (input_type == 'click') {
						user_points.splice(0, 1, { x: snap_x, y: snap_y, state: point_states[0] });
					} else if (input_type == 'mousemove') {
						this.preview_values = [{ type: 'cursor', x: snap_x, y: snap_y }];
					}
				}
			}

			// regenerate user_values from user_points
			this.user_values = user_points.slice(0);
		} else if (input_options.type == 'segments' || input_options.type == 'linear_inequality_1d') {
			const line_width = parseInt(input_options.display_options.line_width) || 2;
			const radius = line_width * 2; // default point size

			// use point radius for cursor threshold distance
			const threshold_px = radius * 2;

			let point_states = ["closed"];
			if (input_options.type == 'linear_inequality_1d') {
				// linear_inequality_1d uses specific end point states
				if (snap_x == graph.x_min || snap_x == graph.x_max) {
					point_states = ['arrow'];
				} else {
					point_states = ['closed', 'open'];
				}
			}

			const closest_point = getPointAtCursorPos(user_points, { x: graph_x, y: graph_y }, threshold_px);
			if (closest_point) {
				// already a point at location. Toggle its state, or remove
				if (point_states.indexOf(closest_point.state) < point_states.length - 1) {
					if (input_type == 'click') {
						// toggle to next state
						closest_point.state = point_states[point_states.indexOf(closest_point.state) + 1];
					} else if (input_type == 'mousemove') {
						this.preview_values = [{ type: 'cursor', x: snap_x, y: snap_y }];
					}
				} else {
					if (input_type == 'click') {
						const point_index = user_points.indexOf(closest_point);
						user_points.splice(point_index, 1);
					} else if (input_type == 'mousemove') {
						this.preview_values = [{ type: 'cross-out', x: snap_x, y: snap_y }];
					}
				}
			} else if (user_points.length == 2) {
				// check if user clicked on the line segment
				const closest_segment_points = getSegmentAtCursorPos(user_points, { x: graph_x, y: graph_y }, threshold_px);
				if (closest_segment_points) {
					if (input_type == 'click') {
						// user clicked on the segment: delete it
						user_points.splice(0, 2);
					} else if (input_type == 'mousemove') {
						// show cross-out at mid-point
						this.preview_values = [{
							type: 'cross-out',
							x: (user_points[0].x + user_points[1].x) / 2,
							y: (user_points[0].y + user_points[1].y) / 2
						}];
					}
				}
			} else {
				// add point, unless we've already reached max num points
				if (user_points.length == 0) {
					if (input_type == 'click') {
						user_points.push({ x: snap_x, y: snap_y, state: point_states[0] });
					} else if (input_type == 'mousemove') {
						this.preview_values = [{ type: 'cursor', x: snap_x, y: snap_y }];
					}
				} else if (user_points.length == 1) {
					if (input_type == 'click') {
						user_points.push({ x: snap_x, y: snap_y, state: point_states[0] });
					} else if (input_type == 'mousemove') {
						this.preview_values = [{
							type: 'segments',
							values: [{
								x1: user_points[0].x,
								y1: user_points[0].y,
								x2: snap_x,
								y2: snap_y,
								end_point: 'closed',
							}],
							display_options: { line_color: 'rgba(255,0,0,0.75)' }
						}];
					}
				}
			}

			// regenerate user_values from user_points
			if (user_points.length == 2) {
				this.user_values = [{
					x1: user_points[0].x,
					y1: user_points[0].y,
					start_point: user_points[0].state,
					x2: user_points[1].x,
					y2: user_points[1].y,
					end_point: user_points[1].state,
				}];
			} else if (user_points.length == 1) {
				this.user_values = user_points.slice(0);
			} else {
				this.user_values = [];
			}
		} else {
			console.log('Error: unsupported user input type: ', input_options.type);
		}

		this.drawUserSeries();

		if (this.postInputHandler) {
			this.postInputHandler();
		}
	}

	display(options: any = {}) {
		// handle passed JSON value data
		const passed_values = options.value;
		if (passed_values) {
			// when displaying passed data, we need to use the series type and display_options from the first configured data series of the Graph
			if (!this.series.length) {
				return '<div style="color: #f00;">'+
					'Error: no data series configured, but when displaying passed data we need'+
					' to use the series type and display_options from the first configured data series of the Graph.'+
					'</div>';
			}

			// handle passed_values from QEValue (e.g. QEValueWidget (Table, Graph), QEValueAnswer, QEValueJSON)
			// - importing Table data is difficult, since the required JSON format varies by displayed series type
			let values;
			if (passed_values instanceof QEValueJSON) {
				values = passed_values.value;
			} else if (passed_values instanceof QEValueAnswer) {
				let answer_value = passed_values.value.value;
				if (answer_value instanceof QEValueJSON) {
					values = answer_value.value;
				}
			}

			if (!values) {
				console.log('Error. Unhandled pass series data: ', passed_values);
				return '<div style="color: #f00;">Unhandled passed series data. Check console.</div>'
			}

			const first_series = this.series[0];
			const passed_series = Object.assign({}, first_series, { values: values });

			this.graph.init();
			return this.draw([passed_series]);
		} else {
			this.graph.init();
			return this.draw();
		}
	}

	bindEventHandlers(widget_container) {
		const self = this;

		// set reference to actual svg dom so that it can be manipulated by user input
		this.graph.svg_dom = widget_container.find('svg');

		// TODO: check self.isInputDisabled() before doing any re-rendering

		widget_container.on('mousemove', 'svg:not(.disable_input)', function(e){
			if (widget_container.is('.disable_input')) return; // skip if disabled
			if (!self.input_handled) return; // skip if we're already handling input

			var event_item = jQuery(this);
			var canvas_elem = event_item.get(0);
			var scaling_factor = (self.graph.padding_left + self.graph.plot_width + self.graph.padding_right) / canvas_elem.clientWidth;

			// get x/y screen coords of mouse on canvas
			var canvas_x = e.offsetX * scaling_factor;
			var canvas_y = e.offsetY; // no vertical scaling when shrinking svg due to width

			// Graph widget should handle event, based on input config
			self.handleInput('mousemove', canvas_x, canvas_y);
		}).on('mouseleave', 'svg:not(.disable_input)', function(e){
			if (widget_container.is('.disable_input')) return; // skip if disabled

			// Graph widget should handle event, based on input config
			self.handleInput('mouseleave');
		}).on('click', 'svg:not(.disable_input)', function(e){
			if (widget_container.is('.disable_input')) return; // skip if disabled

			var event_item = jQuery(this);
			var canvas_elem = event_item.get(0);
			var scaling_factor = (self.graph.padding_left + self.graph.plot_width + self.graph.padding_right) / canvas_elem.clientWidth;

			// get x/y screen coords of mouse on canvas
			var canvas_x = e.offsetX * scaling_factor;
			var canvas_y = e.offsetY; // no vertical scaling when shrinking svg due to width

			// Graph widget should handle event, based on input config
			self.handleInput('click', canvas_x, canvas_y);
		});
	}

	/**
	 * Gets the widget value for the specified input key
	 * @param {string} input_key
	 */
	getInputValue(input_widgets?) {
		return JSON.stringify(this.user_values); 
	}

	isUserInputComplete(){ return this.user_values.length != 0; }

	isUserInputAutoSubmittable(): boolean {
		return false;
	}

	} // class QEWidgetGraph
	return QEWidgetGraph;
};
export { QEWidgetGraph };

function fixFloat(num){ return parseFloat(num.toFixed(12).replace(/\.?0*$/, '')); }
