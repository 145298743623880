import { SvgRenderingContext } from '../../common/SvgRenderingContext';
import { QEGraph } from '../QEGraph';
import { Arc } from './Arc';
import { Point } from './Point';

export class Jump {
	x1: number;
	y1: number;
	x2: number;
	y2: number;
	arrowhead: boolean;
	line_color: string;
	line_width: number;
	pointColor: string;

	constructor(data) {
		this.x1 = NaN;
		this.y1 = NaN;
		this.x2 = NaN;
		this.y2 = NaN;

		this.arrowhead = true;
		this.line_color = "#D00";
		this.line_width = 1;
		this.pointColor = "#D00";

		for (const prop in data) {
			if (data[prop] !== undefined) {
				// attempt to cast to number or boolean
				if (!Number.isNaN(Number(data[prop]))) this[prop] = Number(data[prop]);
				else if (data[prop] === true || data[prop] === false) this[prop] = data[prop];
				else if (data[prop] === "true") this[prop] = true;
				else if (data[prop] === "false") this[prop] = false;
				else this[prop] = data[prop];
			}
		}
	}

	plot(graph: QEGraph, ctx: SvgRenderingContext):void {
		// Plot the arc
		new Arc(this).plot(graph, ctx);

		// Plot the two jump points
		// const radius = Math.floor(graph.gridSize / 4);
		const sPoint = { x: this.x1, y: this.y1, color: this.pointColor };
		const ePoint = { x: this.x2, y: this.y2, color: this.pointColor };

		new Point(sPoint).plot(graph, ctx);
		new Point(ePoint).plot(graph, ctx);
	}
}
