import { QETerm } from '../QETerm';
import { QEHelper } from '../QEHelper';
import { QEWidget, DisplayOptions, TagElement } from '../QEWidget';

export class QEWidgetThermometer extends QEWidget {
	display_options: { [key: string]: any };
	value: number;
	min: number;
	max: number;
	units: string;

	show_minor_steps: boolean;
	background_colour: string;
	stick_colour: string;
	stick_height: number;
	stick_width: number;
	temp_colour: string;
	font_family: string;
	font_size: string;
	label_colour: string;
	line_colour: string;

	static default_style = {
		show_minor_steps: false,
		background_colour: 'none',
		stick_colour: '#000000',
		stick_height: 175,
		stick_width: 16,
		temp_colour: '#d00000',
		font_family: 'Lato, sans-serif',
		font_size: '9pt',
		label_colour: '#000000',
		line_colour: '#000000',
	};

	constructor(value: number, min: number, max: number, units: string, display_options: DisplayOptions = {}) {
		super();

		this.value = value;
		this.min = min;
		this.max = max;
		this.units = units;
		this.display_options = display_options;

		// apply default style, then display options and style overrides
		Object.assign(this, QEWidgetThermometer.default_style, display_options);
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		const deserialized = JSON.parse(serialized);

		const value = QEHelper.resolveToNumber(deserialized.value, resolved_data);
		const min = QEHelper.resolveToNumber(deserialized.min, resolved_data);
		const max = QEHelper.resolveToNumber(deserialized.max, resolved_data);
		if ([value, min, max].filter(x => {return !Number.isInteger(x);}).length) {
			console.log('Error: non-integer thermometer value');
			return null;
		}

		const units = deserialized.units;
		if (['C', 'F'].indexOf(units) == -1) {
			console.log('Invalid thermometer units: ', units);
			return null;
		}

		// build map and resolve any [$name] placeholders in display_options
		const display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new QEWidgetThermometer(value, min, max, units, display_options);
		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options) {
		// TODO: support passed display option overrides

		return this.draw();
	}

	draw() {
		const step_value = 1;
		const steps = this.max - this.min;

		const curr_step = steps - (this.max - this.value) / step_value;
		const max_height = this.stick_height - 10 - 12; // less bulb radius and a 10px buffer
		const step_height = Number((max_height / steps).toFixed(2));
		const temp_range = this.stick_height - (this.stick_height - steps * step_height);
		const temp_height = Number((curr_step / steps * temp_range).toFixed(2));

		const svg = new TagElement("svg");
		svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
		svg.style['background'] = this.background_colour;
		svg.setAttribute('width', "100");
		svg.setAttribute('viewBox', [0,0, 100, 200].join(' '));

		const trans = 'scale(1, -1) translate(0, '+ -(temp_range + 10) +')';

		// Build the core shape
		let markup = '';
		markup += '<rect x="-8" y="0" rx="8" ry="8" width="16" height="'+ this.stick_height +'" fill="'+ this.stick_colour +'"></rect>';
		markup += '<circle cx="0" cy="'+ this.stick_height +'" r="12" fill="'+ this.stick_colour +'"></circle>';
		markup += '<circle cx="0" cy="'+ this.stick_height +'" r="10" fill="'+ this.temp_colour +'"></circle>';
		// add fill to zero out
		markup += '<rect x="-6" y="'+ (temp_range + 8) +'" width="12" height="'+ (this.stick_height - max_height) +'" fill="'+ this.temp_colour +'"></rect>';
		markup += '<rect x="-6" y="0" width="12" height="'+ temp_height +'" fill="'+ this.temp_colour +'" transform="'+ trans +'"></rect>';

		const g = new TagElement('g'); // enclose the shape into a group element
		g.innerHTML += markup;

		// Now build the line markings
		markup = '';

		// units (C/F)
		markup += '<text x="'+ 30 +'" y="'+ -1 +'" font-family="'+ this.font_family +'" font-size="'+ this.font_size +'" alignment-baseline="hanging" text-anchor="end" fill="'+ this.label_colour +'" stroke="none">' + '°'+ this.units + '</text>';

		for (let i = 0; i <= steps; i++) {
			const x1 = 0;
			const minor_x = 8;
			const major_x = 30;

			let y = 10.5 + Math.round(i * step_height);
			let str = (this.max - step_value * i);

			if (!(i % 5)) {
				markup += '<line x1="'+ x1 +'" y1="'+ y +'" x2="'+ major_x +'" y2="'+ y +'" fill="none" stroke="'+ this.line_colour +'"></line>';
				markup += '<text x="'+ major_x +'" y="'+ (y + 3) +'" font-family="'+ this.font_family +'" font-size="'+ this.font_size +'" alignment-baseline="hanging" text-anchor="end" fill="'+ this.label_colour +'" stroke="none">' + str + '</text>';
			} else if (this.show_minor_steps) {
				markup += '<line x1="'+ x1 +'" y1="'+ y +'" x2="'+ minor_x +'" y2="'+ y +'" fill="none" stroke="'+ this.line_colour +'"></line>';
			}
		}

		g.innerHTML += '<g transform="translate(10, 0)">' + markup + '</g>';
		g.setAttribute('transform', 'translate(35, 5)');

		svg.innerHTML += g.outerHTML();

		return svg.outerHTML();
	}

	exportValue(options?){
		return {
			type: 'thermometer',
			value: this.value,
			min: this.min,
			max: this.max,
			units: this.units,
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

