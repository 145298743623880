import { QETerm } from '../QETerm';
import { QEHelper } from '../QEHelper';
import { QEWidget, DisplayOptions } from '../QEWidget';
import * as jQuery from 'jquery';

export class QEWidgetDropBucket extends QEWidget {
	display_options: { [key: string]: any };
	user_value: { [key: string]: number };
	name: string;
	source_key: string;
	max_quantity: number;

	constructor(source_key: string, max_quantity: number, options: { name?: string, display_options?: DisplayOptions } = {}) {
		super();

		// DropBucket is used to present the user with a target that DragSource items can be dragged to

		this.source_key = source_key;
		this.max_quantity = Number(max_quantity || -1);
		this.user_value = {};
		this.display_options = options.display_options || {};

		if (options.name) this.name = options.name;
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		const deserialized = JSON.parse(serialized);

		// build map and resolve any [$name] placeholders in display_options
		const display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new QEWidgetDropBucket(deserialized.source_key, deserialized.max_quantity, {name: options.name, display_options: display_options});
		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options = {}) {
		// TODO: support passed display option overrides

		var display_options = Object.assign({}, this.display_options, options);
		var value = display_options.value || "";

		let container_classes = display_options.container_classes || "";
		let container_styles = "";
		let ml = '';

		if (display_options.container_styles) {
			container_styles = ' style="' + display_options.container_styles + '"';
		}

		ml += '<div class="drop-bucket ' + container_classes  + '"' + container_styles + '>';
		ml += '</div>';

		return ml;
	}

	bindEventHandlers(widget_container) {
		// DropBucket input events are bound by the calling code since they may involve updating a parent widget if nested
		return;
	}

	hasRoom() {
		let self = this;
		let num_items = 0;
		Object.keys(this.user_value).forEach(function(key){
			num_items += self.user_value[key];
		});
		// -1 indicates infinite room
		return this.max_quantity == -1 ||
			num_items < this.max_quantity;
	}

	canAccept(drop_item) {
		return drop_item.attr('data-drag-source-key') == this.source_key;
	}

	setInputValue(value: string) {
		this.user_value = value;
	}

	getInputValue(input_widgets?): string { return this.user_value; }

	isUserInputComplete(): boolean {
		// check if value set, and at least one item has a quantity > 0
		return this.user_value !== null && Object.keys(this.user_value).filter((key_name)=>{ return this.user_value[key_name] > 0; }).length > 0;
	}

	exportValue(options?){
		return {
			type: 'drop-bucket',
			name: this.name,
			source_key: this.source_key,
			max_quantity: this.max_quantity,
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

