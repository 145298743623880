import { QETerm } from '../QETerm';
import { QEHelper } from '../QEHelper';
import { QEWidget, DisplayOptions, TagElement } from '../QEWidget';

export class QEWidgetCoin extends QEWidget {
	display_options: { [key: string]: any };

	labels: string[];
	value: number;

	face0_colour: string;
	face1_colour: string;
	font_family: string;
	font_size: number;
	offset: number;
	outline_colour: string;
	padding: number;
	radius: number;
	shadow_colour: string;
	face0_label_colour: string;
	face1_label_colour: string;

	static default_style = {
		face0_colour: '#ffffff',
		face1_colour: '#ffffff',
		font_family: 'ArialMT, Arial',
		font_size: 24,
		offset: 2,
		outline_colour: '#333333',
		padding: 5,
		radius: 40,
		shadow_colour: '#333333',
		face0_label_colour: '#333333',
		face1_label_colour: '#333333',
	};

	constructor(labels: string[], value: number, display_options: DisplayOptions = {}) {
		super();

		this.labels = labels;
		this.value = value;
		this.display_options = display_options;

		// apply default style, then display options and style overrides
		Object.assign(this, QEWidgetCoin.default_style, display_options);
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		const deserialized = JSON.parse(serialized);

		// 0 or 1 only
		const value = QEHelper.resolveToNumber(deserialized.value, resolved_data);
		if ([0, 1].indexOf(value) == -1) {
			console.log('Error: non- 0|1 coin value');
			return null;
		}

		// labels
		const labels = deserialized.labels.split(/,/);
		if (labels.length != 2) {
			console.log('Error: coins labels should be a 2-element comma-delimited list');
			return null;
		}

		// build map and resolve any [$name] placeholders in display_options
		const display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new QEWidgetCoin(labels, value, display_options);
		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options) {
		// TODO: support passed display option overrides

		return this.draw();
	}

	draw() {
		const sWidth = this.radius * 2 + this.padding * 2;
		const offset = this.offset * 2 + this.radius;

		let sx = 1;
		const sy = 1;
		let tx = this.padding + this.radius;
		const ty = tx;
		if (this.value == 1) {
			sx *= -1;
			tx *= -1;
		}

		const coin_colour = this.value ? this.face1_colour : this.face0_colour;
		const label_colour = this.value ? this.face1_label_colour : this.face0_label_colour;

		var markup = '';
		markup += '<g transform="scale('+ sx +','+ sy +') translate('+ tx +','+ ty +')">';
		markup += 	'<ellipse cx="'+ this.offset +'" cy="'+ this.offset +'" rx="'+ this.radius +'" ry="'+ this.radius +'" fill="'+ this.shadow_colour +'"></ellipse>';
		markup += 	'<ellipse cx="'+ -this.offset +'" cy="0" rx="'+ this.radius +'" ry="'+ this.radius +'" fill="'+ coin_colour +'" stroke="'+ this.outline_colour +'"></ellipse>';
		markup += '</g>';

		let cx = this.padding + this.radius;
		let cy = cx;

		if (this.value == 1)
			cx += 2;
		else
			cx -= 2;

		let label = this.labels[this.value];
		markup += '<text x="'+ cx +'" y="'+ cy +'" font-family="'+ this.font_family +'" font-size="'+ this.font_size +'" alignment-baseline="middle" text-anchor="middle" fill="'+ label_colour +'">'+ label +'</text>';

		const svg = new TagElement("svg");
		svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
		svg.setAttribute('width', sWidth.toString());
		svg.setAttribute('viewBox', [0, 0, sWidth, sWidth].join(' '));
		svg.innerHTML = markup;

		return svg.outerHTML();
	}

	exportValue(options?){
		return {
			type: 'coin',
			labels: this.labels.join(','),
			value: this.value,
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

