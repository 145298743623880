import * as katex from 'katex';
import { QEHelper, numberToDecimal, QEValue, QEValueTree, QEValueString, QEValueJSON, QEValueMap, QEValueBoolean, QEValueWidget, QEValueAnswer } from '../../common/QEHelper';
import { QETerm } from '../../common/QETerm';
import { QESolver } from '../QESolver';
import { Eq } from '../../common/QEWidget';
import { QEWidgetTable as Table } from '../../common/Widget/Table';

const QEWidgetGraph = function(QEWidgetGraphCommon) {
	class QEWidgetGraph extends QEWidgetGraphCommon {

	/**
	 * Display widget for representing Widget values on a graph
	 * @constructor
	 * @param {Object} value - Widget object (usually Equation) containing the value to be presented
	 * @param {string} display_options - string representing the image style to use
	 */
	constructor(series_data, display_options, input_options) {
		super(series_data, display_options, input_options);

		this.x_min = display_options.x_min === undefined ? -10 : parseFloat(display_options.x_min);
		this.x_max = display_options.x_min === undefined ? 10 : parseFloat(display_options.x_max);
	}

	/**
	 * Instantiates and returns a Graph widget from serialized data
	 * @param {string} serialized - serialized string containing value and display config
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 * @param {Object} [options]
	 */
	static instantiate(serialized, resolved_data, options) {
		options = options || {};

		let deserialized = JSON.parse(serialized || '{}');

		// resolve any [$name] placeholders in display_options fields
		let display_options = deserialized.display_options || {};
		for (let field_name in display_options) {
			let field_value = QEHelper.resolvePlaceholderToRefs(display_options[field_name], resolved_data);

			// serialize any trees
			if (field_value instanceof QEValueTree) {
				field_value = new QEValueString({ value: field_value.serialize_to_text() });
			}

			// check if there was an unresolved dependency
			if (!field_value) return null;
			display_options[field_name] = field_value.value;
		}

		// TODO: resolve any [$name] placeholders in input_options fields? Are there cases where we'd do so?
		let input_options = deserialized.input_options || {};

		// NOTE: instantiating Graph widget prior to series resolution, so we can read back the graph limits
		let widget = new QEWidgetGraph([], display_options, input_options);

		let series_src = deserialized.series || [];
		let series_data = [];

		// TODO: series-type-specific handling config for value fields
		// e.g. renaming field, resolving with display flag, default values, etc.
		/*
			let series_field_configs = {
				'labels': {
				},
				'circle': {
				}
			};
		*/

		// resolve any [$name] placeholders in each series, and split values into arrays of {x, y, etc} maps
		for (let series_idx = 0; series_idx < series_src.length; series_idx++) {
			let series = series_src[series_idx];

			// switch based on type - different types have different fields
			// if function, iterate over x range and interval to produce x,y values
			let series_display_options = Object.assign({}, series.display_options);
			if (['labels', 'points', 'segments'].indexOf(series.type) != -1) {
				let values;
				if (typeof series.values === 'object') {
					values = series.values;

					if (!(values instanceof Array)) {
						values = [values];
					}
				} else {
					// first JSON.parse, THEN resolve each value -> otherwise parse can fail on double-unescaped strings
					let series_values = series.values || '';
					if (!series_values.match(/^\[[\s\S]*\]$/)) {
						// wrap values in array - to support legacy non-JSON config lists
						series_values = '[' + series_values + ']';
					}
					try {
						values = JSON.parse(series_values);
					} catch (err) {
						console.log('PARSE ERROR: ', series);
						values = [];
					}
				}

				// now iterate over each entry in values, and for each map value resolve
				for (let i = 0; i < values.length; i++) {
					let value = values[i];
					for (let field_name in value) {
						// resolve value placeholders
						let values_str;

						// NOTE: this field-specific handling could be specified by config
						if (series.type == 'labels' && field_name == 'value' || field_name.match(/label$/)) {
							values_str = QEHelper.resolvePlaceholderToMarkup(value[field_name], resolved_data).value;

							// check if there was an unresolved dependency
							if (values_str === null) return null;

							// check string for '<katex>' and '</katex>' -> if found, latex render
							if (values_str.indexOf('<katex>') !== -1) {
								values_str = values_str.replace(/<katex>(.*?)<\/katex>/g, function (full, inner) {
									return katex.renderToString(inner, { displayMode: true });
								});
							}

							// wrap in map so Graph library can handle as markup
							values_str = { type: 'markup', value: values_str };
						} else {
							values_str = QEHelper.resolvePlaceholderToString(value[field_name], resolved_data);

							// check if there was an unresolved dependency
							if (values_str === null) return null;
							values_str = values_str.value;
						}

						value[field_name] = values_str;
					}
				}

				series_data.push({ type: series.type, values: values, display_options: series_display_options });
			} else if (['arc', 'circle'].indexOf(series.type) != -1) {
				let values;
				if (typeof series.values === 'object') {
					values = series.values;

					if (!(values instanceof Array)) {
						values = [values];
					}
				} else {
					// first JSON.parse, THEN resolve each value -> otherwise parse can fail on double-unescaped strings
					let series_values = series.values || '';
					if (!series_values.match(/^\[[\s\S]*\]$/)) {
						// wrap values in array - to support legacy non-JSON config lists
						series_values = '[' + series_values + ']';
					}
					try {
						values = JSON.parse(series_values);
					} catch (err) {
						console.log('PARSE ERROR: ', series);
						values = [];
					}
				}

				// now iterate over each entry in values, and for each map value resolve
				for (let i = 0; i < values.length; i++) {
					let value = values[i];
					for (let field_name in value) {
						// resolve value placeholders
						let values_str;

						// NOTE: this field-specific handling could be specified by config
						if (field_name.match(/label$/)) {
							values_str = QEHelper.resolvePlaceholderToMarkup(value[field_name], resolved_data);

							// check if there was an unresolved dependency
							if (values_str === null) return null;

							// wrap in map so Graph library can handle as markup
							values_str = { type: 'markup', value: values_str.value };
						} else {
							values_str = QEHelper.resolvePlaceholderToString(value[field_name], resolved_data);

							// check if there was an unresolved dependency
							if (values_str === null) return null;
							values_str = values_str.value;
						}

						value[field_name] = values_str;
					}

					// NOTE: this field-renaming could be specified by config
					value.cX = value.x;
					value.cY = value.y;
					value.radius = value.r;
					value.end_angle = 2 * Math.PI;
				}

				// NOTE: this display_options default value could be specified by config
				series_display_options = Object.assign({ line_width: 2, line_color: "#d00", fill: false, fill_color: "#d00" }, series.display_options);

				// resolve display_options placeholders
				for (let field_name in series_display_options) {
					// resolve value placeholders
					let values_str;

					// NOTE: this field-specific handling could be specified by config
					if (field_name.match(/label$/)) {
						values_str = QEHelper.resolvePlaceholderToMarkup(series_display_options[field_name], resolved_data);

						// check if there was an unresolved dependency
						if (values_str === null) return null;

						// wrap in map so Graph library can handle as markup
						values_str = { type: 'markup', value: values_str.value };
					} else {
						values_str = QEHelper.resolvePlaceholderToString(series_display_options[field_name], resolved_data);

						// check if there was an unresolved dependency
						if (values_str === null) return null;

						values_str = values_str.value;
					}

					series_display_options[field_name] = values_str;
				}

				series_data.push({ type: 'arcs', values: values, display_options: series_display_options });
			} else if (['polygon'].indexOf(series.type) != -1) {
				let values;
				if (typeof series.values === 'object') {
					values = series.values;

					if (!(values instanceof Array)) {
						values = [values];
					}
				} else {
					// first JSON.parse, THEN resolve each value -> otherwise parse can fail on double-unescaped strings
					let series_values = series.values || '';
					if (!series_values.match(/^\[[\s\S]*\]$/)) {
						// wrap values in array - to support legacy non-JSON config lists
						series_values = '[' + series_values + ']';
					}
					try {
						values = JSON.parse(series_values);
					} catch (err) {
						console.log('PARSE ERROR: ', series);
						values = [];
					}
				}

				// now iterate over each entry in values, and for each map value resolve
				for (let i = 0; i < values.length; i++) {
					let value = values[i];
					for (let field_name in value) {
						// resolve value placeholders
						let values_str = QEHelper.resolvePlaceholderToString(value[field_name], resolved_data);

						// check if there was an unresolved dependency
						if (values_str === null) return null;
						values_str = values_str.value;

						value[field_name] = values_str;
					}
				}

				// convert array structure into flat, numbered key structure
				let temp_values = {
					num_sides: undefined,
				};
				if (values.length == 1) {
					// already in flat structure
					temp_values = values[0];
				} else {
					for (let i = 0; i < values.length; i++) {
						let value = values[i];
						for (let field_name in value) {
							temp_values[field_name + (i + 1).toString()] = value[field_name];
						}
					}
				}
				temp_values.num_sides = values.length;
				values = [temp_values];

				// NOTE: this display_options default value could be specified by config
				series_display_options = Object.assign({ line_width: 2, line_color: "#d00", fill: false, fill_color: "#d00" }, series.display_options);

				series_data.push({ type: 'polygon', values: values, display_options: series_display_options });
			} else if (series.type == 'points_from_table') {
				// resolve values string into a tree
				let values_str = QEHelper.resolvePlaceholderToRefs(series.values || '', resolved_data);

				// check if there was an unresolved dependency
				if (!values_str) return null;

				// validate widget is a Table
				if (!(values_str.value instanceof Table)) {
					console.log('Error: invalid series table type: ', values_str);
					continue;
				}

				// get field names from table header columns
				let headers = values_str.value.values.headers;
				let header_keys = [];
				for (let col = 0; col < headers.length; col++) {
					header_keys.push(headers[col].value.serialize_to_text());
				}

				let values = [];

				// generate points array from table rows
				let rows = values_str.value.values.rows;
				for (let row_idx = 0; row_idx < rows.length; row_idx++) {
					let row = rows[row_idx];

					let value = {};
					for (let col = 0; col < headers.length; col++) {
						value[header_keys[col]] = row[col].value.serialize_to_text();
					}
					values.push(value);
				}

				series_data.push({ type: 'points', values: values, display_options: series_display_options });
			} else if (series.type == 'segments_from_table') {
				// resolve values string into a tree
				let values_str = QEHelper.resolvePlaceholderToRefs(series.values || '', resolved_data);

				// check if there was an unresolved dependency
				if (!values_str) return null;

				// validate widget is a Table
				if (!(values_str.value instanceof Table)) {
					console.log('Error: invalid series table type: ', values_str);
					continue;
				}

				// get field names from table header columns
				let headers = values_str.value.values.headers;
				let header_keys = [];
				for (let col = 0; col < headers.length; col++) {
					header_keys.push(headers[col].value.serialize_to_text());
				}

				let values = [];

				// NOTE: segments expect x1, y1, x2, y2
				// generate points array from table rows
				let rows = values_str.value.values.rows;
				for (let row_idx = 0; row_idx < rows.length; row_idx++) {
					let row = rows[row_idx];

					let value = {};
					for (let col = 0; col < headers.length; col++) {
						value[header_keys[col]] = row[col].value.serialize_to_text();
					}
					values.push(value);
				}

				series_data.push({ type: 'segments', values: values, display_options: series_display_options });
			} else if (series.type == 'polygon_from_table') {
				// resolve values string into a tree
				let values_str = QEHelper.resolvePlaceholderToRefs(series.values || '', resolved_data);

				// check if there was an unresolved dependency
				if (!values_str) return null;

				// validate widget is a Table
				if (!(values_str.value instanceof Table)) {
					console.log('Error: invalid series table type: ', values_str);
					continue;
				}

				// get field names from table header columns
				let headers = values_str.value.values.headers;
				let header_keys = [];
				for (let col = 0; col < headers.length; col++) {
					header_keys.push(headers[col].value.serialize_to_text());
				}

				let values = [];

				// generate points array from table rows
				let rows = values_str.value.values.rows;
				for (let row_idx = 0; row_idx < rows.length; row_idx++) {
					let row = rows[row_idx];

					let value = {};
					for (let col = 0; col < headers.length; col++) {
						value[header_keys[col]] = row[col].value.serialize_to_text();
					}
					values.push(value);
				}

				// convert array structure into flat, numbered key structure
				let temp_values = {
					num_sides: undefined,
				};
				if (values.length == 1) {
					// already in flat structure
					temp_values = values[0];
				} else {
					for (let i = 0; i < values.length; i++) {
						let value = values[i];
						for (let field_name in value) {
							temp_values[field_name + (i + 1).toString()] = value[field_name];
						}
					}
				}
				temp_values.num_sides = values.length;
				values = [temp_values];

				// NOTE: this display_options default value could be specified by config
				series_display_options = Object.assign({ line_width: 2, line_color: "#d00", fill: false, fill_color: "#d00" }, series.display_options);


				series_data.push({ type: 'polygon', values: values, display_options: series_display_options });
			} else if (series.type == 'fn_of_x') {
				// resolve values string into a tree
				let values_str: QEValueTree = QEHelper.resolvePlaceholderToTree(series.values || '', resolved_data);

				// check if there was an unresolved dependency
				if (!values_str) return null;

				let func;
				if (values_str.value instanceof QETerm) {
					func = values_str.value;
				} else {
					console.log('Error: invalid series function type: ', series);
					continue;
				}

				// cap to graph area
				let x_min = widget.x_min;
				let x_max = widget.x_max;

				let steps = 100; // TODO: use graph pixel width and x-range to ensure steps every 5 pixels?
				let interval = (x_max - x_min) / steps; // TODO: use set number of points? Range / 20?

				// keep track of previous point so we don't double-calculate on each segment
				let x1 = null;
				let y1 = null;
				let x2, y2;

				// iterate over x and find y values by replacing x value in tree and evaluating
				let values = [];
				for (let step_num = 0; step_num < steps; step_num++) {
					if (x1 === null) {
						x1 = x_min + step_num * interval;

						// clone func, then replace VARIABLE "x" nodes with DECIMAL x values and evaluate 
						let clone = func.clone();
						let vars = clone.findAllChildren("type", "VARIABLE");
						for (let var_idx = 0; var_idx < vars.length; var_idx++) {
							vars[var_idx].replaceWith(QETerm.create({ type: "RATIONAL", value: x1 }));
						}
						y1 = clone.evaluate_to_float();
					}

					// clone func, then replace VARIABLE "x" nodes with DECIMAL x values and evaluate
					x2 = x_min + (step_num + 1) * interval;

					// quick and dirty floating point error cleanup
					x2 = parseFloat(x2.toFixed(12).replace(/\.?0*$/, ''));

					let clone = func.clone();
					let vars = clone.findAllChildren("type", "VARIABLE");
					for (let var_idx = 0; var_idx < vars.length; var_idx++) {
						vars[var_idx].replaceWith(QETerm.create({ type: "RATIONAL", value: x2 }));
					}
					y2 = clone.evaluate_to_float();

					// quick and dirty floating point error cleanup
					y2 = parseFloat(y2.toFixed(12).replace(/\.?0*$/, ''));

					if (!isNaN(y1) && !isNaN(y2)) {
						values.push({ x1: x1, y1: y1, x2: x2, y2: y2 });
					}

					// save end point as start of next segment
					x1 = x2;
					y1 = y2;
				}

				series_data.push({ type: 'segments', values: values, display_options: series_display_options });
			} else if (series.type == 'linear_inequality_1d') {
				// resolve values string into a tree
				let values_src = QEHelper.resolvePlaceholderToRefs(series.values || '', resolved_data);

				// check if there was an unresolved dependency
				if (!values_src) return null;

				if (values_src.value instanceof QEValueAnswer) {
					values_src = values_src.value;
				}

				// if values_src is a string, parse to a tree
				if (values_src instanceof QEValueString) {
					values_src = QEHelper.resolvePlaceholderToTree(values_src.value, resolved_data);
				}

				// if data isn't already json points, pass to graph_linear_inequality_1d Solution to generate
				if (!(values_src.value instanceof QEValueJSON)) {
					// get values from graph_linear_inequality_1d Solution
					if (values_src.value instanceof QETerm) {
						// no change needed - already expected format for Solution
					} else if (values_src.value instanceof Eq) {
						values_src = new QEValueTree({value: values_src.value.value });
					} else {
						console.log('Error: invalid series function type: ', series);
						return null;
					}

					const passed_widget = QEValue.create({ type: "widget", subtype: "graph", value: widget });
					values_src = QESolver.solveUsing('graph_linear_inequality_1d', values_src, { graph_widget: passed_widget });

					if (!values_src) return null;
					else values_src = values_src.value;

					if (!values_src || values_src.type != 'json') {
						console.log('Error: Solution did not produce graph data: ', values_src);
						return null;
					}
				}

				// cast json points to segment data
				if (values_src.value.length == 1 && 'x' in values_src.value[0]) {
					// line segment is actually a single point
					// TODO: set radius and color from display_options line_width and line_color?
					series_data.push({ type: 'points', values: values_src.value, display_options: display_options });
				} else {
					series_data.push({ type: 'segments', values: values_src.value, display_options: display_options });
				}
			} else {
				console.log('Error: invalid series type: ', series);
			}
		}
		widget.series = series_data;

		return widget;
	}

	} // class QEWidgetGraph
	return QEWidgetGraph;
};
export { QEWidgetGraph };
