import { QETerm } from '../../QETerm';
import { QEHelper } from '../../QEHelper';
import { QEWidget, DisplayOptions, TagElement } from '../../QEWidget';

export class QEWidgetDotPlot extends QEWidget {
	display_options: { [key: string]: any };

	dataset_from_ref: boolean;
	dataset: { label: string, value: number }[];
	title: string;
	x_label: string;
	y_label: string;

	dot_colour: string;
	dot_offset: number;
	dot_size: number;
	dot_spacing: number;
	item_spacing: number;

	font_family: string;
	font_size: string;
	label_colour: string;
	label_offset: number;
	title_colour: string;

	static default_style = {
		title: '',
		x_label: '',
		y_label: '',

		dot_colour: '#d00000',
		dot_offset: 15,
		dot_size: 10,
		dot_spacing: 5,
		item_spacing: 75,

		font_family: 'Lato, sans-serif',
		font_size: '10pt',
		label_colour: '#000000',
		label_offset: 10,
		title_colour: '#333333',
	};

	constructor(dataset: { label: string, value: number }[], display_options: DisplayOptions = {}) {
		super();

		this.dataset = dataset;
		this.display_options = display_options;

		// apply default style, then display options and style overrides
		Object.assign(this, QEWidgetDotPlot.default_style, display_options);
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		const deserialized = JSON.parse(serialized);

		const dataset = QEHelper.resolvePlotDataset(serialized, resolved_data, options);
		if (!dataset) return null; // unresolved dependency, or resolution error

		// build map and resolve any [$name] placeholders in display_options
		const display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new QEWidgetDotPlot(dataset, display_options);
		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options) {
		// TODO: support passed display option overrides

		return this.draw();
	}

	draw() {
		// Determine the max value within the dataset
		const max_count = this.dataset.length ? Math.max.apply(null, this.dataset.map(x => { return x.value; })) : 0;

		const svg = new TagElement("svg");
		svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
		const g = new TagElement("g"); // enclose the shape into a group element

		const gHeight = this.dot_size * max_count + this.dot_spacing * max_count;
		const gWidth = this.item_spacing * this.dataset.length;

		const sHeight = gHeight + this.dot_offset + this.label_offset + Math.ceil(parseInt(this.font_size) * 1.4) + (this.title ? 20 : 0) + (this.x_label ? 20 : 0);
		const sWidth = gWidth + (this.y_label ? 30 : 0);

		// Draw the main axis
		const arr = [];
		arr[0] = 'M 0 ' + gHeight;
		arr[1] = 'L ' + gWidth + ' ' + gHeight;
		g.innerHTML += '<path d="'+ arr.join(' ') +'" fill="none" stroke="#000" stroke-width="2"/>';

		// Draw the dataset contents
		for (let i = 0; i < this.dataset.length; i++) {
			// Determine the label origin
			const x = this.item_spacing * i + this.item_spacing/2;
			const y = gHeight + this.label_offset;
			const item = this.dataset[i];

			g.innerHTML += '<text x="'+ x +'" y="'+ y +'" font-family="'+ this.font_family +'" font-size="'+ this.font_size +'" alignment-baseline="hanging" text-anchor="middle" fill="'+ this.label_colour +'" stroke="none">'+ item.label +'</text>';

			for (let j = 0; j < item.value; j++) {
				const cx = x;
				const cy = gHeight - this.dot_offset - (j * (this.dot_spacing + this.dot_size));
				g.innerHTML += '<circle cx="'+ cx +'" cy="'+ cy +'" r="'+ this.dot_size/2 +'" fill="'+ this.dot_colour +'" stroke="none" />';
			}
		}

		if (this.title) {
			const x = gWidth / 2;
			const y = -30;
			g.innerHTML += '<text x="'+ x +'" y="'+ y +'" font-family="'+ this.font_family +'" font-size="'+ this.font_size +'" alignment-baseline="hanging" text-anchor="middle" fill="'+ this.title_colour +'" stroke="none">'+ this.title +'</text>';
		}

		if (this.x_label) {
			const x = gWidth / 2;
			const y = gHeight + 25;
			g.innerHTML += '<text x="'+ x +'" y="'+ y +'" font-family="'+ this.font_family +'" font-size="'+ this.font_size +'" alignment-baseline="hanging" text-anchor="middle" fill="'+ this.label_colour +'" stroke="none">'+ this.x_label +'</text>';
		}

		if (this.y_label) {
			const x = -20;
			const y = gHeight/2;
			const rot = [-90, x, y];
			g.innerHTML += '<text x="'+ x +'" y="'+ y +'" font-family="'+ this.font_family +'" font-size="'+ this.font_size +'" alignment-baseline="hanging" text-anchor="middle" fill="'+ this.label_colour +'" stroke="none" transform="rotate('+ rot.toString() +')">'+ this.y_label +'</text>';
		}

		g.setAttribute('transform', 'translate('+ (this.y_label ? 30 : 0) +','+ (this.dot_offset + (this.title ? 20 : 0)) +')');

		svg.innerHTML += g.outerHTML();

		svg.setAttribute('width', sWidth.toString());
		svg.setAttribute('viewBox', [0,0, sWidth, sHeight].join(' '));

		return svg.outerHTML();
	}

	exportValue(options?){
		return {
			type: 'dot_plot',
			dataset: JSON.stringify(this.dataset),
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

