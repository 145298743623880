import { QEWidget } from '../QEWidget';


export interface GraphSeries {
	type: string;
	values: any;
	display_options?: any;
}

export interface GraphPointsSeries extends GraphSeries {
	type: "points";
	values: GraphPoints[];
}

export interface GraphSegmentsSeries extends GraphSeries {
	type: "segments",
	values: GraphSegment[]
}

export interface GraphPoints {
	x: number;
	y: number;
	state: "closed" | "open";
}

export interface GraphSegment {
	x1: number;
	y1: number;
	x2: number;
	y2: number;
	start_point: "closed" | "open";
	end_point: "closed" | "open" | "arrow";
}


export class QEWidgetGraph extends QEWidget {
	display_options: unknown;
	series: GraphSeries[];
	input_options: unknown;
	user_points: any[];
	user_values: any[];
	preview_values: any[];

	/**
	 * Display widget for representing Widget values on a graph
	 * @constructor
	 * @param {Object} value - Widget object (usually Equation) containing the value to be presented
	 * @param {string} display_options - string representing the image style to use
	 */
	constructor(series_data, display_options, input_options) {
		super();

		const default_graph_options = { // unnecessary, already supplied client/QEGraph.ts?
			// padding_bottom: 30,
			// padding_left: 30,
			// padding_right: 30,
			// padding_top: 30,
			// watermarkPos: 4,
		};

		display_options = Object.assign(default_graph_options, display_options);
		
		// if (display_options.is_number_line) {
		// 	if (display_options.padding_top === undefined) { // if no value, adjust the default for num line
		// 		display_options.padding_top = -10; // trim excess top space
		// 	}
		// 	if (display_options.padding_bottom === undefined) { // if no value,
		// 		display_options.padding_top = 0; // set a default
		// 	}
		// 	display_options.plot_width = 400;
		// 	display_options.plot_height = 60;
		// } else {
		// 	display_options.plot_height = 300;
		// }
		
		this.display_options = display_options;
		this.series = series_data || [];
		this.input_options = input_options || {};

		// user_points holds data points added by user input events
		this.user_points = [];

		// user_values holds the displayable/comparable series values derived from the input points
		this.user_values = [];

		// preview_values holds the displayable values derived from the current state and mousemove position
		this.preview_values = [];
	}

	/**
	 * Returns widget markup for inclusion in question output
	 * @param {Object} value
	 * @param {Object} options
	 * @returns {string} Generated display markup
	 */
	display(options) {
		// graph markup generated entirely on-the-fly, so empty
		return "";
	}

	addSeries(graphSeries: GraphSeries): void {
		this.series.push(graphSeries);
	}

	setSeries(graphSeries: GraphSeries): void {
		this.series = [graphSeries];
	}

	clearSeries(): void {
		this.series = [];
	}

	exportValue() {
		return {
			type: "graph",
			series: JSON.stringify(this.series || []),
			display_options: JSON.stringify(this.display_options || {}),
			input_options: JSON.stringify(this.input_options || {}),
		};
	}
}
