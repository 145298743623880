import { SvgRenderingContext } from '../../common/SvgRenderingContext';
import { QEGraph } from '../QEGraph';
import { Label } from './Label';

export class Point {
	x: number;
	y: number;
	state: string;
	color: string;
	label: string;
	label_pos: string;
	radius: number;
	line_width: number;

	constructor(data) {
		this.x = NaN;
		this.y = NaN;
		this.state = "closed";
		this.color = "#D00";
		this.label = "";
		this.label_pos = "N";
		this.radius = 4;
		this.line_width = 2;

		for (const prop in data) {
			if (data[prop] !== undefined) {
				// attempt to cast to number or boolean
				if (!Number.isNaN(Number(data[prop]))) this[prop] = Number(data[prop]);
				else if (data[prop] === true || data[prop] === false) this[prop] = data[prop];
				else if (data[prop] === "true") this[prop] = true;
				else if (data[prop] === "false") this[prop] = false;
				else this[prop] = data[prop];
			}
		}
	}

	plot(graph: QEGraph, ctx: SvgRenderingContext): void {
		// TODO: need to be able to pass/set a label offset
		const x = graph.screenX(this.x);
		const y = graph.screenY(this.y);
		const radius = this.radius;
		const start_angle = 0;
		const end_angle = 2 * Math.PI;

		ctx.save();

		// Set the visual style
		ctx.lineWidth = this.line_width;
		ctx.fillStyle = ctx.strokeStyle = this.color;

		// create clipping area, to restrict line drawing to graph area
		ctx.save();
		ctx.beginPath();
		ctx.rect(graph.padding_left - radius, graph.padding_top - radius, graph.plot_width + 2 * radius, graph.plot_height + 2 * radius);
		ctx.clip();

		ctx.beginPath();
		if (this.state == "cross-out") {
			const corner_offset = radius * 1.5;
			ctx.moveTo(x - corner_offset, y - corner_offset);
			ctx.lineTo(x + corner_offset, y + corner_offset);
			ctx.moveTo(x - corner_offset, y + corner_offset);
			ctx.lineTo(x + corner_offset, y - corner_offset);
			ctx.stroke();
		} else if (this.state == "closed") {
			// Draw the point
			ctx.arc(x, y, radius, start_angle, end_angle);
			ctx.fill();
		} else if (this.state == "open") {
			// Draw the point
			ctx.arc(x, y, radius, start_angle, end_angle);
			ctx.stroke();
		}
		ctx.closePath();

		// Draw the label
		if (this.label) {
			new Label({
				x: this.x,
				y: this.y,
				color: this.color,
				value: this.label,
				pos: this.label_pos,
			}).plot(graph, ctx);
		}
		ctx.restore();
		ctx.restore();
	}
}
