import { SvgRenderingContext } from '../../common/SvgRenderingContext';
import { QEGraph } from '../QEGraph';
import { Label } from './Label';
import { Line } from './Line';

export class Arc {
	pxRadius: number;
	start_angle: number;
	end_angle: number;
	counterClockwise: boolean;
	color: string;
	line_width: number;
	line_color: string;
	line_style: string;
	fill: boolean;
	fill_color: string;
	start_ray_arrow: boolean;
	show_start_ray: boolean;
	show_end_ray: boolean;
	end_ray_arrow: boolean;
	show_arc: boolean;
	start_deg: number;
	end_deg: number;
	cX: number;
	cY: number;
	vertex_label: string;
	vertex_label_pos: string;

	isArrowheadSolid: boolean;
	arrowheadLength: number;
	arrowheadWidth: number;

	x: number;
	y: number;

	angle_sym: string;
	radius: number;
	angle_mark: string;
	radiusX: number;
	radiusY: number;

	constructor(data) {
		(this.cX = NaN), (this.cY = NaN), (this.pxRadius = 10);
		this.start_angle = 0;
		this.end_angle = Math.PI; // semi-circle
		this.counterClockwise = true;
		this.color = "#D00";
		this.line_width = 1;
		this.fill = false;
		this.fill_color = "#D00";
		this.show_start_ray = false;
		this.start_ray_arrow = false;
		this.show_end_ray = false;
		this.end_ray_arrow = false;
		this.show_arc = true;

		for (const prop in data) {
			if (data[prop] !== undefined) {
				// attempt to cast to number or boolean
				if (!Number.isNaN(Number(data[prop]))) this[prop] = Number(data[prop]);
				else if (data[prop] === true || data[prop] === false) this[prop] = data[prop];
				else if (data[prop] === "true") this[prop] = true;
				else if (data[prop] === "false") this[prop] = false;
				else this[prop] = data[prop];
			}
		}

		// convert start/end degrees to radians
		if (this.start_deg !== undefined) {
			this.start_angle = (2 * Math.PI * this.start_deg) / 360;
		}
		if (this.end_deg !== undefined) {
			this.end_angle = (2 * Math.PI * this.end_deg) / 360;
		}

		// NOTE: html5 canvas angles increase in a clockwise direction. We multiply by -1 to flip them back
		this.start_angle = -this.start_angle;
		this.end_angle = -this.end_angle;
	}

	plot(graph: QEGraph, ctx: SvgRenderingContext): void {
		const x = graph.screenX(this.cX);
		const y = graph.screenY(this.cY);

		// NOTE: not supporting arcs with different x, y radii - just using radiusX
		const radiusX = graph.xWidthToPixels(this.radiusX || this.radius || 0) || this.pxRadius;
		const radiusY = graph.yHeightToPixels(this.radiusY || this.radius || 0) || this.pxRadius;

		// save context style info
		ctx.save();

		ctx.lineWidth = this.line_width;
		ctx.strokeStyle = this.line_color || this.color;

		// Set line styling
		if (this.line_style == "dotted") {
			ctx.setLineDash([5, 5]);
		} else if (this.line_style == "dashed") {
			ctx.setLineDash([5, 15]);
		} else if (this.line_style == "none") {
			ctx.strokeStyle = "rgba(0,0,0,0)";
		}

		// do two passes, first for fill, then for strokes
		if (this.fill) {
			ctx.beginPath();

			// go to center, then extend path to start of arc
			ctx.moveTo(x, y);

			const startAngle = this.start_angle % (2*Math.PI);
			const startX = x + radiusX * Math.cos(startAngle),
			startY = y + radiusX * Math.sin(startAngle);
			ctx.lineTo(startX, startY);

			ctx.arc(x, y, radiusX, this.start_angle, this.end_angle, this.counterClockwise);

			ctx.fillStyle = this.fill_color || this.color;
			ctx.fill();

			ctx.closePath();
		}

		if (this.show_arc) {
			ctx.beginPath();
			ctx.arc(x, y, radiusX, this.start_angle, this.end_angle, this.counterClockwise);
			ctx.stroke();
		}

		if (this.show_start_ray) {
			new Line({
				line_color: this.line_color || this.color,
				line_width: this.line_width,
				x1: this.cX,
				y1: this.cY,
				x2: this.cX + (this.radiusX || this.radius) * Math.cos(-this.start_angle),
				y2: this.cY + (this.radiusY || this.radius) * Math.sin(-this.start_angle),
				start_point: "none",
				end_point: this.start_ray_arrow ? "arrow" : "none",
			}).plot(graph, ctx);
		}

		if (this.show_end_ray) {
			new Line({
				line_color: this.line_color || this.color,
				line_width: this.line_width,
				x1: this.cX,
				y1: this.cY,
				x2: this.cX + (this.radiusX || this.radius) * Math.cos(-this.end_angle),
				y2: this.cY + (this.radiusY || this.radius) * Math.sin(-this.end_angle),
				start_point: "none",
				end_point: this.end_ray_arrow ? "arrow" : "none",
			}).plot(graph, ctx);
		}

		// Draw arrowhead
		/*
        // TODO: atan args are incorrect. The drawArrowhead code should be invoked from Jump, not Arc. FIXME!
                if (this.arrowhead) {
                    var arrowheadY = graph.screenY(this.y1 + 2);
                    var endRadians = Math.atan((y - y) / (this.x2 - this.x1));
                    endRadians += ((this.x2 > this.x1) ? 90 : -90) * Math.PI / 180;
                    if (this.x2 > this.x1) {
                        this.drawArrowhead(ctx, x+5, arrowheadY, endRadians);
                    } else this.drawArrowhead(ctx, x-5, arrowheadY, endRadians);
                }
        */
		// vertex angle arcs and marks
		if (["arc", "right"].indexOf(this.angle_sym) != -1) {
			ctx.save();
			ctx.setLineDash([]); // clear any dotted/dashed style

			// "equivalent" marks on angle arcs
			const num_marks = parseInt(this.angle_mark);

			const angle_sym = this.angle_sym;

			if (angle_sym == "right") {
				// only allow if 90 degrees?
				ctx.save();
				ctx.beginPath();
				ctx.translate(x, y);
				ctx.rotate(this.start_angle);
				ctx.moveTo(10, 0);
				ctx.lineTo(10, -10);
				ctx.lineTo(0, -10);
				ctx.stroke();
				ctx.restore();
			} else {
				const arc_radius = 20;

				ctx.save();
				ctx.beginPath();
				ctx.ellipse(x, y, arc_radius, arc_radius, 0, this.start_angle, this.end_angle, true);
				ctx.stroke();
				ctx.restore();

				// equivalent angle marks - allowed on arcs only
				if (num_marks) {
					ctx.save();

					ctx.lineWidth = 1.15;

					ctx.translate(x, y);
					ctx.rotate((this.start_angle + this.end_angle) / 2);

					const inner_radius = arc_radius - 5;
					const outer_radius = arc_radius + 5;
					if (num_marks == 3) {
						ctx.moveTo(inner_radius, -2.5);
						ctx.lineTo(outer_radius, -2.5);
						ctx.stroke();

						ctx.moveTo(inner_radius, 0);
						ctx.lineTo(outer_radius, 0);
						ctx.stroke();

						ctx.moveTo(inner_radius, 2.5);
						ctx.lineTo(outer_radius, 2.5);
						ctx.stroke();
					} else if (num_marks == 2) {
						ctx.moveTo(inner_radius, -1.5);
						ctx.lineTo(outer_radius, -1.5);
						ctx.stroke();

						ctx.moveTo(inner_radius, 1.5);
						ctx.lineTo(outer_radius, 1.5);
						ctx.stroke();
					} else {
						ctx.moveTo(inner_radius, 0);
						ctx.lineTo(outer_radius, 0);
						ctx.stroke();
					}
					ctx.restore();
				}
			}
			ctx.restore();
		}

		// vertex labels
		if (this["vertex_label"]) {
			ctx.save();
			new Label({
				x: this.x,
				y: this.y,
				color: this.line_color,
				value: this.vertex_label,
				pos: this.vertex_label_pos,
			}).plot(graph, ctx);
			ctx.restore();
		}

		// restore context style info
		ctx.restore();
	}

	drawArrowhead(ctx: SvgRenderingContext, x: number, y: number, radians: number): void {
		const isSolid = this.isArrowheadSolid || false;
		const arrowheadLength = this.arrowheadLength || 10; // x
		const arrowheadWidth = this.arrowheadWidth || 6;

		ctx.save();
		ctx.beginPath();
		ctx.translate(x, y);
		ctx.rotate(radians);

		if (isSolid) {
			ctx.moveTo(0, 0);
			ctx.lineTo(arrowheadWidth, arrowheadLength);
			ctx.lineTo(-arrowheadWidth, arrowheadLength);
			ctx.closePath();
			ctx.restore();
			ctx.fill();
		} else {
			ctx.moveTo(0, 0);
			ctx.lineTo(arrowheadWidth, arrowheadLength);
			ctx.stroke();
			ctx.moveTo(0, 0);
			ctx.lineTo(-arrowheadWidth, arrowheadLength);
			ctx.stroke();
			ctx.restore();
		}
	}
}
