import { QETerm } from '../QETerm';
import { QEHelper } from '../QEHelper';
import { QEWidget, DisplayOptions, TagElement } from '../QEWidget';
import * as jQuery from 'jquery';

// FractionSet is a set of coloured balls, grouped with ovals (or ellipses)
export class QEWidgetFractionSet extends QEWidget {
	display_options: { [key: string]: any };
	value: number; // set size
	num_wanted_groups: number;
	num_equal_groups: number;

	fill_colour: string; // non-selected object fill colour
	line_colour: string; // non-selected object line colour
	num_fill_colour: string; // selected object fill colour
	num_line_colour: string; // selected object line colour
	group_line_colour: string; // non-selected group line colour
	num_group_line_colour: string; // selected group line colour

	static default_style = {
		fill_colour: '#ffffff',
		line_colour: '#000000',
		num_fill_colour: '#b2fcfc',
		num_line_colour: '#10a0d8',
		group_line_colour: '#20ff20',
		num_group_line_colour: '#f92df8',
	};

	constructor(value: number, num_wanted_groups: number, num_equal_groups: number, display_options: DisplayOptions = {}) {
		super();

		this.value = value;
		this.num_wanted_groups = num_wanted_groups;
		this.num_equal_groups = num_equal_groups;
		this.display_options = display_options;

		// apply default style
		Object.assign(this, QEWidgetFractionSet.default_style);

		// apply side length, angle settings, and any style overrides
		Object.assign(this, display_options);
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		let deserialized = JSON.parse(serialized);

		// resolve any references in value
		let resolved = QEHelper.resolvePlaceholderToTree(deserialized.value, resolved_data);
		if (!resolved) return null;

		let value = QEHelper.resolveToNumber(deserialized.value, resolved_data);
		let num_wanted_groups = QEHelper.resolveToNumber(deserialized.num_wanted_groups, resolved_data);
		let num_equal_groups = QEHelper.resolveToNumber(deserialized.num_equal_groups, resolved_data);

		// TODO: validate

		// build map and resolve any [$name] placeholders in display_options
		let display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new QEWidgetFractionSet(value, num_wanted_groups, num_equal_groups, display_options);

		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} value
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options) {
		// TODO: support passed display option overrides

		return this.draw();
	}

	draw() {
		const items_per_group = this.value / this.num_equal_groups;
		const wanted_objects = items_per_group * this.num_wanted_groups;

		const padding = 8; // padding to prevent edge clipping
		const r = 16;
		const inter_obj_padding = 10;

		const svg_width = 2 * padding + this.value * (2 * r + inter_obj_padding) - inter_obj_padding ;
		const svg_height = 2 * padding + 2 * r;

		const svg = new TagElement("svg");
		svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
		svg.setAttribute('viewBox', [0, 0, svg_width, svg_height].join(' '));
		svg.setAttribute('width', svg_width.toString());

		// BUILD THE SHAPE
		let markup = '';

		const g = new TagElement('g'); // enclose the shape into a group element
		g.setAttribute('transform', 'translate(0,0)');

 		g.innerHTML += '<style>';
		g.innerHTML += 	'g .fraction_set_object { stroke: '+ this.line_colour +'; fill: '+ this.fill_colour +'; }';
		g.innerHTML += 	'g .fraction_set_object-wanted { stroke: '+ this.num_line_colour +'; fill: '+ this.num_fill_colour +'; }';
		g.innerHTML += 	'g .fraction_set_group { stroke: '+ this.group_line_colour +'; }';
		g.innerHTML += 	'g .fraction_set_group-wanted { stroke: '+ this.num_group_line_colour +'; }';
		g.innerHTML += '</style>';

		// first draw set objects
		for (let j = 0; j < this.value; j++) {
			const cx = padding + r + j * (2 * r + inter_obj_padding);
			const cy = padding + r;
			const object_class = j < wanted_objects ? 'fraction_set_object-wanted' : 'fraction_set_object';
			g.innerHTML += '<circle cx="'+ cx +'" cy="'+ cy +'" r="'+ r +'" stroke-width="2" class="'+ object_class +'" />';
		}

		// now draw groupings
		const inter_group_padding = inter_obj_padding / 2;

		const group_width = items_per_group * (2 * r + inter_obj_padding) - inter_obj_padding + inter_group_padding;
		const group_height = 2 * r + 2 * inter_group_padding;

		for (let j = 0; j < this.num_equal_groups; j++) {
			const x = padding + j * (group_width + inter_group_padding) - inter_group_padding / 2;
			const y = padding - inter_group_padding;
			const group_class = j < this.num_wanted_groups ? 'fraction_set_group-wanted' : 'fraction_set_group';
			g.innerHTML += '<rect x="'+ x +'" y="'+ y +'" width="'+ group_width +'" height="'+ group_height +'" stroke-width="2" rx="'+ r +'" class="'+ group_class +'" fill="none" />';
		}

		svg.innerHTML += g.outerHTML();

		return svg.outerHTML();
	}

	exportValue(options?){
		return {
			type: 'fraction_set',
			value: this.value,
			num_wanted_groups: this.num_wanted_groups,
			num_equal_groups: this.num_equal_groups,
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

