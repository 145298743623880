import { QEAssert } from './QEAssert.js';
import { QETerm } from './QETerm.ts';

class QEQTenBased {
	num: number;
	den: number;

	constructor(num: number, den: number) {
		if (!Number.isInteger(num) || !Number.isInteger(den)) {
			console.log("QEQ Error 1:", num, den);
			return undefined;
		}
		if (den === 0) {
			console.log("QEQ Error 2:", num, den);
			return undefined;
		}
		var b_sign = (den < 0) ? -1 : 1;
		this.num = num * b_sign;
		this.den = den * b_sign;
	}

	multiply(other: QEQ | QEQTenBased) {
		let q = new QEQ(this.num, this.den);
		let r = new QEQ(other.num, other.den);
		return q.multiply(r);
	}
	
	divide(other: QEQ | QEQTenBased) {
		let q = new QEQ(this.num, this.den);
		let r = new QEQ(other.num, other.den);
		return q.divide(r);
	}
}

// Only the numerator may be negative
// The denominator is always positive
export class QEQ {
	num: number;
	den: number;
	imprecise: boolean;

	constructor(num: number, den: number, options: {imprecise: boolean} = {imprecise: false}) {
		options = Object.assign({imprecise: 0}, options);

		this.imprecise = options.imprecise;

		// TODO: support sign and sig_figs options and Q fields

		if (!Number.isInteger(num) || !Number.isInteger(den)) {
			console.log("QEQ Error 1:", num, den);
			return undefined;
		}
		if (den === 0) {
			console.log("QEQ Error 2:", num, den);
			return undefined;
		}
		var b_sign = (den < 0) ? -1 : 1;
		this.num = num * b_sign;
		this.den = den * b_sign;
		this.reduce();
	}
	reduce(): QEQ {
		var gcd = QEQ.GCD(this.num, this.den);
		this.num /= gcd;
		this.den /= gcd;
		return this;
	}
	isInteger(): boolean {
		return this.den === 1;
	}
	isOne(): boolean {
		return this.num === 1 && this.den === 1;
	}
	add(other: QEQ | number): QEQ {
		var a = this.num;
		var b = this.den;
		var c;
		var d;
		if (typeof other === "number") {
			c = other;
			d = 1;
		} else {
			c = other.num;
			d = other.den;
		}
		return new QEQ(a * d + c * b, b * d);
	}
	subtract(other: QEQ | number): QEQ {
		var a = this.num;
		var b = this.den;
		var c;
		var d;
		if (typeof other === "number") {
			c = other;
			d = 1;
		} else {
			c = other.num;
			d = other.den;
		}
		return new QEQ(a * d - c * b, b * d);
	}
	multiply(other: QEQ | number): QEQ {
		var a = this.num;
		var b = this.den;
		var c;
		var d;
		if (typeof other === "number") {
			c = other;
			d = 1;
		} else {
			c = other.num;
			d = other.den;
		}
		// (a/b)*(c/d) == (a*c)/(b*d) == a/d * c/b
		// reduce a/d and b/c instead of (a*c)/(b*d)
		var l = new QEQ(a, d);
		var r = new QEQ(c, b);
		l.num *= r.num;
		l.den *= r.den;
		return l; // new QEQ( a*c, b*d );
	}
	divide(other: QEQ | number): QEQ {
		const a: number = this.num;
		const b: number = this.den;
		let c: number;
		let d: number;
		if (typeof other === "number") {
			c = other;
			d = 1;
		} else {
			c = other.num;
			d = other.den;
		}
		if (d === 0) {
			return undefined;
		}
		// (a/b)/(c/d) == (a*d)/(b*c) == a/c * d/b
		// reduce a/c and d/b instead of (a*d)/(b*c)
		var l = new QEQ(a, c);
		var r = new QEQ(d, b);
		l.num *= r.num;
		l.den *= r.den;
		return l; // new QEQ( a*d, b*c );
	}
	opposite(): QEQ {
		return new QEQ(-this.num, this.den);
	}
	inverse(): QEQ {
		return new QEQ(this.den, this.num);
	}
	// a/b < c/d   ===   a*d < c*b   (b and d are positive)
	less(other: QEQ | number): boolean {
		var a = this.num;
		var b = this.den;
		var c;
		var d;
		if (typeof other === "number") {
			c = other;
			d = 1;
		} else {
			c = other.num;
			d = other.den;
		}
		return a * d < c * b;
	}
	less_or_equal(other: QEQ | number): boolean {
		var a = this.num;
		var b = this.den;
		var c;
		var d;
		if (typeof other === "number") {
			c = other;
			d = 1;
		} else {
			c = other.num;
			d = other.den;
		}
		return a * d <= c * b;
	}
	equal(other: QEQ | number): boolean {
		var a = this.num;
		var b = this.den;
		var c;
		var d;
		if (typeof other === "number") {
			c = other;
			d = 1;
		} else {
			c = other.num;
			d = other.den;
		}
		return a * d === c * b;
	}
	greater(other: QEQ | number): boolean {
		var a = this.num;
		var b = this.den;
		var c;
		var d;
		if (typeof other === "number") {
			c = other;
			d = 1;
		} else {
			c = other.num;
			d = other.den;
		}
		return a * d > c * b;
	}
	greater_or_equal(other: QEQ | number): boolean {
		var a = this.num;
		var b = this.den;
		var c;
		var d;
		if (typeof other === "number") {
			c = other;
			d = 1;
		} else {
			c = other.num;
			d = other.den;
		}
		return a * d >= c * b;
	}
	to_term(options: { value_type?: string } = {}): QETerm {
		// use options.value_type to decide how serialize value string, and set value_type attribute of RATIONAL term
		var term;
		switch (options.value_type) {
			case "decimal":
				// TODO: support options flags for rounding, specified number of digits, scientific notation
				term = QETerm.create({ type: "RATIONAL", value: this.serialize_to_decimal() });
				term.attr('value_type', 'decimal');
				return term;
			case "fraction":
				if (this.den === 1) {
					term = QETerm.create({ type: "RATIONAL", value: this.num.toString() });
					term.attr('value_type', 'integer');
				} else {
					term = QETerm.create({ type: "RATIONAL", value: this.serialize_to_frac() });
					term.attr('value_type', 'fraction');
				}
				return term;
			case "decimal_fraction":
				term = QETerm.create({ type: "RATIONAL", value: this.serialize_to_decimal_fraction() });
				term.attr('value_type', 'fraction'); return term;
			case "integer":
				if (this.den === 1) {
					term = QETerm.create({ type: "RATIONAL", value: this.num.toString() });
				} else {
					console.log('Warning: integer value_type set but den != 1');
					return this.to_term({ value_type: "fraction" });
				}
				term.attr('value_type', 'integer');
				return term;
			case "mixed":
				if (this.den === 1) {
					term = QETerm.create({ type: "RATIONAL", value: this.num.toString() });
					term.attr('value_type', 'integer');
				} else {
					term = QETerm.create({ type: "RATIONAL", value: this.serialize_to_mfrac() });
					term.attr('value_type', 'mixed');
				}
				return term;
			case "percent":
				// TODO: support options flags for rounding, specified number of digits
				term = QETerm.create({ type: "RATIONAL", value: this.serialize_to_percent() });
				term.attr('value_type', 'percent');
				return term;
			default:
				console.log('Warning: value_type not set');
				return this.to_term({ value_type: "fraction" });
		}
	}
	serialize_to_divide(): string {
		if (this.den === 1) {
			return this.num.toString();
		}
		return this.num.toString() + "/" + this.den.toString();
	}
	serialize_to_frac(): string {
		if (this.den === 1) {
			return this.num.toString();
		}
		return "\\frac{" + this.num.toString() + "," + this.den.toString() + "}";
	}
	serialize_to_mfrac(): string {
		if (this.den === 1) {
			return this.num.toString();
		}
		if (Math.abs(this.num) > this.den) {
			return "\\mfrac{" + (this.num % this.den).toString() + "," + Math.abs(Math.trunc(this.num / this.den)).toString() + "," + this.den.toString() + "}";
		}
		return "\\frac{" + this.num.toString() + "," + this.den.toString() + "}";
	}
	serialize_to_decimal_parts() {
		var num = this.num;
		var den = this.den;

		// sign
		var sign = '';
		if (num < 0) {
			num = -num;
			sign = "-";
		}
		QEAssert(num >= 0);
		QEAssert(den > 0);
		QEAssert(Number.isInteger(num));
		QEAssert(Number.isInteger(den));

		// TODO: support scientific notation output

		//////////////////////////////////////////////////////////////////////
		// Integer
		if (den === 1) {
			return [sign + num.toString(), "", "", ""];
		}
		// euclidian division
		var remainder = num % den;
		var quotient = (num - remainder) / den;
		var integer = sign + quotient.toString();
		if (remainder === 0) {
			return [integer, "", "", ""];
		}
		// keep dividing with the remainder
		num = remainder;

		//////////////////////////////////////////////////////////////////////
		// What's next? We have decimals. Do we have a reptend (repeating decimals)?
		// Look at the prime factors of the denominator
		// - denominator is only divisible by 2 and 5 --> No reptend in base 10.
		// - denominator is not divisible by 2 and 5 --> No non-repeating decimals

		// truncate decimal/repeating digits if longer than our supported precision, and use "..." to indicate it's no longer precise
		var PRECISION_DIGITS = 12;

		var repeating = "";
		var ellipsis = ""; // imprecise
		if (this.imprecise) {
			ellipsis = "...";
		}

		var remainder_2_5 = den;
		var exp_power_2 = 0;
		var exp_power_5 = 0;
		while (remainder_2_5 % 2 === 0) {
			remainder_2_5 /= 2;
			exp_power_2++;
		}
		while (remainder_2_5 % 5 === 0) {
			remainder_2_5 /= 5;
			exp_power_5++;
		}
		// Smallest power of 10 divisible by the 2 and 5 factors of the denominator
		// exp_power_10 == min{ n in N | 10^n === max{ GCD(10^m,den) | m in N} } }
		var power_10 = 1;
		var exp_power_10 = Math.max(exp_power_2, exp_power_5);
		for (var i = exp_power_10; i > 0; i--) {
			power_10 *= 10;
		}
		var nb_decimals = exp_power_10; // number of non-repeating decimals

		// We compute the decimals by computing the following euclidian division:
		//		(num * 10^nb_decimals) / den
		// The quotient equals the non-repeating decimals, to be padded with zeroes
		// The remainder is used later to compute the reptend
		//////////////////////////////////////////////////////////////////////
		// simple decimal number, no reptend, but possibly imprecise
		if (remainder_2_5 === 1) {
			let decimal = (num * (power_10 / den)).toString();
			// leading_zeros = nb_decimals - decimal.toString().length;
			decimal = decimal.padStart(nb_decimals, "0");
			return [integer, decimal, repeating, ellipsis];
		}

		//////////////////////////////////////////////////////////////////////
		// decimal number with reptend
		// non-repeating decimal part
		var decimal = "";
		if (nb_decimals > 0) {
			num *= power_10;
			// euclidian division
			remainder = num % den;
			quotient = (num - remainder) / den;
			// prepend digit place zeros to decimal portion
			decimal = quotient.toString().padStart(nb_decimals, "0");
			// keep dividing with the remainder
			num = remainder;
		}

		//////////////////////////////////////////////////////////////////////
		// decimal number with reptend
		// repeating part
		var repeating = "";
		var dividend = num;
		var divisor = den;
		// multiply the dividend by 10 until greater than divisor
		// allow detecting the end of the repeating sequence with a simple comparison.
		var n_zeros = 0;
		while (dividend < divisor) {
			dividend *= 10;
			n_zeros++;
		}

		// NOTE: the "imprecise" flag and PRECISION_DIGITS act as a safety brake to prevent the below loop from going out of control for huge reptends
		var original_dividend = dividend;
		do {
			// euclidian division
			remainder = dividend % divisor;
			quotient = (dividend - remainder) / divisor;
			// append new digits
			var new_digits = quotient.toString();
			repeating += new_digits.padStart(n_zeros, "0");
			// reset dividend for loop comparison
			dividend = remainder;
			n_zeros = 0;
			while (dividend < divisor) {
				dividend *= 10;
				n_zeros++;
			}
			if ((decimal.length + repeating.length) >= PRECISION_DIGITS) {
				// we've exceeded our supported precision limit
				ellipsis = "...";
			}
		} while (dividend !== original_dividend && !ellipsis);

		if (ellipsis) {
			// imprecise; append the truncated repeating portion to the decimal and don't display as repeating
			decimal = decimal + repeating;
			repeating = "";
		}

		return [integer, decimal, repeating, ellipsis];
	}
	serialize_to_decimal(): string {
		var parts = this.serialize_to_decimal_parts();
		var integer = parts[0];
		var decimal = parts[1];
		var repeating = parts[2];
		var ellipsis = parts[3];

		var value = integer;
		if (decimal.length || repeating.length) {
			value += '.';
		}
		value += decimal;

		if (repeating.length) {
			value += '\\repeat{' + repeating + '}';
		} else if (ellipsis) {
			// ellipsis and repeating are mutually exclusive
			value += ellipsis;
		}
		return value;
	}
	serialize_to_percent() {
		return new QEQ(this.num * 100, this.den).serialize_to_decimal() + "%";
	}
	serialize_to_decimal_fraction(): string {
		var parts = this.serialize_to_decimal_parts();
		var integer = parts[0];
		var decimal = parts[1];

		let decimalLength = decimal.length;
		let tenBaseDen = Math.pow(10, decimalLength);

		let num = parseInt(integer) * tenBaseDen + parseInt(decimal);
		let den = tenBaseDen;

		return "\\frac{" + num.toString() + "," + den.toString() + "}";
	}

	serialize_to_decimal_tenBased(): QEQTenBased {
		const parts = this.serialize_to_decimal_parts();
		let integer = Number(parts[0]);
		const decimal = parts[1];

		if ( decimal.length == 0 ) {
			return new QEQTenBased(integer, 1);
		}
		const isNegative: boolean = integer < 0;
		integer = Math.abs(integer);

		let decimalLength = decimal.length;
		let tenBaseDen = Math.pow(10, decimalLength);

		let num = (integer * tenBaseDen + Math.trunc(Number(decimal))) * (isNegative ? -1 : 1);
		let den = tenBaseDen;

		return new QEQTenBased(num, den);
	}	
	// rational from scientific notation:
	// - '1.23e-3'
	// rational from a fraction written with a 'divide' operator
	// - '3/100'
	// rational from a decimal reperesentation
	// - finite decimal part, eg: '0.25' --> 1/4
	// - trivial repeating, eg: '0.\repeat{3}' --> 1/3
	// - non-trivial repeating, eg: '0.\repeat{142857}' --> 1/7
	// - non-trivial repeating, eg: '0.\repeat{142857142857}' --> 1/7
	// - non-trivial repeating, eg: '11111.1234\repeat{142857}' --> '777778639/70000'
	// any of the above with a trailing '%'
	//
	// Other examples:
	// '0.\repeat{1250}' -->  '1250/9999'
	// '0.12500' -->  '0.125' --> '1/8'
	// '0.\repeat{09}' -->  '0.09090909...' --> '1/11'
	// '0.0\repeat{9}' -->  '0.09999999...' --> '0.1' --> '1/10'
	//
	//	- simplify trailing zeros when no reepating
	//	- simplify single-digit ellirepeating: '0.3333...' repeating is '3' not '33'
	static from_string(str: string, options: { imprecise: boolean } = { imprecise: false }): QEQ {
		// handle trailing "%" by multiplying denominator by 100
		const percent = str.indexOf('%');
		if (percent !== -1) {
			// only accept a single percent character at the very end
			if (percent !== str.length - 1) {
				return undefined;
			}
			return QEQ.from_string(str.slice(0, -1)).divide(100);
		}

		// handle scientific notation
		var e = str.indexOf('e');
		if (e !== -1 &&
			str.indexOf('repeat') === -1 // don't get caught by the "e" in "\repeat{...}"
		) {
			QEAssert(e !== str.length - 1);
			var exponent = parseInt(str.slice(e + 1));
			var tmp = QEQ.from_string(str.slice(0, e));
			if (exponent >= 1) {
				var factor = 1;
				for (var i = 0; i < exponent; i++) {
					factor *= 10;
				}
				return new QEQ(tmp.num * factor, tmp.den);
			} else if (exponent <= -1) {
				var factor = 1;
				for (var i = exponent; i < 0; i++) {
					factor *= 10;
				}
				return new QEQ(tmp.num, tmp.den * factor);
			} else if (exponent === 0) {
				return tmp;
			}
			return undefined;
		}

		// Handle fraction written with a divide operator
		var divide = str.indexOf('/');
		if (divide !== -1) {
			var num = parseInt(str.slice(0, divide));
			var den = parseInt(str.slice(divide + 1));
			return new QEQ(num, den);
		}

		if (str.indexOf("\\mfrac{") !== -1) { // cheap test
			// handle fraction written as a serialized mfrac{,}
			if (str.match(/^\\mfrac\{-?\d+,-?\d+,-?\d+\}$/)) { // more expensive test
				var frac_parts = str.match(/^\\mfrac\{(-?\d+),(-?\d+),(-?\d+)\}$/);

				// NOTE:
				// - whole number portion *should* be non-zero
				// - sign *should* only be set for whole number portion
				// - ... but we still handle it
				var sign = 1;
				if (frac_parts[1].indexOf('-') === 0) {
					sign *= -1;
				}
				if (frac_parts[2].indexOf('-') === 0) {
					sign *= -1;
				}
				if (frac_parts[3].indexOf('-') === 0) {
					sign *= -1;
				}

				var whole = Math.abs(parseInt(frac_parts[1]));
				var num = Math.abs(parseInt(frac_parts[2]));
				var den = Math.abs(parseInt(frac_parts[3]));
				return new QEQ(sign * (whole * den + num), den);
			}
			// we found "mfrac{" sub-string but failed to parse the mixed fraction
			return undefined;
		} else if (str.indexOf("\\frac{") !== -1) { // cheap test
			// handle fraction written as a serialized frac{,}
			if (str.match(/^\\frac\{-?\d+,-?\d+\}$/)) { // more expensive test
				var frac_parts = str.match(/^\\frac\{(-?\d+),(-?\d+)\}$/);
				var num = parseInt(frac_parts[1]);
				var den = parseInt(frac_parts[2]);
				return new QEQ(num, den);
			}
			// we found "frac{" sub-string but failed to parse the whole fraction
			return undefined;
		}

		// Handle leading minus sign to only have positive integers later on, making
		// it easier to 'add' integer and decimal parts.
		var sign = 1;
		var minus = str.indexOf('-');
		if (minus === 0) {
			sign = -1;
			str = str.slice(1);
			minus = str.indexOf('-');
		}
		if (str.indexOf('-') !== -1) { // misplaced '-'
			return undefined;
		}

		// detect one of 4 cases: integer, decimal, repeating decimal (\repeat{[0-9]+}), ellipsis (imprecise)
		var dot = str.indexOf('.');
		var repeat = str.indexOf('\\repeat{');
		var ellipsis = str.indexOf('...');

		var integer = 0;
		if (dot !== 0) { // allow decimal without a leading digit eg:'.42'
			integer = parseInt(str);
		}
		if (dot === str.length - 1) { // allow trailing dot eg:'42.'
			if (dot === 0) { // we are trying to parse a single dot '.'
				// One wouldn't expect '.' to be valid but we parse it as '0'.
				// It makes us more resilient to weird inputs and makes things
				// easier when implementing the 'keyboard'
				return new QEQ(0, 1);
			}
			dot = -1;
		}

		// TODO consider which of those rules to relax and accept more inputs
		QEAssert((dot !== ellipsis) || dot === -1); // forbids '99...' or '1...324'
		QEAssert(str.indexOf(' ') === -1);

		if (dot === -1) { // integer
			return new QEQ(sign * parseInt(str), 1);
		} else if (repeat === -1) { // decimal (no repeating digits)
			let decimal: string = str.slice(dot + 1);

			if (ellipsis !== -1) {
				decimal = decimal.slice(0, -3); // discard '...'

				// flag number as imprecise
				options.imprecise = true;
			}

			var factor = 1;
			for (var i = 0; i < decimal.length; i++) {
				factor *= 10;
			}

			return new QEQ(sign * (integer * factor + Math.trunc(Number(decimal))), factor, options);
		} else { // repeating digits
			var decimal = str.slice(dot + 1, repeat); // digits between "." and "\repeat{"
			var repeat_close = str.indexOf('}');
			var repeated = str.slice(repeat + 8, -1); // digits between "\repeat{" and "}"

			QEAssert(repeated !== "");

			// rf repeat factor == 999999 == 10^length - 1
			// df decimal factor == 10000 == 10^length
			var df = 1;
			for (var i = 0; i < decimal.length; i++) {
				df *= 10;
			}
			var rf = 1;
			for (var i = 0; i < repeated.length; i++) {
				rf *= 10;
			}
			rf -= 1;
			QEAssert(rf >= 9);

			QEAssert(repeated !== "");
			if (decimal === "") { // '0.09...' or '0.0909...'
				decimal = "0";
			}

			// result == sign * (integer + decimal/df + repeated/(rf*df))
			return new QEQ(sign * (rf * (df * integer + Math.trunc(Number(decimal))) + Math.trunc(Number(repeated))), rf * df);
		}
	}
	static GCD(a, b) {
		QEAssert(Number.isInteger(a));
		QEAssert(Number.isInteger(b));

		a = Math.abs(a);
		b = Math.abs(b);

		if (a == 0)
			return b;
		if (b == 0)
			return a;
		if (a == b)
			return a;

		var shift = 1; // power of 2 dividing both a and b
		while ((a % 2 === 0) && (b % 2 === 0)) {
			shift *= 2;
			a /= 2;
			b /= 2;
		}

		while (a % 2 === 0) {
			a /= 2;
		}

		do { // a is odd
			while (b % 2 === 0) {
				b /= 2;
			}
			// gcd(a, b) = gcd((a−b)/2, b)
			if (a > b) { // Swap
				var tmp = b;
				b = a;
				a = tmp;
			}
			b -= a;
		} while (b !== 0);

		return a * shift;
	}
	static combine_value_types(a, b) {
		if (!a || !b) {
			console.log('Warning: missing value_type');
			return undefined;
		}

		var value_types = ["decimal", "fraction", "integer", "mixed", "percent"];
		var type_a = value_types.indexOf(a);
		var type_b = value_types.indexOf(b);

		if (type_a == -1 || type_b == -1) {
			console.log('Warning: missing value_type combo: ', a, b);
			return undefined;
		}

		var combos = [
			// decimal, fraction, integer, mixed, percent
			["decimal", "decimal", "decimal", "decimal", "decimal"],
			["decimal", "fraction", "fraction", "mixed", "decimal"],
			["decimal", "fraction", "integer", "mixed", "decimal"],
			["decimal", "mixed", "mixed", "mixed", "decimal"],
			["decimal", "decimal", "decimal", "decimal", "percent"],
		];
		return combos[type_a][type_b];
	}
}

export class QEQinf {
	constructor() { }
	static assert(Q_or_Infinity) {
		if (!(Q_or_Infinity instanceof QEQ)) {
			QEAssert(Q_or_Infinity === "+Infinity" || Q_or_Infinity === "-Infinity");
		}
	}
	static equal(low, high) {
		QEQinf.assert(low);
		QEQinf.assert(high);
		if (low instanceof QEQ && high instanceof QEQ) {
			return low.equal(high);
		}
		return low === high;
	}
	static less(low, high) {
		QEQinf.assert(low);
		QEQinf.assert(high);
		if (low instanceof QEQ && high instanceof QEQ) {
			return low.less(high);
		}
		return low === "-Infinity" && high !== "-Infinity"
			|| low !== "+Infinity" && high === "+Infinity";
	}
	static less_or_equal(low, high) {
		QEQinf.assert(low);
		QEQinf.assert(high);
		if (low instanceof QEQ && high instanceof QEQ) {
			return low.less_or_equal(high);
		}
		return low === "-Infinity"
			|| high === "+Infinity";
	}
	static greater(high, low) {
		QEQinf.assert(low);
		QEQinf.assert(high);
		if (low instanceof QEQ && high instanceof QEQ) {
			return high.greater(low);
		}
		return high === "+Infinity" && low !== "+Infinity"
			|| high !== "-Infinity" && low === "-Infinity";
	}
	static greater_or_equal(high, low) {
		QEQinf.assert(low);
		QEQinf.assert(high);
		if (low instanceof QEQ && high instanceof QEQ) {
			return high.greater_or_equal(low);
		}
		return high === "+Infinity"
			|| low === "-Infinity";
	}
	static serialize(low) {
		QEQinf.assert(low);
		if (low instanceof QEQ) {
			return low.serialize_to_frac();
		} else {
			return low;
		}
	}
}

export class QEQInterval {
	low: number;
	high: number;
	left_bracket: boolean;
	right_bracket: boolean;

	constructor(low: number, high: number, left_bracket: boolean, right_bracket: boolean) {
		this.low = low;
		this.high = high;
		this.left_bracket = left_bracket;
		this.right_bracket = right_bracket;
	}
	intersection(other: QEQInterval): QEQInterval {
		if (other === undefined) {
			return undefined;
		}

		var low = this.low;
		var high = this.high;
		var left_bracket = this.left_bracket;
		var right_bracket = this.right_bracket;

		// intersect 'this' with [other.low, +Infinity"[
		if (QEQinf.less(other.low, low)) {
			// 'this' is included in ]other.low, +Infinity"[
		} else if (QEQinf.greater(other.low, high)) {
			return undefined;
		} else {
			if (QEQinf.equal(other.low, low)) {
				left_bracket = left_bracket && other.left_bracket;
			} else {
				left_bracket = other.left_bracket;
			}
			low = other.low;
		}

		// intersect 'this' with ]-Infinity, other.high]
		if (QEQinf.greater(other.high, high)) {
			// 'this' is included in ]-Infinity, other.high[
		} else if (QEQinf.less(other.high, low)) {
			return undefined;
		} else {
			if (QEQinf.equal(other.high, high)) {
				right_bracket = right_bracket && other.right_bracket;
			} else {
				right_bracket = other.right_bracket;
			}
			high = other.high;
		}

		if (QEQinf.equal(low, high) && !left_bracket && !right_bracket) {
			return undefined;
		}

		return new QEQInterval(low, high, left_bracket, right_bracket);
	}
	serialize(): string {
		var str = "";
		var LEFT_BOUND = QEQinf.serialize(this.low);
		var RIGHT_BOUND = QEQinf.serialize(this.high);
		var LEFT_BRACKET = "[";
		var RIGHT_BRACKET = "]";
		if (!this.left_bracket) {
			LEFT_BRACKET = "]";
		}
		if (!this.right_bracket) {
			RIGHT_BRACKET = "[";
		}
		return LEFT_BRACKET + LEFT_BOUND + ", " + RIGHT_BOUND + RIGHT_BRACKET;
	}
}

// NOTE: the logging calls defined here should support adding information to a context object (e.g. question key, and param values) which can then be retrieved at a later point,
//    typically when error logging but possibly to display on demand in the tools
// - when executed under nodejs, the calling code should override these functions, so the interface will remain the same but on the nodejs side we would also have access to
//    information about the request, such as URL and user info
// - an init/clear method is needed for the tools, since we want to be able to clear the context whenever a new question is generated
//    - on the node side, the function overrides should instead result in using a separate AsyncLocalStorage context for each request
export const log = {
	context: {},
	init: function() {
		log.context = {};
	},
	addTrackData: function(nested_keys?: string[], data){
		let context = log.getContextData();

		// if nested_keys provided, initialize any values as container keys
		if (nested_keys instanceof Array) {
			for (let i = 0; i < nested_keys.length; i++) {
				// init context container if empty, and traverse into it
				if (!context[nested_keys[i]]) {
					context[nested_keys[i]] = {};
				}
				context = context[nested_keys[i]];
			}
			Object.assign(context, data);
		} else {
			// no nested_keys provided: first param is data
			Object.assign(context, nested_keys);
		}
	},
	appendItemTo: function(key, ...all_args){
		let context = log.getContextData();

		// append items to a specified key; initialize if not already present or not an array
		if (context[key] === undefined) context[key] = [];
		else if (!(context[key] instanceof Array)) context[key] = [context[key]];
		context[key].push([...all_args]);
	},
	getContextData: function(){
		return log.context;
	},
	info: function(...all_args) {
		let now = new Date().toJSON().replace(/\..*$/, '').replace(/T/, ' ');
		console.info.apply(null, [...all_args, { module: 'QE', current_time: now, context: log.context }]);
	},
	log: function(...all_args) {
		let now = new Date().toJSON().replace(/\..*$/, '').replace(/T/, ' ');
		console.log.apply(null, [...all_args, { module: 'QE', current_time: now, context: log.context }]);
	},
	warn: function(...all_args) {
		let now = new Date().toJSON().replace(/\..*$/, '').replace(/T/, ' ');
		console.warn.apply(null, [...all_args, { module: 'QE', current_time: now, context: log.context }]);
	},
	error: function(...all_args) {
		let now = new Date().toJSON().replace(/\..*$/, '').replace(/T/, ' ');
		console.error.apply(null, [...all_args, { module: 'QE', current_time: now, context: log.context }]);
	}
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
