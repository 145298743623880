import { QETerm } from '../QETerm';
import { QEHelper } from '../QEHelper';
import { QEWidget, DisplayOptions, TagElement } from '../QEWidget';

export class QEWidgetDice extends QEWidget {
	display_options: { [key: string]: any };

	sides: number;
	value: number;

	font_family: string;
	label_colour: string;
	line_colour: string;
	face_colour: string;
	focus_colour: string;

	static default_style = {
		font_family: 'ArialMT, Arial',
		label_colour: '#333',
		line_colour: '#454545',
		face_colour: '#eee',
		focus_colour: '#fff',
	};

	constructor(sides: number, value: number, display_options: DisplayOptions = {}) {
		super();

		this.sides = sides;
		this.value = value;
		this.display_options = display_options;

		// apply default style, then display options and style overrides
		Object.assign(this, QEWidgetDice.default_style, display_options);
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		const deserialized = JSON.parse(serialized);

		const sides = QEHelper.resolveToNumber(deserialized.sides, resolved_data);
		const value = QEHelper.resolveToNumber(deserialized.value, resolved_data);
		if (!Number.isInteger(sides) || !Number.isInteger(value)) {
			console.log('Error: non-integer sides or value');
			return null;
		}

		// build map and resolve any [$name] placeholders in display_options
		const display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new QEWidgetDice(sides, value, display_options);
		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options) {
		// TODO: support passed display option overrides

		return this.draw();
	}

	draw() {
		const padding = 5; // padding to prevent edge clipping
		const sides = this.sides;

		var markup = '';

		var gHeight = 88;
		var gWidth = 0;

		const value = this.value;

		function capToSides(number, sides){
			// avoid "0" values: 
			return (number + (sides - 1)) % sides + 1;
		}

		switch (sides) {

		case 4:
			gWidth = 86;
			markup += '<polygon stroke="'+ this.line_colour +'" fill="'+ this.focus_colour +'" points="2 61 55 85 84 50 43 5"></polygon>';
			markup += '<path d="M43,5 L55,85 L43,5 Z" stroke="'+ this.line_colour +'"></path>';
			// left face
			markup += '<text x="37" y="24" font-family="'+ this.font_family +'" font-size="14" alignment-baseline="hanging" text-anchor="middle" fill="'+ this.label_colour +'">'+ capToSides(value, 4) +'</text>';
			markup += '<text x="46" y="72" font-family="'+ this.font_family +'" font-size="14" alignment-baseline="bottom" text-anchor="end" fill="'+ this.label_colour +'">'+ capToSides(value + 3, 4) +'</text>';
			markup += '<text x="12" y="62" font-family="'+ this.font_family +'" font-size="14" alignment-baseline="bottom" text-anchor="start" fill="'+ this.label_colour +'">'+ capToSides(value + 2, 4) +'</text>';
			// right face
			markup += '<text x="53.5" y="24" font-family="'+ this.font_family +'" font-size="14" alignment-baseline="hanging" text-anchor="middle" fill="'+ this.label_colour +'">'+ capToSides(value, 4) +'</text>';
			markup += '<text x="57" y="70" font-family="'+ this.font_family +'" font-size="14" alignment-baseline="bottom" text-anchor="start" fill="'+ this.label_colour +'">'+ capToSides(value + 3, 4) +'</text>';
			markup += '<text x="77" y="56" font-family="'+ this.font_family +'" font-size="14" alignment-baseline="bottom" text-anchor="end" fill="'+ this.label_colour +'">'+ capToSides(value + 1, 4) +'</text>';

			break;
		case 6:
			gWidth = 76;
			markup += '<polygon points="1 21 1 66 34 87 75 66 75 21 42 1" fill="'+ this.face_colour +'" stroke="'+ this.line_colour +'"></polygon>';
			markup += '<polygon points="34 42 75 21 42 1 1 21" fill="'+ this.focus_colour +'" stroke="'+ this.line_colour +'" stroke-linejoin="bevel"></polygon>';
			markup += '<line x1="34" y1="42" x2="34" y2="87" stroke="'+ this.line_colour +'"></line>';
			markup += '<text x="38" y="22" font-family="'+ this.font_family +'" font-size="20" alignment-baseline="middle" text-anchor="middle" fill="'+ this.label_colour +'">'+ capToSides(value, 6) +'</text>';

			break;
		case 8:
			gWidth = 76;
			markup += '<polygon points="38 1 1 17 1 63 38 87 75 63 75 17" fill="'+ this.face_colour +'" stroke="'+ this.line_colour +'"></polygon>';
			markup += '<polygon points="38 1 1 63 75 63" fill="'+ this.focus_colour +'" stroke="'+ this.line_colour +'" stroke-linejoin="bevel"></polygon>';
			markup += '<text x="38" y="44" font-family="'+ this.font_family +'" font-size="22" alignment-baseline="middle" text-anchor="middle" fill="'+ this.label_colour +'">' + capToSides(value, 8) + '</text>';

			break;
		case 10:
			gWidth = 84;
			markup += '<polygon points="42 2 1 44 7 56 42 86 77 56 83 44" fill="'+ this.face_colour +'" stroke="'+ this.line_colour +'"></polygon>';
			markup += '<polygon points="42 2 15 49 42 61 69 49" fill="'+ this.focus_colour +'" stroke="'+ this.line_colour +'" stroke-linejoin="bevel"></polygon>';
			markup += '<line x1="15" y1="49" x2="7" y2="56" stroke="'+ this.line_colour +'"></line>';
			markup += '<line x1="42" y1="61" x2="42" y2="86" stroke="'+ this.line_colour +'"></line>';
			markup += '<line x1="69" y1="49" x2="77" y2="56" stroke="'+ this.line_colour +'"></line>';
			markup += '<text x="41" y="40" font-family="'+ this.font_family +'" font-size="20" alignment-baseline="middle" text-anchor="middle" fill="'+ this.label_colour +'">' + capToSides(value, 10) + '</text>';

			break;
		case 12:
			gWidth = 84;
			markup += '<polygon points="42 2 16 12 2 32 2 58 16 76 42 86 68 76 82 58 82 32 68 12" fill="'+ this.face_colour +'" stroke="'+ this.line_colour +'"></polygon>';
			markup += '<polygon points="42 21 19 38 28 64 56 64 65 38" fill="'+ this.focus_colour +'" stroke="'+ this.line_colour +'" stroke-linejoin="bevel"></polygon>';
			markup += '<line x1="42" y1="2" x2="42" y2="21" stroke="'+ this.line_colour +'"></line>';
			markup += '<line x1="2" y1="32" x2="19" y2="38" stroke="'+ this.line_colour +'"></line>';
			markup += '<line x1="16" y1="76" x2="28" y2="64" stroke="'+ this.line_colour +'"></line>';
			markup += '<line x1="68" y1="76" x2="56" y2="64" stroke="'+ this.line_colour +'"></line>';
			markup += '<line x1="82" y1="32" x2="65" y2="38" stroke="'+ this.line_colour +'"></line>';
			markup += '<text x="41" y="47" font-family="'+ this.font_family +'" font-size="20" alignment-baseline="middle" text-anchor="middle" fill="'+ this.label_colour +'">' + capToSides(value, 12) + '</text>';

			break;
		case 20:
			gWidth = 84;
			markup += '<g>';
			markup +=   '<polygon points="42 1 1 22 1 66 42 87 83 66 83 22" fill="'+ this.face_colour +'" stroke="'+ this.line_colour +'"></polygon>';
			markup +=   '<polygon points="21 25 42 65 63 25" fill="'+ this.focus_colour +'" stroke="'+ this.line_colour +'" stroke-linejoin="bevel"></polygon>';
			markup +=   '<polyline points="42 87 42 65 1 66 21 25" fill="none" stroke="'+ this.line_colour +'" stroke-linejoin="bevel"></polyline>';
			markup +=   '<polyline points="42 87 42 65 83 66 63 25" fill="none" stroke="'+ this.line_colour +'" stroke-linejoin="bevel"></polyline>';
			markup +=   '<polyline points="1 22 21 25 42 1 63 25 83 22" fill="none" stroke="'+ this.line_colour +'" stroke-linejoin="bevel"></polyline>';
			markup +=   '<text x="42" y="40" font-family="'+ this.font_family +'" font-size="16" alignment-baseline="middle" text-anchor="middle" fill="'+ this.label_colour +'">' + capToSides(value, 20) + '</text>';
			markup += '</g>';
			break;
		}

		const sHeight = padding * 2 + gHeight;
		const sWidth = padding * 2 + gWidth;

		const svg = new TagElement("svg");
		svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
		svg.setAttribute('width', sWidth.toString());
		svg.setAttribute('viewBox', [0, 0, sWidth, sHeight].join(' '));

		svg.innerHTML += '<g transform="translate('+ padding +','+ padding +')">' + markup + '</g>';

		return svg.outerHTML();
	}

	exportValue(options?){
		return {
			type: 'dice',
			sides: this.sides,
			value: this.value,
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

