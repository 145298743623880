import { log } from '../../QE';
import { QETerm } from '../../QETerm';
import { QEHelper, QEValue } from '../../QEHelper';
import { QEValConstants } from '../../QEValConstants';
import { QEWidget, DisplayOptions, TagElement } from '../../QEWidget';

export class QEWidgetPictograph extends QEWidget {
	display_options: { [key: string]: any };

	img_map: string;
	random_type: boolean;
	img_type: string;
	dataset_from_ref: boolean;
	dataset: { label: string, value: number }[];
	title: string;
	step_value: number;

	font_family: string;
	font_size: string;
	image_width: number;
	image_height: number;

	static default_style = {
		random_type: false,
		img_map: 'animal',
		img_type: 'bear',
		title: '',
		step_value: 2,

		font_family: 'Lato, sans-serif',
		font_size: '16',
		image_width: 25,
		image_height: 25,
	};

	constructor(dataset: {label: string, value: number }[], display_options: DisplayOptions = {}) {
		super();

		this.dataset = dataset;
		this.display_options = display_options;

		// apply default style, then display options and style overrides
		Object.assign(this, QEWidgetPictograph.default_style, display_options);
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		const deserialized = JSON.parse(serialized);

		const dataset = QEHelper.resolvePlotDataset(serialized, resolved_data, options);
		if (!dataset) return null; // unresolved dependency, or resolution error

		// build map and resolve any [$name] placeholders in display_options
		const display_options_resolved = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);
		// check if there was an unresolved dependency
		if (!display_options_resolved) return null;

		// cast display_options to string, int
		let display_options = {};
		if (display_options_resolved.img_map !== undefined) {
			if (display_options_resolved.img_map instanceof QEValue) display_options.img_map = display_options_resolved.img_map.serialize_to_text();
			else display_options.img_map = display_options_resolved.img_map;
		}
		if (display_options_resolved.img_type !== undefined) {
			if (display_options_resolved.img_type instanceof QEValue) display_options.img_type = display_options_resolved.img_type.serialize_to_text();
			else display_options.img_type = display_options_resolved.img_type;
		}
		if (display_options_resolved.title !== undefined) {
			if (display_options_resolved.title instanceof QEValue) display_options.title = display_options_resolved.title.serialize_to_text();
			else display_options.title = display_options_resolved.title;
		}
		if (display_options_resolved.step_value !== undefined) {
			if (display_options_resolved.step_value instanceof QEValue) {
				display_options.step_value = parseInt(display_options_resolved.step_value.serialize_to_text());
			} else display_options.step_value = parseInt(display_options_resolved.step_value);
			if (Number.isNaN(display_options.step_value)) {
				log.error('Error: specified step_value is not an integer: ', display_options.step_value);
				return null;
			}
		}

		// TODO: validate img_map / img_type
		const style_options = Object.assign({}, QEWidgetPictograph.default_style, display_options);
		let img_map = style_options.img_map;
		let img_type;
		if (!style_options.random_type) {
			if (QEValConstants.image_maps[img_map]['type'].indexOf(style_options.img_type) == -1) {
				log.error('Error: specified img_type not found in img_map: ', {img_map:img_map, img_type:style_options.img_type});
				return null;
			}
		}

		let widget = new QEWidgetPictograph(dataset, display_options);
		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options) {
		// TODO: support passed display option overrides

		return this.draw();
	}

	draw() {
		const self = this;

		// Determine the max value within the dataset
		const max_value = Math.max.apply(null, this.dataset.map(x => { return x.value; }));

		const svg = new TagElement("svg");
		svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");

		const sWidth = 300;
		const sHeight = this.dataset.length * this.image_height + (this.title ? 30 : 0) + (this.step_value > 1 ? this.image_height : 0);

		// determine the image url
		const img_path = '/img/qe/';
		const image_map = QEValConstants.image_maps[this.img_map];

		// iterate over the image key parts list to build the image name
		const image_key_parts = [this.img_map];
		const key_parts = image_map.key_parts;
			// handle key_part-specific display options - applied in order they are encountered, so one option can override another
		key_parts.map(function (key_part) {
			if (key_part == "type") {
				if (self.random_type) {
					// select a random img type value
					image_key_parts.push(image_map[key_part][Math.trunc(Math.random() * image_map[key_part].length)]);
				} else {
					image_key_parts.push(self.img_type);
				}
			} else {
				// use the first/default part
				image_key_parts.push(image_map[key_part][0]);
			}
		});
		const image_url = img_path + image_key_parts.join('_') + '.png';

		// Draw the dataset contents
		let markup = '';

		markup += '<clipPath id="half-width-clip"><rect x="0" y="0" width="'+ (this.image_width / 2) +'" height="'+ this.image_height +'"></clipPath>';

		for (let i = 0; i < this.dataset.length; i++) {
			const item = this.dataset[i];
			const ix = 85;
			const iy = i * this.image_height;
			const tx = 80;
			const ty = i * this.image_height + this.image_height / 2;

			markup += '<text x="'+ tx +'" y="'+ ty +'" font-family="'+ this.font_family +'" alignment-baseline="middle" text-anchor="end">' + item.label + '</text>';

			// Add the number of items
			for (let j = 0; j < Math.ceil(item.value / this.step_value); j++) {
				const offset = j * this.image_width + 8;

				// check if we have a partial symbol
				if (item.value % this.step_value && j === Math.floor(item.value / this.step_value)) {
					markup += '<image href="'+ image_url +'" width="'+ this.image_width +'" height="'+ this.image_height +'" transform="translate('+ (ix + offset) +','+ iy +')" clip-path="url(#half-width-clip)" />';
				} else {
					markup += '<image href="'+ image_url +'" width="'+ this.image_width +'" height="'+ this.image_height +'" transform="translate('+ (ix + offset) +','+ iy +')" />';
				}
			}
		}

		markup = '<g transform="translate('+ 0 +','+ (this.title ? 25 : 0) +')">' + markup + '</g>';

		if (this.title) {
			markup += '<text x="10" y="10" alignment-baseline="hanging" text-anchor="start">'+ this.title +'</text>';
		}

		// display step_value scale
		if (this.step_value > 1) {
			let x = 0;
			let y = 0;
			let str = '<image href="'+ image_url +'" width="'+ this.image_width +'" height="'+ this.image_height +'" transform="translate('+ x +','+ y +')" />';
			x += this.image_width;
			y += this.image_height / 2;
			str += '<text x="'+ x +'" y="'+ y +'" alignment-baseline="middle" text-anchor="start"> = ' + this.step_value + '</text>';
			markup += '<g transform="translate(93, '+ (this.dataset.length * this.image_height + (this.title ? 30 : 0)) +')">' + str + '</g>';
		}

		svg.innerHTML += markup;

		svg.setAttribute('width', sWidth.toString());
		svg.setAttribute('viewBox', [0,0, sWidth, sHeight].join(' '));

		return svg.outerHTML();
	}

	exportValue(options?){
		return {
			type: 'pictograph',
			dataset: JSON.stringify(this.dataset),
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

