import { QETerm } from '../QETerm';
import { QEHelper } from '../QEHelper';
import { QEWidget, DisplayOptions } from '../QEWidget';
import * as jQuery from 'jquery';

export class QEWidgetDropDown extends QEWidget {
	display_options: { [key: string]: any };
	dataset: { label: string, value: string }[];
	user_value: string;
	input_key_index: number;
	name: string;

	constructor(dataset: { label: string, value: string }[], options: { name?: string, input_key_index?: number, display_options?: DisplayOptions } = {}) {
		super();

		// DropDown is used to present the user with a dropdown select list
		this.dataset = dataset;
		this.user_value = ""

		this.display_options = options.display_options || {};

		if (options.name)
			this.name = options.name;
		if (options.input_key_index !== undefined)
			this.input_key_index = options.input_key_index;
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		const deserialized = JSON.parse(serialized);

		// each dataset item should have a value (string), a quantity (number), and optional display (placeholder reference?) field
		const dataset = QEHelper.resolveDataset(serialized, resolved_data, options);
		if (!dataset) return null; // unresolved dependency, or resolution error

		// build map and resolve any [$name] placeholders in display_options
		const display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new QEWidgetDropDown(dataset, {name: options.name, display_options: display_options});
		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options = {}) {
		// TODO: support passed display option overrides

		var display_options = Object.assign({}, this.display_options, options);
		var value = display_options.value || "";

		let ml = '';
		if (this.name && this.input_key_index !== undefined) {
			// nested drop-downs should be wrapped so we can identify the name and index
			ml += '<span' +
				' data-name="' + this.name + '"' +
				' data-input_key_index="' + this.input_key_index + '"' +
			'>';

			ml += '<div class="dropdown">';
			ml += 	'<div data-value="" class="option current not-set">...</div>';
			ml += 	'<div class="options">';
			for (let i = 0; i < this.dataset.length; i++) {
				let item = this.dataset[i];
				// use passed values to display box as checked
				if (item.value == value) {
					ml += '<div data-value="'+ item.value +'" selected>'+ item.label +'</div>';
				} else {
					ml += '<div data-value="'+ item.value +'">'+ item.label +'</div>';
				}
			}
			ml += 	'</div>'; // .options
			ml += '</div>'; // .dropdown
			ml += '</span>';
		} else {
			// bare drop-down
			ml += '<div class="dropdown">';
			ml += 	'<div data-value="" class="current not-set">...</div>';
			ml += 	'<div class="options">';
			for (let i = 0; i < this.dataset.length; i++) {
				let item = this.dataset[i];
				// use passed values to display box as checked
				if (item.value == value) {
					ml += '<div data-value="'+ item.value +'" selected>'+ item.label +'</div>';
				} else {
					ml += '<div data-value="'+ item.value +'">'+ item.label +'</div>';
				}
			}
			ml += 	'</div>'; // .options
			ml += '</div>'; // .dropdown
		}

		return ml;
	}

	bindEventHandlers(widget_container) {
		// DropDown input events are bound by the calling code since they may involve updating a parent widget if nested
		return;
	}

	setInputValue(value: string) {
		this.user_value = value;
	}

	getInputValue(input_widgets?): string { return this.user_value; }

	isUserInputComplete(): boolean {
		return this.user_value !== "";
	}

	exportValue(options?){
		return {
			type: 'dropdown',
			name: this.name,
			dataset: JSON.stringify(this.dataset),
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

