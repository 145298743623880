import { QETerm } from '../../QETerm';
import { QEHelper } from '../../QEHelper';
import { QEWidget, DisplayOptions, TagElement } from '../../QEWidget';

export class QEWidgetBarGraph extends QEWidget {
	display_options: { [key: string]: any };

	dataset_from_ref: boolean;
	dataset: { label: string, value: number }[];
	title: string;
	x_label: string;
	y_label: string;

	bar_colour: string;
	bar_height: number;
	bar_width: number;
	font_family: string;
	font_size: string;
	item_colour: string;
	item_offset: number;
	item_spacing: number;
	label_colour: string;
	step_value: number;
	title_colour: string;

	static default_style = {
		title: '',
		x_label: '',
		y_label: '',
		bar_colour: '#d00000',
		bar_height: 200,
		bar_width: 50,
		font_family: 'Lato, sans-serif',
		font_size: '10pt',
		item_colour: '#000000',
		item_offset: 5,
		item_spacing: 75,
		label_colour: '#666666',
		step_value: 5,
		title_colour: '#333333',
	};

	constructor(dataset: { label: string, value: number }[], display_options: DisplayOptions = {}) {
		super();

		this.dataset = dataset;
		this.display_options = display_options;

		// apply default style, then display options and style overrides
		Object.assign(this, QEWidgetBarGraph.default_style, display_options);
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		const deserialized = JSON.parse(serialized);

		const dataset = QEHelper.resolvePlotDataset(serialized, resolved_data, options);
		if (!dataset) return null; // unresolved dependency, or resolution error

		// build map and resolve any [$name] placeholders in display_options
		const display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new QEWidgetBarGraph(dataset, display_options);
		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options) {
		// TODO: support passed display option overrides

		return this.draw();
	}

	draw() {
		// Determine the max value within the dataset
		const max_value = Math.max.apply(null, this.dataset.map(x => { return x.value; }));

		const svg = new TagElement("svg");
		svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
		const g = new TagElement("g"); // enclose the shape into a group element

		const gHeight = this.bar_height;
		const gWidth = this.item_spacing * this.dataset.length;

		const sHeight = gHeight + 80;
		const sWidth = gWidth + 50;

		const steps = Math.ceil(max_value / this.step_value);

		// Draw the value marks
		for (let i = steps; i >= 0; i--) {
			const x1 = -10;
			const x2 = 0;
			const y = this.bar_height - this.bar_height / Math.max(1, steps) * i;
			const str = this.step_value * i;

			g.innerHTML += '<line x1="0" y1="'+ y +'" x2="'+ gWidth +'" y2="'+ y +'" fill="none" stroke="#ccc" />';
			g.innerHTML += '<line x1="'+ x1 +'" y1="'+ y +'" x2="'+ x2 +'" y2="'+ y +'" fill="none" stroke="#000" />';
			g.innerHTML += '<text x="'+ (x1 - 5) +'" y="'+ y +'" font-family="'+ this.font_family +'" font-size="'+ this.font_size +'" alignment-baseline="middle" text-anchor="end" fill="'+ this.label_colour +'" stroke="none">'+ str  +'</text>';
		}

		// Draw the dataset
		for (let i = 0; i < this.dataset.length; i++) {
			// Determine the label origin
			const x = this.item_spacing * i + this.item_spacing/2;
			const y = gHeight + this.item_offset;

			const item = this.dataset[i];
			const item_height = Math.floor(item.value / Math.max(1, steps * this.step_value) * this.bar_height);
			const item_x = x - this.bar_width / 2;
			const item_y = 0;

			g.innerHTML += '<text x="'+ x +'" y="'+ y +'" font-family="'+ this.font_family +'" font-size="'+ this.font_size +'" alignment-baseline="hanging" text-anchor="middle" fill="'+ this.item_colour +'" stroke="none">'+ item.label +'</text>';
			g.innerHTML += '<rect x="'+ item_x +'" y="'+ item_y +'" width="'+ this.bar_width +'" height="'+ item_height +'" fill="'+ this.bar_colour +'" stroke="none" transform="scale(1, -1) translate(0, '+ -gHeight +')" />';
		}

		// Draw the graph axes
		const arr = [];
		arr[0] = 'M 0 0';
		arr[1] = 'L 0 ' + gHeight;
		arr[2] = 'L ' + gWidth + ' ' + gHeight;
		g.innerHTML += '<path d="'+ arr.join(' ') +'" fill="none" stroke="#000" stroke-width="1" />';

		if (this.title) {
			const x = gWidth / 2;
			const y = -20;
			g.innerHTML += '<text x="'+ x +'" y="'+ y +'" font-family="'+ this.font_family +'" font-size="'+ this.font_size +'" alignment-baseline="hanging" text-anchor="middle" fill="'+ this.title_colour +'" stroke="none">'+ this.title +'</text>';
		}

		if (this.x_label) {
			const x = gWidth / 2;
			const y = gHeight + 25;
			g.innerHTML += '<text x="'+ x +'" y="'+ y +'" font-family="'+ this.font_family +'" font-size="'+ this.font_size +'" alignment-baseline="hanging" text-anchor="middle" fill="'+ this.label_colour +'" stroke="none">'+ this.x_label +'</text>';
		}

		if (this.y_label) {
			const x = -50;
			const y = gHeight/2;
			const rot = [-90, x, y];
			g.innerHTML += '<text x="'+ x +'" y="'+ y +'" font-family="'+ this.font_family +'" font-size="'+ this.font_size +'" alignment-baseline="hanging" text-anchor="middle" fill="'+ this.label_colour +'" stroke="none" transform="rotate('+ rot.toString() +')">'+ this.y_label +'</text>';
		}

		g.setAttribute('transform', 'translate(50,30)');

		svg.innerHTML += g.outerHTML();

		svg.setAttribute('width', sWidth.toString());
		svg.setAttribute('viewBox', [0,0, sWidth, sHeight].join(' '));

		return svg.outerHTML();
	}

	exportValue(options?){
		return {
			type: 'bar_graph',
			dataset: JSON.stringify(this.dataset),
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

