///////////////////////////
// NOTE: these built-in data values could be moved into a DB and managed via a tool, but there is little value in doing so at this point. We can re-visit when the scale of data increases

export const QEValConstants = {
	image_maps: {
		animal: {
			key_parts: ["type", "size"],
			size: ["med", "lg"],
			type: ["bear", "bunny", "cat", "deer", "dog", "fox", "raccoon", "squirrel"],
		},
		ball: {
			key_parts: ["type", "size"],
			size: ["sm", "med", "lg"],
			type: ["baseball", "basketball", "billiardball", "bowlingball", "football", "soccerball", "tennisball", "volleyball"],
		},
		bg: {
			color: ["green", "orange", "purple"],
			key_parts: ["type", "color", "size"],
			size: ["sm", "med", "lg"],
			type: ["circle", "crest", "star"],
		},
		dot: {
			key_parts: ["type", "size"],
			size: ["sm", "med"],
			type: ["blue", "green", "purple", "red", "yellow"],
		},
		fraccircle: {			
			color: ["blue", "red", "yellow"],
			cw: ["cw"],
			key_parts: ["type", "cw", "color", "size"],
			size: ["lg", "xl"],
			type: [
				"0_1",
				"1_1",
				"0_2",
				"1_2",
				"2_2",
				"0_3",
				"1_3",
				"2_3",
				"3_3",
				"0_4",
				"1_4",
				"2_4",
				"3_4",
				"4_4",
				"0_5",
				"1_5",
				"2_5",
				"3_5",
				"4_5",
				"5_5",
				"0_6",
				"1_6",
				"2_6",
				"3_6",
				"4_6",
				"5_6",
				"6_6",
				"0_7",
				"1_7",
				"2_7",
				"3_7",
				"4_7",
				"5_7",
				"6_7",
				"7_7",
				"0_8",
				"1_8",
				"2_8",
				"3_8",
				"4_8",
				"5_8",
				"6_8",
				"7_8",
				"8_8",
				"0_9",
				"1_9",
				"2_9",
				"3_9",
				"4_9",
				"5_9",
				"6_9",
				"7_9",
				"8_9",
				"9_9",
				"0_10",
				"1_10",
				"2_10",
				"3_10",
				"4_10",
				"5_10",
				"6_10",
				"7_10",
				"8_10",
				"9_10",
				"10_10",
				"0_11",
				"1_11",
				"2_11",
				"3_11",
				"4_11",
				"5_11",
				"6_11",
				"7_11",
				"8_11",
				"9_11",
				"10_11",
				"11_11",
				"0_12",
				"1_12",
				"2_12",
				"3_12",
				"4_12",
				"5_12",
				"6_12",
				"7_12",
				"8_12",
				"9_12",
				"10_12",
				"11_12",
				"12_12",
			],
		},
		fruit: {
			key_parts: ["type", "size"],
			size: ["med", "lg"],
			type: ["apple", "banana", "cherry", "grape", "lemon", "pear", "pineapple", "strawberry"],
		},
		misc: {
			key_parts: ["type", "size"],
			size: ["sm", "med", "lg"],
			type: ["check", "cross", "strike"],
		},
		number: {
			color: ["black", "grey", "white"],
			key_parts: ["type", "color", "size"],
			size: ["sm", "med", "lg"],
			type: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
		},
		op: {
			color: ["blue", "green", "red"],
			key_parts: ["type", "color", "size"],
			size: ["sm", "med", "lg"],
			type: ["equal", "minus", "plus", "times", "divide"],
		},
		pair: {
			key_parts: ["type", "size"],
			size: ["med", "lg"],
			type: ["boots", "gloves", "mitts", "shoes", "socks"],
		},
		shape: {
			color: ["blue", "green", "purple", "red", "yellow"],
			key_parts: ["type", "color", "size"],
			size: ["sm", "med", "lg"],
			type: ["circle", "clover", "decagon", "diamond", "heart", "heptagon", "hexagon", "nonagon", "octagon", "pentagon", "ring", "spade", "square", "triangle"],
		},
		signball: {
			key_parts: ["type", "size"],
			size: ["sm", "med"],
			type: ["minus", "plus"],
		},
		vegetable: {
			key_parts: ["type", "size"],
			size: ["med", "lg"],
			type: ["beet", "broccoli", "carrot", "chilipepper", "eggplant", "mushroom", "potato", "redpepper"],
		},
		vehicle: {
			key_parts: ["type", "size"],
			size: ["med", "lg"],
			type: ["airplane", "bicycle", "boat", "hotairballoon", "rocket", "submarine"],
		},
	},

	string_lists: {
		animal: ["bear", "bunny", "cat", "deer", "dog", "fox", "raccoon", "squirrel"],
		balls: ["baseball", "basketball", "billiardball", "bowlingball", "football", "soccerball", "tennisball", "volleyball"],
		female_name: ["Alice", "Betty", "Cindy", "Deborah", "Ella", "Gianna", "Hannah", "Isabella", "Josephine", "Karly", "Lizzy", "Mia", "Nora", "Olivia", "Penny", "Sophia", "Vicky", "Zoey"],
		fruit: ["apple", "banana", "cherry", "grape", "lemon", "pear", "pineapple", "strawberry"],
		gender: ["female", "male"],
		hex_colour: ['#0084D5','#2EC44E','#8F52D9','#FF3A3A','#FFCE39'], // blue, green, purple, red, yellow
		hostable_event: ["birthday party", "dance", "party", "pool party"],
		male_name: ["Andrew", "Bob", "Charles", "Dave", "Edward", "Gabriel", "Henry", "Jacob", "Kent", "Liam", "Mason", "Noah", "Oliver", "Peter", "Raymond", "Sebastian", "Thomas", "Wyatt"],
		name_colour: ["blue", "green", "purple", "red", "yellow"],
		neutral_name: ["Adrian", "Blair", "Charlie", "Devon", "Evan", "Finley", "Grayson", "Hayden", "Jayden", "Kendall", "Leslie", "Morgan", "Noel", "Oakley", "Presley", "Quinn", "Riley", "Skylar", "Taylor"],
		pastry: ["brownie", "cookie", "cupcake", "danish", "muffin", "scone"],
		place: ["library", "mall"],
		sport: ["baseball", "basketball", "football", "soccer", "tennis", "volleyball"],
		time_units: ["second", "minute", "hour", "day"],
		vegetable: ["beet", "broccoli", "carrot", "chilipepper", "eggplant", "mushroom", "potato", "redpepper"],
		vehicle: ["airplane", "bicycle", "boat", "hotairballoon", "rocket", "submarine"],
	},

	string_maps: {
		animal: {
			bear: { one: "bear", many: "bears" },
			bunny: { one: "bunny", many: "bunnies" },
			cat: { one: "cat", many: "cats" },
			deer: { one: "deer", many: "deer" },
			dog: { one: "dog", many: "dogs" },
			fox: { one: "fox", many: "foxes" },
			raccoon: { one: "raccoon", many: "raccoons" },
			squirrel: { one: "squirrel", many: "squirrels" },
		},
		pastry: {
			many: {
				brownie: "brownies",
				cookie: "cookies",
				cupcake: "cupcakes",
				danish: "danishes",
				muffin: "muffins",
				scone: "scones",
			},
		},
		fruit: {
			apple: { one: "apple", many: "apples" },
			banana: { one: "banana", many: "bananas" },
			cherry: { one: "cherry", many: "cherries" },
			grape: { one: "grape", many: "grapes" },
			lemon: { one: "lemon", many: "lemons" },
			pear: { one: "pear", many: "pears" },
			pineapple: { one: "pineapple", many: "pineapples" },
			strawberry: { one: "strawberry", many: "strawberries" },
		},
		number: {
			cardinal: {
				"0": "zero",
				"1": "one",
				"2": "two",
				"3": "three",
				"4": "four",
				"5": "five",
				"6": "six",
				"7": "seven",
				"8": "eight",
				"9": "nine",
				"10": "ten",
				"11": "eleven",
				"12": "twelve",
				"13": "thirteen",
				"14": "fourteen",
				"15": "fifteen",
				"16": "sixteen",
				"17": "seventeen",
				"18": "eighteen",
				"19": "nineteen",
				"20": "twenty",
				"30": "thirty",
				"40": "forty",
				"50": "fifty",
				"60": "sixty",
				"70": "seventy",
				"80": "eighty",
				"90": "ninety",
			},
			ordinal: {
				"0": "zeroeth",
				"1": "first",
				"2": "second",
				"3": "third",
				"4": "fourth",
				"5": "fifth",
				"6": "sixth",
				"7": "seventh",
				"8": "eighth",
				"9": "ninth",
				"10": "tenth",
				"11": "eleventh",
				"12": "twelveth",
				"13": "thirteenth",
				"14": "fourteenth",
				"15": "fifteenth",
				"16": "sixteenth",
				"17": "seventeenth",
				"18": "eighteenth",
				"19": "nineteenth",
				"20": "twentieth",
				"30": "thirtieth",
				"40": "fortieth",
				"50": "fiftieth",
				"60": "sixtieth",
				"70": "seventieth",
				"80": "eightieth",
				"90": "ninetieth",
			},
			place: {
				"0.000001": "millionths",
				"0.00001": "hundred-thousandths",
				"0.0001": "ten-thousandths",
				"0.001": "thousandths",
				"0.01": "hundredths",
				"0.1": "tenths",
				"1": "ones",
				"10": "tens",
				"100": "hundreds",
				"1000": "thousands",
				"10000": "ten-thousands",
				"100000": "hundred-thousands",
				"1000000": "millions",
				"10000000": "ten-millions",
				"100000000": "hundred-millions",
				"1000000000": "billions",
				"10000000000": "ten-billions",
				"100000000000": "hundred-billions",
				"1000000000000": "trillions",
				"10000000000000": "ten-trillions",
				"100000000000000": "hundred-trillions",
				"1000000000000000": "quadrillions",
			},
			place_position: {
				"0.000001": "millionth",
				"0.00001": "hundred-thousandth",
				"0.0001": "ten-thousandth",
				"0.001": "thousandth",
				"0.01": "hundredth",
				"0.1": "tenth",
				"1": "whole number",
				"10": "ten",
				"100": "hundred",
				"1000": "thousand",
				"10000": "ten-thousand",
				"100000": "hundred-thousand",
				"1000000": "million",
			},
		},
		polygon: {
			sides: {
				"2": "circle",
				"3": "triangle",
				"4": "quadrilateral",
				"5": "pentagon",
				"6": "hexagon",
				"7": "heptagon",
				"8": "octagon",
				"9": "nonagon",
				"10": "decagon",
				"11": "hendecagon",
				"12": "dodecagon",
			},
		},
		pronoun: {
			subject: {
				// he
				male: "he",
				female: "she",
				neuter: "it",
			},
			object: {
				// him
				male: "him",
				female: "her",
				neuter: "it",
			},
			possessive: {
				// his
				male: "his",
				female: "her",
				neuter: "its",
			},
		},
		true_false: {
			yes_no: {
				true: "Yes",
				false: "No",
			},
		},
		unit_length_metric: {
			millimetre: { one: "millimetre", many: "millimetres" },
			centimetre: { one: "centimetre", many: "centimetres" },
			metre: { one: "metre", many: "metres" },
			kilometre: { one: "kilometre", many: "kilometres" },
		},
		unit_time: {
			second: { one: "second", many: "seconds" },
			minute: { one: "minute", many: "minutes" },
			hour: { one: "hour", many: "hours" },
			day: { one: "day", many: "days" },
			week: { one: "week", many: "weeks" },
			month: { one: "month", many: "months" },
			year: { one: "year", many: "years" },
		},
		date: {
			day_ordinal: {
				"1": "st",
				"2": "nd",
				"3": "rd",
				"4": "th",
				"5": "th",
				"6": "th",
				"7": "th",
				"8": "th",
				"9": "th",
				"10": "th",
				"11": "th",
				"12": "th",
				"13": "th",
				"14": "th",
				"15": "th",
				"16": "th",
				"17": "th",
				"18": "th",
				"19": "th",
				"20": "th",
				"21": "st",
				"22": "nd",
				"23": "rd",
				"24": "th",
				"25": "th",
				"26": "th",
				"27": "th",
				"28": "th",
				"29": "th",
				"30": "th",
				"31": "st",
			},
			month_days: {
				"1": "31",
				"2": "28",
				"3": "31",
				"4": "30",
				"5": "31",
				"6": "30",
				"7": "31",
				"8": "31",
				"9": "30",
				"10": "31",
				"11": "30",
				"12": "31",
			},
			month_name: {
				"1": "January",
				"2": "February",
				"3": "March",
				"4": "April",
				"5": "May",
				"6": "June",
				"7": "July",
				"8": "August",
				"9": "September",
				"10": "October",
				"11": "November",
				"12": "December",
			},
			num_str: {
				"1": "01",
				"2": "02",
				"3": "03",
				"4": "04",
				"5": "05",
				"6": "06",
				"7": "07",
				"8": "08",
				"9": "09",
				"10": "10",
				"11": "11",
				"12": "12",
				"13": "13",
				"14": "14",
				"15": "15",
				"16": "16",
				"17": "17",
				"18": "18",
				"19": "19",
				"20": "20",
				"21": "21",
				"22": "22",
				"23": "23",
				"24": "24",
				"25": "25",
				"26": "26",
				"27": "27",
				"28": "28",
				"29": "29",
				"30": "30",
				"31": "31"
			}
		},
		date_time: {
			hours_am_pm: {
				"0": "AM",
				"1": "AM",
				"2": "AM",
				"3": "AM",
				"4": "AM",
				"5": "AM",
				"6": "AM",
				"7": "AM",
				"8": "AM",
				"9": "AM",
				"10": "AM",
				"11": "AM",
				"12": "PM",
				"13": "PM",
				"14": "PM",
				"15": "PM",
				"16": "PM",
				"17": "PM",
				"18": "PM",
				"19": "PM",
				"20": "PM",
				"21": "PM",
				"22": "PM",
				"23": "PM",
				"24": "AM",
			},
			hours_12: {
				"0": "12",
				"1": "1",
				"2": "2",
				"3": "3",
				"4": "4",
				"5": "5",
				"6": "6",
				"7": "7",
				"8": "8",
				"9": "9",
				"10": "10",
				"11": "11",
				"12": "12",
				"13": "1",
				"14": "2",
				"15": "3",
				"16": "4",
				"17": "5",
				"18": "6",
				"19": "7",
				"20": "8",
				"21": "9",
				"22": "10",
				"23": "11",
				"24": "12",
			},
			hours_24: {
				"0": "00",
				"1": "01",
				"2": "02",
				"3": "03",
				"4": "04",
				"5": "05",
				"6": "06",
				"7": "07",
				"8": "08",
				"9": "09",
				"10": "10",
				"11": "11",
				"12": "12",
				"13": "13",
				"14": "14",
				"15": "15",
				"16": "16",
				"17": "17",
				"18": "18",
				"19": "19",
				"20": "20",
				"21": "21",
				"22": "22",
				"23": "23",
				"24": "00",
			},
			mins_secs: {
				"0": "00", "1": "01", "2": "02", "3": "03", "4": "04", "5": "05", "6": "06", "7": "07", "8": "08", "9": "09",
				"10": "10", "11": "11", "12": "12", "13": "13", "14": "14", "15": "15", "16": "16", "17": "17", "18": "18", "19": "19",
				"20": "20", "21": "21", "22": "22", "23": "23", "24": "24", "25": "25", "26": "26", "27": "27", "28": "28", "29": "29",
				"30": "30", "31": "31", "32": "32", "33": "33", "34": "34", "35": "35", "36": "36", "37": "37", "38": "38", "39": "39",
				"40": "40", "41": "41", "42": "42", "43": "43", "44": "44", "45": "45", "46": "46", "47": "47", "48": "48", "49": "49",
				"50": "50", "51": "51", "52": "52", "53": "53", "54": "54", "55": "55", "56": "56", "57": "57", "58": "58", "59": "59", 
				"60": "00",
			}
		}
	},

	// NOTE: Rename to whatever
	// TODO: Instead of copy and pasting the items from the string_lists, can they be added programmatically after?
	// EXAMPLE: string_consts[key] = string_lists[key].toString()
	string_consts: {
		// imperial_units_length: "", // foot, yard, mile
		// imperial_units_volume: "oz,gill,pint,", // ounce, gill, cup, pint, quart, gallon
		// imperial_units_weight: "", // ounce, pound
		metric_units_length: "mm,cm,m,km",
		metric_units_volume: "mL,L",
		metric_units_weight: "lb",
		us_units_length: "in,ft,yd,m",
		us_units_volume: "tsp,tbsp,oz,c,pt,qt,gal",
		us_units_weight: "oz,lb",
	},
};
