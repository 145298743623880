import { SvgRenderingContext } from '../../common/SvgRenderingContext';
import { QEGraph } from '../QEGraph';
import { Label } from './Label';

export class Polygon {
    lineType: string;
    line_color: string;
    line_width: number;
    line_style: string;
    num_sides: number;
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    x3: number;
    y3: number;

    fill: boolean;
    fill_color: string;
    color: string;

    constructor(data) {
        // Default line properties
        this.lineType = "segment"; // segment, ray, line
        this.line_color = "#D00";
        this.line_width = 2;

        this.num_sides = 3;

        this.x1 = NaN;
        this.y1 = NaN;
        this.x2 = NaN;
        this.y2 = NaN;
        this.x3 = NaN;
        this.y3 = NaN;

        this.line_style = "solid";

        this.fill = false;
        this.fill_color = "#D00";

        // Add new and override any default properties
		for (const prop in data) {
            if (data[prop] !== undefined) {
				// attempt to cast to number or boolean
				if (!Number.isNaN(Number(data[prop]))) this[prop] = Number(data[prop]);
				else if (data[prop] === true || data[prop] === false) this[prop] = data[prop];
				else if (data[prop] === "true") this[prop] = true;
				else if (data[prop] === "false") this[prop] = false;
				else this[prop] = data[prop];
            }
        }        
    }

    plot(graph: QEGraph, ctx: SvgRenderingContext): void {
        // convert graph points to screen positions
        const screenPos = {x1: 0, y1: 0};
        for (let i = 1; i <= this.num_sides; i++) {
            // cast coords to floats
            this["x" + i] = parseFloat(this["x" + i]);
            this["y" + i] = parseFloat(this["y" + i]);

            screenPos["x" + i] = graph.screenX(this["x" + i] || 0);
            screenPos["y" + i] = graph.screenY(this["y" + i] || 0);
        }

        // save context style info
        ctx.save();

        ctx.lineWidth = this.line_width;
        ctx.strokeStyle = this.line_color;

        // Set line styling
        if (this.line_style == "dotted") {
            ctx.setLineDash([5, 5]);
        } else if (this.line_style == "dashed") {
            ctx.setLineDash([5, 15]);
        } else if (this.line_style == "none") {
            ctx.strokeStyle = "rgba(0,0,0,0)";
        }

        // create clipping area, to restrict line drawing to graph area
        ctx.save();
        ctx.beginPath();
        ctx.rect(
            graph.padding_left,
            graph.padding_top,
            graph.plot_width,
            graph.plot_height
        );
        ctx.clip();

        // draw the path
        ctx.beginPath();
        ctx.moveTo(screenPos.x1, screenPos.y1);
        for (let i = 1; i <= this.num_sides; i++) {
            ctx.lineTo(
                screenPos["x" + ((i % this.num_sides) + 1)],
                screenPos["y" + ((i % this.num_sides) + 1)]
            );
        }

        if (this.fill) {
            ctx.fillStyle = this.fill_color || this.color;
            ctx.fill();
        }

        ctx.stroke();

        ctx.restore();

        // line marks
        ctx.save();
        for (let i = 1; i <= this.num_sides; i++) {
            if (parseInt(this["side_mark" + i])) {
                // at midpoint of side, rotate context and draw marks
                const num_marks = parseInt(this["side_mark" + i]);

                let rotation = Math.atan(
                    (screenPos["y" + ((i % this.num_sides) + 1)] -
                        screenPos["y" + i]) /
                        (screenPos["x" + ((i % this.num_sides) + 1)] -
                            screenPos["x" + i])
                );
                if (
                    screenPos["x" + i] !==
                    screenPos["x" + ((i % this.num_sides) + 1)]
                ) {
                    rotation +=
                        ((screenPos["x" + ((i % this.num_sides) + 1)] >
                        screenPos["x" + i]
                            ? 90
                            : -90) *
                            Math.PI) /
                        180;
                } else {
                    // straight vertical rays
                    rotation +=
                        ((screenPos["x" + ((i % this.num_sides) + 1)] >
                        screenPos["x" + i]
                            ? -90
                            : 90) *
                            Math.PI) /
                        180;
                }

                ctx.save();
                ctx.beginPath();
                ctx.translate(
                    (screenPos["x" + ((i % this.num_sides) + 1)] -
                        screenPos["x" + i]) /
                        2 +
                        screenPos["x" + i],
                    (screenPos["y" + ((i % this.num_sides) + 1)] -
                        screenPos["y" + i]) /
                        2 +
                        screenPos["y" + i]
                );
                ctx.rotate(rotation);

                if (num_marks == 3) {
                    ctx.moveTo(-7, 0);
                    ctx.lineTo(7, 0);
                    ctx.stroke();

                    ctx.moveTo(-7, -4);
                    ctx.lineTo(7, -4);
                    ctx.stroke();

                    ctx.moveTo(-7, 4);
                    ctx.lineTo(7, 4);
                    ctx.stroke();
                } else if (num_marks == 2) {
                    ctx.moveTo(-7, -3);
                    ctx.lineTo(7, -3);
                    ctx.stroke();

                    ctx.moveTo(-7, 2);
                    ctx.lineTo(7, 2);
                    ctx.stroke();
                } else {
                    ctx.moveTo(-7, 0);
                    ctx.lineTo(7, 0);
                    ctx.stroke();
                }

                ctx.restore();
            }
        }
        ctx.restore();

        // side labels
        ctx.save();
        for (let i = 1; i <= this.num_sides; i++) {
            if (this["side_label" + i]) {
                // Use the line's midpoint for the label's reference pt
                new Label({
                    x:
                        (this["x" + ((i % this.num_sides) + 1)] -
                            this["x" + i]) /
                            2 +
                        this["x" + i],
                    y:
                        (this["y" + ((i % this.num_sides) + 1)] -
                            this["y" + i]) /
                            2 +
                        this["y" + i],
                    color: this.line_color,
                    value: this["side_label" + i],
                    pos: this["side_label_pos" + i],
                }).plot(graph, ctx);
            }
        }
        ctx.restore();

        // vertex angle arcs and marks
        ctx.save();
        for (let i = 1; i <= this.num_sides; i++) {
            if (["arc", "right"].indexOf(this["angle_sym" + i]) != -1) {
                // "equivalent" marks on angle arcs
                const num_marks = parseInt(this["angle_mark" + i]);

                const angle_sym = this["angle_sym" + i];

                const cur_index = i;
                const next_index = (i % this.num_sides) + 1;
                const prev_index = i - 1 || this.num_sides;

                let start_radians = Math.atan(
                    (screenPos["y" + next_index] - screenPos["y" + cur_index]) /
                        (screenPos["x" + next_index] -
                            screenPos["x" + cur_index])
                );
                if (
                    screenPos["x" + cur_index] !== screenPos["x" + next_index]
                ) {
                    start_radians +=
                        ((screenPos["x" + next_index] >
                        screenPos["x" + cur_index]
                            ? 0
                            : -180) *
                            Math.PI) /
                        180;
                } else {
                    // straight vertical rays
                    start_radians +=
                        ((screenPos["x" + next_index] >
                        screenPos["x" + cur_index]
                            ? -180
                            : 0) *
                            Math.PI) /
                        180;
                }

                let end_radians = Math.atan(
                    (screenPos["y" + prev_index] - screenPos["y" + cur_index]) /
                        (screenPos["x" + prev_index] -
                            screenPos["x" + cur_index])
                );
                if (
                    screenPos["x" + cur_index] !== screenPos["x" + prev_index]
                ) {
                    end_radians +=
                        ((screenPos["x" + prev_index] >
                        screenPos["x" + cur_index]
                            ? 0
                            : -180) *
                            Math.PI) /
                        180;
                } else {
                    // straight vertical rays
                    end_radians +=
                        ((screenPos["x" + prev_index] >
                        screenPos["x" + cur_index]
                            ? -180
                            : 0) *
                            Math.PI) /
                        180;
                }

                if (angle_sym == "right") {
                    // only allow if 90 degrees?
                    // && Math.abs(end_radians - start_radians)/Math.PI == 0.5i) {
                    ctx.save();
                    ctx.beginPath();
                    ctx.translate(screenPos["x" + i], screenPos["y" + i]);
                    ctx.rotate(start_radians);
                    ctx.moveTo(10, 0);
                    ctx.lineTo(10, -10);
                    ctx.lineTo(0, -10);
                    ctx.stroke();
                    ctx.restore();
                } else {
                    const arc_radius = 20;

                    ctx.save();
                    ctx.beginPath();
                    ctx.ellipse(
                        screenPos["x" + i],
                        screenPos["y" + i],
                        arc_radius,
                        arc_radius,
                        0,
                        start_radians,
                        end_radians,
                        true
                    );
                    ctx.stroke();
                    ctx.restore();

                    // equivalent angle marks - allowed on arcs only
                    if (num_marks) {
                        ctx.save();

                        ctx.lineWidth = 1.15;

                        ctx.translate(screenPos["x" + i], screenPos["y" + i]);
                        ctx.rotate((start_radians + end_radians) / 2);

                        const inner_radius = arc_radius - 5;
                        const outer_radius = arc_radius + 5;
                        if (num_marks == 3) {
                            ctx.moveTo(inner_radius, -2.5);
                            ctx.lineTo(outer_radius, -2.5);
                            ctx.stroke();

                            ctx.moveTo(inner_radius, 0);
                            ctx.lineTo(outer_radius, 0);
                            ctx.stroke();

                            ctx.moveTo(inner_radius, 2.5);
                            ctx.lineTo(outer_radius, 2.5);
                            ctx.stroke();
                        } else if (num_marks == 2) {
                            ctx.moveTo(inner_radius, -1.5);
                            ctx.lineTo(outer_radius, -1.5);
                            ctx.stroke();

                            ctx.moveTo(inner_radius, 1.5);
                            ctx.lineTo(outer_radius, 1.5);
                            ctx.stroke();
                        } else {
                            ctx.moveTo(inner_radius, 0);
                            ctx.lineTo(outer_radius, 0);
                            ctx.stroke();
                        }
                        ctx.restore();
                    }
                }
            }
        }
        ctx.restore();

        // vertex labels
        ctx.save();
        for (let i = 1; i <= this.num_sides; i++) {
            if (this["vertex_label" + i]) {
                new Label({
                    x: this["x" + i],
                    y: this["y" + i],
                    color: this.line_color,
                    value: this["vertex_label" + i],
                    pos: this["vertex_label_pos" + i],
                }).plot(graph, ctx);
            }
        }
        ctx.restore();

        // restore context style info
        ctx.restore();
    }
}
