import { QETerm } from '../QETerm';
import { QEHelper } from '../QEHelper';
import { QEWidget, DisplayOptions, TagElement } from '../QEWidget';

export class QEWidgetSpinner extends QEWidget {
	display_options: { [key: string]: any };

	dataset: { label: string, value?: any }[];
	value_index: number;

	font_family: string;
	font_size: string;
	label_colour: string;
	line_colour: string;
	fill_colours: string[];
	show_colours: boolean;

	static default_style = {
		font_family: 'sans-serif',
		font_size: '14px',
		label_colour: '#000000',
		line_colour: '#000000',
		fill_colours: ['#f50', '#36f', '#3cc', '#cc0', '#f39', '#09f', '#0f9', '#fc0'],
		show_colours: false,
	};

	constructor(dataset: { label: string, value?: any }[], value_index: number, display_options: DisplayOptions = {}) {
		super();

		this.dataset = dataset;
		this.value_index = value_index;
		this.display_options = display_options;

		// apply default style, then display options and style overrides
		Object.assign(this, QEWidgetSpinner.default_style, display_options);
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		const deserialized = JSON.parse(serialized);

		const value_index = QEHelper.resolveToNumber(deserialized.value_index, resolved_data);
		if (!Number.isInteger(value_index)) {
			console.log('Error: non-integer selected value_index');
			return null;
		}

		// TODO: support placeholder values and resolution?

		let dataset;
		try {
			dataset = JSON.parse(deserialized.dataset);
		} catch (err) {
			console.log('Error: unable to parse dataset values: ', deserialized.dataset, err);
			return null;
		}

		// build map and resolve any [$name] placeholders in display_options
		const display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new QEWidgetSpinner(dataset, value_index, display_options);
		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options) {
		// TODO: support passed display option overrides

		return this.draw();
	}

	draw() {
		const padding = 5; // padding to prevent edge clipping
		const size = 200 + padding * 2; // draw size

		const r = size / 2 - padding;
		const cx = size / 2;
		const cy = size / 2;
		const width = size;

		const svg = new TagElement("svg");
		svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");

		let rot = 0;
		let rot2 = 0; // for the pointer

		for (let i = 0; i < this.dataset.length; i++) {
			const g = new TagElement('g'); // enclose the shape into a group element

			const item = this.dataset[i];
			const wedge_angle = 360 / this.dataset.length;
			let arr = [rot, cx, cy];
			let theta = -wedge_angle * (Math.PI/180);
			let transform = 'rotate('+ arr.toString() +')';
			let x = ((0 * Math.cos(theta) - 1 * Math.sin(theta)) * r + r) + padding;
			let y = (r - (1 * Math.cos(theta) + 0 * Math.sin(theta)) * r) + padding;

			const pts = [];

			pts[0] = ['M', cx, cy].join(' ');
			pts[1] = ['L', cx, padding].join(' ');
			pts[2] = ['A', r, r, 0, 0, 1, x.toFixed(2), y.toFixed(2), 'Z'].join(' ');

			g.setAttribute('transform', transform);

			let fill_colour = "none";
			if (this.show_colours) {
				fill_colour = this.fill_colours[i % this.fill_colours.length];
			}
			g.innerHTML += '<path class="" d="'+ pts.join(' ') +'" fill="'+ fill_colour +'" stroke="'+ this.line_colour +'" stroke-width="2"/>';

			// Determine the label position
			theta = -wedge_angle/2 * (Math.PI/180);
			x = ((0 * Math.cos(theta) - 0.7 * Math.sin(theta)) * r + r) + padding;
			y = (r - (0.7 * Math.cos(theta) + 0 * Math.sin(theta)) * r) + padding;

			arr = [-rot, x, y];
			transform = 'rotate('+ arr.toString() +')';

			g.innerHTML += '<text x="'+ x +'" y="'+ y +'" font-family="'+ this.font_family +'" font-size="'+ this.font_size +'" alignment-baseline="middle" text-anchor="middle" fill="'+ this.label_colour +'" stroke="none" transform="'+ transform +'">';
			g.innerHTML += 	'<tspan x="'+ x +'">'+ item.label + '</tspan>';
			g.innerHTML += '</text>';

			if (i == this.value_index) {
				// spinner arrow stays in the middle 10-90% of the wedge
				rot2 = rot + Math.floor(0.1 * wedge_angle + 0.8 * Math.random() * wedge_angle);
			}
			rot += wedge_angle;
			svg.innerHTML += g.outerHTML();
		}

		svg.innerHTML += '<circle cx="'+ cx +'" cy="'+ cy +'" r="10" fill="#000" />';
		svg.innerHTML += '<circle cx="'+ cx +'" cy="'+ cy +'" r="5" fill="#D00" />';
		svg.innerHTML += '<g transform="translate('+ cx +','+ cy +') rotate('+ rot2 +')"><path d="M -3 0 L -3 -70 L -6 -70 L 0 -85 L 6 -70 L 3 -70 L 3 0 Z" fill="#D00" /></g>';

		svg.setAttribute('width', width.toString());
		svg.setAttribute('viewBox', [0,0, size, size].join(' '));

		return svg.outerHTML();
	}

	exportValue(options?){
		return {
			type: 'spinner',
			dataset: JSON.stringify(this.dataset),
			value_index: this.value_index,
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

