import { QETerm } from '../QETerm';
import { QEHelper } from '../QEHelper';
import { QEWidget, DisplayOptions } from '../QEWidget';
import * as jQuery from 'jquery';

export class QEWidgetDropZone extends QEWidget {
	display_options: { [key: string]: any };
	user_value: string;
	input_key_index: number;
	name: string;
	source_key: string;
//	max_quantity: number; // only for DropBucket

	constructor(source_key: string, options: { name?: string, input_key_index?: number, display_options?: DisplayOptions } = {}) {
		super();

		// DropZone is used to present the user with a target that DragSource items can be dragged to

		this.source_key = source_key;
		this.user_value = null;
		this.display_options = options.display_options || {};

		if (options.name) this.name = options.name;
		if (options.input_key_index !== undefined) this.input_key_index = options.input_key_index;
	}

	/**
	* Instantiates and returns widget from serialized data
	* @param {string} serialized - serialized string containing value and display config
	* @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	* @param {Object} [options]
	*/
	static instantiate(serialized, resolved_data, options?) {
		const deserialized = JSON.parse(serialized);

		// build map and resolve any [$name] placeholders in display_options
		const display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new QEWidgetDropZone(deserialized.source_key, {name: options.name, display_options: display_options});
		return widget;
	}

	/**
	* Returns widget markup for inclusion in question output
	* @param {Object} options
	* @returns {string} Generated display markup
	*/
	display(options = {}) {
		// TODO: support passed display option overrides

		var display_options = Object.assign({}, this.display_options, options);
		var value = display_options.value || "";

		let container_classes = display_options.container_classes || "";
		let container_styles = "";
		let ml = '';

		if (display_options.container_styles) {
			container_styles = ' style="' + display_options.container_styles + '"';
		}

		if (this.name && this.input_key_index !== undefined) {
			ml += '<div class="dropzone ' + container_classes  + '"' + container_styles +
				' data-name="' + this.name + '"' +
				' data-input_key_index="' + this.input_key_index + '"' +
			'>';
			ml += '</div>';
		} else {
			ml += '<div class="dropzone ' + (display_options.container_classes || '') +'"' + container_styles + '>';
			ml += '</div>';
		}

		return ml;
	}

	bindEventHandlers(widget_container) {
		// DropZone input events are bound by the calling code since they may involve updating a parent widget if nested
		return;
	}

	hasRoom() {
		return this.user_value === null;
	}

	canAccept(drop_item) {
		return drop_item.attr('data-drag-source-key') == this.source_key;
	}

	setInputValue(value: string) {
		this.user_value = value;
	}

	getInputValue(input_widgets?): string { return this.user_value; }

	isUserInputComplete(): boolean {
		// check if value set
		return this.user_value !== null;
	}

	isUserInputAutoSubmittable(): boolean {
		// check if value set
		return this.user_value !== null;
	}

	exportValue(options?){
		return {
			type: 'dropzone',
			name: this.name,
			source_key: this.source_key,
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

