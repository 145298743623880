import { QESolverStep } from '../QESolverStep';

export class Validate {
	
 	// eq type-checking helpers
	static validateTermIsNumber(term): boolean {
		// check the term is a number, or a PLUS/MINUS and number
		if (term.type == "RATIONAL") {
			return true;
		} else if ((term.type == "MINUS" || term.type == "PLUS") &&
			(term.children[0].type == "RATIONAL")) {
			return true;
		} else {
			return false;
		}
	}

	static validateTermIsComparator(term): boolean {
		// check the term is a comparator
		const comparator_types = ['LESS_OR_EQUAL', 'LESS', 'GREATER_OR_EQUAL', 'GREATER', 'EQUAL'];

		if (comparator_types.indexOf(term.type) != -1)
			return true;
		else
			return false;
	}

	static validateEqIsNumericInequality(eq): boolean {
		// check first child of ROOT is a comparator
		if (!Validate.validateTermIsComparator(eq.children[0]))
			return false;

		// check the eq contains only comparators with numeric leaf nodes
		function isComparatorOrNumber(term) {
			if (Validate.validateTermIsNumber(term)) {
				return true;
			} else if (Validate.validateTermIsComparator(term)) {
				// this node is a comparator, now check children
				if (isComparatorOrNumber(term.children[0]) && isComparatorOrNumber(term.children[1]))
					return true;
			}
			return false;
		}

		return isComparatorOrNumber(eq.children[0]);
	}	

	static isPrime(input_value, options) {
		if (input_value.type != "tree") return undefined;

		// TODO: validate number
		var number = parseInt(input_value.value.serialize_to_text());

		var desc =
			"Definition of a prime number: a positive whole number greater than 1 that has only two factors: 1 and itself.<br><br>";

		var is_prime = false;
		if (number < 0)
			desc +=
				'<span style="font-weight: bold;">' +
				number +
				"</span> is negative, so is NOT prime";
		else if (number < 2)
			desc +=
				'<span style="font-weight: bold;">' +
				number +
				"</span> is not greater than 1, so is NOT prime";
		else {
			var prime_factors = QESolverStep.getPrimeFactorList(number);

			desc +=
				'Factors of <span style="font-weight: bold;">' +
				number +
				"</span>: 1 * " +
				prime_factors.join(" * ") +
				"<br>";
			if (prime_factors.length == 1) {
				is_prime = true;
				desc +=
					'<span style="font-weight: bold;">' +
					number +
					"</span> is prime";
			} else {
				desc +=
					'<span style="font-weight: bold;">' +
					number +
					"</span> is NOT prime";
			}
		}

		var result = {
			desc: desc,
			value: is_prime,
			type: "boolean",
		};
		return result;
	}

	/**
	 * isDivisibleBy	Checks if the dividend is divisible by the specified divisor
	 */
	static isDivisibleBy(input_value, options) {
		if (input_value.type != "map") return undefined;
		var params = input_value.value;

		if (!params["dividend"] || !params["divisor"]) {
			return undefined;
		}

		// validate dividend
		var dividend = parseInt(params["dividend"].serialize_to_text());
		if (isNaN(dividend)) {
			return undefined;
		}

		// validate divisor
		var divisor = parseInt(params["divisor"].serialize_to_text());
		if (isNaN(divisor)) {
			return undefined;
		}

		var divisible = false;
		if (dividend % divisor === 0) {
			divisible = true;
		}

		// include divisibility rules
		var desc = "Remember the divisibility rules:";
		desc +=
			"<style>.rules td {padding: 0 10px; border: 1px solid #000;}</style>";
		desc += '<table class="rules">';
		desc += "<tbody>";
		desc += "<tr><td>2</td><td>Last digit is even</td></tr>";
		desc += "<tr><td>3</td><td>Sum of digits is divisible by 3</td></tr>";
		desc += "<tr><td>4</td><td>Last 2 digits divisible by 4</td></tr>";
		desc += "<tr><td>5</td><td>Last digit is 0 or 5</td></tr>";
		desc += '<tr><td>6</td><td>"2 rule" and "3 rule" must work</td></tr>';
		desc += "<tr><td>7</td><td>Double the last digit and subtract from the";
		desc += "<br>rest of the number. Result is divisible by 7</td></tr>";
		desc += "<tr><td>8</td><td>Last 2 digits divisible by 8</td></tr>";
		desc += "<tr><td>9</td><td>Sum of digits is divisible by 9</td></tr>";
		desc += "<tr><td>10</td><td>Last digit is 0</td></tr>";
		desc += "</tbody>";
		desc += "</table>";

		var result = {
			desc: desc,
			value: divisible,
			type: "boolean",
		};

		return result;
	}
}
