export function QEAssert( condition, msg ) {
	if (!condition){
		msg = "assert failed: '" + msg + "'";
		var isStrict = (function() { return !this; })();
		if (!isStrict && ("caller" in QEAssert))  {
			if (QEAssert.caller != null)  {
				msg = QEAssert.caller + '\n' + msg;
			}
		}
		console.assert( condition, msg );
//		if (typeof(window) != "undefined") {
//			window.alert(msg)
//		}
	}
}
