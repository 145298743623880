import { log } from './QE';
import { tokenize_and_parse } from './QEGrammar';
import { QETerm } from './QETerm';
import { QEHelper, numberToDecimal, QEValue, QEValueTree, QEValueString, QEValueJSON, QEValueMap, QEValueBoolean, QEValueWidget, QEValueAnswer } from './QEHelper';
import { QEWidgetMC } from './Widget/QEWidgetMC';
import { QEValConstants } from './QEValConstants';
import { QEQ } from './QE';
import { SvgRenderingContext } from './SvgRenderingContext';
import * as jQuery from 'jquery';

//////////////////////////////////////////////////////////////
export interface DisplayOptions {
	[key: string]: any;
}

export class TagElement {
	name: string;
	innerHTML: string;
	attributes: { [key: string]: string };
	dataset: { [key: string]: string };
	style: { [key: string]: string };
	id: string;
	children: TagElement[];
	parent_element: TagElement;
	ctx: SvgRenderingContext; // only used to mirror canvas element
	width: number;
	height: number;

	constructor(name: string) {
		this.name = name;
		this.innerHTML = "";
		this.attributes = {};
		this.dataset = {};
		this.style = {};
		this.id = "";
		this.children = [];
	}

	setAttribute(attr: string, value: string){
		this.attributes[attr] = value;
	}
	getAttribute(attr: string): string{
		return this.attributes[attr];
	}
	append(child: TagElement) {
		child.parent_element = this;
		this.children.push(child);
	}
	removeChild(child: TagElement) {
		let child_index = -1;
		for (let i = 0; i < this.children.length; i++) {
			if (this.children[i] === child) {
				child_index = i;
			}
		}

		if (child_index == -1) {
			throw "Error: called removeChild but specified element is not a child of current element.";
		} else {
			// detach
			this.children.splice(child_index, 1);
			child.parent_element = null;
		}
	}
	findChildrenWithId(id: string) {
		const matching_elements = [];
		for (let i = 0; i < this.children.length; i++) {
			if (this.children[i].id === id) {
				matching_elements.push(this.children[i]);
			}
		}
		return matching_elements;
	}
	findChildrenWithAttribute(attr: string, value: string) {
		const matching_elements = [];
		for (let i = 0; i < this.children.length; i++) {
			if (this.children[i].getAttribute(attr) === value) {
				matching_elements.push(this.children[i]);
			}
		}
		return matching_elements;
	}
	outerHTML(): string {
		const self = this;

		let attr_str = Object.keys(self.attributes).map(key => { return key +'="'+ self.attributes[key] +'"' }).join(' ');
		let dataset_str = Object.keys(self.dataset).map(key => { return 'data-'+ key +'="'+ self.dataset[key] +'"' }).join(' ');
		let style_str = Object.keys(self.style).map(key => { return key +':'+ self.style[key] +';' }).join(' ');

		let ml = '<'+ this.name;
		ml += this.id ? ' id="'+ this.id +'"' : '';
		ml += attr_str.length ? ' '+ attr_str : '';
		ml += dataset_str.length ? ' '+ dataset_str : '';
		ml += style_str.length ? ' style="'+ style_str +'"' : '';
		ml += '>';
		ml += 	self.innerHTML;
		for (let i = 0; i < this.children.length; i++) {
			ml += this.children[i].outerHTML();
		}
		ml += '</'+ self.name +'>'; // closing tag

		return ml;
	}

	getContext(contextType: string) {
		// validate this tag is an svg
		if (this.name !== "svg") {
			log.warn("Error: getContext() called on non-svg tag.");
			return;
		}

		if (!this.ctx) {
			this.ctx = new SvgRenderingContext();
			this.ctx.setRootElement(this);
		}
		return this.ctx;
	}
}

function escapeHtml(unsafe) {
	if (typeof unsafe == 'string') return unsafe.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
	else return unsafe;
}

export class QEWidget {
	series?: unknown;
	display_options?: unknown;

	constructor(){}

	display(options?){
		log.warn("Warning: display() called on base QEWidget");
		return null;
	}

	getInputValue(input_widgets?): string {
		// base getInputValue call
		// input_widgets map may be passed to allow widget access to nested input widgets (i.e. keyboards)
		return null;
	}

	isUserInputComplete(): boolean {
		// base isUserInputComplete call
		return false;
	}

	isUserInputAutoSubmittable(): boolean {
		// base isUserInputAutoSubmittable call
		return false;
	}

	exportValue(options?){
		log.warn("Warning: exportValue() called on base QEWidget");
		return null;
	}

	bindEventHandlers(widget_container) {
		log.warn("Warning: bindEventHandlers() called on base QEWidget");
		return;
	}
}

// config value to support overriding image location
const img_path = '/img/qe/';
export class ImgSet extends QEWidget {
	image_map_key: string;
	type: string;
	quantity: number;
	image_map: { key_parts: string[], type: string[], size: string[], color?: string[], cw?: string[] };
	display_options: DisplayOptions;

	constructor(image_map_key: string, type: string, quantity: number, display_options: DisplayOptions) {
		super();

		display_options = display_options || {};

		this.image_map_key = image_map_key;
		this.image_map = QEValConstants.image_maps[image_map_key];
		this.type = type;
		this.quantity = quantity;
		this.display_options = display_options;
	}

	/**
	 * Instantiates and returns an ImgSet widget from serialized data
	 * @param {string} serialized - serialized string containing value and display config
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 * @param {Object} [options]
	 */
	static instantiate(serialized, resolved_data, options?) {
		let deserialized = JSON.parse(serialized);

		let image_map_key_qev: QEValueString = QEHelper.resolvePlaceholderToString(deserialized.image_map, resolved_data);
		if (!image_map_key_qev) return null;
		let image_map_key = image_map_key_qev.value;

		let image_map = QEValConstants.image_maps[image_map_key];
		if (!image_map) return null;

		// build map and resolve any [$name] placeholders in display_options
		let display_options = QEHelper.resolveOptionsString(deserialized.display_options || {}, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		// detect old format - display_options has any old field populated
		let type = '';
		let quantity = 1;

		if (deserialized.type) {
			// new format: serialized contains "image_map", "value", "quantity", and "display_options"
			//    - display_options contains container_classes and container_styles, and possibly other image key_parts
			let type_qev = QEHelper.resolvePlaceholderToString(deserialized.type, resolved_data);
			if (!type_qev) return null;
			type = type_qev.value;
		} else if (['type','random_type',"value_as_type","value_as_quantity"].filter((x)=>{ return deserialized.display_options[x] !== undefined; }).length) {
			// old format: serialized contains "image_map_key" and "display_options"
			//    - display_options contains "random_type", "value_as_type", "type", "value_as_quantity"
			if (!deserialized.display_options.random_type) {
				// if random_type set leave type blank so we default to random, otherwise use specified type
				let type_qev = QEHelper.resolvePlaceholderToString(deserialized.display_options.type, resolved_data);
				if (!type_qev) return null;
				type = type_qev.value;
			}
		}

		if (deserialized.quantity) {
			let quantity_qev = QEHelper.resolvePlaceholderToString(deserialized.quantity, resolved_data);
			if (!quantity_qev) return null;
			if (Number.isInteger(Number(quantity_qev.value))) {
				quantity = Number(quantity_qev.value);
			}
		}

		// resolve other image key_parts and set to display_options
		var key_parts = image_map.key_parts.filter((x)=>{ return x != 'type'; });
		for (var i = 0; i < key_parts.length; i++) {
			// if key part value not specified, default to first item of the list for that key part
			var key_part = key_parts[i];
			var part_list = image_map[key_part];
			var specified_value = display_options[key_part]; // should be a QEValueString
			if (specified_value instanceof QEValue) specified_value = specified_value.serialize_to_text();

			if (specified_value === undefined || specified_value === '') {
				// property value not set; use random value from list, or first value if property is size
				display_options[key_part] = part_list[Math.floor(Math.random() * part_list.length)];
				if (key_part === 'size') {
					display_options[key_part] = part_list[0]; // default size to small if not specified
				}
			} else if (part_list.indexOf(specified_value) == -1) {
				// invalid value was specified
				log.warn('Error: invalid value specified for image key part: ', key_part, specified_value);
				return null;
			} else {
				display_options[key_part] = specified_value;
			}
		}

		let widget = new ImgSet(image_map_key, type, quantity, display_options);
		return widget;
	}

	/**
	 * Returns widget markup for inclusion in question output
	 * @param {Object} options
	 * @param {Object} options.type
	 * @param {Object} options.quantity
	 * @param {Object} options.value
	 * @returns {string} Generated display markup
	 */
	display(options) {
		// use this.display_options, then override with passed options
		var display_options = Object.assign({}, this.display_options, options);

		let passed_type = display_options.type;
		if (passed_type instanceof QEValue) {
			passed_type = passed_type.serialize_to_text();
		} else if (["string", "undefined"].indexOf(typeof passed_type) == -1) {
			passed_type = null;
			log.warn('Error: ImgSet display() called with unhandled type: ', display_options);
		}

		let passed_quantity = display_options.quantity;
		if (passed_quantity instanceof QEValue) {
			passed_quantity = passed_quantity.serialize_to_text();
		} else if (["number", "string","undefined"].indexOf(typeof passed_quantity) == -1) {
			passed_quantity = null;
			log.warn('Error: ImgSet display() called with unhandled quantity: ', display_options);
		}

		// if value passed, serialize and use as either quantity (if serialized value is an integer) or type
		let passed_value = display_options.value;
		if (passed_value instanceof QEValue) {
			passed_value = passed_value.serialize_to_text();
		} else if (["number", "string", "undefined"].indexOf(typeof passed_value) == -1) {
			passed_value = null;
			log.warn('Error: ImgSet display() called with unhandled value type: ', display_options);
		}

		// construct image key from specified parts
		var image_map_key = this.image_map_key;
		var image_map = this.image_map;

		var key_parts = image_map.key_parts;
		var image_key_parts = [image_map_key];
		var size_class = '';

		for (var i = 0; i < key_parts.length; i++) {
			// if key part value not specified, default to first item of the list for that key part
			var key_part = key_parts[i];
			var part_list = image_map[key_part];
			var specified_value = display_options[key_part];
			if (key_part == 'type') {
				let type = this.type;

				if (passed_type) {
					// if type was explicitly passed in, use as type override
					type = passed_type;
				} else if (passed_value && Number.isNaN(Number(passed_value))) {
					// if passed_value is defined and not a number, use as type override
					type = passed_value;
				}

				if (!type) {
					// if no type set, randomize
					type = image_map['type'][Math.trunc(Math.random() * image_map['type'].length)];
				} else if (part_list.indexOf(type) == -1) {
					// invalid value was specified
					log.warn('Error: invalid value specified for image key part: ', {key_part: key_part, type: type});
					return 'ERR';
				}

				image_key_parts.push(type);
			} else if (specified_value === undefined || specified_value === '') {
				// property value not set; use random value from list, or first value if property is size
				image_key_parts.push(part_list[Math.floor(Math.random() * part_list.length)]);
				if (key_part === 'size') {
					size_class = part_list[0];
				}
			} else if (part_list.indexOf(specified_value) == -1) {
				// invalid value was specified
				log.warn('Error: invalid value specified for image key part: ', key_part, specified_value);
				return 'ERR';
			} else {
				image_key_parts.push(specified_value);
				if (key_part === 'size') {
					size_class = specified_value;
				}
			}
		}

		var image_alt = image_key_parts.join(',');
		var image_size = '';
		var image_url = img_path + image_key_parts.join('_') + '.png';

		// Now set the image height based on the size class
		// Only valid as long as our name and sizing conventions stay the same
		switch (size_class) {
			case 'sm':
				image_size = '25';
				break;
			case 'med':
				image_size = '50';
				break;
			case 'lg':
				image_size = '100';
				break;
		}

		let quantity = this.quantity;

		if (passed_quantity && Number.isInteger(Number(passed_quantity))) {
			quantity = Number(passed_quantity);
		} else if (passed_value && Number.isInteger(Number(passed_value))) {
			// if passed_value is defined and is a number, use as quantity override
			quantity = Number(passed_value);
		}

		let break_every = 0;
		if (display_options.break_every && Number.isInteger(Number(display_options.break_every))) {
			break_every = Number(display_options.break_every);
		}

		var ml = '';
		for (var i = 0; i < quantity; i++) {
			if (i && break_every && !(i % break_every)) ml += '<br>'; // break after specified images

			ml += '<img';
			ml += 	(display_options.container_classes ? ' class="'+ display_options.container_classes +'"' : '');
			ml += 	(display_options.inline_styles ? ' style="'+ display_options.inline_styles +'"' : '');
			ml += 	' alt="'+ image_alt +'" src="'+ image_url +'" width="'+ image_size +'" height="' + image_size + '"/>';
		}

		return ml;
	}

	exportValue(options?){
		return {
			type: 'image_set',
			value: this.type,
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

export class Eq extends QEWidget {
	value: QETerm;
	display_options: DisplayOptions;
	input_values: {};
	postInputHandler: any; // handler function to execute after each user input event

	constructor(value, display_options) {
		super();

		this.value = value;
		this.display_options = display_options;
		this.input_values = {};
		this.postInputHandler = null;
	}

	/**
	 * Instantiates and returns an Eq widget from serialized data
	 * @param {string} serialized - serialized string containing value
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 * @param {Object} [options]
	 * @param {bool} [options.editor_mode] - flag indicating parser should return a map containing the parsed equation, cursor position info, and token arrays with and without the cursor
	*/
	static instantiate(serialized, resolved_data, options) {
		resolved_data = resolved_data || {};
		options = options || {};

		// coerce legacy QE.Widget.Eq data to current format -> { value: '...', display_options: '...' }
		let deserialized;
		try {
			deserialized = JSON.parse(serialized);
		} catch (err) {
			deserialized = {
				value: serialized,
				display_options: '',
			};
		}

		// resolve any references in value
		let resolved = QEHelper.resolvePlaceholderToTree(deserialized.value, resolved_data);
		if (!resolved) return null;

		// build map and resolve any [$name] placeholders in display_options
		let display_options = QEHelper.resolveOptionsString(deserialized.display_options, resolved_data);

		// check if there was an unresolved dependency
		if (!display_options) return null;

		let widget = new Eq(resolved.value, display_options);
		return widget;
	}

	/**
	 * Returns widget markup for inclusion in question output
	 * @returns {string} Generated display markup
	 */
	display(options) {
		let ml = '';
		if (this.display_options && this.display_options['display_as']) {
			// pass value to designated display widget
			let display_widget = this.display_options['display_as'];
			if (typeof display_widget == "object") {
				ml += display_widget.display({ value: this.value });
			} else {
				ml += "Error: display_as widget unresolved: " + display_widget;
			}
		} else if (!this.value) {
			log.warn('ERROR: Eq attempting to display unresolved value.');
			ml += '<div style="color:#f00;">ERR</div>';
		} else {
			// assign input_key_index to individual input fields so they can separately have focus and values
			let input_key_index = 0;
			this.value.findAllChildren('type', 'INPUT').forEach(function (node: QETerm) {
				let input_key_match = node.value.match(/\[\?(.*)\]/);
				if (input_key_match) {
					node.attr('input_key_index', input_key_index);
					input_key_index++;
				}
			});

			ml += this.value.display(Object.assign({}, this.display_options, options));
		}
		return ml;
	}

	setPostInputHandler(postInputHandler){ this.postInputHandler = postInputHandler }

	/**
	 * Sets the widget value for the specified input key
	 * @param {string} input_key
	 * @param {string} value
	 */
	setInputValue(input_key, value) {
		this.input_values[input_key] = value;

		if (this.postInputHandler) { this.postInputHandler(); }

		return value;
	}

	/**
	 * Gets the widget value for the specified input key
	 * @param {string} input_key
	 */
	getInputValue(input_widgets?): string {
		// ensure all input nodes contain valid content
		var missing_content = false;
		this.value.findAllChildren('type', 'INPUT').forEach(function (node) {
			if (!node.content) {
				missing_content = true;
			}
		});
		if (missing_content) {
			return;
		}

		var serialized = this.value.serialize_to_text({ include_input_content: true });
		return serialized;
	}

	isUserInputComplete(): boolean {
		// ensure all input nodes contain valid content
		var missing_content = false;
		this.value.findAllChildren('type', 'INPUT').forEach(function (node) {
			if (!node.content) {
				missing_content = true;
			} else if (node.content.serialize_to_text() == "") {
				missing_content = true;
			}
		});
		if (missing_content) {
			return false;
		}

		return true;
	}

	exportValue(options?){
		return {
			type: 'equation',
			value: this.value.serialize_to_text(),
			display_options: JSON.stringify(this.display_options || {}),
		};
	}

	bindEventHandlers(widget_container) {
		// client-side fix for radical tails obscuring input box focus: swap element order
		// included here for first-render. Subsequently performed in calling code setKeyboardCB
		widget_container.find('.hide-tail').parent().each(function(){
			var tail = jQuery(this);
			if (tail.prev().hasClass('svg-align')){
				tail.insertBefore(tail.prev());
			}
		});

		// Eq input events should be bound directly to any nested KB inputs
		return;
	}
}

export class Tally extends QEWidget {
	value: number;
	display_options: DisplayOptions;

	constructor(value: number, display_options: DisplayOptions) {
		super();

		this.value = value;
		this.display_options = display_options || {};
	}

	/**
	 * Instantiates and returns a Tally widget from serialized data
	 * @param {string} serialized - serialized string containing value and display config
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 * @param {Object} [options]
	 */
	static instantiate(serialized, resolved_data, options) {
		resolved_data = resolved_data || {};
		options = options || {};

		// coerce legacy Eq data to current format -> { value: '...', display_options: '...' }
		let deserialized;
		try {
			deserialized = JSON.parse(serialized);
		} catch (err) {
			deserialized = {
				value: serialized,
				display_options: '',
			};
		}

		// resolve references in value, default to "0" for empty
		let value = QEHelper.resolveToNumber(deserialized.value || "0", resolved_data);
		if (value === null || Number.isNaN(value)) {
			 return null;
		}

		let widget = new Tally(value, deserialized.display_options);
		return widget;
	}

	display(options) {
		// use this.display_options, then override with passed options
		var display_options = Object.assign({}, this.display_options, options);

		let passed_value = options.value;
		if (passed_value instanceof QEValue) {
			passed_value = passed_value.serialize_to_text();
		} else if (["number", "string","undefined"].indexOf(typeof passed_value) == -1) {
			passed_value = null;
			log.warn('Error: Tally display() called with unhandled value: ', display_options);
		}

		let value = this.value;
		if (passed_value && Number.isInteger(Number(passed_value))) {
			value = Number(passed_value);
		}

		if (Number.isNaN(value)) {
			return;
		}

		let break_every = 0;
		if (display_options.break_every && Number.isInteger(Number(display_options.break_every))) {
			break_every = Number(display_options.break_every);
		}

		var ml = '<ol class="tally">';
		for (var i = 0; i < value; i++) {
			if (i && break_every && !(i % break_every)) ml += '</ol><br><ol class="tally">'; // break into rows

			ml += '<li></li>';
		}
		ml += '</ol>'

		return ml;
	}

	exportValue(options?){
		return null; // no exportable data
	}
}

export class FormatSelector extends QEWidget {
	format_widget_keys: string[];
	format_widgets: QEWidget[];
	current_active_index: number;

	constructor(format_widget_keys, format_widgets) {
		super();

		this.format_widget_keys = format_widget_keys;
		this.format_widgets = format_widgets;
		this.current_active_index = 0;
	}

	/**
	 * Instantiates and returns a FormatSelector widget from serialized data
	 * @param {string} serialized - serialized string containing format_widget_keys
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 * @param {Object} [options]
	 */
	static instantiate(serialized, resolved_data, options) {
		resolved_data = resolved_data || {};
		options = options || {};

		let deserialized;
		try {
			deserialized = JSON.parse(serialized);
		} catch (err) {
			deserialized = {
				widget_keys: [],
			};
		}

		// resolve any references in each format_widget_keys entry
		let format_widget_keys = deserialized.format_widget_keys;
		let format_widgets = [];

		for (let i = 0; i < format_widget_keys.length; i++) {
			let widget_ref = '[$'+ format_widget_keys[i] +']';
			let resolved = QEHelper.resolvePlaceholderToRefs(widget_ref, resolved_data);
			if (!resolved) return null;

			format_widgets.push(resolved.value);
		}

		let widget = new FormatSelector(format_widget_keys, format_widgets);
		return widget;
	}

	display(options) {
		// selector box with format widgets rendered and hidden (except current_active)
		let default_index = 0;


		let ml = '<div class="format_selector">';
		ml += '<div class="format_widgets">';
		for (let i = 0; i < this.format_widgets.length; i++) {
			ml += '<div class="format_widget'+ (i == default_index ? ' current_active' : '') +'">';
			ml += 	'<div class="widget" data-widget_key="'+ this.format_widget_keys[i] +'" style="display: inline-block; vertical-align: middle;">';
			ml += 		this.format_widgets[i].display();
			ml += 	'</div>';
			ml += '</div>';
		}
		ml += '</div>';

		ml += '<div class="expander fa fa-caret-down"></div>';
		ml += '</div>';

		return ml;
	}

	setCurrentActiveIndex(index) {
		this.current_active_index = index;
	}

	bindEventHandlers(widget_container) {
		const self = this;

		widget_container.on('click', '.format_selector .expander', function(e){
			if (widget_container.is('.disable_input')) return; // skip if disabled

			var event_item = jQuery(this);
			event_item.parent().toggleClass('expanded');
		}).on('click', '.format_selector.expanded .format_widget', function(e){
			if (widget_container.is('.disable_input')) return; // skip if disabled

			// if clicked format_widget is not current_active, set it to be active
			var event_item = jQuery(this);
			if (!event_item.hasClass('current_active')) {
				// update format selector current widget index so submission can choose the selected child widget
				var format_widget_index = event_item.index();
				self.setCurrentActiveIndex(format_widget_index)

				event_item.parent().find('> .format_widget').removeClass('current_active');
				event_item.addClass('current_active');
			}

			event_item.closest('.format_selector').toggleClass('expanded');
		});
	}

	getInputValue(input_widgets?): string {
		let current_active_widget = this.format_widgets[this.current_active_index];
		return current_active_widget.getInputValue(input_widgets);
	}

	isUserInputComplete(): boolean {
		let current_active_widget = this.format_widgets[this.current_active_index];
		return current_active_widget.isUserInputComplete();
	}

	exportValue(options?){
		return {
			type: 'format_selector',
			formats: JSON.stringify(this.format_widget_keys),
		};
	}
}

export class WidgetList extends QEWidget {
	widget_keys: string[];
	sub_widgets: QEWidget[];
	active_index: number;
	active_widget_key: string;

	constructor(widget_keys, sub_widgets, active_index) {
		super();

		this.widget_keys = widget_keys;
		this.sub_widgets = sub_widgets;
		this.active_index = active_index;
		this.active_widget_key = this.widget_keys[active_index] || '';
	}

	/**
	 * Instantiates and returns a WidgetList widget from serialized data
	 * @param {string} serialized - serialized string containing widget_keys
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 * @param {Object} [options]
	 */
	static instantiate(serialized, resolved_data, options) {
		resolved_data = resolved_data || {};
		options = options || {};

		let deserialized;
		try {
			deserialized = JSON.parse(serialized);
		} catch (err) {
			deserialized = {
				widget_keys: [],
			};
		}

		// resolve the index of the widget to display
		let index = QEHelper.resolvePlaceholderToString(deserialized.index, resolved_data);
		if (!index) return null;
		let active_index = Number(index.value);

		// resolve any references in each widget_keys entry
		let widget_keys = deserialized.widget_keys;
		let sub_widgets = [];

		for (let i = 0; i < widget_keys.length; i++) {
			let widget_ref = '[$'+ widget_keys[i] +']';
			let resolved = QEHelper.resolvePlaceholderToRefs(widget_ref, resolved_data);
			if (!resolved) return null;

			sub_widgets.push(resolved.value);
		}

		let widget = new WidgetList(widget_keys, sub_widgets, active_index);
		return widget;
	}

	display(options) {
		let ml = '<div class="widget" data-widget_key="'+ this.widget_keys[this.active_index] +'" style="display: inline-block; vertical-align: middle;">';
		ml += 		this.sub_widgets[this.active_index].display(options);
		ml += 	'</div>';
		return ml;
	}

	bindEventHandlers(widget_container) {}

	getInputValue(input_widgets?): string {
		let active_widget = this.sub_widgets[this.active_index];
		return active_widget.getInputValue(input_widgets);
	}

	isUserInputComplete(): boolean {
		let active_widget = this.sub_widgets[this.active_index];
		return active_widget.isUserInputComplete();
	}

	exportValue(options?){
		return {
			type: 'widget_list',
			active_index: this.active_index,
			sub_widget_keys: JSON.stringify(this.widget_keys),
		};
	}
}

export class StringLookup extends QEWidget {
	string_map: { [key: string]: { [key: string]: string } };
	theme_key: string;
	quantity: string;

	constructor(string_map, theme_key, quantity) {
		super();

		this.string_map = string_map;
		this.theme_key = theme_key;
		this.quantity = quantity;
	}

	/**
	 * Instantiates and returns a StringLookup widget from serialized data
	 * @param {string} serialized - serialized string containing value and display config
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 * @param {Object} [options]
	 */
	static instantiate(serialized, resolved_data, options?) {
		let deserialized = JSON.parse(serialized);

		// NOTE: the "value" passed on construction should really have been called "theme_key"
		let value = QEHelper.resolvePlaceholderToString(deserialized.value, resolved_data);
		if (!value) return null;
		let theme_key = value.value;

		let string_map_key = QEHelper.resolvePlaceholderToString(deserialized.string_map, resolved_data, {});
		if (!string_map_key) return null;

		let string_map = QEValConstants.string_maps[string_map_key.value];
		if (!string_map) return null;

		// resolve references in quantity, default to "0" for empty 
		let quantity = QEHelper.resolveToNumber(deserialized.quantity || "0", resolved_data);
		if (quantity === null || Number.isNaN(quantity))
			return null;

		let widget = new StringLookup(string_map, theme_key, quantity);
		return widget;
	}

	lookup(string_key: string, options?): string {
		return this.display(Object.assign({ value: string_key }, options));
	}

	pluralize(theme_key: string, quantity: string | QEValue, options?): string {
		return this.display(Object.assign({ theme_key: theme_key, value: quantity }, options));
	}

	// display behaviour for the string lookup widget is as follows:
	// - the widget has string_map_key and theme_key, set, and may have a quantity value
	// - if a value is passed, it overrides quantity
	// - if {value_test: "plurality"} is passed OR the themed string map contains "one" and "many", then the quantity is used to lookup the one or many value
	display(options: { value?: string | QEValue, theme_key?: string | QEValue, value_test?: string } = {}): string {
		// NOTE: if we want to allow fully flexible lookup, we should store (and allow override of) "string_map_key" instead of "string_map"
		const string_map = this.string_map;

		let theme_key = this.theme_key;
		if (options.theme_key !== undefined) {
			if (typeof options.theme_key == 'string') theme_key = options.theme_key;
			else theme_key = options.theme_key.serialize_to_text();
		}
		const theme_string_map = string_map[theme_key];
		if (!theme_string_map) {
			log.warn('Error: StringLookup specified theme string map not found: ', theme_key, string_map);
			return 'ERR1';
		}

		let string_key;
		if (options.value !== undefined) {
			if (typeof options.value == 'string') string_key = options.value;
			else string_key = options.value.serialize_to_text();

			// if the specified string_map->theme_key contains "one" and "many", use quantity by default to do a pluralize() lookup
			if (options.value_test == 'plurality' ||
				(theme_string_map['one'] && theme_string_map['many'] && !Number.isNaN(Number(string_key)))
			) {
				// check plurality of value
				if (parseInt(string_key) == 1) string_key = 'one';
				else string_key = 'many';
			}
		} else {
			// if no value passed, try to use configured quantity
			string_key = this.quantity;

			// if the specified string_map->theme_key contains "one" and "many", use quantity by default to do a pluralize() lookup
			if (theme_string_map['one'] && theme_string_map['many'] && !Number.isNaN(Number(string_key))) {
				if (parseInt(this.quantity) == 1) string_key = 'one';
				else string_key = 'many';
			}
		}

		var string = theme_string_map[string_key];
		if (typeof string == 'undefined') {
			log.warn('Error: StringLookup specified string key not found in theme string map: ', string_key, theme_key, string_map);
			return 'ERR2';
		}

		return string;
	}

	exportValue(options?){
		return null; // no exportable data
	}
}

export class DecimalGrid extends QEWidget {
	value: string;
	user_value: string;
	display_options: DisplayOptions;
	postInputHandler: any; // handler function to execute after each user input event

	constructor(value: string, display_options: DisplayOptions) {
		super();

		this.value = value;
		this.display_options = display_options;
		this.postInputHandler = null;
	}

	/**
	 * Instantiates and returns a DecimalGrid widget from serialized data
	 * @param {string} serialized - serialized string containing value and display config
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 * @param {Object} [options]
	 */
	static instantiate(serialized, resolved_data, options) {
		resolved_data = resolved_data || {};
		options = options || {};

		// coerce legacy Eq data to current format -> { value: '...', display_options: '...' }
		let deserialized;
		try {
			deserialized = JSON.parse(serialized);
		} catch (err) {
			deserialized = {
				value: serialized,
				display_options: '',
			};
		}

		// resolve references in value, default to "0" for empty value
		let value = QEHelper.resolveToNumber(deserialized.value || "0", resolved_data);
		if (value === null || Number.isNaN(value))
			return null;

		// deserialize display_options string
		let display_options = QEHelper.resolveOptionsString(deserialized.display_options, {});

		let widget = new DecimalGrid(value.toString(), display_options);
		return widget;
	}

	display(options) {
		let value = this.value;

		if (options.value !== undefined) {
			value = options.value.serialize_to_text();
		}
		if (value === undefined) return null;

		let padding = 5;
		let margin = 0;
		let start_x = 1.5;
		let start_y = 1.5;
		let col_width = 8;
		let row_height = 8;

		let outer_stroke_width = 2;
		let inner_stroke_width = 1;
		let fill_color = '#cff0fc';
		let empty_fill_color = '#ffffff';
		let stroke_color = '#0ea2d8';

		function genWholeSvg(num){
			let ml = '<div style="width: 94px; height: 94px; padding: 5px; display: inline-block;">';
			ml += 	'<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">';
			if (num)
				ml += 		'<rect x="'+ (outer_stroke_width / 2) +'" y="'+ (outer_stroke_width / 2) +'" width="81" height="81" fill="'+ fill_color +'" stroke="'+ stroke_color +'" stroke-width="'+ outer_stroke_width +'"/>';
			else
				ml += 		'<rect x="'+ (outer_stroke_width / 2) +'" y="'+ (outer_stroke_width / 2) +'" width="81" height="81" fill="'+ empty_fill_color +'" stroke="'+ stroke_color +'" stroke-width="'+ outer_stroke_width +'"/>';

			ml += 	'</svg>';
			ml += '</div>';
			return ml;
		}

		function genTenthsSvg(num){
			let ml = '<div style="width: 94px; height: 94px; padding: 5px; display: inline-block;">';
			ml += 	'<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">';
			ml += 		'<rect x="1" y="1" width="81" height="81" fill="'+ empty_fill_color +'" stroke="'+ stroke_color +'" stroke-width="'+ outer_stroke_width +'"/>';

			// filled columns
			for (let i = 0; i < num; i++) {
				ml += '<rect data-col="'+ (i+1) +'" x="'+ (start_x + i * col_width) +'" y="'+ start_y +'" width="'+ col_width +'" height="80" fill="'+ fill_color +'" stroke="'+ stroke_color +'" stroke-width="1"/>';
			}
			// empty columns
			for (let i = num; i < 10; i++) {
				ml += '<rect data-col="'+ (i+1) +'" x="'+ (start_x + i * col_width) +'" y="'+ start_y +'" width="'+ col_width +'" height="80" fill="'+ empty_fill_color +'" stroke="'+ stroke_color +'" stroke-width="1"/>';
			}
			ml += 	'</svg>';
			ml += '</div>';
			return ml;
		}

		function genHundredthsSvg(num){
			let ml = '<div style="width: 94px; height: 94px; padding: 5px; display: inline-block;">';
			ml += 	'<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">';
			ml += 		'<rect x="1" y="1" width="81" height="81" fill="'+ empty_fill_color +'" stroke="'+ stroke_color +'" stroke-width="'+ outer_stroke_width +'"/>';

			// filled cells
			for (let i = 0; i < num; i++) {
				ml += '<rect data-cell="'+ (i+1) +'" x="'+ (start_x + Math.trunc(i/10) * col_width) +'" y="'+ (start_y + Math.trunc(i%10) * row_height) +'" width="'+ col_width +'" height="'+ row_height +'" fill="'+ fill_color +'" stroke="'+ stroke_color +'" stroke-width="1"/>';
			}

			// empty cells
			for (let i = num; i < 100; i++) {
				ml += '<rect data-cell="'+ (i+1) +'" x="'+ (start_x + Math.trunc(i/10) * col_width) +'" y="'+ (start_y + Math.trunc(i%10) * row_height) +'" width="'+ col_width +'" height="'+ row_height +'" fill="'+ empty_fill_color +'" stroke="'+ stroke_color +'" stroke-width="1"/>';
			}
			ml += 	'</svg>';
			ml += '</div>';
			return ml;
		}

		var Q = QEQ.from_string(value);
		var parts = Q.serialize_to_decimal_parts();
		var integer = Number(parts[0]);
		var decimal = parts[1];

		var tenths = Math.trunc(Number(decimal[0]) || 0);
		var hundredths = Math.trunc(Number(decimal[1]) || 0);

		// default drawing to "largest units", i.e. 2 uses ones, 0.2 uses tenths, and 0.02 uses hundredths
		// TODO: support display options for forcing rendering to tenths or hundredths

		// default drawing to "column-oriented"
		// TODO: support display options for row-oriented

		let ml = '<div style="display: inline-block; vertical-align: middle;">';

		// handle the "0" case
		if (Q.num === 0) {
			if (this.display_options.display_unit == "hundredths") ml += genHundredthsSvg(0);
			else if (this.display_options.display_unit == "tenths") ml += genTenthsSvg(0);
			else ml += genWholeSvg(0);
		}

		// display ones first
		for (let i = 0; i < integer; i++) {
			if (this.display_options.display_unit == "hundredths") {
				ml += genHundredthsSvg(100);
			} else if (this.display_options.display_unit == "tenths") {
				ml += genTenthsSvg(10);
			} else {
				ml += genWholeSvg(1);
			}
		}

		// now the decimal portion
		if (hundredths || (tenths && this.display_options.display_unit == "hundredths")) {
			ml += genHundredthsSvg(tenths * 10 + hundredths);
		} else if (tenths) {
			ml += genTenthsSvg(tenths);
		}

		ml += '</div>';

		return ml;
	}

	bindEventHandlers(widget_container) {
		const self = this;

		widget_container.on('click', 'svg rect', function(){
			if (widget_container.is('.disable_input')) return; // skip if disabled

			var event_item = jQuery(this);
			if (event_item.is('[data-cell]')) {
				const grid_val = event_item.attr('data-cell');
				self.setInputValue((Number(grid_val) / 100).toString());
			} else if (event_item.is('[data-col]')) {
				const grid_val = event_item.attr('data-col');
				self.setInputValue((Number(grid_val) / 10).toString());
			} else return;

			// re-draw
			widget_container.html(self.display({}));
		});
	}

	setPostInputHandler(postInputHandler){ this.postInputHandler = postInputHandler }

	setInputValue(value) {
		// special case: clear value if it is already equal to the current value
		if (value == this.value) {
			value = "0";
		}

		this.user_value = value;
		this.value = value;

		if (this.postInputHandler) { this.postInputHandler(); }
	}

	getInputValue(input_widgets?): string { return this.user_value; }

	isUserInputComplete(): boolean {
		return typeof this.user_value != 'undefined' && this.user_value !== '';
	}

	exportValue(options?) {
		return {
			type: 'decimal_grid',
			value: this.value,
			display_options: JSON.stringify(this.display_options || {}),
		};
	}
}

export class PlaceBlocks extends QEWidget {
	value: QETerm;

	constructor(value) {
		super();

		this.value = value;
	}

	/**
	 * Instantiates and returns a PlaceBlocks widget from serialized data
	 * @param {string} serialized - serialized string containing value and display config
	 * @param {Object} resolved_data - resolved value data for resolving placeholder dependencies
	 * @param {Object} [options]
	 */
	static instantiate(serialized, resolved_data, options) {
		resolved_data = resolved_data || {};
		options = options || {};

		// coerce legacy Eq data to current format -> { value: '...', display_options: '...' }
		let deserialized;
		try {
			deserialized = JSON.parse(serialized);
		} catch (err) {
			deserialized = {
				value: serialized,
				display_options: '',
			};
		}

		// resolve any references in value
		let resolved = QEHelper.resolvePlaceholderToTree(deserialized.value, resolved_data);
		if (!resolved) return null;

		let widget = new PlaceBlocks(resolved.value);
		return widget;
	}

	display(options) {
		let obj = options.value;
		if (obj === null || obj.value == null) {
			return;
		}

		let stroke_width = 1;
		let fill_color = '#cff0fc';
		let empty_fill_color = '#ffffff';
		let shade_fill_color = '#41c3f3';
		let stroke_color = '#0ea2d8';

		let aspect_y = 3;
		let aspect_x = 4;

		let start_x = stroke_width / 2;
		let start_y = stroke_width / 2;
		let col_width = 8;
		let row_height = 8;

		function genBlocksSvg(w: number, h: number, d: number, style_options?: { [key: string]: string } ){
			let style = {
				'margin-left': '0px',
				'margin-right': '0px',
				'margin-top': '0px',
				'margin-bottom': '0px',
				'display': 'inline-block'
			};
			Object.assign(style, style_options);

			let ml = '<div style="width: '+ (stroke_width + w * col_width + d * aspect_x) +'px; height: '+ (stroke_width + h * row_height + d * aspect_y) +'px; '+
				Object.keys(style).map(function(field){ return field +': '+ style[field] }).join('; ') +'">';
			ml += 	'<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">';

			// faces
			for (let i = 0; i < w * h; i++) {
				ml += '<rect x="'+
					(start_x + (i % w) * col_width) +'" y="'+
					(start_y + Math.trunc(i / w) * row_height + d * aspect_y) +
					'" width="'+ col_width +'" height="'+ row_height +'" fill="'+ fill_color +'" stroke="'+ stroke_color +'" stroke-width="'+ stroke_width +'"/>';
			}

			// upper edge aspect
			for (let i = 0; i < w * d; i++) {
				ml += '<polygon points="'+
					(start_x + (i % w) * col_width + Math.trunc(i/w) * aspect_x                       ) +','+ (start_y + (d - Math.trunc(i/w)) * aspect_y) +' '+
					(start_x + (i % w) * col_width + Math.trunc(i/w) * aspect_x + aspect_x            ) +','+ (start_y + (d - Math.trunc(i/w)) * aspect_y - aspect_y) +' '+
					(start_x + (i % w) * col_width + Math.trunc(i/w) * aspect_x + aspect_x + col_width) +','+ (start_y + (d - Math.trunc(i/w)) * aspect_y - aspect_y) +' '+
					(start_x + (i % w) * col_width + Math.trunc(i/w) * aspect_x + col_width           ) +','+ (start_y + (d - Math.trunc(i/w)) * aspect_y) +
					'" fill="'+ shade_fill_color +'" stroke="'+ stroke_color +'" stroke-width="'+ stroke_width +'"/>'
			}

			// right edge aspects
			for (let i = 0; i < d * h; i++) {
				ml += '<polygon points="'+
					(start_x + w * col_width + (i % d) * aspect_x           ) +','+ (start_y + Math.trunc(i/d) * row_height + (d - i % d) * aspect_y) +' '+
					(start_x + w * col_width + (i % d) * aspect_x + aspect_x) +','+ (start_y + Math.trunc(i/d) * row_height + (d - i % d) * aspect_y - aspect_y) +' '+
					(start_x + w * col_width + (i % d) * aspect_x + aspect_x) +','+ (start_y + Math.trunc(i/d) * row_height + (d - i % d) * aspect_y - aspect_y + row_height) +' '+
					(start_x + w * col_width + (i % d) * aspect_x           ) +','+ (start_y + Math.trunc(i/d) * row_height + (d - i % d) * aspect_y + row_height) +
					'" fill="'+ shade_fill_color +'" stroke="'+ stroke_color +'" stroke-width="'+ stroke_width +'"/>'
			}

			ml += 	'</svg>';
			ml += '</div>';
			return ml;
		}


		var integer  = Math.trunc(obj.value.serialize_to_text());
		var thousands = Math.trunc(integer / 1000);
		var hundreds = Math.trunc((integer % 1000) / 100);
		var tens = Math.trunc((integer % 100) / 10);
		var ones = Math.trunc(integer % 10);

		let ml = '<div style="display: inline-block; vertical-align: middle;">';

		for (let i = 0; i < thousands ; i++) {
			ml += genBlocksSvg(10, 10, 10, { 'margin-right': '5px' });
		}

		for (let i = 0; i < hundreds ; i++) {
			// horizontal stacking: apply negative margin-left to each slice of 100 after the first so they overlap
			if (i) {
				ml += genBlocksSvg(1, 10, 10, { 'margin-left': '-30px' });
			} else {
				ml += genBlocksSvg(1, 10, 10);
			}
		}

		for (let i = 0; i < tens ; i++) {
			ml += genBlocksSvg(1, 10, 1, { 'margin-right': '5px' });
		}

		if (ones) {
			// stacking container for ones
			ml += '<div style="display: inline-block; max-width: 40px; font-size: 0;">';
			for (let i = 0; i < ones ; i++ ) {
				ml += genBlocksSvg(1, 1, 1, { 'margin-bottom': '3px', 'margin-right': '3px' });
			}
			ml += '</div>';
		}

		ml += '</div>';

		return ml;
	}

	exportValue(options?){
		return null; // no exportable data
	}
}
