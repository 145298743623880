import * as katex from 'katex';
import { log } from '../common/QE';
import { QEAssert } from '../common/QEAssert.js';
import { QETerm } from '../common/QETerm';
import { QEHelper, ExplanationStep, Solution, QEValue, QEValueTree, QEValueString, QEValueJSON, QEValueMap, QEValueBoolean, QEValueWidget, QEValueAnswer } from '../common/QEHelper';
import { QESolverStep } from './Solver/QESolverStep';
import { Simplify } from './Solver/Steps/Simplify';
import { GraphSolver } from './Solver/Steps/GraphSolver';
import { Evaluate } from './Solver/Steps/Evaluate';
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// NOTE: SolverStepOutput is different than ExplanationStep
// SolverStepOutput is the returned data from a SolverStep function
// ExplanationStep is the formatted display info, generated for the purpose of presenting to a user
export interface SolverStepOutput {
	type: string;
	desc: string;
	widget_data?: unknown;
	substeps?: ExplanationStep[];
	old_term?: QETerm;
	new_term?: QETerm;
	hide_transformation?: boolean;
	value?: QEValue | QETerm | { [key: string]: any };
	hide_step?: boolean
}

interface SolverConfig {
	desc?: string;
	initial_steps?: SolverStepConfig[];
	steps: SolverStepConfig[];
	input_param: SolverConfigInputParam;
	output_type: string;
}

interface SolverConfigInputParam {
	type: string;
	fields?: { [key: string]: {
		type: string,
		clone?: boolean,
		optional?: boolean,
		simplify?: boolean
	} };
	simplify?: boolean;
	example?: string;
}

interface SolverStepConfig { step_key: string | SolverFunctionType, desc?: string }

interface SolverFunctionType { (input_value: QEValue, options?:unknown): SolverStepOutput }

/**
 * loopOverSteps: helper - executes a list of solver steps on the provided value and updates the output map with the resulting steps and value
 */
function loopOverSteps(steps, intermediate_value, output, options) {
	let step = steps[0];
	let applied = undefined;
	let loop_counter = 0;
	let next_step_options;

	for (let i = 0; i < steps.length /* no-op */; ) {
		loop_counter++;
		if (loop_counter > 500) {
			if (intermediate_value.type == "tree") {
				throw { msg: 'Excessive solver loop count for: "' + intermediate_value.value.serialize_to_text() + '"', stack: new Error().stack };
			} else {
				throw { msg: 'Excessive solver loop count.', stack: new Error().stack };
			}
		}

		// pass global solveUsing options, solver-specific step options, and immediate-execution next_step options
		const step_options = Object.assign(
			{},
			options,
			step.options,
			next_step_options // can be specified by step output when specifying next_solution_step
		);

		// clear next_step_options - is only valid for immediately executed next_solution_step
		next_step_options = undefined;

		if (typeof step.step_key === "string") {
			applied = QESolverStep[step.step_key](intermediate_value, step_options);
		} else if (step.step_key instanceof Function) {
			applied = step.step_key(intermediate_value, step_options);
		} else if (step.apply) {
			applied = step.apply(intermediate_value, step_options);
		} else {
			throw { msg: 'Solver step missing function in solver.', stack: new Error().stack };
		}

		// update 'output' data
		if (applied !== undefined) {
			if (!applied.desc) {
				applied.desc = step.desc;
			}

			const step_output = QESolver.formatStepOutput(intermediate_value, applied, options);

			// instantiate new QEValue based on step_output type and value
			intermediate_value = QEValue.create(step_output.value);
			output.value = intermediate_value;

			if (options.preserve_steps && !applied.hide_step) {
				output.steps.push(step_output);
			}
		}

		// decide what to do next
		if (applied === undefined) {
			// applied no step, try the next one in steps
			step = steps[++i];
		} else if (applied.final_step) {
			// step indicated it is final
			break;
		} else if (!("next_solution_step" in applied)) {
			// applied a step, try to apply all steps with higher priority
			step = steps[(i = 0)];
		} else if (applied.next_solution_step !== undefined) {
			// applied a step which overrides next step, try to apply it
			step = applied.next_solution_step;

			// when a step specifies the next solver step, it can also pass options that will be included for ONLY that next step
			next_step_options = applied.next_step_options;
		} else {
			// applied a "final" step.
			break;
		}
		applied = undefined; // clears 'next_solution_step' member
	}
}

export class QESolver {
	static solver_list: Array<string>;
	static solvers: { [key: string]: SolverConfig } = {};

	static cloneInputValue(input: QEValue, options): QEValue {
		const input_param_options = Object.assign({ clone: true }, options);

		if (!input_param_options.clone) {
			// cloning not required
			return input;
		}

		if (input.type == "tree") {
			const input_tree = input.value;
			QEAssert(input_tree instanceof QETerm);

			// if the root of the cloned tree is not a ROOT node, then give it a new ROOT parent
			let clone_tree = input_tree.clone();
			if (clone_tree.type !== "ROOT") {
				const new_root = QETerm.create({ type: "ROOT" });
				new_root.pushChild(clone_tree);
				clone_tree = new_root;
			}

			return new QEValueTree({ value: clone_tree });
		} else if (input.type == "map") {
			// clone each map value
			const clone_map = {};

			const map_field_options = input_param_options.fields || {};

			Object.keys(input.value).map(function (key_name) {
				const clone_item = QESolver.cloneInputValue(input.value[key_name], map_field_options[key_name]);
				if (clone_item) {
					clone_map[key_name] = clone_item;
				} else {
					log.error("Error: failed to clone input map item", input.value[key_name]);
					return null;
				}
			});
			return new QEValueMap({ value: clone_map });
		}

		// attempt to instantiate new QEValue based on input.type - does not deep copy!
		const clone_value = QEValue.create(input);
		if (clone_value) {
			return clone_value;
		}

		log.error("Error: expected input of type tree, string, or map", input);
		return null;
	}
	/**
	 * formatStepOutput: generates formatted solution step output from input value and QESolverStep output
	 * - applies old_term -> new_term replace transformation on input value tree
	 */
	static formatStepOutput(input: QEValue, output: SolverStepOutput, options): ExplanationStep {
		const step_output: ExplanationStep = {
			desc: "",
			widget_data: null,
			value: null,
		};

		// TODO: change options.preserve_steps to options.display
		if (options.preserve_steps) {
			// TODO: use display options in step display - e.g. display_as widgets
			// TODO: display nested solution step data?
			// add surrounding markup to desc
			step_output.desc = "<div>" + output.desc + "</div>";

			// render any katex blocks in the step desc
			if (step_output.desc.indexOf("<katex>") !== -1) {
				step_output.desc = step_output.desc.replace(/<katex>(.*?)<\/katex>/g, function (full, inner) {
					return katex.renderToString(inner, { displayMode: true });
				});
			}

			// keep desc widget_data for client-side rendering
			if (output.widget_data) {
				step_output.widget_data = output.widget_data;
			}

			// support solver steps calling solveUsing on a subtree and including the resulting substeps in the solution
			// - e.g. given "3 * 2 * (4 + 5 * 6)", simplify the bracketed expression all the way to "34" before simplifying "3 * 2"
			// - step should still return old_term, new_term, but may include "substeps"
			if (output.substeps) {
				step_output.substeps = output.substeps;
			}
		}

		if (input instanceof QEValueTree && output.type == "tree") {
			const old_term = output.old_term; // Term to be replaced
			const new_term = output.new_term; // new "simplified" Term
			QEAssert(old_term.type != "ROOT");
			QEAssert(old_term.parent !== undefined);

			// TODO: change options.preserve_steps to options.display
			if (options.preserve_steps) {
				// create copy of the tree before old_term replaced
				const input_eq = input.value.clone();

				// replace old_term with new_term
				old_term.replaceWith(new_term);
				const output_eq = input.value; // no need to clone, since we'll generate display output immediately, and can strip highlight classes after

				if (!output.hide_transformation) {
					// append transformation display to desc
					const before = input_eq.display(options.display_options);
					const after = output_eq.display(options.display_options);
					step_output.desc += before + '<span style="margin: 0 15px;">becomes</span>' + after;
				}
			} else {
				old_term.replaceWith(new_term);
			}

			// tree-input/tree-output steps modify reference to input value, rather than create a new value
			step_output.value = input;

			// clean up output value
			new_term.removeClassToAll("highlight_input");
			new_term.removeClassToAll("highlight_output");
			new_term.removeClassToAll("highlight_input_sign");
			new_term.removeClassToAll("highlight_output_sign");

			QEAssert(input.value.validate());
		} else {
			// instantiate step output QEValue based on output.type
			step_output.value = QEValue.create(output);
		}
		return step_output;
	}
	/**
	 * Executes a set of solver step transformations on an input QEValue to produce an output QEValue
	 * @param {string} solver_key - key referencing an entry in QESolver.solvers
	 * @param {Object} input_value - map containing the input value and type
	 * @param {string} input_value.type - the type of the input value: tree, boolean, or list
	 * @param {Object} input_value.value - the input value itself, usually a tree
	 * @param {Object} options
	 * @param {bool} options.preserve_steps - flag indicating the solver should return an array containing the states of the tree at each transformation step
	 * @returns {Object} output - map containing the output value and type
	 * @returns {string} output.type - the type of the output value: tree, boolean, or list
	 * @returns {Object} output.value - the output value itself, usually a transformed output tree
	 * @returns {Object[]} output.steps - array of tracked transformation steps -> empty array if options.preserve_steps not true
	 * @returns {Object} output.steps[].desc - description text of transformation step
	 */
	static solveUsing(solver_key, input_value, options: { [key: string]: any } = {}): Solution {
		const output = { steps: [], value: null };

		const solver = QESolver.solvers[solver_key];
		if (solver === undefined) {
			log.error('ERROR: solver with key "' + solver_key + '" not found.');
			return undefined;
		}

		return QESolver.applySolverStepLists(solver.initial_steps || [], solver.steps, input_value, Object.assign({}, options, {solver_input_param: solver.input_param}));
	}

	static applySolverStepLists(initial_steps, main_steps, input_value, options: { [key: string]: any } = {}): Solution {
		const output = { steps: [], value: null };

		// TODO: return object with error code, instead of undefined
		// validate input data
		if (input_value.type == "tree") {
			if (!input_value.value) {
				log.error("ERROR: solveUsing called on unresolved input.");
				return undefined;
			} else if (input_value.value.findAllChildren("type", "EMPTY").length) {
				log.error("ERROR: input contained EMPTY values.");
				return undefined;
			}
		}

		// clone input_value, so applying solver doesn't modify anything upstream
		let intermediate_value: QEValue = QESolver.cloneInputValue(input_value, options.solver_input_param);
		if (!intermediate_value) return null;
		output.value = intermediate_value;

		// apply initial solver steps
		if (initial_steps.length) {
			loopOverSteps(initial_steps, intermediate_value, output, options);

			// use the output from the initial steps as the new working value
			intermediate_value = output.value;
		}

		// apply main solver steps
		loopOverSteps(main_steps, intermediate_value, output, options);

		return output;
	}

	/**
	 * Executes a specified solver step transformations on an input QEValue to produce an output QEValue - basically solveUsing, but for a single solver step
	 * @param {string} step_key - key referencing an entry in QESolver.solvers
	 * @param {Object} input_value - map containing the input value and type
	 * @param {string} input_value.type - the type of the input value: tree, boolean, or list
	 * @param {Object} input_value.value - the input value itself, usually a tree
	 * @param {Object} options
	 * @param {bool} options.preserve_steps - flag indicating the solver should return an array containing the states of the tree at each transformation step
	 * @returns {Object} output - map containing the output value and type
	 * @returns {string} output.type - the type of the output value: tree, boolean, or list
	 * @returns {Object} output.value - the output value itself, usually a transformed output tree
	 * @returns {Object[]} output.steps - array of tracked transformation steps -> empty array if options.preserve_steps not true
	 * @returns {Object} output.steps[].desc - description text of transformation step
	 */
	static applySolverStep(step_key, input_value, options: { [key: string]: any } = {}): Solution {
		const output = { steps: [], value: null };

		const solver_step = QESolverStep[step_key];
		if (solver_step === undefined) {
			log.error('ERROR: solver step with key "' + step_key + '" not found.');
			return undefined;
		}

		// TODO: return object with error code, instead of undefined
		// validate input data
		if (input_value.type == "tree") {
			if (!input_value.value) {
				log.error("ERROR: solveUsing called on unresolved input.");
				return undefined;
			} else if (input_value.value.findAllChildren("type", "EMPTY").length) {
				log.error("ERROR: input contained EMPTY values.");
				return undefined;
			}
		}

		// clone input_value, so applying solver doesn't modify anything upstream
		let intermediate_value: QEValue = QESolver.cloneInputValue(input_value, {});
		if (!intermediate_value) return null;
		output.value = intermediate_value;

		// apply main solver steps
		loopOverSteps([{step_key: solver_step}], intermediate_value, output, options);

		return output;
	}

	static display(solution, options: { [key: string]: any } = {}) {
		const solution_steps = solution.steps;
		// TODO: use display options in step display - e.g. for display_as widgets
		let solution_ml = "";

		if (!solution_steps.length) {
			solution_ml += "<div>Solver input unchanged</div>";
			solution_ml += "<div>Value: " + solution.value.display() + "</div>";
		}

		function showSteps(steps) {
			for (let i = 0; i < steps.length; i++) {
				const step = steps[i];
				solution_ml += '<div class="step question-solution">';
				solution_ml += step.desc;
				solution_ml += "</div>";
			}
		}
		showSteps(solution_steps);

		return solution_ml;
	}
	static bedmas_simplify(eq) {
		// no need to simplify single values
		if (eq.type == "ROOT" && eq.children[0].arity == "VALUE") return eq;

		// call simplify_bedmas solution, return output_eq only
		return QESolver.solveUsing("simplify_bedmas", { type: "tree", value: eq }).value;
	}
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

QESolver.solver_list = [
	'bedmas_mixed_numbers',
	'canonicalize',
	'decimal_to_decimal_fraction_then_comparison',
	'distributive_addition',
	'fraction_bedmas_remove_common_fraction_factors_last',
	'fraction_comparison',
	'fraction_to_decimal_then_comparison',
	'make_int_comparison',
	'make_frac_comparison',
	'characterize_expression',
	'characterize_number',
	'characterize_term',
	'convert_decimal_fraction',
	'convert_decimal_percent',
	'convert_fraction_decimal',
	'convert_fraction_percent',
	'convert_imperial_length',
	'convert_metric_length',
	'convert_percent_decimal',
	'convert_percent_fraction',
	'convert_numbers_to_words',
	'convert_fraction_to_words',
	'convert_mixed_radical_to_entire',
	'convert_radical_to_fractional_exponent',
	'convert_fractional_exponent_to_radical',
	'convert_whole_number_to_roman',
	'digit_at_place',
	'expand_power_to_chain',
	'get_gcf',
	'get_factors',
	'get_prime_factors',
	'get_common_factors',
	'get_standard_multiplication',
	'get_dividing_using_place_value',
	'get_dividing_using_long_division',
	'get_forwards_numbers',
	'get_arithmetic_sequence',
	'get_addition_statement',
	'get_multiples', // input: [base, start_multiple, length], e.g. [5,3,4] -> [15,20,25,30]
	'get_lcm_by_gcf', // [num1, num2]
	'get_list_mean',
	'get_list_median',
	'get_list_mode',
	'get_list_range',
	'is_divisible_by',
	'is_prime',
	'lookup_table_value',
	'prime_or_composite',
	'reduce_chain_to_power',
	'rotate_point_around_point',
	'round_number',
	'simplify_bedmas',
	'simplify_isolate_variable_in_comparison',
	'sort_fractions_by_ascend',
	'substitute_variable_values',
	'graph_linear_inequality_1d',
];

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// canonicalize "cleans up" an expression, typically for presenting algebra expressions (standardizes generated algebra expression presentation)
// - pulls plus/minus signs leftwards out of terms and combines with preceding ops
// - removes 1-coefficients from multiplied terms
// - turns 0-coefficient terms into 0 terms
// - removes 0 terms from additive expressions
// - gets rid of unnecessary brackets
// - merges multiplied terms separated by "*" operators to use implied multiply where possible (e.g. "2*x" -> "2x")
// - merges divided terms into a fraction denominator, with multiplied terms on top as the numerator
QESolver.solvers.canonicalize = {
	desc: 'Canonicalize an expression by merging/removing superfluous signs, brackets, coefficients (e.g. "*1"), and terms (e.g. "+0").',
	initial_steps: [
		{step_key: "convertToChains", desc: "Convert binary op-tree to chain-tree"},
		// convert UNARY SIGN ops to term "sign", and then migrate signs leftwards
		{step_key: "CT_removePlusSigns",			desc:"Remove all plus signs"},
		{step_key: "CT_combineNestedMinusSigns",	desc: "Combine minus signs"},
		{step_key: "CT_mergeMinusWithTerms",		desc: "Merge MINUS with terms - convert MINUS to negative sign field"},
		{step_key: "CT_extractNegativeSign",				desc: "Pulls a negative sign upwards in a multiplicative CHAIN"},
		{step_key: "CT_combineNegativeSignsWithAddSubtract",	desc: "Combine negative signs with preceding add or subtract"},
		// remove brackets from non-chains where sign not negative (or parent not exponent, or postfix op - e.g. "!")
		{step_key: "CT_removeTermBrackets",				desc: "Remove unnecessary brackets from a single value"},
		// get rid of all DIVIDE operations
		{step_key: "CT_invertDividedFraction",			desc: "When dividing by a fraction, flip it and multiply"},
		{step_key: "CT_mergeFractionInFraction",		desc: "Merge a fraction within a fraction with the containing fraction"},
		{step_key: "CT_convertMixedMultiplicativeChainToFraction",	desc: "Combine chains of MULTIPLY and DIVIDE into a single complex fraction"},
	],
	steps: [
		{step_key: "CT_extractNegativeSign",				desc: "Pulls a negative sign upwards in a multiplicative CHAIN"},
		{step_key: "CT_combineNegativeSignsWithAddSubtract",	desc: "Combine negative signs with preceding add or subtract"},
		{step_key: "CT_combineZero",						desc: "Replace multiplicative zero terms, evaluate power/root zero terms, and remove additive zero terms"},
		{step_key: "CT_combineOne",							desc: "Remove multiplicative one terms, simplify power/root one terms"},
	],
	input_param: { type: 'tree' },
	output_type: 'tree',
};

// TODO: separate solver that lexically sorts multiply chain terms
// TODO: separate solver that lexically sorts add chain terms

// TODO: separate solver that reduces integer fractions (e.g. "\frac{2,4}" -> "\frac{1,2}"
// QESolver.solvers.reduce_integer_fractions

QESolver.solvers.digit_at_place = {
	desc: "Find the digit of the number at the specified place value",
	steps: [ {step_key: "digitAtPlace"} ],
	input_param: {
		type: 'map',
		fields: {
			number: { type: 'tree', clone: false },
			place: { type: 'tree', clone: false },
		},
	},
	output_type: 'tree', // whole
};
QESolver.solvers.get_gcf = {
	desc:"Find the greatest common factor (GCF) of two or more numbers",
	steps: [ {step_key: "getGCF"} ],
	input_param: { type: 'tree', example: 'list{num1, num2, ...}' }, // list{num1, num2, ...}
	output_type: 'tree', // whole
};
QESolver.solvers.get_factors = {
	desc:"Find the factors of a number",
	steps: [ {step_key: "getFactors"} ],
	input_param: { type: 'tree' }, // integer
	output_type: 'tree', // list{}
};
QESolver.solvers.get_forwards_numbers = {
// TODO: deprecate!
	desc:"Find forward numbers of a number",
	steps: [ {step_key: "getForwardsNumbers"} ],
	input_param: {
		type: 'map',
		fields: {
			'number': { type: 'tree', clone: false },
			'counting': { type: 'tree', clone: false },
		},
	},
	output_type: 'tree', // list{}
};
QESolver.solvers.get_arithmetic_sequence = {
	desc: 'Returns a sequence of numbers starting at "start", of length "length", and incremented by "increment" (optional, else by 1)',
	steps: [ {step_key: "getArithmeticSequence" } ],
	input_param: {
		type: 'map',
		fields: {
			'start': { type: 'tree', clone: false, simplify: true },
			'length': { type: 'tree', clone: false, simplify: true },
			'increment': { type: 'tree', clone: false, optional: true },
		},
	},
	output_type: 'tree', // list{}
};
QESolver.solvers.get_addition_statement = {
	desc: "Returns a description of how to find the difference between two numbers",
	steps: [ {step_key: "getAdditionStatement"} ],
	input_param: { type: 'tree', example: 'list{start, diff, last}' }, // list{start, diff, last}
	output_type: 'string',
};
QESolver.solvers.get_prime_factors = {
	desc:"Find the prime factors of a number",
	steps: [ {step_key: "getPrimeFactors"} ],
	input_param: { type: 'tree' }, // integer
	output_type: 'tree', // list{}
};
QESolver.solvers.get_common_factors = {
	desc:"Find the common factors of two or more numbers",
	steps: [ {step_key: "getCommonFactors"} ],
	input_param: { type: 'tree', example: 'list{num1, num2, ...}' }, // list{num1, num2, ...}
	output_type: 'tree', // list{}
};
QESolver.solvers.get_standard_multiplication = {
	desc:"Uses long multiplication to evaluate the multiplication statement",
	steps: [ {step_key: "getStandardMultiplication"} ],
	input_param: { type: 'tree', example: '6*12' }, // multiply chain
	output_type: 'tree', // number
};
QESolver.solvers.get_dividing_using_place_value = {
	desc: "Performs multi-digit division by dividing digits of the dividend by the divisor (e.g. 24/4 -> (20+4)/4 -> 20/4 + 4/4",
	steps: [ {step_key: "getDividingUsingPlaceValue"} ],
	input_param: { type: 'tree', example: '72/6' }, // divide chain. Does not handle decimal inputs
	output_type: 'tree', // number
};
QESolver.solvers.get_dividing_using_long_division = {
	desc: "How to divide by long division to make multi-digit division",
	steps: [ {step_key: "getDividingUsingLongDivision"} ],
	input_param: { type: 'tree', example: '72/6' }, // divide chain
	output_type: 'tree', // number
};

QESolver.solvers.get_multiples = {
	desc: "List the multiples of a number",
	steps: [ {step_key: "getMultiples"} ],
	input_param: {
		type: 'map',
		fields: {
			'base': { type: 'tree', clone: false },
			'length': { type: 'tree', clone: false },
		},
	},
	output_type: 'tree', // list{}
};
QESolver.solvers.get_lcm_by_gcf = {
	desc: "Find the lowest common multiple (LCM) of two numbers, using the GCF method",
	steps: [ {step_key: "get_LCM_by_GCF"} ],
	input_param: { type: 'tree', example: 'list{num1, num2}' }, // list{num1, num2}
	output_type: 'tree', // number
};

QESolver.solvers.get_list_mean = {
	desc: "Find the mean value of a list of numbers.",
	steps: [ {step_key: "getListMean"} ],
	input_param: { type: 'tree', example: 'list{num1, num2, ...}' }, // list{num1, num2, ...}
	output_type: 'tree', // number
};
QESolver.solvers.get_list_median = {
	desc: "Find the median value of a list of numbers.",
	steps: [ {step_key: "getListMedian"} ],
	input_param: { type: 'tree', example: 'list{num1, num2, ...}' }, // list{num1, num2, ...}
	output_type: 'tree', // number
};
QESolver.solvers.get_list_mode = {
	desc: "Find the mode value of a list of numbers.",
	steps: [ {step_key: "getListMode"} ],
	input_param: { type: 'tree', example: 'list{num1, num2, ...}' }, // list{num1, num2, ...}
	output_type: 'tree', // number
};
QESolver.solvers.get_list_range = {
	desc: "Find the range of a list of numbers.",
	steps: [ {step_key: "getListRange"} ],
	input_param: { type: 'tree', example: 'list{num1, num2, ...}' }, // list{num1, num2, ...}
	output_type: 'tree', // number
};

QESolver.solvers.is_prime = {
	desc: "Determine if the number is prime",
	steps: [ {step_key: "isPrime"} ],
	input_param: { type: 'tree' }, // integer
	output_type: 'boolean',
};
QESolver.solvers.prime_or_composite = {
	desc: "Determine if the number is prime or composite",
	steps: [ {step_key: "primeOrComposite"} ],
	input_param: { type: 'tree' }, // integer
	output_type: 'string',
};
QESolver.solvers.round_number = {
	desc: 'Round the number to the specified place exponent, e.g. "1" → tens, "2" → hundreds, "0" → ones, "-1" → tenths',
	steps: [ {step_key: "roundNumberToNumPlaces"} ],
	input_param: {
		type: 'map',
		fields: {
			'number': { type: 'tree', clone: false },
			'place': { type: 'tree', clone: false },
		},
	},
	output_type: 'tree', // number
};

QESolver.solvers.convert_numbers_to_words = {
	desc: "Convert number to word names",
	steps: [ {step_key: "convertNumberToWords"} ],
	input_param: { type: 'tree' }, // rational
	output_type: 'string',
};
QESolver.solvers.convert_fraction_to_words = {
	desc: "Convert number to word names",
	steps: [ {step_key: "convertFractionToWords"} ],
	input_param: { type: 'tree' }, // fraction
	output_type: 'string',
};

QESolver.solvers.graph_linear_inequality_1d = {
	desc: "Generate graph end-points from one-variable linear inequality",
	steps: [ {step_key: GraphSolver.graphLinearInequality1d} ],
	input_param: { type: 'tree', example: 'x>5' }, // inequality 1d
	output_type: 'json',
};

/// Solvers related to number evaluation and conversion

QESolver.solvers.characterize_expression = {
	desc: "Characterizes num_terms, num_vars, degree, sign, coefficient, etc. of an expression",
    initial_steps: QESolver.solvers.canonicalize.initial_steps.slice(0),
	steps: [ {step_key: "characterizeExpression"} ],
	input_param: { type: 'tree' }, // expression, not comparison
	output_type: 'json',
};

QESolver.solvers.characterize_number = {
	desc: "Classify which number systems the number belongs to",
    initial_steps: QESolver.solvers.canonicalize.initial_steps.slice(0),
	steps: [ {step_key: "characterizeNumber"}, ],
	input_param: { type: 'tree' }, // number
	output_type: 'json',
};

QESolver.solvers.characterize_term = {
	desc: "Characterizes num_vars, degree, sign, coefficient, etc. of a term",
    initial_steps: QESolver.solvers.canonicalize.initial_steps.slice(0),
	steps: [ {step_key: "characterizeTerm"}, ],
	input_param: { type: 'tree' }, // number
	output_type: 'json',
};

QESolver.solvers.convert_decimal_fraction = {
	desc: "Converts a decimal number to the equivalent fraction in lowest terms",
	steps: [ {step_key: "convertDecimalToFraction"}, ],
	input_param: { type: 'tree' }, // rational, decimal
	output_type: 'tree', // frac{}
};

QESolver.solvers.convert_decimal_percent = {
	desc: "Converts a decimal number to the equivalent percentage",
	steps: [ {step_key: "convertDecimalToPercent"} ],
	input_param: { type: 'tree' }, // rational, decimal
	output_type: 'tree', // percent
};

QESolver.solvers.convert_fraction_decimal = {
	desc: "Converts a fraction of rationals to the equivalent decimal number",
	steps: [ {step_key: "convertFractionToDecimal"} ],
	input_param: { type: 'tree' }, // fraction of rationals
	output_type: 'tree', // decimal
};

QESolver.solvers.convert_fraction_percent = {
	desc: "Converts a fraction of rationals to the equivalent percentage",
	steps: [ {step_key: "convertFractionToPercent"} ],
	input_param: { type: 'tree' }, // fraction of rationals
	output_type: 'tree', // percent
};

QESolver.solvers.convert_percent_decimal = {
	desc: "Converts a percentage to the equivalent decimal number",
	steps: [ {step_key: "convertPercentToDecimal"} ],
	input_param: { type: 'tree' }, // rational, percent
	output_type: 'tree', // decimal
};

QESolver.solvers.convert_percent_fraction = {
	desc: "Converts a percentage to the equivalent fraction in lowest terms",
	steps: [ {step_key: "convertPercentToFraction"}, ],
	input_param: { type: 'tree' }, // rational, percent
	output_type: 'tree', // frac{}
};

QESolver.solvers.convert_imperial_length = {
	desc: "Given a value and imperial length unit, returns the value converted to the specified imperial length unit",
	steps: [ {step_key: "convertImperialLengthUnit"}, ],
	input_param: {
		type: 'map',
		fields: {
			'src_value': { type: 'tree', clone: false }, // rational
			'src_unit': { type: 'string', clone: false }, // "in", "ft", "yd", "mi"
			'dest_unit': { type: 'string', clone: false }, // "in", "ft", "yd", "mi"
		},
	},
	output_type: 'tree', // number
};

QESolver.solvers.convert_metric_length = {
	desc: "Given a value and metric length unit, returns the value converted to the specified metric length unit",
	steps: [ {step_key: "convertMetricLengthUnit"}, ],
	input_param: {
		type: 'map',
		fields: {
			'src_value': { type: 'tree', clone: false }, // rational
			'src_unit': { type: 'string', clone: false }, // "mm", "cm", "m", "km"
			'dest_unit': { type: 'string', clone: false }, // "mm", "cm", "m", "km"
		},
	},
	output_type: 'tree', // number
};

QESolver.solvers.expand_power_to_chain = {
	desc: 'Converts a power term to a chain of "base" terms multiplied together "exp" times',
	steps: [ {step_key: "expandPowerToChain"}, ],
	input_param: { type: 'tree' }, // pow{}
	output_type: 'tree', // e.g. 5*5*5
};

QESolver.solvers.is_divisible_by = {
	desc: "Tests whether the dividend is evenly divisible by the divisor (no remainder)",
	steps: [ {step_key: "isDivisibleBy"}, ],
	input_param: {
		type: 'map',
		fields: {
			'dividend': { type: 'tree', clone: false }, // integer
			'divisor': { type: 'tree', clone: false }, // integer
		},
	},
	output_type: 'boolean',
};

QESolver.solvers.lookup_table_value = {
	desc: 'Performs a lookup for the lookup_value in the lookup column. If found, returns the value in the output column.',
	steps: [ {step_key: "lookupTableValue"}, ],
	input_param: {
		type: 'map',
		fields: {
			'lookup_value': { type: 'string', clone: false },
			'lookup_col_index': { type: 'tree', clone: false },
			'output_col_index': { type: 'tree', clone: false },
			'table': { type: 'widget', clone: false },
		},
	},
	output_type: 'any', // depends on the cell value type in the Table
};

QESolver.solvers.rotate_point_around_point = {
	desc: 'Rotates "input_point" clockwise around "reference_point" by "angle" and returns "output_point".',
	steps: [ {step_key: "rotatePointAroundPoint"}, ],
	input_param: {
		type: 'map',
		fields: {
			'input_point': { type: 'json', clone: false },
			'ref_point': { type: 'json', clone: false },
			'angle': { type: 'tree', clone: false },
//			'graph': { type: 'widget', clone: false },
		},
	},
	output_type: 'json',
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
QESolver.solvers.reduce_chain_to_power = {
	desc: 'Converts like multiplied terms to powers.',
    initial_steps: [ {step_key: "convertToChains", desc: "Convert binary op-tree to chain-tree"} ],
    steps: [ {step_key: "reduceChainToPower", desc: "Combine identical multiplied values into a power"} ],
	input_param: { type: 'tree' }, // e.g. 5*5*5
	output_type: 'tree', // pow{}
};

QESolver.solvers.simplify_bedmas = {
	desc: 'Apply BEDMAS operations to simplify/evaluate an expression.',
	initial_steps: [
		{step_key: "convertToChains",			desc:"Convert binary op-tree to chain-tree"},
		// get rid of all SIGNs
		{step_key: "CT_removePlusSigns",			desc:"Remove all plus signs"},
		{step_key: "CT_combineNestedMinusSigns",	desc: "Combine minus signs"},
		{step_key: "CT_mergeMinusWithTerms",		desc: "Merge MINUS with terms - convert MINUS to negative sign field"},
		{step_key: "CT_extractNegativeSign",				desc: "Pulls a negative sign upwards in a multiplicative CHAIN"},
		{step_key: "CT_combineNegativeSignsWithAddSubtract",	desc: "Combine negative signs with preceding add or subtract"},
		// remove brackets from non-chains where sign not negative (or parent not exponent, or postfix op - e.g. "!")
		{step_key: "CT_removeTermBrackets",				desc: "Remove unnecessary brackets from a single value"},
		// get rid of all DIVIDE operations
		{step_key: "CT_invertDividedFraction",			desc: "When dividing by a fraction, flip it and multiply"},
		{step_key: "CT_mergeFractionInFraction",		desc: "Merge a fraction within a fraction with the containing fraction"},
		{step_key: "CT_convertMixedMultiplicativeChainToFraction",	desc: "Combine chains of MULTIPLY and DIVIDE into a single complex fraction"},
	],
	steps: [
		{step_key: "CT_extractNegativeSign",				desc: "Pulls a negative sign upwards in a multiplicative CHAIN"},
		{step_key: "CT_combineNegativeSignsWithAddSubtract",	desc: "Combine negative signs with preceding add or subtract"},
		{step_key: "CT_handleDivideByZero",					desc: "Dividing by zero is undefined"},
		{step_key: "CT_handleRootOfNegative",				desc: "The sqrt of a negative number is undefined"},
		{step_key: "CT_combineZero",						desc: "Replace multiplicative zero terms, evaluate power/root zero terms, and remove additive zero terms"},
		{step_key: "CT_combineOne",							desc: "Remove multiplicative one terms, simplify power/root one terms"},

		// remove brackets from non-chains where sign not negative (or parent not exponent, or postfix op - e.g. "!")
		{step_key: "CT_removeTermBrackets",				desc: "Remove unnecessary brackets from a single value"},
		{step_key: "CT_removeChainBrackets",			desc: "Remove unnecessary brackets from an expression"},

		// invert negative exponents and roots
		{step_key: "CT_invertNegativeExponent",			desc: "Make a negative exponent positive by moving it to the denominator of a fraction"},
		{step_key: "CT_invertNegativeRoot",				desc: "Make a negative root positive by moving it to the denominator of a fraction"},

		// simplify powers and roots
		{step_key: "CT_simplifyPower",					desc: "Simplify a power by raising each base term by the exponent (a*b)^c -> a^c * b^c"},
		{step_key: "CT_simplifyRoot",					desc: "Simplify a root by pulling out common factors"},
		{step_key: "CT_separateRootOfFraction",			desc: "Split the root of a fraction into a fraction of two roots."},
		{step_key: "CT_separatePowerOfFraction",		desc: "Split the power of a fraction into a fraction of two powers."},

		// simplify fractions
		{step_key: "CT_removeCommonFractionFactors",	desc: "Cancel out common factors in a fraction"},
		{step_key: "CT_mergeFractionInFraction",		desc: "Merge a fraction within a fraction with the containing fraction"},

		{step_key: "CT_evaluateFactorial",				desc: "Compute the value of a positive integer factorial"},

		{step_key: "CT_convertMixedMultiplicativeChainToFraction",	desc: "Combine chains of MULTIPLY and DIVIDE into a single complex fraction"},

		{step_key: "CT_combineMultiplicativeChainTerms", desc: "Multiply like terms"},
		{step_key: "CT_combineAdditiveChainTerms", desc: "Add/subtract like terms"},
		{step_key: "CT_multiplyBracketTerms", desc: "Multiply bracketed expressions"},

		// canonicalize chain term order
		{step_key: "CT_sortMultiplicativeChainTerms", 		desc: "Standardize term order within a term"},
		{step_key: "CT_sortAdditiveChainTerms", 			desc: "Standardize term order in an expression"},

		// add fraction terms
		{step_key: "CT_combineAdditiveChainRationalFractions", desc: "Add/subtract rational fractions and terms in an additive CHAIN by finding the LCD of the terms. E.g. frac{1,2} + 1"},
		{step_key: "CT_combineAdditiveChainPolyFractions", desc: "Add/subtract polynomial fractions and terms in an additive CHAIN by finding the LCD of the terms. E.g. frac{x,2} + x"},

		// CT_extractNegativeSignFromAddChain
		{step_key: "CT_extractNegativeSignFromAddChain",	desc: "Pulls a negative sign upwards from an additive CHAIN"},
	],
	input_param: { type: 'tree' },
	output_type: 'tree',
};

// Move CT_multiplyBracketTerms in front of CT_combineMultiplicativeChainTerms in order to do distributive property. a*(b+c) = a*b + a*c
const modify_distributive_addition_steps = function() {
	const steps = QESolver.solvers.simplify_bedmas.steps.slice(0);
	
	const combineMultiplicativeChainTerms_index = steps.findIndex(x => x.step_key === "CT_combineMultiplicativeChainTerms");
	const multiplyBracketTerms_index = steps.findIndex(x => x.step_key === "CT_multiplyBracketTerms");
	if ( combineMultiplicativeChainTerms_index != -1 && multiplyBracketTerms_index != -1 ) {
		// splice out CT_multiplyBracketTerms and insert it before CT_combineMultiplicativeChainTerms
		const multiplyBracketTerms_step = steps.splice(multiplyBracketTerms_index, 1)[0];
		steps.splice(combineMultiplicativeChainTerms_index, 0, multiplyBracketTerms_step);
	}
	return steps;
};
QESolver.solvers.distributive_addition = {
	desc: 'Apply the distributive property a(b+c) = ab + ac to evaluate an expression',
    initial_steps: QESolver.solvers.simplify_bedmas.initial_steps.slice(0),
	steps: modify_distributive_addition_steps(),
	input_param: { type: 'tree' },
	output_type: 'tree',
};

// Simplify_bedmas, but remove "remove common fraction fractors". Grade 5 math doesn't have this step.
const remove_common_fraction_factors_step = function() {
	const steps = QESolver.solvers.simplify_bedmas.steps.slice(0);

	// move removeCommonFractionFactors_step to be final step
	const CT_removeCommonFractionFactors_index = steps.findIndex(x => x.step_key === "CT_removeCommonFractionFactors");
	const removeCommonFractionFactors_step = steps.splice(CT_removeCommonFractionFactors_index, 1)[0];
	steps.push(removeCommonFractionFactors_step);
	return steps;
};
QESolver.solvers.fraction_bedmas_remove_common_fraction_factors_last = {
	desc: 'Applies BEDMAS operations to simplify an expression, but leaves cancelling out of common factors to last to demonstrate how to do so',
    initial_steps: QESolver.solvers.simplify_bedmas.initial_steps.slice(0),
	steps: remove_common_fraction_factors_step(),
	input_param: { type: 'tree' },
	output_type: 'tree',
};

QESolver.solvers.fraction_comparison = {
	desc: "Compares a list{} of 2 fractions and returns a string comparison sign (>, <, or =)",
	steps: [
		{step_key: Simplify.simplifyComparisonFractions,	desc:""},
		{step_key: "convertFractionsToSameDenominator",		desc:""},
		{step_key: "compareFractionsWithSameDenominator",	desc:""},
		{step_key: "extractComparisonSign",					desc:""} 		
	],
	input_param: { type: 'tree' }, // list{frac1, frac2}
	output_type: 'string', // >, <, or =
};

QESolver.solvers.decimal_to_decimal_fraction_then_comparison = {
	desc: "Converts a list{} of 2 decimals to fractions of same denominator, then returns a string comparison sign (>, <, or =)",	
	steps: [
		{step_key: Simplify.simplifyComparisonDecimalsToDecimalFraction,	desc:"Converting decimal to decimal fraction"},
		{step_key: "convertFractionsToSameDenominator",		desc:""},
		{step_key: "compareFractionsWithSameDenominator",	desc:""},
		{step_key: "extractComparisonSign",					desc:""} 		
	],
	input_param: { type: 'tree' }, // list{rational1, rational2}
	output_type: 'string', // >, <, or =
};

QESolver.solvers.fraction_to_decimal_then_comparison = {
	desc: "Converts a list{} of 2 fractions to decimals, then returns a string comparison sign (>, <, or =)",	
	steps: [ 
		{step_key: Simplify.simplifyDecimalFractions, desc: "Converting fractions to decimal"},
		{step_key: "compareDecimals",				 	desc: ""},
		{step_key: "extractComparisonSign",				desc:""}
	],
	input_param: { type: 'tree' }, // list{frac1, frac2}
	output_type: 'string', // >, <, or =
};

QESolver.solvers.convert_mixed_radical_to_entire = {
	desc: 'Moves the coefficient of a radical to within the radical.',
    initial_steps: QESolver.solvers.simplify_bedmas.initial_steps.slice(0),
	steps: QESolver.solvers.simplify_bedmas.steps.map(function(step){
		if (step.step_key == "CT_simplifyRoot") return {step_key: "convertMixedRadicalToEntire", desc: ''};
		else return step;
	}),
	input_param: { type: 'tree' },
	output_type: 'tree',
};

QESolver.solvers.convert_radical_to_fractional_exponent = {
	desc: 'Changes a radical into a fractional exponent.',
	steps: [ {step_key: "convertRadicalToFractionalExponent", desc: ''}, ],
	input_param: { type: 'tree' },
	output_type: 'tree',
};

QESolver.solvers.convert_fractional_exponent_to_radical = {
	desc: 'Changes a fractional exponent into a radical.',
	steps: [ {step_key: "convertFractionalExponentToRadical", desc: ''}, ],
	input_param: { type: 'tree' },
	output_type: 'tree',
};
QESolver.solvers.convert_whole_number_to_roman = {
	desc: 'Converts a whole number from 1 to 3999 to a Roman numeral.',
	steps: [ {step_key: "convertWholeNumberToRoman", desc: ''}, ],
	input_param: { type: 'tree' },
	output_type: 'string',
};

const convert_simplify_bedmas_from_and_to_mixed_numbers = function(){
	let steps: SolverStepConfig[] = [ {step_key: "CT_convertMixedNumberToFraction", desc:"Turn a mixed number into a fraction"} ];
	steps = steps.concat(QESolver.solvers.simplify_bedmas.steps.slice(0));
	steps = steps.concat({step_key: "CT_convertFractionToMixedNumberSimplified", desc:"Turn a fraction into a mixed number"});
	return steps;
}
// Simplify_bedmas, but convert mixed number to/from improper fractions
QESolver.solvers.bedmas_mixed_numbers = {
	desc: 'Convert any mixed numbers to improper fractions then apply BEDMAS operations to simplify/evaluate',
	initial_steps: QESolver.solvers.simplify_bedmas.initial_steps.slice(0),
	steps: convert_simplify_bedmas_from_and_to_mixed_numbers(),
	input_param: { type: 'tree' },
	output_type: 'tree',
};

const substitute_initial_steps = function(){
	let steps: SolverStepConfig[] = [{step_key: "substituteVariableValues", desc: 'Replace one or more variables in the equation with given values (which can be expressions).'}];
	return steps.concat(QESolver.solvers.simplify_bedmas.initial_steps.slice(0));
}
QESolver.solvers.substitute_variable_values = {
	desc: 'Substitutes specified variables in an expression, then applies BEDMAS operations to simplify',
	initial_steps: substitute_initial_steps(),
	steps: QESolver.solvers.simplify_bedmas.steps.slice(0),
	input_param: {
		type: 'map',
		fields: {
			'src': { type: 'tree', clone: true },
			'<var_name>': { type: 'tree', clone: false },
		},
	},
	output_type: 'tree',
};

// Isolate variable in a comparison (equality or inequality) by moving other terms and coefficents to the other side of the comparator
QESolver.solvers.simplify_isolate_variable_in_comparison = {
	desc: "Isolate variable in a comparison (equality or inequality) by moving other terms and coefficents to the other side of the comparator",
	initial_steps: QESolver.solvers.simplify_bedmas.initial_steps.slice(0),
	steps: QESolver.solvers.simplify_bedmas.steps.concat([
		{step_key: "CT_isolateVar_AddSubtractNonVarTermToRHS", desc:""},
		{step_key: "CT_isolateVar_AddSubtractVarTermToLHS", desc:""},
		{step_key: "CT_isolateVar_MultiplyDenominatorToRHS", desc:""},
		{step_key: "CT_isolateVar_MultiplyVarDenominator", desc:""},
		{step_key: "CT_isolateVar_DivideNonVarToRHS", desc:""},
		{step_key: "CT_isolateVar_MultiplyNegativeToRHS", desc:""},
	]),
	input_param: {
		type: 'tree',
		fields: {
			'var_name': { type: 'string', clone: false },
		},
	},
	output_type: 'tree',
};

/////////////
/// Solvers related to comparison

QESolver.solvers.make_int_comparison = {
	desc: 'Compares a list containing two rational numbers and returns a string containing >, =, or <',
	steps: [ {step_key: "MakeRationalComparison", desc:"Compares a list{} of two rational numbers and returns >, =, or <"} ],
	input_param: { type: 'tree' }, // list{int1, int2}
	output_type: 'string',
};

QESolver.solvers.make_frac_comparison = {
	desc: 'Compares a list containing two fractions and returns a string containing >, =, or <',
    initial_steps: QESolver.solvers.simplify_bedmas.initial_steps.slice(0),
	// Note: MakeFracComparison internally calls frac_comparison_simplify_to_int with solveUsing()
	steps: [ {step_key: "MakeFracComparison",	desc:"Compares a list{} of two integer fractions and returns >, =, or <"} ],
	input_param: { type: 'tree' }, // list{frac1, frac2}
	output_type: 'string',
};

QESolver.solvers.sort_fractions_by_ascend = {
	desc: 'Sorts a list of rational fractions by comparing their evaluated float values',
	steps: [ {step_key: "sortFractionListByAscend",	desc:"Sort factions by ascend"} ],
	input_param: { type: 'tree' }, // list{frac1, frac2, ...}
	output_type: 'tree', // list{frac1, frac2, ...}
};

// frac_comparison_simplify_to_int - simplifies a comparison of integer fractions by multiplying both sides by the denominators, leaving a comparison of integers
//    E.g. "1/2 < 5/7" -> "1*7 < 5*2" -> "7 < 10"
QESolver.solvers.frac_comparison_simplify_to_int = {
	desc: 'Simplifies a comparison of fractions by multiplying both sides by the denominators',
    initial_steps: QESolver.solvers.simplify_bedmas.initial_steps.slice(0),
	steps: QESolver.solvers.simplify_bedmas.steps.slice(0).concat(
		{step_key: Simplify.CT_simplifyComparisonFracToInts, desc:"Simplify a comparison of fractions by multiplying both sides by the denominators"}
	),
	input_param: { type: 'tree' }, // frac comparison
	output_type: 'tree', // int comparison
};

QESolver.solvers.frac_comparison_evaluate = {
	desc: 'Converts a list{} of 2 fractions to Q-values, then returns a string comparison sign (>, <, or =)',
    initial_steps: QESolver.solvers.simplify_bedmas.initial_steps.slice(0),
	steps: [ {step_key: "FracComparisonEvaluate", desc:"Determine if a comparison or inequality is true"} ],
	input_param: { type: 'tree' }, // frac comparison
	output_type: 'string', // >, <, or =
};

